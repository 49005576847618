import { SelectProps } from "antd";
import { TAdminUserRole } from "@Component/Admin/types";

export const getUserSelectProps = (
  loading: boolean,
  usersOptionList: Array<{ label: string; value: string }>,
  role: TAdminUserRole
) => {
  if (usersOptionList && usersOptionList?.length === 1) return;
  const userSelectProps: SelectProps = {
    mode: "multiple",
    loading: loading,
    style: { width: "100%" },
    placeholder: `Select ${role}`,
    filterOption: false,
    options: usersOptionList,
    allowClear: true,
    size: "middle",
    maxTagCount: 2
  };
  return userSelectProps;
};
