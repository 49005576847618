import { FC, useState } from "react";
import {
  Col,
  Input,
  InputNumber,
  Row,
  List,
  Select,
  Form,
  Flex,
  Switch,
  Dropdown,
  Tooltip
} from "antd";
import { useAntToken } from "@hooks/common/useAntToken";
import { reviewInvoiceStyles } from "./styles";
import { MoreOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import CommonFormLabel from "@Component/Common/CommonFormLabel";
import TextArea from "antd/es/input/TextArea";
import { INVOICE_PENDING } from "@utils/constants";
import { TGlCode, TUnit, ILineItem, TAlternativeGlCode } from "@Component/Common/types";
import { generateGlCodeLabel } from "@Component/Common/utils";
import { getMoreOptions, previousValueTooltip } from "./utils";
import RejectLineItemModal from "./RejectLineItemModal";
import InvoiceLineItemStatusIcon from "@Component/Common/ReviewInvoice/InvoiceLineItemStatusIcon";

interface ILineItemsListProps {
  preLineItems: any;
  lineItems: ILineItem[];
  glCodes?: TGlCode[];
  handleLineItemDelete?: (value: number) => void;
  editGLCodes?: boolean;
  handleEditLineItem?: (
    lineItemId: string,
    lineItemChangeKey: string,
    lineItemValue: unknown
  ) => void;
  readonlyView?: boolean;
  rejectedInvoiceView?: boolean;
  propertyManagerView?: boolean;
  units: TUnit[];
  propertyType: string;
  invoiceApprovalStatus: string;
  isRegionalManager?: boolean;
}

const LineItemsList: FC<ILineItemsListProps> = (props) => {
  const { styles } = reviewInvoiceStyles();
  const { token } = useAntToken();

  const getGlCodeSelectOptions = (glCodes: TAlternativeGlCode[], color: string) => {
    return glCodes
      ?.sort((a, b) =>
        a.glCode.reference > b.glCode.reference
          ? 1
          : b.glCode.reference > a.glCode.reference
          ? -1
          : 0
      )
      ?.filter((g: TAlternativeGlCode) => g.colorCode === color)
      ?.map((g: TAlternativeGlCode) => {
        const color =
          g.colorCode === "green"
            ? "green"
            : g.colorCode === "yellow"
            ? "orange"
            : token.colorTextSecondary;
        return (
          <Select.Option
            key={g.glCode.id}
            value={g.glCode.id}
            label={generateGlCodeLabel(g.glCode.name, g.glCode.reference)}>
            <div style={{ color }}>{generateGlCodeLabel(g.glCode.name, g.glCode.reference)}</div>
          </Select.Option>
        );
      });
  };

  const GlCodeSelectDropdown = (props: { d: ILineItem; isEditingGlCodesDisabled?: boolean }) => {
    const { d, isEditingGlCodesDisabled } = props;
    return (
      <>
        {d.alternativeGlCodes ? (
          <Select
            style={{ width: "100%" }}
            showSearch
            optionFilterProp="label"
            value={generateGlCodeLabel(d.gl.name, d.gl.reference)}
            className={styles["ant-select-override"]}
            disabled={isEditingGlCodesDisabled}
            onChange={(value) => {
              const filteredGlCodes =
                d.alternativeGlCodes?.filter(
                  (g: TAlternativeGlCode) => g.glCode.id === Number(value)
                ) || [];
              handleEditLineItem?.(d.lineItemShortId, "gl", filteredGlCodes[0]?.glCode);
            }}>
            {[
              ...getGlCodeSelectOptions(d.alternativeGlCodes, "green"),
              ...getGlCodeSelectOptions(d.alternativeGlCodes, "yellow"),
              ...getGlCodeSelectOptions(d.alternativeGlCodes, "grey")
            ]}
          </Select>
        ) : (
          <Select
            style={{ width: "100%" }}
            showSearch
            optionFilterProp="label"
            value={generateGlCodeLabel(d.gl.name, d.gl.reference)}
            className={styles["ant-select-override"]}
            disabled={isEditingGlCodesDisabled}
            onChange={(value) => {
              const filteredGlCodes = glCodes?.filter((g) => g.id === Number(value)) || [];
              handleEditLineItem?.(d.lineItemShortId, "gl", filteredGlCodes[0]);
            }}>
            {glCodes?.map((g) => {
              const color = token.colorTextSecondary;
              return (
                <Select.Option key={g.id} value={g.id} label={g.name}>
                  <div style={{ color: color }}>{generateGlCodeLabel(g.name, g.reference)}</div>
                </Select.Option>
              );
            })}
          </Select>
        )}
      </>
    );
  };

  const {
    preLineItems,
    lineItems,
    glCodes,
    handleLineItemDelete,
    editGLCodes,
    handleEditLineItem,
    readonlyView,
    propertyType,
    units,
    invoiceApprovalStatus,
    propertyManagerView,
    isRegionalManager,
    rejectedInvoiceView
  } = props;
  const commonFormItemStyes = {
    border: `1px solid ${token.colorBorder}`,
    height: token.controlHeightLG,
    paddingTop: token.paddingXXS
  };
  const commontInputStyles = {
    border: 0,
    borderRadius: 0,
    backgroundColor: "transparent",
    height: "inherit"
  };

  const commonInfoStyles = {
    marginLeft: token.marginXS
  };

  const [isRejectionModalOpen, setIsRejectionModalOpen] = useState<boolean>(false);
  const [selectedLineItemForRejectionId, setSelectedLineItemForRejectionId] = useState<string>("");
  const handleRejectionModalHide = () => {
    setSelectedLineItemForRejectionId("");
    setIsRejectionModalOpen(false);
  };

  const handleRejectionModalOpen = (lineItemShortId: string) => {
    setSelectedLineItemForRejectionId(lineItemShortId);
    setIsRejectionModalOpen(true);
  };

  const isEditingGlCodesDisabled = propertyManagerView
    ? invoiceApprovalStatus !== INVOICE_PENDING
    : !editGLCodes || readonlyView;
  return (
    <>
      <List
        className={styles["invoice-list"]}
        header={false}
        footer={false}
        bordered={false}
        dataSource={lineItems}
        pagination={false}
        renderItem={(item: ILineItem) => {
          let isChange = false;
          const previous = preLineItems?.filter((line: any) => line?.lineId === item.lineId)[0];
          if (previous && Object.keys(previous).length > 1) {
            isChange = true;
          }
          if (preLineItems && preLineItems.length > 0 && !previous) {
            isChange = true;
          }
          return (
            <List.Item
              style={{
                marginTop: 20,
                border: isChange ? `4px solid ${token.colorWarningBorder}` : "none",
                padding: isChange ? 12 : 0
              }}>
              <Row justify="space-between">
                <CommonFormLabel>
                  Description{" "}
                  {isChange && (
                    <Tooltip title={`Previous Values: ${previousValueTooltip(previous)}`}>
                      <ExclamationCircleOutlined style={commonInfoStyles} />
                    </Tooltip>
                  )}
                </CommonFormLabel>
                <Col>
                  {propertyManagerView && !isRegionalManager ? (
                    <Flex align="center" justify="center" style={{ height: "45%" }}>
                      <Col
                        style={{
                          marginLeft: "auto",
                          // marginRight: token.marginLG,
                          cursor: "pointer",
                          width: "28px",
                          border: `1px solid ${token.colorBorder}`,
                          boxShadow: token.boxShadow,
                          borderRadius: token.borderRadiusSM,
                          // position: "absolute",
                          right: 0
                        }}>
                        <Row align="middle" justify="center">
                          <Col>
                            <Dropdown
                              menu={{
                                items: getMoreOptions({
                                  handleEditLineItem,
                                  lineItemShortId: item.lineItemShortId,
                                  handleRejectionModalOpen,
                                  handleLineItemDelete,
                                  key: item.key,
                                  isDeletable: false
                                })
                              }}
                              trigger={["click", "contextMenu"]}
                              placement="bottomRight">
                              <a
                                onClick={(e) => e.preventDefault()}
                                style={{
                                  fontSize: token.fontSizeLG,
                                  fontWeight: token.fontWeightStrong,
                                  color: token.colorText
                                }}>
                                <MoreOutlined />
                              </a>
                            </Dropdown>
                          </Col>
                        </Row>
                      </Col>
                    </Flex>
                  ) : rejectedInvoiceView && !isRegionalManager ? (
                    <Flex align="center" justify="center" style={{ height: "45%" }}>
                      <Col
                        style={{
                          marginLeft: "auto",
                          // marginRight: token.marginLG,
                          cursor: "pointer",
                          width: "28px",
                          border: `1px solid ${token.colorBorder}`,
                          boxShadow: token.boxShadow,
                          borderRadius: token.borderRadiusSM,
                          // position: "absolute",
                          right: 0
                        }}>
                        <Row align="middle" justify="center">
                          <Col>
                            <Dropdown
                              menu={{
                                items: getMoreOptions({
                                  handleEditLineItem,
                                  lineItemShortId: item.lineItemShortId,
                                  handleRejectionModalOpen,
                                  handleLineItemDelete,
                                  key: item.key,
                                  isDeletable: true
                                })
                              }}
                              trigger={["click", "contextMenu"]}
                              placement="bottomRight">
                              <a
                                onClick={(e) => e.preventDefault()}
                                style={{
                                  fontSize: token.fontSizeLG,
                                  fontWeight: token.fontWeightStrong,
                                  color: token.colorText
                                }}>
                                <MoreOutlined />
                              </a>
                            </Dropdown>
                          </Col>
                        </Row>
                      </Col>
                    </Flex>
                  ) : !readonlyView && !isRegionalManager ? (
                    <Flex align="center" justify="center" style={{ height: "45%" }}>
                      <Col
                        style={{
                          marginLeft: "auto",
                          marginRight: token.marginLG,
                          cursor: "pointer",
                          width: "28px",
                          border: `1px solid ${token.colorBorder}`,
                          boxShadow: token.boxShadow,
                          borderRadius: token.borderRadiusSM,
                          // position: "absolute",
                          right: 0
                        }}>
                        <Row align="middle" justify="center">
                          <Col>
                            <Dropdown
                              menu={{
                                items: getMoreOptions({
                                  handleEditLineItem,
                                  lineItemShortId: item.lineItemShortId,
                                  handleRejectionModalOpen,
                                  handleLineItemDelete,
                                  key: item.key,
                                  isDeletable: true
                                })
                              }}
                              trigger={["click", "contextMenu"]}
                              placement="bottomRight">
                              <a
                                onClick={(e) => e.preventDefault()}
                                style={{
                                  fontSize: token.fontSizeLG,
                                  fontWeight: token.fontWeightStrong,
                                  color: token.colorText
                                }}>
                                <MoreOutlined />
                              </a>
                            </Dropdown>
                          </Col>
                        </Row>
                      </Col>
                    </Flex>
                  ) : (
                    <></>
                  )}
                </Col>
                <Col span={24}>
                  <Form.Item>
                    <TextArea
                      disabled={isEditingGlCodesDisabled}
                      defaultValue={item.description}
                      style={{
                        borderRadius: 0,
                        borderColor: token.colorBorderSecondary,
                        height: 100,
                        resize: "none",
                        paddingLeft: token.paddingMD,
                        backgroundColor: token.colorBgBase
                      }}
                      value={item.internalDescription}
                      name="description"
                      onChange={(e) =>
                        handleEditLineItem?.(item.lineItemShortId, "description", e.target.value)
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={24} style={{ marginBottom: token.marginXS }}>
                  <CommonFormLabel>Gl Code</CommonFormLabel>
                </Col>
                <Col
                  span={24}
                  style={{
                    marginBottom: token.marginXS,
                    backgroundColor: token.colorBgBase
                  }}>
                  <GlCodeSelectDropdown
                    d={item}
                    isEditingGlCodesDisabled={isEditingGlCodesDisabled}
                  />
                </Col>
                <Col span={24}>
                  <Col span={24}>
                    <Flex
                      justify="space-between"
                      style={{
                        width: "100%"
                      }}>
                      <Col style={{ width: "48%" }}>
                        <Col span={24} style={{ marginBottom: token.marginXS }}>
                          <CommonFormLabel>Pre Tax Amount</CommonFormLabel>
                        </Col>
                        <Col
                          span={24}
                          style={{
                            backgroundColor: token.colorBgBase
                          }}>
                          <Form.Item
                            style={{ ...commonFormItemStyes, textAlign: "right" }}
                            className={styles["ant-table-overider"]}>
                            <InputNumber
                              controls={false}
                              disabled={readonlyView || propertyManagerView}
                              precision={2}
                              style={{ ...commontInputStyles }}
                              value={
                                Number(item.pricePerUnit)
                                  ?.toFixed(2)
                                  ?.toString()
                                  ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0
                              }
                              name="pricePerUnit"
                              onChange={(value) => {
                                if (isNaN(Number(value?.toString()?.replace(".", "")))) {
                                  return;
                                }
                                handleEditLineItem?.(
                                  item.lineItemShortId,
                                  "pricePerUnit",
                                  value?.toString()
                                );
                              }}
                            />
                          </Form.Item>
                        </Col>
                      </Col>
                      <Col style={{ width: "48%" }}>
                        <Col span={24} style={{ marginBottom: token.marginXS }}>
                          <CommonFormLabel>Post Tax Amount</CommonFormLabel>
                        </Col>
                        <Col span={24}>
                          <Form.Item style={{ ...commonFormItemStyes }}>
                            <Input
                              disabled={true}
                              style={{ ...commontInputStyles, textAlign: "end" }}
                              defaultValue={item.postTaxAmount}
                              value={Number(item.postTaxAmount)
                                ?.toFixed(2)
                                ?.toString()
                                ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              name="postTaxAmount"
                              onChange={(e) =>
                                handleEditLineItem?.(
                                  item.lineItemShortId,
                                  "postTaxAmount",
                                  e.target.value
                                )
                              }
                            />
                          </Form.Item>
                        </Col>
                      </Col>
                    </Flex>
                  </Col>
                  <Col span={24}>
                    <Flex
                      justify="space-between"
                      style={{
                        width: "100%"
                      }}>
                      <Col style={{ width: "48%" }}>
                        <Col span={24} style={{ marginBottom: token.marginXS }}>
                          <CommonFormLabel>Taxable</CommonFormLabel>
                        </Col>
                        <Col
                          span={24}
                          style={{
                            backgroundColor: token.colorBgBase
                          }}>
                          <Form.Item style={commonFormItemStyes}>
                            <Flex
                              align="start"
                              justify="start"
                              style={{ paddingLeft: token.paddingSM }}>
                              <Switch
                                disabled={readonlyView || propertyManagerView}
                                defaultValue={item.taxable}
                                value={item.taxable}
                                onChange={(checked) =>
                                  handleEditLineItem?.(item.lineItemShortId, "taxable", checked)
                                }
                              />
                            </Flex>
                          </Form.Item>
                        </Col>
                      </Col>
                      {propertyType === "UnitProperty" && (
                        <Col style={{ width: "48%" }}>
                          <Col span={24} style={{ marginBottom: token.marginXS }}>
                            <CommonFormLabel>Unit</CommonFormLabel>
                          </Col>
                          <Col span={24}>
                            <Select
                              showSearch
                              optionFilterProp="label"
                              defaultValue={item?.unit?.id || "None"}
                              value={item?.unit?.id || "None"}
                              style={{ width: "100%" }}
                              className={styles["ant-select-override"]}
                              disabled={isEditingGlCodesDisabled}
                              onChange={(value) => {
                                () => handleEditLineItem?.(item.lineItemShortId, "unit", value);
                              }}
                              options={units?.map((u: { id: number; name: string }) => {
                                return {
                                  value: u.id,
                                  label: u.name
                                };
                              })}
                            />
                          </Col>
                        </Col>
                      )}
                    </Flex>
                    {(readonlyView || propertyManagerView) && (
                      <Col>
                        <Col span={24}>
                          <CommonFormLabel>Status</CommonFormLabel>
                        </Col>
                        <Col span={24} style={{ paddingTop: 8, paddingLeft: 10 }}>
                          <InvoiceLineItemStatusIcon
                            approvalStatus={item?.approvalStatus}
                            tooltipTitle={item?.rejectionReason}
                            propertyManagerView={propertyManagerView}
                          />
                        </Col>
                      </Col>
                    )}
                  </Col>
                </Col>
              </Row>
            </List.Item>
          );
        }}
      />
      <RejectLineItemModal
        handleRejectionModalHide={handleRejectionModalHide}
        isRejectionModalOpen={isRejectionModalOpen}
        lineItemShortId={selectedLineItemForRejectionId}
        handleEditLineItem={handleEditLineItem}
      />
    </>
  );
};

export default LineItemsList;
