import { FC } from "react";
import {
  Col,
  Input,
  Row,
  List,
  Select,
  Modal,
  Form,
  Flex,
  Switch,
  Dropdown,
  InputNumber
} from "antd";
import { useAntToken } from "@hooks/common/useAntToken";
import { invoiceStyles } from "./styles";
import { ExclamationCircleOutlined, CloseCircleOutlined, MoreOutlined } from "@ant-design/icons";
import CommonFormLabel from "@Component/Common/CommonFormLabel";
import TextArea from "antd/es/input/TextArea";
import { TGlCode, TUnit, ILineItem, TAlternativeGlCode } from "@Component/Common/types";
import { generateGlCodeLabel } from "@Component/Common/utils";

interface ILineItemsListProps {
  lineItems: ILineItem[];
  glCodes?: TGlCode[];
  handleLineItemDelete?: (value: number) => void;
  editGLCodes?: boolean;
  handleEditLineItem?: (
    lineItemId: string,
    lineItemChangeKey: string,
    lineItemValue: unknown
  ) => void;
  units: TUnit[];
  propertyType: string;
  readOnly: boolean;
}

const { confirm } = Modal;

const LineItemsList: FC<ILineItemsListProps> = (props) => {
  const { styles } = invoiceStyles();
  const { token } = useAntToken();
  const { readOnly } = props;
  const GlCodeSelectDropdown = (props: { d: ILineItem; isEditingGlCodesDisabled?: boolean }) => {
    const { d, isEditingGlCodesDisabled } = props;
    return (
      <>
        {d.alternativeGlCodes ? (
          <Select
            style={{ width: "100%" }}
            showSearch
            value={generateGlCodeLabel(d.gl.name, d.gl.reference)}
            className={styles["ant-select-override"]}
            disabled={isEditingGlCodesDisabled || readOnly}
            onChange={(value) => {
              const filteredGlCodes =
                d.alternativeGlCodes?.filter(
                  (g: TAlternativeGlCode) => g.glCode.id === Number(value)
                ) || [];
              handleEditLineItem?.(d.lineItemShortId, "gl", filteredGlCodes[0]?.glCode);
            }}>
            {d.alternativeGlCodes
              ?.sort((a, b) =>
                a.glCode.reference > b.glCode.reference
                  ? 1
                  : b.glCode.reference > a.glCode.reference
                  ? -1
                  : 0
              )
              .map((g: TAlternativeGlCode) => {
                const color =
                  g.colorCode === "green"
                    ? "green"
                    : g.colorCode === "yellow"
                    ? "orange"
                    : token.colorTextSecondary;
                return (
                  <Select.Option
                    key={g.glCode.id}
                    value={g.glCode.id}
                    label={generateGlCodeLabel(g.glCode.name, g.glCode.reference)}>
                    <div style={{ color: color }}>
                      {generateGlCodeLabel(g.glCode.name, g.glCode.reference)}
                    </div>
                  </Select.Option>
                );
              })}
          </Select>
        ) : (
          <Select
            style={{ width: "100%" }}
            showSearch
            value={generateGlCodeLabel(d.gl.name, d.gl.reference)}
            className={styles["ant-select-override"]}
            disabled={isEditingGlCodesDisabled || readOnly}
            onChange={(value) => {
              const filteredGlCodes = glCodes?.filter((g) => g.id === Number(value)) || [];
              handleEditLineItem?.(d.lineItemShortId, "gl", filteredGlCodes[0]);
            }}>
            {glCodes
              ?.sort((a, b) => (a.reference > b.reference ? 1 : b.reference > a.reference ? -1 : 0))
              .map((g) => {
                const color = token.colorTextSecondary;
                return (
                  <Select.Option
                    key={g.id}
                    value={g.id}
                    label={generateGlCodeLabel(g.name, g.reference)}>
                    <div style={{ color: color }}>{generateGlCodeLabel(g.name, g.reference)}</div>
                  </Select.Option>
                );
              })}
          </Select>
        )}
      </>
    );
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const showConfirm = (onOk: any) => {
    confirm({
      title: `Are You Sure?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      cancelText: "No",
      onOk: onOk,
      className: styles["navarino-confirm"]
    });
  };
  const { lineItems, glCodes, handleLineItemDelete, handleEditLineItem, propertyType, units } =
    props;
  const commonFormItemStyes = {
    border: `1px solid ${token.colorBorder}`,
    height: token.controlHeightLG,
    paddingTop: token.paddingXXS
  };
  const commontInputStyles = {
    border: 0,
    borderRadius: 0,
    backgroundColor: "transparent",
    height: "inherit"
  };

  const clonedLineItems = structuredClone(lineItems);
  return (
    <List
      className={styles["invoice-list"]}
      header={false}
      footer={false}
      bordered={false}
      dataSource={clonedLineItems}
      pagination={false}
      renderItem={(item: ILineItem, key: number) => {
        return (
          <List.Item
            style={{
              marginTop: 0,
              marginBottom: 20
            }}>
            <Row>
              <Col span={24}>
                <Flex align="center" justify="center" style={{ height: "45%" }}>
                  <CommonFormLabel>Description</CommonFormLabel>

                  {!readOnly && (
                    <Col
                      style={{
                        marginLeft: "auto",
                        width: "28px",
                        border: `1px solid ${token.colorBorder}`,
                        boxShadow: token.boxShadow,
                        borderRadius: token.borderRadiusSM,
                        right: 0
                      }}>
                      <Row align="middle" justify="center">
                        <Col>
                          <Dropdown
                            menu={{
                              items: [
                                {
                                  label: "Delete",
                                  key: "3",
                                  icon: <CloseCircleOutlined />,
                                  onClick: () => showConfirm(() => handleLineItemDelete?.(key))
                                }
                              ]
                            }}
                            trigger={["click", "contextMenu"]}
                            placement="bottomRight">
                            <a
                              onClick={(e) => e.preventDefault()}
                              style={{
                                fontSize: token.fontSizeLG,
                                fontWeight: token.fontWeightStrong,
                                color: token.colorText
                              }}>
                              <MoreOutlined />
                            </a>
                          </Dropdown>
                        </Col>
                      </Row>
                    </Col>
                  )}
                </Flex>
              </Col>
              <Col span={24}>
                <Form.Item>
                  <TextArea
                    disabled={readOnly}
                    placeholder={"Description"}
                    defaultValue={item.description}
                    style={{
                      borderRadius: 0,
                      backgroundColor: token.colorBgBase,
                      borderColor: token.colorBorderSecondary,
                      height: 100,
                      resize: "none",
                      paddingLeft: token.paddingMD
                    }}
                    value={item.description}
                    name="description"
                    onChange={(e) =>
                      handleEditLineItem?.(item.lineItemShortId, "description", e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={24} style={{ marginBottom: token.marginXS }}>
                <CommonFormLabel>Gl Code</CommonFormLabel>
              </Col>
              <Col span={24} style={{ marginBottom: token.marginXS }}>
                <GlCodeSelectDropdown d={item} isEditingGlCodesDisabled={false} />
              </Col>
              <Col span={24}>
                <Col span={24}>
                  <Flex
                    justify="space-between"
                    style={{
                      width: "100%"
                    }}>
                    <Col style={{ width: "48%" }}>
                      <Col span={24} style={{ marginBottom: token.marginXS }}>
                        <CommonFormLabel>Pre Tax Amount</CommonFormLabel>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          style={{ ...commonFormItemStyes, textAlign: "right" }}
                          className={styles["ant-table-overider"]}>
                          <InputNumber
                            type={"number"}
                            controls={false}
                            disabled={readOnly}
                            precision={2}
                            style={{ ...commontInputStyles, textAlign: "right" }}
                            value={item.pricePerUnit || 0}
                            name="pricePerUnit"
                            onChange={(value) => {
                              if (isNaN(Number(value?.toString()?.replace(".", "")))) {
                                return;
                              }
                              handleEditLineItem?.(
                                item.lineItemShortId,
                                "pricePerUnit",
                                value?.toString()
                              );
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Col>
                    <Col style={{ width: "48%" }}>
                      <Col span={24} style={{ marginBottom: token.marginXS }}>
                        <CommonFormLabel>Post Tax Amount</CommonFormLabel>
                      </Col>
                      <Col span={24}>
                        <Form.Item style={{ ...commonFormItemStyes, textAlign: "right" }}>
                          <Input
                            disabled={true}
                            style={{ ...commontInputStyles, textAlign: "right" }}
                            defaultValue={item.postTaxAmount}
                            value={Number(item.postTaxAmount).toFixed(2)}
                            name="postTaxAmount"
                            onChange={(e) =>
                              handleEditLineItem?.(
                                item.lineItemShortId,
                                "postTaxAmount",
                                e.target.value
                              )
                            }
                          />
                        </Form.Item>
                      </Col>
                    </Col>
                  </Flex>
                </Col>
                <Col span={24}>
                  <Flex
                    justify="space-between"
                    style={{
                      width: "100%"
                    }}>
                    <Col style={{ width: "48%" }}>
                      <Col span={24} style={{ marginBottom: token.marginXS }}>
                        <CommonFormLabel>Taxable</CommonFormLabel>
                      </Col>
                      <Col span={24}>
                        <Form.Item style={commonFormItemStyes}>
                          <Flex
                            align="start"
                            justify="start"
                            style={{ paddingLeft: token.paddingSM }}>
                            <Switch
                              disabled={readOnly}
                              defaultValue={item.taxable}
                              value={item.taxable}
                              onChange={(checked) =>
                                handleEditLineItem?.(item.lineItemShortId, "taxable", checked)
                              }
                            />
                          </Flex>
                        </Form.Item>
                      </Col>
                    </Col>
                    {propertyType === "UnitProperty" && (
                      <Col style={{ width: "48%" }}>
                        <Col span={24} style={{ marginBottom: token.marginXS }}>
                          <CommonFormLabel>Unit</CommonFormLabel>
                        </Col>
                        <Col span={24}>
                          <Select
                            showSearch
                            optionFilterProp="label"
                            disabled={readOnly}
                            defaultValue={"None"}
                            value={item?.unit?.id || "None"}
                            style={{ width: "100%" }}
                            className={styles["ant-select-override"]}
                            onChange={(value) => {
                              () => handleEditLineItem?.(item.lineItemShortId, "unit", value);
                            }}
                            options={units?.map((u: { id: number; name: string }) => {
                              return {
                                value: u.id,
                                label: u.name
                              };
                            })}
                          />
                        </Col>
                      </Col>
                    )}
                  </Flex>
                </Col>
              </Col>
            </Row>
          </List.Item>
        );
      }}
    />
  );
};

export default LineItemsList;
