import { FC } from "react";
import { Col, Divider, Flex, Form, InputNumber, Row, Typography } from "antd";
import { useAntToken } from "@hooks/common/useAntToken";
import formatter from "@utils/formatter";
import { useBreakpoints } from "@hooks/common/useBreakPoints";
import { commonAntStyles } from "@Component/Common/styles";
interface IInvoiceContentFooterProps {
  totalTaxAmount?: number;
  invoiceTotal?: number;
  onTaxAmountChange?: (e: string | 0 | null) => void;
  readonlyView?: boolean;
  propertyManagerView?: boolean;
}

const InvoiceContentFooter: FC<IInvoiceContentFooterProps> = (props) => {
  const { token } = useAntToken();
  const { isScreenXS } = useBreakpoints();
  const { styles: commonStyles } = commonAntStyles();
  const { totalTaxAmount, onTaxAmountChange, invoiceTotal, readonlyView, propertyManagerView } =
    props;
  const commonInputStyles = {
    border: 0,
    borderRadius: 0,
    backgroundColor: token.colorBgBase
  };
  const commonFormItemStyes = {
    border: `1px solid ${token.colorBorder}`
  };
  return (
    <Row justify="end" style={{ marginTop: !readonlyView ? token.marginSM : token.marginXL }}>
      <Flex
        justify="flex-start"
        align="middle"
        style={{
          padding: token.paddingLG,
          width: 300,
          paddingTop: isScreenXS ? token.paddingLG : 0,
          paddingBottom: isScreenXS ? token.paddingLG : token.paddingSM
        }}
        vertical>
        <Flex
          justify="space-between"
          align={"bottom"}
          style={{ height: "40px" }}
          className={commonStyles["ant-input-override"]}>
          <Col style={{ marginTop: token.marginXXS }}>
            <Typography.Text
              style={{
                color: token.colorTextBase,
                fontSize: token.fontSizeLG,
                fontWeight: token.fontWeightStrong
              }}>
              Tax
            </Typography.Text>
          </Col>
          <Col>
            <Form.Item
              style={{
                ...commonFormItemStyes,
                height: "28px"
              }}>
              <InputNumber
                disabled={readonlyView || propertyManagerView}
                controls={false}
                precision={2}
                style={{
                  ...commonInputStyles,
                  width: "100px",
                  marginBottom: token.marginXS,
                  height: "26px",
                  textAlign: "right"
                }}
                placeholder="Total Tax"
                value={Number(totalTaxAmount)
                  ?.toFixed(2)
                  ?.toString()
                  ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                name="totalTaxAmount"
                onChange={onTaxAmountChange}
              />
            </Form.Item>
          </Col>
        </Flex>
        <Divider style={{ marginTop: 0, marginBottom: token.marginSM }} />
        <Row justify="space-between">
          <Col>
            <Typography.Text
              style={{
                color: token.colorTextBase,
                fontSize: 18,
                fontWeight: token.fontWeightStrong
              }}>
              Total Amount
            </Typography.Text>
          </Col>
          <Col>
            <Typography.Text
              style={{
                color: token.colorTextBase,
                fontSize: token.fontSizeLG,
                fontWeight: token.fontWeightStrong
              }}>
              {formatter.format(invoiceTotal as number)}
            </Typography.Text>
          </Col>
        </Row>
      </Flex>
    </Row>
  );
};

export default InvoiceContentFooter;
