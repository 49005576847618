import { TUnit, ILineItem } from "@Component/Common/types";
import { formatToPrecession } from "@utils/formatter";

export const getInvoiceTotal = (lineItems: Array<ILineItem>) => {
  let total = 0;
  lineItems.forEach((item) => {
    total += Number(item.postTaxAmount);
  });
  return total;
};

export const getUpdatedLineItemsOnEditing = (
  lineItemId: string,
  lineItemChangeKey: string,
  lineItemValue: unknown,
  lineItems: Array<ILineItem>,
  totalTaxAmount: number,
  units: TUnit[]
) => {
  let copiedLineItems = [...lineItems];
  const objIndex = copiedLineItems.findIndex((obj) => obj.lineItemShortId === lineItemId);

  if (lineItemChangeKey === "taxable") {
    copiedLineItems[objIndex] = {
      ...copiedLineItems[objIndex],
      taxable: lineItemValue as boolean
    };
    const taxableLineItemsCount = copiedLineItems.filter((l) => l.taxable).length;
    if (taxableLineItemsCount > 0) {
      copiedLineItems = copiedLineItems.map((l) => {
        const pricePerUnit = l.pricePerUnit;
        return {
          ...l,
          postTaxAmount: l.taxable
            ? pricePerUnit + formatToPrecession(totalTaxAmount / taxableLineItemsCount, 4)
            : pricePerUnit
        };
      });
    } else {
      copiedLineItems[objIndex] = {
        ...copiedLineItems[objIndex],
        taxable: lineItemValue as boolean,
        postTaxAmount: Number(copiedLineItems[objIndex].pricePerUnit)
      };
    }
  } else if (lineItemChangeKey === "pricePerUnit") {
    copiedLineItems[objIndex] = <ILineItem>{
      ...copiedLineItems[objIndex],
      pricePerUnit: Number(lineItemValue),
      preTaxAmount: Number(lineItemValue)
    };
    const taxableLineItemsCount = copiedLineItems.filter((l) => l.taxable).length;
    if (taxableLineItemsCount > 0) {
      copiedLineItems = copiedLineItems.map((l) => {
        const pricePerUnit = Number(l.pricePerUnit);
        return {
          ...l,
          postTaxAmount: l.taxable
            ? pricePerUnit + formatToPrecession(totalTaxAmount / taxableLineItemsCount, 4)
            : pricePerUnit
        };
      });
    } else {
      copiedLineItems[objIndex] = {
        ...copiedLineItems[objIndex],
        postTaxAmount: Number(lineItemValue)
      };
    }
  } else if (lineItemChangeKey === "unit") {
    const filteredUnit = units.filter((u: { id: number; name: string }) => u.id === lineItemValue);
    copiedLineItems[objIndex] = {
      ...copiedLineItems[objIndex],
      unit: filteredUnit[0]
    };
  } else {
    copiedLineItems[objIndex] = {
      ...copiedLineItems[objIndex],
      [lineItemChangeKey]: lineItemValue
    };
  }
  return copiedLineItems;
};
