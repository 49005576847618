import { createStyles } from "antd-style";

export const invoiceStyles = createStyles(({ token, css }) => ({
  "ant-table-overider": {
    ".ant-table": css`
      background: ${token.colorBgBase} !important;
      border-radius: 0px !important;
      scroll-x: hidden !important;
    `,
    ".ant-table-thead>tr>.ant-table-cell": css`
      padding-bottom: 20px !important;
    `,
    ".ant-table-cell": css`
      border: 0px !important;
      margin-bottom: 0 !important;
      padding-bottom: 0px !important;
    `,
    "table>tbody>tr:last-child >*:last-child": css`
      border-bottom-right-radius: 0px !important;
    `,
    "table>tbody>tr:last-child >*:first-child": css`
      border-bottom-left-radius: 0px !important;
    `,
    ".ant-table-cell::before": css`
      background-color: ${token.colorBgBase} !important;
    `,
    ".ant-input-disabled": css`
      color: ${token.colorText} !important;
      opacity: 0.7 !important;
    `,
    ".ant-input-number:focus-within": css`
      box-shadow: none;
    `,
    ".ant-input-number": css`
      max-width: 100%;
    `
  },
  "ant-select-header-override": {
    ".ant-select-selector": css`
      background-color: inherit !important;
      color: #ffffff !important;
      border: 1px solid #ffffff !important;
      border-radius: 0px !important;
    `,
    ".ant-select-arrow": css`
      color: #ffffff;
      font-size: ${token.fontSize}px !important;
    `,
    ".ant-select-selection-placeholder": css`
      color: #ffffff;
      font-size: ${token.fontSize}px !important;
    `
  },
  "ant-select-override": {
    ".ant-select-selector": css`
      background-color: #ede7fb !important;
      border: 0px !important;
    `,
    ".ant-select-arrow": css`
      color: ${token.colorText};
      font-size: ${token.fontSize}px !important;
    `
  },
  "navarino-confirm": {
    ".ant-btn-primary": css`
      background-color: ${token.volcano7} !important;
    `
  },
  "invoice-list": {
    ".ant-list-item": css`
      background: ${token.colorBgBase} !important;
    `
  }
}));
