export const APP_BASE_URL = import.meta.env.VITE_BASE_URL + "/api/v1";
export const signInUrl = "/auth/login";
export const fetchAllVendorsUrl = "/admin/vendors/invite";
export const fetchAllVendorPropertiesUrl = "/vendor/properties";
export const fetchAllInvoiceGlCodesUrl = "/invoice/glcodes";
export const fetchAllVendorGlCodesUrl = "/vendor/glcodes";
export const inviteVendorsUrl = "/admin/vendors/invite";
export const inviteUserUrl = "/admin/users/invite";
export const reInviteUserUrl = "/admin/users/reinvite";
export const reInviteVendorsUrl = "/admin/vendors/reinvite";
export const deleteUserUrl = "/admin/user/:authUserId";
export const resetPasswordUrl = "/auth/reset-password";
export const setPasswordUrl = "/auth/set-password";
export const invoiceUploadUrl = "/vendor/upload-invoice";
export const invoiceSubmitUrl = "/vendor/submit-invoice";
export const vendorInvoicesUrl = "/vendor/invoices";
export const signDocumentUrl = "/vendor/sign-document";
export const fetchSignDocumentUrl = "/vendor/legal-form-detail";
export const userInvoicesUrl = "/user/invoices";
export const regionalManagerInvoicesUrl = "/user/regionalManager/invoices";
export const invoiceUpdateUrl = "/invoice/:invoiceId";
export const invoiceCancelUrl = "/invoice/cancel/:invoiceId";
export const fetchInvoiceByIdUrl = "/invoice/:invoiceId";
export const fetchAdminUsersUrl = "/admin/users";
export const fetchAdminUserPropertiesUrl = "/admin/properties";
export const fetchAdminUserTypePropertiesUrl = "/admin/properties/users";
export const updateUserPermissionsUrl = "/admin/users/permissions";
export const fetchUserLogsUrl = "/admin/logs";
export const fetchOriginalInvoiceUrl = "/invoice/content/:persistedFileId";
export const updateAdminSettingsUrl = "/admin/settings";
export const fetchAdminSettingsUrl = "/admin/settings";
export const fetchVendorsToBeReInvitedUrl = "/admin/vendors/reinvite";
export const fetchManagersToBeReInvitedUrl = "/admin/users/reinvite";
export const fetchInvoiceUrl = "/invoice/:invoiceId";
export const fetchUserPropertiesUrl = "/invoice/properties";
export const fetchCardsUrl = "/admin/cards";

export const fetchGlGroup = "/admin/glcategories";
export const crudGlGroup = "/admin/glcategory/:endpoint";

export const crudHomeDepot = "/admin/cards/:endpoint";
