import { useAntToken } from "@hooks/common/useAntToken";
import { useBreakpoints } from "@hooks/common/useBreakPoints";
import { FC, useEffect } from "react";
import CommonAppButton from "@Component/Common/CommonAppButton";
import { Col, Row, Typography } from "antd";
import { GrayFilterIcon, PlusIcon } from "@assets/icons/CustomIcons";

interface IDashboardHeaderProps {
  navigateToUploadInvoice: () => void;
  clearFilters: () => void;
  title?: string;
  isPropertyManager?: boolean;
  navigateToAdmin?: () => void;
}
const DashboardHeader: FC<IDashboardHeaderProps> = (props) => {
  const { isScreenXS } = useBreakpoints();
  const { token } = useAntToken();
  const {
    navigateToUploadInvoice,
    clearFilters,
    title = "Vendor Portal",
    isPropertyManager = false,
    navigateToAdmin
  } = props;

  useEffect(() => {
    return () => {
      clearFilters();
    };
  }, []);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const fontWeightNormal = token.fontWeightNormal;
  return (
    <Row justify={isScreenXS ? "space-around" : "space-between"} align="middle">
      <Col>
        <Typography.Title
          level={3}
          style={{
            color: token.colorText,
            fontSize: isScreenXS ? token.fontSizeLG : token.fontSizeHeading2,
            fontWeight: token.fontWeightStrong
          }}>
          {title}
        </Typography.Title>
      </Col>
      <Col>
        <Row justify="end" gutter={[isScreenXS ? 2 : 8, 0]}>
          {isScreenXS ? (
            <>
              <Col style={{ display: "flex", flexDirection: "row" }}>
                {isPropertyManager && (
                  <CommonAppButton
                    type="primary"
                    block
                    onClick={navigateToAdmin}
                    style={{
                      marginTop: token.marginSM,
                      height: token.controlHeight,
                      fontSize: 12,
                      boxShadow: token.boxShadow,
                      fontWeight: fontWeightNormal,
                      marginRight: 10
                    }}
                    text="Admin Portal"
                  />
                )}
                <CommonAppButton
                  type="primary"
                  block
                  icon={<GrayFilterIcon />}
                  onClick={() => clearFilters()}
                  style={{
                    marginTop: token.marginSM,
                    height: token.controlHeight,
                    fontSize: 12,
                    boxShadow: token.boxShadow,
                    fontWeight: fontWeightNormal,
                    backgroundColor: token.colorBgContainer,
                    color: token.colorPrimary
                  }}
                  text="Clear Filters"
                />
              </Col>
              {!isPropertyManager && (
                <Col>
                  <CommonAppButton
                    type="primary"
                    block
                    icon={<PlusIcon />}
                    onClick={navigateToUploadInvoice}
                    style={{
                      marginTop: token.marginSM,
                      backgroundColor: token.colorPrimary,
                      height: token.controlHeight,
                      color: token.colorTextLightSolid,
                      fontSize: 12,
                      boxShadow: "0px 2px 0px rgba(0, 0, 0, 0.02)",
                      fontWeight: fontWeightNormal
                    }}>
                    Add Invoice
                  </CommonAppButton>
                </Col>
              )}
            </>
          ) : (
            <>
              <Col style={{ display: "flex", flexDirection: "row" }}>
                {isPropertyManager && (
                  <CommonAppButton
                    type="primary"
                    block
                    onClick={navigateToAdmin}
                    style={{
                      marginTop: token.marginLG,
                      height: token.controlHeightLG,
                      fontSize: token.fontSizeLG,
                      boxShadow: token.boxShadow,
                      fontWeight: fontWeightNormal,
                      marginRight: 20
                    }}
                    text="Admin Portal"
                  />
                )}
                <CommonAppButton
                  type="primary"
                  block
                  icon={<GrayFilterIcon />}
                  onClick={() => clearFilters()}
                  style={{
                    marginTop: token.marginLG,
                    height: token.controlHeightLG,
                    fontSize: token.fontSizeLG,
                    boxShadow: token.boxShadow,
                    fontWeight: fontWeightNormal,
                    backgroundColor: token.colorBgContainer,
                    color: token.colorPrimary
                  }}
                  text="Clear Filters"
                />
              </Col>
              {!isPropertyManager && (
                <Col>
                  <CommonAppButton
                    type="primary"
                    block
                    icon={<PlusIcon />}
                    onClick={navigateToUploadInvoice}
                    style={{
                      marginTop: token.marginLG,
                      backgroundColor: token.colorPrimary,
                      height: token.controlHeightLG,
                      color: token.colorTextLightSolid,
                      fontSize: token.fontSizeLG,
                      boxShadow: "0px 2px 0px rgba(0, 0, 0, 0.02)",
                      fontWeight: fontWeightNormal
                    }}>
                    Add Invoice
                  </CommonAppButton>
                </Col>
              )}
            </>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default DashboardHeader;
