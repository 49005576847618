import axios from "axios";
import { store } from "../redux/store";
import showToast from "./showToast";

axios.interceptors.request.use((config) => {
  config.params = config.params || {};
  //eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const token = store.getState().auth.token;
  config.headers["Authorization"] = `Bearer ${token}`;
  config.headers["Access-Control-Allow-Origin"] = "*";
  return config;
});

/**
 * interceptors is like a middleware it fire betweeen request and response
 * Further more https://www.sitepoint.com/axios-beginner-guide/
 *
 */
axios.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    //Expected errors for debugging
    const expectedError =
      error.response && error.response.status >= 400 && error.response.status < 500;

    if (!expectedError) {
      showToast("Something went wrong, Please try again later", "error");
    } else {
      if (error.response.data && error.response.data.message) {
        //showToast(error.response.data.message, "error");
      }
    }
    // return promise, if u need error status in your local component as well...
    return Promise.reject(error);
  }
);

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete
};
