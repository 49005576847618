import { GrayFilterIcon, SelectedFilterIcon } from "@assets/icons/CustomIcons";
import { useAntToken } from "@hooks/common/useAntToken";
import { useBreakpoints } from "@hooks/common/useBreakPoints";
import { Col, Divider, Dropdown, MenuProps, Row, Typography } from "antd";
import { FC } from "react";

interface IDesktopListHeaderProps {
  showPaymentStatus: boolean;
  paymentStatusItems: MenuProps["items"];
  approvalStatusItems: MenuProps["items"];
  approvalStatus: string[];
  paymentStatus: string[];
}
const DesktopListHeader: FC<IDesktopListHeaderProps> = (props) => {
  const {
    showPaymentStatus,
    paymentStatusItems,
    approvalStatusItems,
    approvalStatus,
    paymentStatus
  } = props;
  const { token } = useAntToken();
  const { isScreenXL } = useBreakpoints();
  const commonColumnStyles = {
    borderRight: `1px solid ${token.colorBorder}`,
    // marginRight: token.marginLG,
    paddingLeft: 12
  };
  return (
    <>
      <Row
        align="middle"
        style={{
          width: "100%",
          // paddingLeft: token.paddingXL,
          backgroundColor: token.colorBgBase
        }}>
        <Col span={2} style={commonColumnStyles}>
          <Typography.Text
            style={{
              fontSize: !isScreenXL ? token.fontSizeSM : token.fontSizeLG,
              fontWeight: token.fontWeightStrong
            }}>
            Invoice ID
          </Typography.Text>
        </Col>
        <Col span={3} style={commonColumnStyles}>
          <Typography.Text
            style={{
              fontSize: !isScreenXL ? token.fontSizeSM : token.fontSizeLG,
              fontWeight: token.fontWeightStrong
            }}>
            Invoice No
          </Typography.Text>
        </Col>
        <Col span={3} style={commonColumnStyles}>
          <Typography.Text
            style={{
              fontSize: !isScreenXL ? token.fontSizeSM : token.fontSizeLG,
              fontWeight: token.fontWeightStrong
            }}>
            Total Amount
          </Typography.Text>
        </Col>
        <Col span={6} style={commonColumnStyles}>
          <Typography.Text
            style={{
              fontSize: !isScreenXL ? token.fontSizeSM : token.fontSizeLG,
              fontWeight: token.fontWeightStrong
            }}>
            Property
          </Typography.Text>
        </Col>
        <Col span={2} style={commonColumnStyles}>
          <Typography.Text
            style={{
              fontSize: !isScreenXL ? token.fontSizeSM : token.fontSizeLG,
              fontWeight: token.fontWeightStrong
            }}>
            Submitted
          </Typography.Text>
        </Col>
        {showPaymentStatus && (
          <Col span={3} style={commonColumnStyles}>
            <Dropdown menu={{ items: paymentStatusItems }} trigger={["click", "contextMenu"]}>
              <a onClick={(e) => e.preventDefault()}>
                {paymentStatus.length > 0 ? <SelectedFilterIcon /> : <GrayFilterIcon />}{" "}
              </a>
            </Dropdown>
            <Typography.Text
              style={{
                fontSize: !isScreenXL ? token.fontSizeSM : token.fontSizeLG,
                fontWeight: token.fontWeightStrong,
                color: token.colorText
              }}>
              Paid
            </Typography.Text>
          </Col>
        )}
        <Col span={3} style={commonColumnStyles}>
          <Dropdown menu={{ items: approvalStatusItems }} trigger={["click", "contextMenu"]}>
            <a
              onClick={(e) => e.preventDefault()}
              style={{
                fontSize: !isScreenXL ? token.fontSizeSM : token.fontSizeLG,
                fontWeight: token.fontWeightStrong,
                color: token.colorText
              }}>
              {approvalStatus.length > 0 ? <SelectedFilterIcon /> : <GrayFilterIcon />}{" "}
            </a>
          </Dropdown>
          <Typography.Text
            style={{
              fontSize: !isScreenXL ? token.fontSizeSM : token.fontSizeLG,
              fontWeight: token.fontWeightStrong,
              color: token.colorText
            }}>
            Approval
          </Typography.Text>
        </Col>
        <Col span={2} style={{ paddingLeft: 12 }}>
          <Typography.Text
            style={{
              fontSize: !isScreenXL ? token.fontSizeSM : token.fontSizeLG,
              fontWeight: token.fontWeightStrong
            }}>
            Actions
          </Typography.Text>
        </Col>
      </Row>
      <Divider style={{ marginBottom: 0, marginTop: token.marginSM }} />
    </>
  );
};

export default DesktopListHeader;
