import { FC } from "react";
import { useBreakpoints } from "@hooks/common/useBreakPoints";
import { Col, Divider, Dropdown, Flex, MenuProps, Row, Typography, theme, Select } from "antd";
import { GrayFilterIcon, SelectedFilterIcon } from "@assets/icons/CustomIcons";
import { TVendorActiveStatus, TManagerType } from "@utils/constants";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { getApprovalStatusItems, getVendorStatusItems, getManagerTypeItems } from "./utils";
import CommonAppButton from "@Component/Common/CommonAppButton";
import { TProperties } from "@Component/Admin/AdminViewUsers/types";
import { adminDashboardStyles } from "./styles";

interface IUsersListHeaderProps {
  headers: string[];
  approvalStatus: any[];
  onApprovalStatusChange: (e: CheckboxChangeEvent) => void;
  userType: string;
  vendorStatus: TVendorActiveStatus;
  onVendorStatusChange: (e: CheckboxChangeEvent) => void;
  managerType: TManagerType;
  onManagerTypeChange: (e: CheckboxChangeEvent) => void;
  propertiesFilter: any[];
  properties: TProperties[];
  handlePropertySearch: (value: []) => void;
  handlePropertySearchAction: () => void;
  rolePropertiesDataLoading: boolean;
}

const { useToken } = theme;
const UsersListHeader: FC<IUsersListHeaderProps> = (props) => {
  const {
    headers,
    approvalStatus,
    onApprovalStatusChange,
    userType,
    vendorStatus,
    managerType,
    onVendorStatusChange,
    onManagerTypeChange,
    propertiesFilter,
    properties,
    handlePropertySearch,
    handlePropertySearchAction,
    rolePropertiesDataLoading
  } = props;

  const { isScreenXL, isScreenLG } = useBreakpoints();
  const isTabOrLowerScreen = !isScreenLG;
  const { token } = useToken();
  const { styles } = adminDashboardStyles();

  const approvalStatusItems: MenuProps["items"] = getApprovalStatusItems(
    approvalStatus,
    onApprovalStatusChange
  );
  const vendorStatusItems: MenuProps["items"] = getVendorStatusItems(
    vendorStatus,
    onVendorStatusChange
  );
  const managerTypeItems: MenuProps["items"] = getManagerTypeItems(
    managerType,
    onManagerTypeChange
  );
  const commonColumnStyles = {
    borderLeft: `1px solid ${token.colorBorder}`,
    // marginLeft: token.marginXL,
    paddingLeft: token.paddingSM
  };
  const isVendor = userType === "Vendor";

  const permissionHeaderAndFilter = (headers: string[], rolePropertiesDataLoading: boolean) => {
    return (
      <>
        <Dropdown
          dropdownRender={() => {
            return (
              <Col
                style={{
                  width: 200,
                  backgroundColor: "rgb(255, 255, 255)",
                  boxShadow:
                    "0px 6px 16px 0px rgba(0, 0, 0, 0.08),0px 3px 6px -4px rgba(0, 0, 0, 0.12),0px 9px 28px 8px rgba(0, 0, 0, 0.05)",
                  padding: token.paddingXS,
                  borderRadius: token.borderRadiusLG
                }}>
                <Select
                  disabled={rolePropertiesDataLoading}
                  loading={rolePropertiesDataLoading}
                  mode="multiple"
                  style={{ width: "100%", marginBottom: token.marginSM }}
                  className={styles["ant-select-over-ride"]}
                  placeholder="Filter With Properties"
                  value={propertiesFilter as unknown as []}
                  onChange={handlePropertySearch}
                  filterSort={(optionA, optionB) => {
                    if (optionB.label && optionA.label && optionA.label > optionB.label) {
                      return 1;
                    }
                    return -1;
                  }}
                  filterOption={(input, option) => {
                    if (input.replace(/\s/g, "").length > 0) {
                      return String(option?.label)
                        ?.toLowerCase()
                        .includes(input.toLowerCase());
                    }
                    return true;
                  }}
                  options={properties.map((property: TProperties) => {
                    return {
                      value: property.id,
                      label: property.name
                    };
                  })}
                />
                <CommonAppButton
                  type="default"
                  style={{
                    background: token.colorBgContainer,
                    borderRadius: token.borderRadiusSM
                  }}
                  onClick={handlePropertySearchAction}
                  block>
                  Search
                </CommonAppButton>
              </Col>
            );
          }}
          trigger={["click"]}>
          <a onClick={(e) => e.preventDefault()}>
            {propertiesFilter.length > 0 ? <SelectedFilterIcon /> : <GrayFilterIcon />}{" "}
          </a>
        </Dropdown>
        <Typography.Text
          style={{
            fontSize: !isScreenXL ? token.fontSizeSM : token.fontSizeLG,
            fontWeight: token.fontWeightStrong
          }}>
          {headers[3]}
        </Typography.Text>
      </>
    );
  };
  return (
    <>
      <Row
        align="middle"
        justify="start"
        style={{
          width: "100%",
          paddingLeft: isTabOrLowerScreen ? 0 : token.paddingSM
        }}>
        <Col span={22}>
          <Row justify={"start"}>
            {!isTabOrLowerScreen && (
              <Col sm={4} xs={8} md={4}>
                <Typography.Text
                  style={{
                    fontSize: !isScreenXL ? token.fontSizeSM : token.fontSizeLG,
                    fontWeight: token.fontWeightStrong,
                    paddingLeft: token.paddingXXS
                  }}>
                  {headers[0]}
                </Typography.Text>
              </Col>
            )}
            {!isTabOrLowerScreen && (
              <Col sm={6} xs={6} md={6} style={commonColumnStyles}>
                <Typography.Text
                  style={{
                    fontSize: !isScreenXL ? token.fontSizeSM : token.fontSizeLG,
                    fontWeight: token.fontWeightStrong
                  }}>
                  {headers[1]}
                </Typography.Text>
              </Col>
            )}

            {!isTabOrLowerScreen ? (
              <Col sm={4} xs={4} md={4} style={commonColumnStyles}>
                <Dropdown menu={{ items: approvalStatusItems }} trigger={["click", "contextMenu"]}>
                  <a onClick={(e) => e.preventDefault()}>
                    {approvalStatus.length > 0 ? <SelectedFilterIcon /> : <GrayFilterIcon />}{" "}
                  </a>
                </Dropdown>
                <Typography.Text
                  style={{
                    fontSize: !isScreenXL ? token.fontSizeSM : token.fontSizeLG,
                    fontWeight: token.fontWeightStrong,
                    color: token.colorText
                  }}>
                  {headers[2]}
                </Typography.Text>
              </Col>
            ) : (
              <Col sm={12} md={6} xs={14}>
                <Dropdown menu={{ items: approvalStatusItems }} trigger={["click", "contextMenu"]}>
                  <a onClick={(e) => e.preventDefault()}>
                    {approvalStatus.length > 0 ? <SelectedFilterIcon /> : <GrayFilterIcon />}{" "}
                  </a>
                </Dropdown>
                <Typography.Text
                  style={{
                    fontSize: !isScreenXL ? token.fontSizeSM : token.fontSizeLG,
                    fontWeight: token.fontWeightStrong,
                    color: token.colorText
                  }}>
                  {headers[2]}
                </Typography.Text>
              </Col>
            )}
            {!isTabOrLowerScreen ? (
              <Col md={6} style={{ ...commonColumnStyles }}>
                {permissionHeaderAndFilter(headers, rolePropertiesDataLoading)}
              </Col>
            ) : (
              <Col sm={8} md={6} xs={12}>
                {permissionHeaderAndFilter(headers, rolePropertiesDataLoading)}
              </Col>
            )}
            {!isVendor && (
              <Col
                style={!isTabOrLowerScreen ? { ...commonColumnStyles } : {}}
                sm={2}
                md={isTabOrLowerScreen ? 5 : 4}>
                <Dropdown menu={{ items: managerTypeItems }} trigger={["click", "contextMenu"]}>
                  <a onClick={(e) => e.preventDefault()}>
                    {managerType && managerType != "All" ? (
                      <SelectedFilterIcon />
                    ) : (
                      <GrayFilterIcon />
                    )}{" "}
                  </a>
                </Dropdown>
                <Typography.Text
                  style={{
                    fontSize: !isScreenXL ? token.fontSizeSM : token.fontSizeLG,
                    fontWeight: token.fontWeightStrong,
                    color: token.colorText
                  }}>
                  {headers[4]}
                </Typography.Text>
              </Col>
            )}
            {isVendor && (
              <Col style={!isTabOrLowerScreen ? { ...commonColumnStyles } : {}} md={3}>
                <Dropdown menu={{ items: vendorStatusItems }} trigger={["click", "contextMenu"]}>
                  <a onClick={(e) => e.preventDefault()}>
                    {vendorStatus && vendorStatus != "All" ? (
                      <SelectedFilterIcon />
                    ) : (
                      <GrayFilterIcon />
                    )}{" "}
                  </a>
                </Dropdown>
                <Typography.Text
                  style={{
                    fontSize: !isScreenXL ? token.fontSizeSM : token.fontSizeLG,
                    fontWeight: token.fontWeightStrong,
                    color: token.colorText
                  }}>
                  Active
                </Typography.Text>
              </Col>
            )}
          </Row>
        </Col>
        <Col span={2}>
          {!isTabOrLowerScreen && (
            <Col
              style={{
                ...commonColumnStyles,
                marginLeft: token.marginXXS,
                paddingLeft: token.paddingXXS
              }}>
              <Flex justify="center">
                <Typography.Text
                  style={{
                    fontSize: !isScreenXL ? token.fontSizeSM : token.fontSizeLG,
                    fontWeight: token.fontWeightStrong
                  }}>
                  Actions
                </Typography.Text>
              </Flex>
            </Col>
          )}
        </Col>
      </Row>
      <Divider
        style={{ background: token.colorBgTextHover, marginBottom: 0, marginTop: token.marginMD }}
      />
    </>
  );
};

export default UsersListHeader;
