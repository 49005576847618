import { TAdminUserRole, TUserSearchFilters } from "../Component/Admin/types";
import {
  TAdminUserApiResponse,
  TBusinessUser,
  TBusinessUserApiResponse,
  TProperties,
  TVendor,
  TVendorApiResponse
} from "@Component/Admin/AdminViewUsers/types";
import timeFormatter from "../utils/timeFormatter";
import { TGlCode } from "@Component/Common/types";

export type TUsersApiValidResponse = {
  vendors: TVendorApiResponse[];
  users: TBusinessUserApiResponse[];
};

export const transformAdminUsers = ({ role }: TUserSearchFilters, data: TAdminUserApiResponse) => {
  return role === "vendor" ? transformVendors(data) : transformBusinessUsers(data);
};

const transformVendors = (vendors: TVendorApiResponse[]): TVendor[] => {
  if (!vendors.length) {
    return [];
  }
  return vendors.map((v: TVendorApiResponse) => {
    const vendor: TVendor = {
      ...v,
      id: v.Id,
      role: "vendor",
      assignedProperties: v.properties,
      assignedGLCodes: v.glCodes,
      permissionTags: [...v.properties]
    };
    if (v.editGLCodes) {
      vendor.permissionTags.push({ id: "editGLCodes", name: "Edit GL Codes" });
    }
    if (v.showPaymentStatus) {
      vendor.permissionTags.push({ id: "showPaymentStatus", name: "Show Payment Status" });
    }
    if (v.showLegalForms) {
      vendor.permissionTags.push({ id: "showLegalForms", name: "Show Legal Forms" });
    }
    return vendor;
  });
};

const transformBusinessUsers = (businessUsers: TBusinessUserApiResponse[]): TBusinessUser[] => {
  if (!businessUsers.length) {
    return [];
  }
  return businessUsers.map((businessUser: TBusinessUserApiResponse) => {
    const businessUsers: TBusinessUser = {
      ...businessUser,
      id: businessUser.Id,
      role: "user",
      name: businessUser.name,
      email: businessUser.email,
      invitationStatus: businessUser.invitationStatus,
      permissionTags: [...businessUser.properties],
      assignedProperties: businessUser.properties
    };
    return businessUsers;
  });
};

export const transformParamsToQuery = (params: { [key: string]: string }) => {
  const query = new URLSearchParams();
  if (params.role) {
    query.append("role", params.role);
  }
  if (params.invitationStatus) {
    query.append("invitationStatus", params.invitationStatus);
  }
  if (params.search) {
    query.append("search", params.search);
  }
  if (params.pageNumber) {
    query.append("pageNum", params.pageNumber);
  }
  if (params.pageSize) {
    query.append("pageSize", params.pageSize);
  }
  if (params.vendorStatus) {
    const isActive = params.vendorStatus === "active";
    query.append("isActive", isActive.toString());
  }
  if (params.isRegionalManager) {
    const isRegionalManager = params.isRegionalManager === "regional";
    query.append("isRegionalManager", isRegionalManager.toString());
  }
  if (params.propertyIds) {
    query.append("propertyIds", params.propertyIds);
  }
  return query.toString();
};
export type RequestObject = {
  id?: string;
  vendorId?: string;
  userId?: string;
  assignedProperties?: TProperties[];
  assignedGLCodes?: TGlCode[];
  role: TAdminUserRole;
  editGLCodes?: boolean;
  showLegalForms?: boolean;
  showPaymentStatus?: boolean;
  cardDetails?: string[];
  threshold?: number;
  regionalManagerThreshold?: number;
  glCategoryIds?: any;
};
export const transformUserPermissions = (requestObject: RequestObject): RequestObject => {
  const {
    assignedGLCodes,
    assignedProperties,
    cardDetails,
    editGLCodes,
    showLegalForms,
    showPaymentStatus,
    role,
    id,
    threshold,
    regionalManagerThreshold,
    glCategoryIds
  } = requestObject;
  const transformedRequestObject: RequestObject = {
    id,
    role
  };

  if (role === "vendor") {
    transformedRequestObject["vendorId"] = id;
    if (glCategoryIds !== undefined) {
      transformedRequestObject["glCategoryIds"] = glCategoryIds;
    }
  }

  if (role === "user") {
    transformedRequestObject["userId"] = id;
  }

  if (assignedProperties?.length) {
    transformedRequestObject["assignedProperties"] = assignedProperties;
  }

  if (assignedGLCodes?.length) {
    transformedRequestObject["assignedGLCodes"] = assignedGLCodes;
  }

  if (editGLCodes !== undefined) {
    transformedRequestObject["editGLCodes"] = editGLCodes;
  }

  if (showLegalForms !== undefined) {
    transformedRequestObject["showLegalForms"] = showLegalForms;
  }

  if (showPaymentStatus !== undefined) {
    transformedRequestObject["showPaymentStatus"] = showPaymentStatus;
  }

  if (cardDetails !== undefined) {
    transformedRequestObject["cardDetails"] = cardDetails;
  }

  if (threshold !== undefined) {
    transformedRequestObject["threshold"] = threshold;
  }

  if (regionalManagerThreshold !== undefined) {
    transformedRequestObject["regionalManagerThreshold"] = regionalManagerThreshold;
  }

  delete transformedRequestObject.id;

  return transformedRequestObject;
};

export type TUserLogApiResponse = {
  Id: string;
  createdAt: string;
  updatedAt: string;
  authUserId: string;
  invoiceId: string;
  eventType: TEvent;
  field: any;
  actor: Actor;
};

export interface Actor {
  role: TAdminUserRole;
  name: string;
  email: string;
}

export type TEvent = "create" | "update";

export type TUserLog = {
  message: string;
  timestamp: string;
  invoiceId: string;
};
export const transformUserLogs = (logs: TUserLogApiResponse[]): TUserLog[] => {
  return logs.map(
    ({
      eventType,
      createdAt,
      updatedAt,
      actor: { role, name },
      invoiceId
    }: TUserLogApiResponse) => {
      const userLog: TUserLog = {
        message: ``,
        timestamp: "",
        invoiceId: invoiceId
      };
      if (eventType === "create") {
        userLog.message = `${name} (${role}) has created invoice ${invoiceId}`;
        userLog.timestamp = timeFormatter(createdAt as string);
      }
      timeFormatter(userLog.timestamp as string);
      if (eventType === "update") {
        userLog.message = `${name} (${role}) has updated invoice ${invoiceId}.`;
        userLog.timestamp = timeFormatter(updatedAt as string);
      }

      return userLog;
    }
  );
};

export type SettingsRequestObject = {
  Id: string;
  regionalManagerThreshold?: number;
  vendorErrorResponse?: string;
};
