import AdminSettings from "@Component/Admin/AdminSettings";
import useFetchAdminSettings from "@hooks/Admin/useFetchAdminSettings";
import useUpdateAdminSettings from "@hooks/Admin/useUpdateAdminSettings";
import { useEffect, useRef } from "react";

const AdminSettingsPage = () => {
  const formRef = useRef<{ threshold: number; errorMessage: string }>();

  const { data, isLoading } = useFetchAdminSettings();
  const { mutate: updateAdminSettings, isPending } = useUpdateAdminSettings();
  const Id = data?.Id;
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    formRef.current?.setFieldsValue({
      threshold: data?.regionalManagerThreshold,
      errorMessage: data?.vendorErrorResponse
    });
  }, [data?.regionalManagerThreshold, data?.vendorErrorResponse, isPending]);

  const handleSubmit = async (values: { threshold: number; errorMessage: string }) => {
    updateAdminSettings({
      regionalManagerThreshold: Number(values.threshold),
      vendorErrorResponse: values.errorMessage,
      Id: Id as string
    });
  };
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    <AdminSettings onSubmit={handleSubmit} loading={isLoading || isPending} formRef={formRef} />
  );
};

export default AdminSettingsPage;
