import { FC } from "react";
import { APPROVED, PENDING, REJECTED, TInvitationStatus } from "@utils/constants";
import { Flex, Tag } from "antd";
import { useAntToken } from "@hooks/common/useAntToken";
import { useBreakpoints } from "@hooks/common/useBreakPoints";

interface IInvitationStatusTagProps {
  status: TInvitationStatus;
}
const InvitationStatusTag: FC<IInvitationStatusTagProps> = ({ status }) => {
  const { token } = useAntToken();
  const { isScreenXS } = useBreakpoints();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const fontWeightMedium = token.fontWeightMedium;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const fontSizeXS = token.fontSizeXS;
  const commonStyles = {
    boxShadow: token.boxShadow,
    fontWeight: fontWeightMedium,
    fontSize: isScreenXS ? fontSizeXS : token.fontSize
  };

  const commonFlexStyles = {
    height: "24px",
    width: "104px"
  };
  return (
    <>
      {status === APPROVED && (
        <Flex justify={"start"} align="middle" style={!isScreenXS ? commonFlexStyles : {}}>
          <Tag
            bordered={true}
            color={token.colorSuccess}
            style={{
              ...commonStyles,
              background: token.colorSuccessBgHover,
              color: token.colorText
            }}>
            Approved
          </Tag>
        </Flex>
      )}
      {status === REJECTED && (
        <Flex justify={"start"} align="middle" style={!isScreenXS ? commonFlexStyles : {}}>
          <Tag
            bordered={true}
            color="error"
            style={{
              ...commonStyles,
              background: token.colorErrorBgHover,
              color: token.colorText
            }}>
            Rejected
          </Tag>
        </Flex>
      )}
      {status === PENDING && (
        <Flex justify={"start"} align="middle" style={!isScreenXS ? commonFlexStyles : {}}>
          <Tag
            bordered={true}
            color="warning"
            style={{
              ...commonStyles,
              background: token.colorWarningBgHover,
              color: token.colorText
            }}>
            Pending
          </Tag>
        </Flex>
      )}
    </>
  );
};

export default InvitationStatusTag;
