import { FC, ReactElement, ReactNode } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { ADMIN, VENDOR } from "../utils/constants";
import { IAppState } from "../redux/reducers/rootReducer";
import { IAuthState } from "../redux/reducers/Auth/Auth";

interface Props {
  children?: ReactNode;
}

export const GuestRoute: FC<Props> = ({ children }) => {
  const loginState: IAuthState = useSelector((state: IAppState) => state.auth);

  const { token, role } = loginState;

  if (token) {
    if (role === ADMIN) {
      return <Navigate to="/admin" />;
    } else if (role === VENDOR) {
      return <Navigate to="/vendor" />;
    } else {
      return <Navigate to="/" />;
    }
  }
  return children as ReactElement<any, any> | null;
};
