import { FormInstance, MenuProps, Modal } from "antd";
import { Ref } from "react";
import type { ColumnsType } from "antd/es/table";
import { CheckOutlined, CloseCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { INVOICE_APPROVED, TInvoiceApprovalStatus } from "@utils/constants";
import { ILineItem, TGlCode, TUnit } from "@Component/Common/types";
import { IInvoiceProps } from "@hooks/Invoice/types";
const { confirm } = Modal;

export const getInvoiceLineItemColumns = (
  readonlyView: boolean,
  rejectedInvoiceView?: boolean,
  propertyManagerView?: boolean,
  propertyType?: string
): ColumnsType<ILineItem> => {
  const columns: ColumnsType<ILineItem> = [
    {
      title: "Description",
      dataIndex: "lineItemDescription",
      key: "lineItemDescription",
      width: "25%"
    },
    {
      title: "Taxable",
      dataIndex: "lineItemTaxable",
      key: "lineItemTaxable",
      width: "11%"
    },
    {
      title: "Pre Tax Amount",
      dataIndex: "lineItemPreTaxAmount",
      key: "lineItemPreTaxAmount",
      width: "12%"
    },
    {
      title: "Post Tax Amount",
      dataIndex: "lineItemPostTaxAmount",
      key: "lineItemPostTaxAmount",
      width: "12%"
    },
    {
      title: "GL Code",
      dataIndex: "glCode",
      key: "glCode",
      width: "25%"
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      width: "6%"
    }
  ];

  if (readonlyView) {
    columns.pop();
    columns.push({
      title: "Status",
      dataIndex: "lineItemStatus",
      key: "lineItemStatus",
      width: "9%"
    });
  }
  if (propertyType === "UnitProperty") {
    columns.splice(columns.length - 1, 0, {
      title: "Unit",
      dataIndex: "lineItemUnit",
      key: "lineItemUnit",
      width: "15%"
    });
  }

  if (rejectedInvoiceView || propertyManagerView) {
    columns.splice(columns.length - 1, 0, {
      title: "Status",
      dataIndex: "lineItemRejectionReason",
      key: "lineItemRejectionReason",
      width: "10%"
    });
  }

  return columns;
};

export const getMoreOptions = ({
  handleEditLineItem,
  lineItemShortId,
  handleRejectionModalOpen,
  handleLineItemDelete,
  key,
  isDeletable
}: {
  handleEditLineItem?: (
    lineItemId: string,
    lineItemChangeKey: string,
    lineItemValue: unknown
  ) => void;
  lineItemShortId: string;
  handleRejectionModalOpen: (lineItemShortId: string) => void;
  handleLineItemDelete?: (value: number) => void;
  key: number;
  isDeletable?: boolean;
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const showConfirm = (onOk: any) => {
    confirm({
      title: `Are You Sure?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      cancelText: "No",
      onOk: onOk
    });
  };
  let moreOptions: MenuProps["items"] = [
    {
      label: "Approve",
      key: "0",
      icon: <CheckOutlined />,
      onClick: () => {
        if (handleEditLineItem) {
          handleEditLineItem(lineItemShortId, "approvalStatus", INVOICE_APPROVED);
        }
      }
    }
  ];
  moreOptions.push({
    label: `Reject`,
    key: "2",
    icon: <CloseCircleOutlined />,
    onClick: () => {
      handleRejectionModalOpen(lineItemShortId);
    }
  });
  if (isDeletable) {
    moreOptions = [
      {
        label: "Delete",
        key: "3",
        icon: <CloseCircleOutlined />,
        onClick: () => {
          showConfirm(() => {
            if (handleLineItemDelete) {
              handleLineItemDelete(key);
            }
          });
        }
      }
    ];
  }

  return moreOptions;
};

export const diffCalculator = (obj1: any, obj2: any) => {
  const diff: any = {};

  Object.keys(obj1).forEach((key) => {
    let value2 = undefined;
    if (obj2[key] !== undefined) {
      value2 = obj2[key];
    }
    if (key != "lineId") {
      if (compareValues(obj1[key], value2) != "unchanged") {
        diff[key] = obj2[key];
      }
    } else {
      diff[key] = obj2[key];
    }
  });

  return diff;
};

export const compareValues = (value1: any, value2: any) => {
  if (typeof value1 === "object") {
    return "unchanged";
  }
  if (typeof value2 === "object") {
    return "unchanged";
  }
  if (value1 === "" || value1 === null || value1 == "null") {
    value1 = null;
  }
  if (value2 === "" || value2 === null || value2 == "null") {
    value2 = null;
  }

  const VALUE_UNCHANGED = "unchanged";
  if (value1 === value2) {
    return VALUE_UNCHANGED;
  }
  return "changed";
};

export const previousValueTooltip = (previousItem: any) => {
  let text = "\n";
  if (!previousItem) return "New Line Item";
  if (previousItem?.description) text += `Description: ${previousItem?.description}\n`;
  if (previousItem?.hasOwnProperty("isTaxable"))
    text += `Taxable: ${previousItem?.isTaxable ? "On" : "Off"}\n`;
  if (previousItem?.amount?.toString())
    text += `Pre Tax Amount: ${previousItem?.amount.toString()}\n`;
  return text;
};

export interface IReviewInvoiceProps {
  preLineItems?: any;
  isManagerPortal?: boolean;
  invoiceId?: string;
  invoiceInfo: IInvoiceProps;
  displayIssuingCompanyForm?: boolean;
  setDisplayIssuingCompanyForm?: (value: boolean) => void;
  onIssuingCompanyInfoChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  displayIssueDateInput?: boolean;
  setDisplayIssueDateInput?: (value: boolean) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onIssueDateChange?: (date: any, dateString: any) => void;
  lineItems: ILineItem[];
  handleLineItemDelete?: (value: number) => void;
  displayAddItemModal?: boolean;
  setDisplayAddItemModal?: (value: boolean) => void;
  addItem?: (values: ILineItem) => void;
  glCodes?: TGlCode[];
  invoiceTotal?: number;
  loading: boolean;
  handleSubmit?: () => void;
  navigateToUploadInvoicePage?: () => void;
  formRef?: Ref<FormInstance<ILineItem>>;
  handleDisplayAddItemModal?: () => void;
  editGLCodes?: boolean;
  totalTaxAmount?: number;
  onTaxAmountChange?: (e: string | 0 | null) => void;
  originalInvoiceUrl: string;
  selectedRowTaxable?: boolean;
  onSelectedRowTaxableChange?: (checked: boolean) => void;
  handleEditLineItem?: (
    lineItemId: string,
    lineItemChangeKey: string,
    lineItemValue: unknown,
    unitAllToAll?: boolean
  ) => void;
  readonlyView?: boolean;
  rejectedInvoiceView?: boolean;
  propertyManagerView?: boolean;
  handleInvoiceApprovalStatusChange?: (status: TInvoiceApprovalStatus) => void;
  isInvoiceApprovalAllowed?: boolean;
  handleInvoiceRejection?: (rejectionReason?: string) => void;
  invoiceApprovalStatus?: TInvoiceApprovalStatus;
  invoiceRejectionReason?: string;
  units: TUnit[];
  propertyType: string;
  isRegionalManager?: boolean;
  requestLienWavier?: boolean;
  legalFormUrl?: string;
  displayCancelButton?: boolean;
  userLogsView: boolean;
}

export type TDefaultSignDocumentValuesObject = {
  vendorName?: string;
  vendorAddress?: string;
  ownerName?: string;
  propertyLocation?: string;
  contractorName?: string;
  contractorAddress?: string;
  invoiceAmount?: string;
  invoiceUploadDate?: string;
  signatureName?: string;
  today?: number;
  currentMonth?: string;
  currentYear?: number;
  its?: string;
  vendorEmail?: string;
};
