import { createStyles } from "antd-style";

export const menuStyles = createStyles(({ token, css }) => ({
  "ant-menu-override": {
    ".ant-menu": css`
      border-left: 0 !important;
    `,
    ".ant-menu-item": css`
      font-size: ${token.fontSizeXL} !important;
      //   padding-left: ${token.paddingSM}px !important;
    `,
    ".ant-menu-item.ant-menu-item-selected": css`
      background-color: ${token.colorPrimary} !important;
      color: ${token.colorTextLightSolid};
      border-radius: ${token.borderRadiusSM}px;
      font-size: ${token.fontSizeXL} !important;
    `
  },
  "ant-sider-override": {
    ".ant-layout-sider-trigger": css`
      width: 55px !important;
      margin-bottom: 5px !important;
      background: transparent !important;
    `
  }
}));
