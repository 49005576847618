import { FC, Ref } from "react";
import { Col, Flex, Form, Typography, FormInstance, Row } from "antd";
import TextArea from "antd/es/input/TextArea";
import CommonAppButton from "@Component/Common/CommonAppButton";
import CommonFormLabel from "@Component/Common/CommonFormLabel";
import Loader from "@Component/Common/Loader";
import { commonAntStyles } from "@Component/Common/styles";
import { useAntToken } from "@hooks/common/useAntToken";
import { useBreakpoints } from "@hooks/common/useBreakPoints";
import GlGroup from "@Component/Admin/AdminSettings/GlGroup";
import Cards from "@Component/Admin/AdminSettings/Cards";

interface IAdminSettingsProps {
  loading: boolean;
  onSubmit: (values: { threshold: number; errorMessage: string }) => void;
  formRef?: Ref<FormInstance<{ threshold: number; errorMessage: string }>>;
}
const AdminSettings: FC<IAdminSettingsProps> = (props) => {
  const { onSubmit, loading, formRef } = props;
  const { styles: commonStyles } = commonAntStyles();
  const { isScreenXS } = useBreakpoints();
  const { token } = useAntToken();
  const commonInputStyles = {
    height: token.controlHeightLG,
    backgroundColor: token.colorBgBase,
    borderRadius: token.borderRadiusSM,
    border: `1px solid ${token.colorBorder}`
  };

  if (loading) {
    return <Loader isLoading={loading} />;
  }

  return (
    <Row style={{ marginLeft: isScreenXS ? token.marginLG + 20 : 0, marginBottom: token.marginLG }}>
      <Flex
        justify="space-between"
        vertical
        style={{
          paddingLeft: isScreenXS ? token.paddingXL : token.paddingXL + 20,
          paddingRight: isScreenXS ? token.paddingXL : token.paddingXL + 20,
          paddingTop: isScreenXS ? token.paddingSM : token.padding,
          maxWidth: 800,
          width: "100%"
        }}>
        <Col>
          <Typography.Title
            level={2}
            style={{
              color: token.colorText,
              fontSize: isScreenXS ? token.fontSizeHeading3 : token.fontSizeHeading1,
              fontWeight: token.fontWeightStrong
            }}>
            Settings
          </Typography.Title>
        </Col>
        <Col style={{ marginTop: token.marginLG }}>
          <Form layout="vertical" onFinish={onSubmit} ref={formRef}>
            <Form.Item
              className={commonStyles["ant-input-override"]}
              name={"errorMessage"}
              label={
                <CommonFormLabel
                  style={{
                    color: token.colorText,
                    fontSize: token.fontSize,
                    fontWeight: token.fontWeightStrong,
                    paddingLeft: `${token.paddingXS + 2}px`
                  }}>
                  Error Message For In-Active Vendors
                </CommonFormLabel>
              }
              rules={[
                {
                  required: true,
                  message: `Please enter error message for in active vendors`
                }
              ]}>
              <TextArea
                rows={4}
                style={{ ...commonInputStyles }}
                autoSize={{ minRows: 4, maxRows: 4 }}
                placeholder="Error Message For In-Active Vendors"
              />
            </Form.Item>
            <Form.Item style={{ width: isScreenXS ? "100%" : "25%" }}>
              <CommonAppButton
                type="primary"
                htmlType="submit"
                block
                style={{ height: 40 }}
                disabled={loading}>
                Submit
              </CommonAppButton>
            </Form.Item>
          </Form>
        </Col>
        <Cards />
        <GlGroup loading={loading} />
      </Flex>
    </Row>
  );
};

export default AdminSettings;
