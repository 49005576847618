import {
  APP_BASE_URL,
  invoiceSubmitUrl,
  fetchInvoiceByIdUrl,
  fetchAllInvoiceGlCodesUrl,
  invoiceUpdateUrl,
  fetchOriginalInvoiceUrl,
  invoiceCancelUrl, fetchAllVendorGlCodesUrl
} from "@utils/urls";
import http from "@utils/httpService";
// eslint-disable-next-line
export const submitInvoice = async (fileData: any) => {
  const { data } = await http.post(`${APP_BASE_URL}${invoiceSubmitUrl}`, fileData);
  return { data };
};

export const fetchInvoiceById = async (invoiceId: string | undefined, invoiceIdType?: string) => {
  if (invoiceId) {
    let uri = fetchInvoiceByIdUrl.replace(":invoiceId", invoiceId);
    if (invoiceIdType) {
      uri = uri.concat(`?invoiceIdType=${invoiceIdType}`);
    }
    const { data } = await http.get(`${APP_BASE_URL}${uri}`);
    return { data };
  }
};

export const fetchAllInvoiceGlCodes = async () => {
  const { data } = await http.get(`${APP_BASE_URL}${fetchAllInvoiceGlCodesUrl}`);
  return { data };
};

export const fetchVendorInvoiceGlCodes = async () => {
  const { data } = await http.get(`${APP_BASE_URL}${fetchAllVendorGlCodesUrl}`);
  return { data };
};

export const invoiceUpdate = async (invoice: any) => {
  const url = invoiceUpdateUrl.replace(":invoiceId", invoice.Id);
  const { data } = await http.put(`${APP_BASE_URL}${url}`, invoice);
  return { data };
};

export const invoiceCancel = async (invoiceId: string) => {
  const url = invoiceCancelUrl.replace(":invoiceId", invoiceId);
  const { data } = await http.put(`${APP_BASE_URL}${url}`, { invoiceId });
  return { data };
};

export const fetchOriginalInvoiceImageUrl = async (persistedFileId: string) => {
  const uri = fetchOriginalInvoiceUrl.replace(":persistedFileId", persistedFileId);
  const { data } = await http.get(`${APP_BASE_URL}${uri}`);
  return { data };
};
