import { Form, Input, Row, Col, theme, Typography, Flex } from "antd";
import { FC } from "react";
import { useBreakpoints } from "@hooks/common/useBreakPoints";
import { commonAntStyles } from "@Component/Common/styles";
import CommonAppButton from "@Component/Common/CommonAppButton";
import { LogoIcon } from "@assets/icons/CustomIcons";
const { useToken } = theme;
interface IUpdatePassword {
  password: string;
  confirmPassword: string;
}

interface Props {
  loading: boolean;
  onFinish: (values: IUpdatePassword) => void;
}

const UpdatePassword: FC<Props> = (props) => {
  const { token } = useToken();
  //eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const fontWeightNormal = token.fontWeightNormal;
  const { loading, onFinish } = props;
  const { isScreenXS } = useBreakpoints();
  const { styles: commonStyles } = commonAntStyles();
  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
  };

  const { Title } = Typography;
  const commonInputStyles = {
    borderRadius: 0,
    backgroundColor: `${token.colorBgBase} !important`,
    border: `1px solid ${token.colorBorder}`,
    height: token.controlHeightLG,
    paddingBottom: token.paddingXXS
  };

  const CommonLabel = ({ children }: { children: React.ReactNode }) => (
    <label style={{ color: token.colorText, fontWeight: token.fontWeightStrong }}>{children}</label>
  );

  return (
    <Flex justify="center" align="center" vertical>
      <Row
        style={{ marginLeft: "12px", marginRight: "12px", maxWidth: "460px", marginBottom: 100 }}
        justify={"center"}>
        <Col>
          <LogoIcon style={{ fontSize: isScreenXS ? 130 : 230 }} />
        </Col>
        <Col
          span={24}
          style={{
            backgroundColor: token.colorBgBase,
            padding: token.paddingXL,
            paddingTop: token.paddingXS,
            paddingBottom: token.paddingXS,
            borderRadius: token.borderRadiusLG
          }}>
          <Row justify="start">
            <Col>
              <Title level={2}>Create Password</Title>
            </Col>
          </Row>
          <Col span={24}>
            <Form {...layout} onFinish={onFinish} style={{ paddingBottom: 0 }}>
              <Row style={{ marginTop: token.marginLG, paddingBottom: 0 }} justify="center">
                <Col span={24}>
                  <Form.Item
                    className={commonStyles["ant-input-override"]}
                    label={<CommonLabel>New Password</CommonLabel>}
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please Input Your Password"
                      }
                    ]}>
                    <Input.Password
                      type="password"
                      placeholder="Password"
                      name="password"
                      style={{ ...commonInputStyles, background: "transparent" }}
                    />
                  </Form.Item>
                  <Form.Item
                    className={commonStyles["ant-input-override"]}
                    label={<CommonLabel>Confirm Password</CommonLabel>}
                    name="confirmPassword"
                    rules={[
                      {
                        required: true,
                        message: "Please Input Your Confirm Password"
                      }
                    ]}>
                    <Input.Password
                      type="password"
                      placeholder="Password"
                      name="password"
                      style={{ ...commonInputStyles, background: "transparent" }}
                    />
                  </Form.Item>
                  <Form.Item style={{ marginTop: token.marginXL }}>
                    <CommonAppButton
                      type="primary"
                      htmlType="submit"
                      block
                      loading={loading}
                      style={{
                        backgroundColor: token.colorPrimary,
                        height: isScreenXS ? token.controlHeightSM : token.controlHeightLG,
                        color: token.colorTextLightSolid,
                        fontSize: isScreenXS ? token.fontSizeSM : token.fontSizeLG,
                        boxShadow: token.boxShadow,
                        fontWeight: fontWeightNormal,
                        paddingBottom: isScreenXS ? token.paddingLG : token.paddingXXS
                      }}>
                      Submit
                    </CommonAppButton>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Col>
      </Row>
    </Flex>
  );
};

export default UpdatePassword;
