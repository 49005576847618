import { useSearchParams } from "react-router-dom";
import { TManagerType, TVendorActiveStatus } from "@utils/constants";
import { TAdminUserRole } from "@Component/Admin/types";

interface IFilterObject {
  invitationStatus?: string;
  role?: TAdminUserRole;
  search?: string;
  pageNumber?: string;
  pageSize?: string;
  vendorStatus?: TVendorActiveStatus;
  managerType?: TManagerType;
  propertyIds?: string;
}
const useAdminSearchParams = () => {
  const defaultPageNumber = "1";
  const defaultPageSize = "8";
  const defaultVendorStatus = "";
  const defaultInvitationStatus = "";
  const defaultManagerType = "";
  const defaultPropertyIds = "";
  const [searchParams, setSearchParams] = useSearchParams({
    role: "vendor",
    search: "",
    pageNumber: defaultPageNumber,
    pageSize: defaultPageSize,
    vendorStatus: defaultVendorStatus,
    invitationStatus: defaultInvitationStatus,
    managerType: defaultManagerType,
    propertyIds: defaultPropertyIds
  });
  const invitationStatus = searchParams.get("invitationStatus") as string;
  const managerType = searchParams.get("managerType") as TManagerType;
  const vendorStatus = searchParams.get("vendorStatus") as TVendorActiveStatus;
  const role = searchParams.get("role") as TAdminUserRole;
  const search = searchParams.get("search") as string;
  const propertyIds = (searchParams.get("propertyIds") as string) || defaultPropertyIds;
  const pageNumber = (searchParams.get("pageNumber") as string) || defaultPageNumber;
  const pageSize = (searchParams.get("pageSize") as string) || defaultPageSize;

  const setInvitationStatus = (status: any[]) => {
    const filterObject: IFilterObject = {};
    filterObject.pageNumber = defaultPageNumber;
    filterObject.pageSize = defaultPageSize;

    if (vendorStatus) {
      filterObject.vendorStatus = vendorStatus;
    }
    if (managerType) {
      filterObject.managerType = managerType;
    }
    if (role) {
      filterObject.role = role;
    }
    if (search) {
      filterObject.search = search;
    }
    if (propertyIds) {
      filterObject.propertyIds = propertyIds;
    }

    filterObject.invitationStatus = status.toString();

    setSearchParams({ ...filterObject });
  };

  const setManagerType = (status: TManagerType) => {
    const filterObject: IFilterObject = {};
    filterObject.pageNumber = defaultPageNumber;
    filterObject.pageSize = defaultPageSize;

    if (vendorStatus) {
      filterObject.vendorStatus = vendorStatus;
    }
    if (invitationStatus) {
      filterObject.invitationStatus = invitationStatus;
    }
    if (role) {
      filterObject.role = role;
    }
    if (search) {
      filterObject.search = search;
    }
    if (propertyIds) {
      filterObject.propertyIds = propertyIds;
    }
    if (status === "clear" || status === undefined) {
      delete filterObject.managerType;
    } else {
      filterObject.managerType = status;
    }
    setSearchParams({ ...filterObject });
  };

  const setVendorStatus = (status: TVendorActiveStatus) => {
    const filterObject: IFilterObject = {};
    filterObject.pageNumber = defaultPageNumber;
    filterObject.pageSize = defaultPageSize;

    if (invitationStatus) {
      filterObject.invitationStatus = invitationStatus;
    }
    if (managerType) {
      filterObject.managerType = managerType;
    }
    if (role) {
      filterObject.role = role;
    }
    if (search) {
      filterObject.search = search;
    }
    if (propertyIds) {
      filterObject.propertyIds = propertyIds;
    }
    if (status === "clear" || status === undefined) {
      delete filterObject.vendorStatus;
    } else {
      filterObject.vendorStatus = status;
    }
    setSearchParams({ ...filterObject });
  };

  const clearFilters = () => {
    const filterObject: IFilterObject = {
      pageNumber: defaultPageNumber,
      pageSize: defaultPageSize,
      vendorStatus: defaultVendorStatus,
      invitationStatus: defaultInvitationStatus,
      managerType: defaultManagerType,
      propertyIds: defaultPropertyIds,
      role: role,
      search: ""
    };
    setSearchParams({ ...filterObject });
  };

  const setRole = (role: TAdminUserRole) => {
    const filterObject: IFilterObject = {};
    filterObject.pageNumber = defaultPageNumber;
    filterObject.pageSize = defaultPageSize;
    if (invitationStatus) {
      filterObject.invitationStatus = invitationStatus;
    }
    if (managerType) {
      filterObject.managerType = managerType;
    }
    if (vendorStatus) {
      filterObject.vendorStatus = vendorStatus;
    }
    if (search) {
      filterObject.search = search;
    }
    if (propertyIds) {
      filterObject.propertyIds = propertyIds;
    }
    filterObject.role = role;
    setSearchParams({ ...filterObject });
  };

  const setSearch = (search: string) => {
    const filterObject: IFilterObject = {};
    filterObject.pageNumber = defaultPageNumber;
    filterObject.pageSize = defaultPageSize;
    if (role) {
      filterObject.role = role;
    }
    if (invitationStatus) {
      filterObject.invitationStatus = invitationStatus;
    }
    if (managerType) {
      filterObject.managerType = managerType;
    }
    if (vendorStatus) {
      filterObject.vendorStatus = vendorStatus;
    }
    if (propertyIds) {
      filterObject.propertyIds = propertyIds;
    }
    filterObject.search = search;
    setSearchParams({ ...filterObject });
  };

  const setPageNumber = (pageNumber: string) => {
    const filterObject: IFilterObject = {};
    filterObject.pageSize = pageSize || defaultPageSize;
    if (role) {
      filterObject.role = role;
    }
    if (invitationStatus) {
      filterObject.invitationStatus = invitationStatus;
    }
    if (managerType) {
      filterObject.managerType = managerType;
    }
    if (vendorStatus) {
      filterObject.vendorStatus = vendorStatus;
    }
    if (search) {
      filterObject.search = search;
    }
    if (propertyIds) {
      filterObject.propertyIds = propertyIds;
    }
    filterObject.pageNumber = pageNumber || defaultPageNumber;
    setSearchParams({ ...filterObject });
  };

  const setPageSize = (pageSize: string) => {
    const filterObject: IFilterObject = {};
    filterObject.pageNumber = pageNumber || defaultPageNumber;
    if (role) {
      filterObject.role = role;
    }
    if (invitationStatus) {
      filterObject.invitationStatus = invitationStatus;
    }
    if (managerType) {
      filterObject.managerType = managerType;
    }
    if (vendorStatus) {
      filterObject.vendorStatus = vendorStatus;
    }
    if (search) {
      filterObject.search = search;
    }
    if (propertyIds) {
      filterObject.propertyIds = propertyIds;
    }
    filterObject.pageSize = pageSize || defaultPageSize;
    setSearchParams({ ...filterObject });
  };

  const setProperty = (propertyIds: number[]) => {
    const filterObject: IFilterObject = {};
    filterObject.pageNumber = defaultPageNumber;
    filterObject.pageSize = defaultPageSize;
    if (role) {
      filterObject.role = role;
    }
    if (invitationStatus) {
      filterObject.invitationStatus = invitationStatus;
    }
    if (managerType) {
      filterObject.managerType = managerType;
    }
    if (vendorStatus) {
      filterObject.vendorStatus = vendorStatus;
    }
    if (search) {
      filterObject.search = search;
    }
    filterObject.propertyIds = propertyIds.toString();
    setSearchParams({ ...filterObject });
  };

  return {
    invitationStatus,
    role,
    search,
    pageNumber,
    pageSize,
    setInvitationStatus,
    setRole,
    setSearch,
    setPageNumber,
    setPageSize,
    clearFilters,
    vendorStatus,
    setVendorStatus,
    managerType,
    setManagerType,
    setProperty,
    propertyIds
  };
};

export default useAdminSearchParams;
