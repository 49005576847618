import { applyMiddleware, createStore } from "redux";
import { createMigrate, persistReducer, persistStore } from "redux-persist";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import migrations from "../utils/migrations";
import rootReducer from "./reducers/rootReducer";

const persistConfig = {
  key: "root",
  storage,
  version: 0, // Change versions of state
  debug: true,
  whitelist: ["auth", "invoice"],
  stateReconciler: autoMergeLevel2,
  migrate: createMigrate(migrations, { debug: true }) // Migrates state based on version number
};
//eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(thunk)));

export const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
