import { useAntToken } from "@hooks/common/useAntToken";
import { Divider } from "antd";
import { FC } from "react";

const CommonListDivider: FC = () => {
  const { token } = useAntToken();
  return (
    <Divider
      style={{
        background: token.colorBorder,
        opacity: 0.4,
        marginTop: 0,
        marginBottom: 0
      }}
    />
  );
};

export default CommonListDivider;
