import { useAntToken } from "@hooks/common/useAntToken";
import { Flex, Tag } from "antd";
import { FC } from "react";

interface IInvoice {
  Id: string;
  invoiceNumber: string;
  amount: number;
  paymentStatus: string;
  approvalStatus: string;
  propertyName: string;
}

interface Props {
  invoice: IInvoice;
}

const PaymentTag: FC<Props> = (props) => {
  const { invoice } = props;
  const { token } = useAntToken();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const fontWeightMedium = token.fontWeightMedium;
  const commonStyles = {
    boxShadow: token.boxShadow,
    fontWeight: fontWeightMedium,
    fontSize: token.fontSize
  };

  const commonFlexStyles = {
    height: "24px",
    width: "104px"
  };
  return (
    <>
      {invoice.paymentStatus === "Paid" && (
        <Flex justify="center" align="middle" style={commonFlexStyles}>
          <Tag
            bordered={false}
            color={token.colorSuccess}
            style={{
              ...commonStyles,
              background: token.colorSuccessBgHover,
              color: token.colorText
            }}>
            Paid
          </Tag>
        </Flex>
      )}
      {invoice.paymentStatus === "Unpaid" && (
        <Flex justify="center" align="middle" style={commonFlexStyles}>
          <Tag
            bordered={false}
            color="error"
            style={{
              ...commonStyles,
              background: token.colorErrorBgHover,
              color: token.colorText
            }}>
            Unpaid
          </Tag>
        </Flex>
      )}
      {invoice.paymentStatus === "Pending" && (
        <Flex justify="center" align="middle" style={commonFlexStyles}>
          <Tag
            bordered={false}
            color="warning"
            style={{
              ...commonStyles,
              background: token.colorWarningBgHover,
              color: token.colorText
            }}>
            Pending
          </Tag>
        </Flex>
      )}
    </>
  );
};
export default PaymentTag;
