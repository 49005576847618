import { FC } from "react";
import { IInvoice, getApprovalStatusItems, getPaymentStatusItems } from "./utils";
import { Col, Row, Typography, List, Dropdown, MenuProps } from "antd";
import { vendorDashboardStyles } from "./styles";
import MobileListHeader from "./MobileListHeader";
import { useAntToken } from "@hooks/common/useAntToken";
import MobilePaymentTag from "@Component/Common/MobilePaymentTag";
import MobileApprovalTag from "@Component/Common/MobileApprovalTag";
import formatter from "@utils/formatter";
import { MoreOutlinedIcon } from "@assets/icons/CustomIcons";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import CommonListDivider from "@Component/Common/CommonListDivider";
import { INVOICE_CANCELLED, INVOICE_PENDING } from "@utils/constants";
interface IMobileListProps {
  isRegionalManager?: boolean;
  isManagerPortal: boolean;
  isLoading: boolean;
  invoiceList: IInvoice[];
  showPaymentStatus: boolean;
  navigateToViewInvoice: (invoiceId: string, approvalStatus: string) => void;
  handleCancelInvoice?: (invoiceId: string) => Promise<void>;
  handleDownload: (invoice: IInvoice) => void;
  paymentStatus: string[];
  approvalStatus: string[];
  onPaymentStatusChange: (e: CheckboxChangeEvent) => void;
  onApprovalStatusChange: (e: CheckboxChangeEvent) => void;
}
const MobileList: FC<IMobileListProps> = (props) => {
  const {
    isRegionalManager,
    isManagerPortal,
    isLoading,
    invoiceList,
    showPaymentStatus,
    navigateToViewInvoice,
    handleDownload,
    approvalStatus,
    paymentStatus,
    handleCancelInvoice,
    onPaymentStatusChange,
    onApprovalStatusChange
  } = props;

  const { styles } = vendorDashboardStyles();
  const { token } = useAntToken();
  const paymentStatusItems: MenuProps["items"] = getPaymentStatusItems(
    paymentStatus,
    onPaymentStatusChange
  );

  const approvalStatusItems: MenuProps["items"] = getApprovalStatusItems(
    approvalStatus,
    onApprovalStatusChange,
    !isManagerPortal,
    isRegionalManager
  );

  const getActionItems = (item: IInvoice) => {
    const actionItems = [
      {
        label: (
          <span onClick={() => navigateToViewInvoice(item.Id, item.approvalStatus)}>View</span>
        ),
        key: "0"
      },
      {
        label: <span onClick={() => handleDownload(item)}>Download</span>,
        key: "1"
      }
    ];

    if (!isManagerPortal && item.approvalStatus === INVOICE_PENDING) {
      actionItems.push({
        label: (
          <span
            onClick={() => {
              if (handleCancelInvoice) {
                handleCancelInvoice(item.Id);
              }
            }}>
            Cancel Invoice
          </span>
        ),
        key: "2"
      });
    }
    return actionItems;
  };

  return (
    <List
      loading={isLoading}
      className={styles["ant-list-over-ride"]}
      header={
        <MobileListHeader
          showPaymentStatus={showPaymentStatus}
          approvalStatusItems={approvalStatusItems}
          paymentStatusItems={paymentStatusItems}
          approvalStatus={approvalStatus}
          paymentStatus={paymentStatus}
        />
      }
      footer={false}
      dataSource={invoiceList}
      renderItem={(item) => {
        return (
          <>
            <List.Item className={"list-item"}>
              <Row
                align="middle"
                style={{
                  width: "100%",
                  padding: 10,
                  background: token.colorBgContainer,
                  outline:
                    item.approvalStatus === INVOICE_CANCELLED
                      ? `2px solid ${token.colorErrorBorder}`
                      : "none",
                  outlineOffset: item.approvalStatus === INVOICE_CANCELLED ? -2 : 0
                }}>
                <Col span={5}>
                  <Typography.Text style={{ fontSize: token.fontSizeSM }}>
                    {item.invoiceNumber}
                  </Typography.Text>
                </Col>
                <Col span={7}>
                  <Typography.Text style={{ fontSize: token.fontSizeSM }}>
                    {formatter.format(item.amount)}
                  </Typography.Text>
                </Col>
                {showPaymentStatus && (
                  <Col span={5}>
                    <MobilePaymentTag invoice={item} />
                  </Col>
                )}
                <Col span={5}>
                  <MobileApprovalTag invoice={item} />
                </Col>
                <Col span={2}>
                  <Dropdown
                    menu={{
                      items: getActionItems(item)
                    }}
                    trigger={["click", "contextMenu"]}>
                    <a onClick={(e) => e.preventDefault()}>
                      <MoreOutlinedIcon style={{ fontSize: 18, background: token.colorBgBase }} />
                    </a>
                  </Dropdown>
                </Col>
              </Row>
            </List.Item>
            <CommonListDivider />
          </>
        );
      }}
    />
  );
};

export default MobileList;
