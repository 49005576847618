import { FC } from "react";
import { Col, Typography, Select } from "antd";
import { useAntToken } from "@hooks/common/useAntToken";
import { useBreakpoints } from "@hooks/common/useBreakPoints";
import { TUnit } from "@Component/Common/types";
import { reviewInvoiceStyles } from "@Component/Common/ReviewInvoice/styles";

interface IGlobalInvoiceUnitSelect {
  handleEditLineItem?: (
    lineItemId: string,
    lineItemChangeKey: string,
    lineItemValue: unknown,
    unitAllToAll?: boolean
  ) => void;
  units: TUnit[];
  globalUnit: string;
  setGlobalUnit: (globalUnit: string) => void;
}
const GlobalInvoiceUnitSelect: FC<IGlobalInvoiceUnitSelect> = (props) => {
  const { token } = useAntToken();
  const { handleEditLineItem, units, globalUnit, setGlobalUnit } = props;
  const { isScreenXS } = useBreakpoints();
  const { styles } = reviewInvoiceStyles();

  const applyUnitToAll = (value: string) => {
    setGlobalUnit(value);
    if (handleEditLineItem) {
      handleEditLineItem("toALL", "unit", value, true);
    }
  };

  return (
    <Col
      span={24}
      style={{
        paddingLeft: isScreenXS ? token.paddingXS : token.paddingXL,
        paddingRight: token.paddingXL,
        paddingBottom: isScreenXS ? 0 : token.fontSizeXL
      }}>
      <Typography.Text
        style={{
          fontWeight: token.fontWeightStrong,
          margin: 0,
          paddingRight: 20
        }}>
        Use Unit Number for All Item(s) on Invoice
      </Typography.Text>
      <Select
        style={{ width: isScreenXS ? "40%" : "15%" }}
        showSearch
        optionFilterProp="label"
        className={styles["ant-select-override"]}
        defaultValue={"None"}
        value={globalUnit}
        onChange={applyUnitToAll}
        options={units?.map((u: TUnit) => {
          return {
            value: u.id,
            label: u.name
          };
        })}
      />
    </Col>
  );
};
export default GlobalInvoiceUnitSelect;
