import { Col, Typography, theme, Divider, Input, Form, Flex, Checkbox } from "antd";
import { FC, useState } from "react";
import CommonAppButton from "@Component/Common/CommonAppButton";
import { useForm } from "antd/es/form/Form";
import CommonFormLabel from "@Component/Common/CommonFormLabel";
import { commonAntStyles } from "@Component/Common/styles";
import SelectProperties from "@Component/Common/Property/SelectProperties";
import { TProperties } from "../AdminViewUsers/types";
import { TFormProperty } from "@Component/Common/types";
import AdminCancelAndGoBack from "@Component/Common/AdminCancelAndGoBack";
import SelectCard from "@Component/Common/Card/SelectCard";

const { useToken } = theme;

interface Props {
  loading: boolean;
  properties: TProperties[];
  cards: string[];
  handleSubmit: (values: {
    name: string;
    email: string;
    assignedProperties: TFormProperty[];
    isRegionalManager: boolean;
    regionalManagerThreshold: string;
    cardDetails?: string[];
  }) => void;
}

const AdminInviteBusinessUser: FC<Props> = (props) => {
  const { token } = useToken();
  const [disableLoader, setDisableLoader] = useState(false);
  const { styles: commonStyles } = commonAntStyles();

  const { loading, properties, cards, handleSubmit } = props;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const fontWeightNormal = token.fontWeightNormal;

  type FieldType = {
    name?: string;
    email?: string;
    assignedProperties?: string;
  };

  const [form] = useForm();
  const commonInputStyles = {
    height: token.controlHeightLG,
    backgroundColor: token.colorBgBase,
    borderRadius: token.borderRadiusSM,
    border: `1px solid ${token.colorBorder}`
  };

  const checkPrice = (_: any, value: any) => {
    if (value > 0) {
      return Promise.resolve();
    }
    return Promise.reject(new Error("Threshold must be greater than zero"));
  };

  return (
    <Flex
      justify="center"
      align="middle"
      style={{ width: "100%", marginTop: token.marginLG, marginBottom: token.marginLG }}>
      <Flex vertical justify="center" align="middle" style={{ maxWidth: "350px", width: "100%" }}>
        <Col style={{ paddingLeft: token.paddingSM, marginBottom: token.marginSM }}>
          <Typography.Title
            level={2}
            style={{
              color: token.colorText,
              fontSize: token.fontSizeHeading2,
              fontWeight: token.fontWeightStrong
            }}>
            Invite Manager
          </Typography.Title>
        </Col>
        <Col style={{ background: token.colorBgBase, padding: token.paddingLG }}>
          <Form
            onFinish={(values) => {
              setDisableLoader(true);
              handleSubmit(values);
            }}
            form={form}
            layout="vertical">
            <Form.Item<FieldType>
              name="name"
              className={commonStyles["ant-input-override"]}
              rules={[{ required: true, message: "Please input your name!" }]}
              label={
                <CommonFormLabel
                  style={{
                    color: token.colorText,
                    fontSize: token.fontSize,
                    fontWeight: token.fontWeightStrong
                  }}>
                  Name
                </CommonFormLabel>
              }>
              <Input placeholder="Name" style={commonInputStyles} disabled={loading} />
            </Form.Item>
            <Form.Item<FieldType>
              name="email"
              className={commonStyles["ant-input-override"]}
              rules={[{ required: true, message: "Please input your email!" }]}
              label={
                <CommonFormLabel
                  style={{
                    color: token.colorText,
                    fontSize: token.fontSize,
                    fontWeight: token.fontWeightStrong
                  }}>
                  Email
                </CommonFormLabel>
              }>
              <Input placeholder="Email" style={commonInputStyles} disabled={loading} />
            </Form.Item>
            <Divider style={{ marginBottom: 18 }} />
            <Typography.Title
              level={2}
              style={{
                color: token.colorText,
                fontSize: token.fontSizeXL,
                fontWeight: token.fontWeightStrong
              }}>
              Permissions
            </Typography.Title>
            <Form.Item
              name="isRegionalManager"
              valuePropName="checked"
              style={{ marginBottom: token.marginSM }}>
              <Checkbox
                disabled={loading}
                style={{
                  color: token.colorText,
                  fontSize: 14,
                  fontWeight: fontWeightNormal
                }}>
                Regional manager
              </Checkbox>
            </Form.Item>
            <Form.Item
              className={commonStyles["ant-input-override"]}
              name={"regionalManagerThreshold"}
              label={
                <CommonFormLabel
                  style={{
                    color: token.colorText,
                    fontSize: token.fontSize,
                    fontWeight: token.fontWeightStrong
                  }}>
                  Threshold
                </CommonFormLabel>
              }
              rules={[
                {
                  required: true,
                  message: `Please enter threshold for invoice amount`
                },
                { validator: checkPrice }
              ]}>
              <Input style={commonInputStyles} placeholder="Threshold For Invoice Amount" />
            </Form.Item>
            <SelectProperties
              fetchedProperties={properties}
              properties={properties}
              form={form}
              propertiesLoading={!disableLoader && loading}
            />
            <SelectCard cards={cards} />
            <CommonAppButton
              type="primary"
              block
              loading={loading}
              htmlType="submit"
              style={{
                height: token.controlHeightLG,
                marginTop: token.marginLG,
                color: token.colorTextLightSolid,
                fontSize: token.fontSizeLG,
                boxShadow: token.boxShadow,
                fontWeight: fontWeightNormal
              }}>
              Send Invite
            </CommonAppButton>
            <AdminCancelAndGoBack marginTop={12} />
          </Form>
        </Col>
      </Flex>
    </Flex>
  );
};
export default AdminInviteBusinessUser;
