import { Col, Row, Typography } from "antd";
import { FC, useState } from "react";
import { useAntToken } from "@hooks/common/useAntToken";
import AddInvoiceItemModal from "./AddInvoiceItemModal";
import InvoiceContentHeader from "./InvoiceContentHeader";
import InvoiceLineItems from "./InvoiceLineItems";
import InvoiceContentFooter from "./InvoiceContentFooter";
import { IReviewInvoiceProps } from "./utils";
import InvoiceSideContent from "./InvoiceSideContent";
import { useBreakpoints } from "@hooks/common/useBreakPoints";
import CommonAppButton from "@Component/Common/CommonAppButton";
import { PlusCircleOutlined } from "@ant-design/icons";
import Loader from "@Component/Common/Loader";
import { UNIT_PROPERTY } from "@utils/constants";
import GlobalInvoiceUnitSelect from "@Component/Common/Unit/GlobalInvoiceUnitSelect";

const HomedepotReviewInvoice: FC<IReviewInvoiceProps> = (props) => {
  const { token } = useAntToken();
  const {
    homeDepotPropertyLoading,
    invoiceInfo,
    onIssuingCompanyInfoChange,
    onIssueDateChange,
    lineItems,
    handleLineItemDelete,
    displayAddItemModal,
    setDisplayAddItemModal,
    addItem,
    glCodes,
    invoiceTotal,
    handleSubmit,
    loading,
    navigateToUploadInvoicePage,
    formRef,
    homeDepoProperties,
    handleDisplayAddItemModal,
    editGLCodes,
    totalTaxAmount,
    onTaxAmountChange,
    originalInvoiceUrl,
    onSelectedRowTaxableChange,
    selectedRowTaxable,
    handleEditLineItem,
    units,
    handlePropertyChange,
    readOnly
  } = props;
  const { isScreenXS, isScreenLG } = useBreakpoints();
  const [globalUnit, setGlobalUnit] = useState("None");
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let itemDetailsStyle: { [key: string]: any } = {
    fontWeight: token.fontWeightStrong,
    padding: 0,
    margin: 0,
    paddingBottom: token.paddingMD,
    fontSize: isScreenXS ? token.fontSizeLG : token.fontSizeXL
  };

  itemDetailsStyle = { ...itemDetailsStyle, paddingTop: token.paddingMD, marginLeft: 0 };

  if (isScreenXS) {
    itemDetailsStyle.paddingBottom = 0;
    itemDetailsStyle.top = 40;
  }
  if (isScreenLG) {
    itemDetailsStyle.paddingBottom = 20;
    itemDetailsStyle.top = 20;
  }

  if (loading) {
    return <Loader isLoading={loading} />;
  }
  return (
    <>
      <Row
        gutter={[16, 16]}
        style={{ padding: isScreenXS ? 0 : token.paddingXL, width: "100%", margin: "auto" }}>
        <Col sm={24} xl={18}>
          <Row justify="center" style={{ width: "100%" }}>
            <Col
              span={24}
              style={{
                background: token.colorBgBase,
                minHeight: "85vh",
                marginBottom: token.paddingXL
              }}>
              <InvoiceContentHeader
                homeDepotPropertyLoading={homeDepotPropertyLoading}
                invoiceInfo={invoiceInfo}
                originalInvoiceUrl={originalInvoiceUrl}
                loading={loading}
                navigateToUploadInvoicePage={navigateToUploadInvoicePage}
                homeDepoProperties={homeDepoProperties}
                handlePropertyChange={(value: string) => {
                  handlePropertyChange(value);
                  setGlobalUnit("None");
                }}
                readOnly={readOnly}
              />
              <Col
                xs={20}
                sm={20}
                md={10}
                lg={10}
                xl={10}
                xxl={10}
                style={{
                  paddingLeft: isScreenXS ? token.paddingMD : token.paddingXL,
                  paddingRight: token.paddingXL
                }}>
                <Typography.Title level={3} style={itemDetailsStyle}>
                  Item Details
                </Typography.Title>
              </Col>
              {!readOnly && (
                <GlobalInvoiceUnitSelect
                  units={units}
                  handleEditLineItem={handleEditLineItem}
                  globalUnit={globalUnit}
                  setGlobalUnit={(value: string) => setGlobalUnit(value)}
                />
              )}
              <InvoiceLineItems
                editGLCodes={editGLCodes}
                lineItems={lineItems}
                glCodes={glCodes}
                handleLineItemDelete={handleLineItemDelete}
                onSelectedRowTaxableChange={onSelectedRowTaxableChange}
                selectedRowTaxable={selectedRowTaxable}
                handleEditLineItem={handleEditLineItem}
                loading={loading}
                units={units}
                propertyType={UNIT_PROPERTY.value}
                readOnly={readOnly}
              />
              {!readOnly && (
                <Row
                  justify={isScreenXS ? "end" : "start"}
                  style={{
                    marginTop: token.marginSM,
                    marginRight: isScreenXS ? token.marginLG : 0
                  }}>
                  <Col md={1} lg={1}></Col>
                  <Col
                    xs={24}
                    md={23}
                    lg={22}
                    style={{ paddingLeft: isScreenXS ? token.marginMD : token.marginXXS }}>
                    <Row justify="start">
                      <Col span={24}>
                        <CommonAppButton
                          type="primary"
                          block={isScreenXS}
                          icon={<PlusCircleOutlined />}
                          style={{
                            border: 0,
                            fontSize: token.fontSizeLG,
                            fontWeight: token.fontWeightStrong,
                            height: token.controlHeightLG
                          }}
                          onClick={handleDisplayAddItemModal}>
                          Add Item
                        </CommonAppButton>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}

              <InvoiceContentFooter
                invoiceTotal={invoiceTotal}
                onTaxAmountChange={onTaxAmountChange}
                totalTaxAmount={totalTaxAmount}
                readOnly={readOnly}
              />
            </Col>
          </Row>
        </Col>
        <Col sm={24} xl={6} style={{ width: "100%" }}>
          <Row
            style={{
              background: token.colorBgBase,
              minHeight: isScreenXS ? "10vh" : "85vh"
            }}>
            <InvoiceSideContent
              invoiceId={invoiceInfo.invoiceId}
              invoiceInfo={invoiceInfo}
              onIssuingCompanyInfoChange={onIssuingCompanyInfoChange}
              onIssueDateChange={onIssueDateChange}
              handleSubmit={handleSubmit}
              loading={loading}
              originalInvoiceUrl={originalInvoiceUrl}
              readOnly={readOnly}
            />
          </Row>
        </Col>
      </Row>

      <AddInvoiceItemModal
        addItem={addItem}
        displayAddItemModal={displayAddItemModal}
        setDisplayAddItemModal={setDisplayAddItemModal}
        formRef={formRef}
        glCodes={glCodes}
        units={units}
        propertyType={UNIT_PROPERTY.value}
      />
    </>
  );
};
export default HomedepotReviewInvoice;
