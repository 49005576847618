import { APP_BASE_URL, signInUrl } from "../utils/urls";
import http from "../utils/httpService";
import ShowToast from "../utils/showToast";
import { isAxiosError } from "axios";

export const login = async (email: string, password: string) => {
  try {
    const { data } = await http.post(`${APP_BASE_URL}${signInUrl}`, { email, password });
    return { data };
  } catch (error: unknown) {
    if (isAxiosError(error)) {
      ShowToast(error.response?.data.message, "error");
    }
  }
};
