import { TDefaultSignDocumentValuesObject } from "@Component/Common/ReviewInvoice/utils";
import { useState } from "react";
import { InvoiceProps } from "@Component/Common/types";
import formatter from "@utils/formatter";
import moment from "moment";

const useSignDocumentState = () => {
  const [defaultValues, setDefaultValues] = useState<TDefaultSignDocumentValuesObject>();
  const [openSignDocumentModal, setOpenSignDocumentModal] = useState<boolean>(false);
  const [isFormSigned, setIsFormSigned] = useState<boolean>(false);
  const [submissionId, setSubmissionId] = useState<string>();

  const handleFormSignSuccess = (props: { submission_id: string }) => {
    setIsFormSigned(true);
    setSubmissionId(props.submission_id);
  };
  const handleSignDocumentModalClose = () => {
    setOpenSignDocumentModal(false);
  };
  const handleSignDocumentModalOpen = () => {
    setOpenSignDocumentModal(true);
  };

  const updateDefaultValues = ({
    invoiceInfo,
    vendorName,
    invoiceTotal,
    vendorEmail
  }: {
    invoiceInfo: InvoiceProps;
    vendorName?: string;
    invoiceTotal?: number;
    vendorEmail?: string;
  }) => {
    const invoiceInForDefaultValues: TDefaultSignDocumentValuesObject = {};
    if (invoiceInfo?.propertyName) {
      invoiceInForDefaultValues.ownerName = invoiceInfo?.propertyName;
    }
    if (vendorName) {
      invoiceInForDefaultValues.vendorName = vendorName;
      invoiceInForDefaultValues.contractorName = vendorName;
    }
    if (vendorEmail) {
      invoiceInForDefaultValues.vendorEmail = vendorEmail;
    }
    if (invoiceTotal) {
      invoiceInForDefaultValues.invoiceAmount = formatter.format(invoiceTotal).slice(1);
    }

    if (invoiceInfo?.issueDate) {
      invoiceInForDefaultValues.invoiceUploadDate = moment(invoiceInfo?.issueDate).format(
        "YYYY-MM-DD"
      );
    }
    invoiceInForDefaultValues.vendorAddress = invoiceInfo?.address;
    invoiceInForDefaultValues.propertyLocation = invoiceInfo?.propertyAddress;
    invoiceInForDefaultValues.contractorAddress = invoiceInfo?.address;
    setDefaultValues((prev) => ({
      ...prev,
      ...invoiceInForDefaultValues,
      today: moment().date(),
      currentMonth: moment().format("MMMM"),
      currentYear: Number(moment().year().toString().slice(-2))
    }));
  };

  return {
    defaultValues,
    updateDefaultValues,
    openSignDocumentModal,
    handleSignDocumentModalClose,
    handleSignDocumentModalOpen,
    isFormSigned,
    handleFormSignSuccess,
    submissionId,
    setIsFormSigned
  };
};

export default useSignDocumentState;
