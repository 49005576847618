import { useAntToken } from "@hooks/common/useAntToken";
import { Col, Row, Tag } from "antd";
import { FC } from "react";

interface ICardTagProps {
  cards: string[];
  onDeleteTag: (tag: string) => void;
}

const CardTags: FC<ICardTagProps> = (props) => {
  const { cards, onDeleteTag } = props;
  const { token } = useAntToken();

  if (!cards?.length) return null;
  return (
    <Row
      justify="start"
      align="top"
      style={{
        maxHeight: "150px",
        overflow: "auto"
      }}>
      {cards.map((card: string, index) => {
        return (
          <Col key={card + index}>
            <Tag
              bordered
              onClose={() => {
                onDeleteTag(card);
              }}
              closable
              color={token.colorPrimary}
              key={card}
              style={{
                border: 0,
                borderRadius: token.borderRadiusSM,
                marginTop: token.marginXXS
              }}>
              {String(card).length > 28 ? `${String(card).substring(0, 28)}...` : card}
            </Tag>
          </Col>
        );
      })}
    </Row>
  );
};

export default CardTags;
