/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from "react";
import { Col, Row, Table } from "antd";
import formatter from "@utils/formatter";
import { columns } from "./utils";
import { vendorDashboardStyles } from "./styles";
import { useAntToken } from "@hooks/common/useAntToken";
interface IInvoiceLineItemsTableProps {
  lineItems: Array<{ description: string; pricePerUnit: number; gl: string }>;
  propertyType: string;
}

const InvoiceLineItemsTable: FC<IInvoiceLineItemsTableProps> = (
  props: IInvoiceLineItemsTableProps
) => {
  const { lineItems, propertyType } = props;
  const { styles } = vendorDashboardStyles();
  const { token } = useAntToken();
  const pdfFontFamily = "sans-serif";
  const tableColumns = [...columns];
  if (propertyType === "UnitProperty") {
    tableColumns.push({
      title: <p style={{ fontSize: 9, fontWeight: 600 }}>Unit</p>,
      dataIndex: "itemUnit",
      key: "itemUnit",
      width: "20%"
    });
  }
  return (
    <div style={{ marginTop: token.marginSM }}>
      {lineItems.length > 0 && (
        <Table
          dataSource={lineItems.map((l: any, index: number) => {
            const d = structuredClone(l);
            d.key = index;
            d.glCode = (
              <Row gutter={[8, 0]}>
                <Col
                  style={{
                    fontSize: 6,
                    fontFamily: pdfFontFamily
                  }}>
                  {`${d.glCode?.reference} ${d.glCode?.name}`}
                </Col>
              </Row>
            );
            d.lineItemDescription = d.description ? d.description : "";
            d.lineItemTaxable = (
              <input
                type="checkbox"
                checked={d.isTaxable}
                style={{
                  height: "8px",
                  width: "8px",
                  background: "white",
                  borderRadius: 0
                }}
              />
            );
            d.lineItemPreTaxAmount = <span>{formatter.format(d.preTaxAmount)}</span>;
            d.lineItemPostTaxAmount = <span>{formatter.format(d.amount)}</span>;
            if (propertyType === "UnitProperty") {
              d.itemUnit = <span>{d.unit?.name || "None"}</span>;
            }
            return d;
          })}
          columns={tableColumns}
          pagination={false}
          className={styles["ant-table-overider"]}
        />
      )}
    </div>
  );
};

export default InvoiceLineItemsTable;
