import { AnyAction } from "redux";
import {
  REQUEST_TO_GET_ALL_INVOICES,
  RECEIVE_ALL_INVOICES,
  GETTING_ERROR_IN_GET_ALL_INVOICES,
  SET_CURRENT_PAGE,
  RESET_INVOICE_LIST,
  SET_PAYMENT_STATUS,
  SET_APPROVAL_STATUS,
  INVOICE_LIST_WITH_INITIAL_FILTERS
} from "../../types/Invoice/InvoiceList";

export interface IInvoiceListState {
  invoices: Array<unknown>;
  isLoading: boolean;
  pageNum: number;
  pageSize: number;
  totalPages: number;
  paymentStatus: string[];
  approvalStatus: string[];
  errorMessage: unknown | null;
}

const initialState: IInvoiceListState = {
  invoices: [],
  isLoading: false,
  pageNum: 1,
  pageSize: 10,
  totalPages: 1,
  paymentStatus: [],
  approvalStatus: [],
  errorMessage: null
};

const invoiceList = (state = initialState, action: AnyAction) => {
  if (typeof state === "undefined") {
    return initialState;
  }

  switch (action.type) {
    case REQUEST_TO_GET_ALL_INVOICES:
      return {
        ...state,
        isLoading: true,
        hasError: false,
        errorMessage: null
      };
    case RECEIVE_ALL_INVOICES:
      const { invoices, pageNum, pageSize, totalPages } = action.payload; // eslint-disable-line
      return {
        ...state,
        isLoading: false,
        invoices,
        pageNum,
        pageSize,
        totalPages
      };
    case GETTING_ERROR_IN_GET_ALL_INVOICES:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        errorMessage: action.payload.errorMessage
      };
    case SET_PAYMENT_STATUS:
      return {
        ...state,
        pageNum: 1,
        pageSize: 10,
        paymentStatus: action.payload
      };
    case SET_APPROVAL_STATUS:
      return {
        ...state,
        pageNum: 1,
        pageSize: 10,
        approvalStatus: action.payload
      };
    case SET_CURRENT_PAGE:
      return {
        ...state,
        pageNum: action.payload.pageNum,
        pageSize: action.payload.pageSize
      };
    case INVOICE_LIST_WITH_INITIAL_FILTERS:
      return {
        ...state,
        pageNum: 1,
        pageSize: 10,
        totalPages: 1,
        paymentStatus: "",
        approvalStatus: ""
      };
    case RESET_INVOICE_LIST:
      return initialState;
    default:
      return state;
  }
};

export default invoiceList;
