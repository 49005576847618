import { createStyles } from "antd-style";

export const uploadInvoiceStyles = createStyles(({ token, css }) => ({
  "ant-select-override": {
    ".ant-select-arrow": css`
      color: ${token.colorText};
      font-size: ${token.fontSize}px !important;
    `
  },
  "ant-upload-override": {
    ".ant-upload-wrapper": css`
      display: block !important;
    `
  }
}));
