import { CheckboxChangeEvent } from "antd/es/checkbox";
import { List, Row, Col, Typography, Pagination, Modal, Divider } from "antd";
import { FC, useEffect, useState } from "react";
import { ALL, TVendorActiveStatus, TManagerType } from "@utils/constants";
import UsersListHeader from "./UsersListHeader";
import { useUsersListStyles } from "./useUsersListStyles";
import { useAntToken } from "@hooks/common/useAntToken";

import { TAdminUser, TProperties, TReinviteAdminUser } from "./types";
import useAdminSearchParams from "@hooks/Admin/useAdminSearchParams";
import { useBreakpoints } from "@hooks/common/useBreakPoints";
import { DeleteIcon } from "@assets/icons/CustomIcons";
import DesktopListItems from "./DesktopListItems";
import TabAndSmallerListItems from "./TabAndSmallerListItems";
import CommonAppButton from "@Component/Common/CommonAppButton";
import Loader from "@Component/Common/Loader";
import useAdminReInviteUser from "@hooks/Admin/useAdminReInviteUser";
import { TAdminUserRole } from "@Component/Admin/types";
interface IUsersListProps {
  headers: string[];
  users: TAdminUser[];
  userType: string;
  handleOpenInviteVendorModal: () => void;
  handleUserChange: (user: TAdminUser) => void;
  isLoading: boolean;
  totalPages: number;
  handleDeleteUser: (authUserId: string) => void;
  properties: TProperties[];
  rolePropertiesDataLoading: boolean;
}
const UsersList: FC<IUsersListProps> = (props) => {
  const {
    headers,
    users,
    userType,
    handleOpenInviteVendorModal,
    handleUserChange,
    isLoading,
    totalPages,
    handleDeleteUser,
    properties,
    rolePropertiesDataLoading
  } = props;
  const {
    setInvitationStatus,
    setPageNumber,
    setPageSize,
    pageSize,
    pageNumber,
    invitationStatus,
    setVendorStatus,
    vendorStatus,
    managerType: managerTypeProp,
    setManagerType: setManagerTypeHook,
    setProperty,
    propertyIds
  } = useAdminSearchParams();
  const { mutate: reInviteAdminUsers } = useAdminReInviteUser();
  const [approvalStatus, setApprovalStatus] = useState<any[]>([ALL]);
  const [vendorStatusLocal, setVendorStatusLocal] = useState<TVendorActiveStatus>(
    (vendorStatus as TVendorActiveStatus) || ALL
  );
  const [managerType, setManagerType] = useState<TManagerType>(
    (managerTypeProp as TManagerType) || ALL
  );
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [itemToDelete, setItemToDelete] = useState<TAdminUser>();
  const [propertiesFilter, setPropertiesFilter] = useState<any[]>([]);
  useAdminSearchParams();

  const handleReInviteUser = (user: TAdminUser, role: TAdminUserRole) => {
    const selectedUser: TReinviteAdminUser[] = [
      {
        authUserId: user.authUserId,
        name: user.name,
        email: user.email,
        role: role,
        secondaryEmail: user?.secondaryEmail ? user.secondaryEmail : null,
        useSecondaryEmail: user?.useSecondaryEmail ? user.useSecondaryEmail : false
      }
    ];
    reInviteAdminUsers({ users: selectedUser });
  };

  const handleShowDeleteModal = (item: TAdminUser) => {
    setItemToDelete(item);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteModalOk = () => {
    if (itemToDelete) handleDeleteUser(itemToDelete.authUserId);
    setIsDeleteModalOpen(false);
  };

  const handleDeleteModalCancel = () => {
    setIsDeleteModalOpen(false);
  };

  const { styles } = useUsersListStyles();
  const { token } = useAntToken();
  const { isScreenLG } = useBreakpoints();
  const isTabOrLowerScreen = !isScreenLG;
  const onApprovalStatusChange = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      setApprovalStatus([...approvalStatus, e.target.name]);
      setInvitationStatus([...approvalStatus, e.target.name]);
    } else {
      const filtered = approvalStatus.filter((status) => status != e.target.name);
      setApprovalStatus(filtered);
      setInvitationStatus(filtered);
    }
  };
  const onManagerTypeChange = (e: CheckboxChangeEvent) => {
    if (e.target.name) {
      setManagerType(e.target.name as TManagerType);
      setManagerTypeHook(e.target.name as TManagerType);
    }
  };
  const onVendorStatusChange = (e: CheckboxChangeEvent) => {
    if (e.target.name) {
      setVendorStatusLocal(e.target.name as TVendorActiveStatus);
      setVendorStatus(e.target.name as TVendorActiveStatus);
    }
  };

  const onPropertySearchChange = (value: []) => {
    setPropertiesFilter(value.map((val: any) => Number(val)));
  };

  const handlePropertySearchAction = () => {
    setProperty(propertiesFilter);
  };

  useEffect(() => {
    setApprovalStatus(
      (invitationStatus ? invitationStatus.split(",").map((val: any) => val) : []) as []
    );
  }, [invitationStatus, setApprovalStatus]);

  useEffect(() => {
    setManagerType((managerTypeProp as TManagerType) || ALL);
  }, [managerTypeProp, setManagerType]);

  useEffect(() => {
    setVendorStatusLocal((vendorStatus as TVendorActiveStatus) || ALL);
  }, [vendorStatus, setVendorStatusLocal]);

  useEffect(() => {
    setPropertiesFilter(
      (propertyIds ? propertyIds.split(",").map((val: any) => Number(val)) : []) as []
    );
  }, [propertyIds, setPropertiesFilter]);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const colorPrimaryBase = token.colorPrimaryBase;

  if (isLoading) {
    return <Loader isLoading={isLoading} />;
  }
  const DeleteModalFooter = () => {
    return (
      <Row gutter={[8, 8]} style={{ paddingLeft: token.padding, paddingRight: token.padding }}>
        <Col span={12}>
          <CommonAppButton
            type="default"
            style={{
              height: 40,
              border: `1px solid ${token.colorTextPlaceholder}`,
              background: token.colorBgContainer
            }}
            onClick={handleDeleteModalCancel}
            block>
            No
          </CommonAppButton>
        </Col>
        <Col span={12}>
          <CommonAppButton
            type="primary"
            style={{ height: 40, background: colorPrimaryBase }}
            onClick={handleDeleteModalOk}
            block>
            Yes
          </CommonAppButton>
        </Col>
      </Row>
    );
  };

  return (
    <>
      <Modal
        title={null}
        className={styles["delete-modal"]}
        width={330}
        open={isDeleteModalOpen}
        onOk={handleDeleteModalOk}
        footer={<DeleteModalFooter />}
        onCancel={handleDeleteModalCancel}>
        <Row justify="center">
          <Col style={{ marginTop: token.marginLG }}>
            <DeleteIcon style={{ fontSize: 105 }} />
          </Col>
        </Row>
        <Row justify="center">
          <Col
            style={{ marginTop: token.marginLG, textAlign: "center", padding: token.padding }}
            span={24}>
            <Typography.Text
              style={{ fontSize: token.fontSizeXL, fontWeight: token.fontWeightStrong }}>
              Are you sure you want to Disable {itemToDelete?.name}?
            </Typography.Text>
          </Col>
        </Row>
        <Divider />
      </Modal>
      <List
        loading={isLoading}
        className={styles["ant-list-over-ride"]}
        style={{
          marginTop: isTabOrLowerScreen ? token.marginLG : 0,
          borderRadius: token.borderRadius,
          boxShadow: token.boxShadow,
          backgroundColor: token.colorBgBase,
          padding: token.paddingSM,
          paddingBottom: 0
        }}
        header={
          <UsersListHeader
            approvalStatus={approvalStatus}
            onApprovalStatusChange={onApprovalStatusChange}
            headers={headers}
            userType={userType}
            vendorStatus={vendorStatusLocal}
            onVendorStatusChange={onVendorStatusChange}
            managerType={managerType}
            onManagerTypeChange={onManagerTypeChange}
            properties={properties}
            handlePropertySearch={onPropertySearchChange}
            propertiesFilter={propertiesFilter}
            handlePropertySearchAction={handlePropertySearchAction}
            rolePropertiesDataLoading={rolePropertiesDataLoading}
          />
        }
        footer={false}
        dataSource={users}
        renderItem={(item: TAdminUser, key: number) => {
          return !isTabOrLowerScreen ? (
            <DesktopListItems
              item={item}
              itemKey={key}
              userType={userType}
              handleShowDeleteModal={handleShowDeleteModal}
              handleOpenInviteVendorModal={handleOpenInviteVendorModal}
              handleUserChange={handleUserChange}
              handleReInviteUser={handleReInviteUser}
            />
          ) : (
            <TabAndSmallerListItems
              item={item}
              itemKey={key}
              userType={userType}
              handleShowDeleteModal={handleShowDeleteModal}
              handleOpenInviteVendorModal={handleOpenInviteVendorModal}
              handleUserChange={handleUserChange}
              headers={headers}
              handleReInviteUser={handleReInviteUser}
            />
          );
        }}
      />
      <Row justify="center" style={{ marginTop: token.marginXL, marginBottom: token.marginXL }}>
        <Col>
          <Pagination
            size={isTabOrLowerScreen ? "small" : "default"}
            current={Number(pageNumber)}
            pageSize={Number(pageSize)}
            onChange={(page: number) => {
              setPageNumber(String(page));
            }}
            onShowSizeChange={(pageSize: number) => {
              setPageSize(String(pageSize));
            }}
            total={Number(totalPages) * Number(pageSize) || 0}
            className={styles["ant-pagination-over-ride"]}
          />
        </Col>
      </Row>
    </>
  );
};

export default UsersList;
