import { combineReducers, AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import auth, { IAuthState } from "./Auth/Auth";
import invoice, { IInvoiceState } from "./Invoice/Invoice";
import invoiceList, { IInvoiceListState } from "./Invoice/InvoiceList";

export default combineReducers({
  auth,
  invoice,
  invoiceList
});

export interface IAppState {
  auth: IAuthState;
  invoice: IInvoiceState;
  invoiceList: IInvoiceListState;
}

export type AppDispatch = ThunkDispatch<IAppState, unknown, AnyAction>;
