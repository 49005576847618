import { AppDispatch } from "../../reducers/rootReducer";
import {
  REQUEST_USER_LOGIN,
  USER_LOGGED_IN,
  LOGOUT_USER,
  SET_USER_ROLE
} from "../../types/Auth/Auth";
import { login } from "../../../Services/AuthService";

export interface ILoginValues {
  email: string;
  password: string;
}

export const requestUserLogin = () => {
  return {
    type: REQUEST_USER_LOGIN
  };
};

export const userLoggedIn = (loginInfo: unknown) => {
  return {
    type: USER_LOGGED_IN,
    payload: loginInfo
  };
};

export const setUserRole = (role: string) => {
  return {
    type: SET_USER_ROLE,
    payload: role
  };
};

export const logIn = (data: ILoginValues) => {
  return async (dispatch: AppDispatch) => {
    const { email, password } = data;
    try {
      dispatch(requestUserLogin());
      const loginInfo = await login(email, password);
      dispatch(userLoggedIn(loginInfo));
      dispatch(setUserRole(loginInfo?.data.role));
    } catch (e) {
      dispatch(Logout());
    }
  };
};

export const Logout = () => {
  return {
    type: LOGOUT_USER
  };
};
