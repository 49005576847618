import { useQuery } from "@tanstack/react-query";
import { TUserSearchFilters } from "@Component/Admin/types";
import { fetchAdminUsers } from "@Services/UserService";
import { React_Query_Keys } from "@utils/constants";
import useAdminSearchParams from "./useAdminSearchParams";

export const useFetchAdminUsers = (providedFilters?: TUserSearchFilters) => {
  const {
    invitationStatus,
    role,
    search,
    pageNumber,
    pageSize,
    vendorStatus,
    managerType,
    propertyIds
  } = useAdminSearchParams();
  const filters: TUserSearchFilters = {
    invitationStatus: providedFilters?.invitationStatus || invitationStatus,
    role: providedFilters?.role || role,
    search,
    pageNumber,
    pageSize,
    vendorStatus,
    isRegionalManager: managerType
  };

  if (propertyIds.length > 0) {
    filters.propertyIds = propertyIds.toString();
  }

  return useQuery({
    queryKey: [React_Query_Keys.ADMIN_USERS, filters],
    queryFn: () => fetchAdminUsers(filters)
  });
};
