import { useSearchParams } from "react-router-dom";

export interface ILogsFilterObject {
  pageNumber?: string;
  pageSize?: string;
  search?: string;
}
const useAdminLogsSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams({
    pageNumber: "1",
    pageSize: "10",
    search: ""
  });

  const defaultPageNumber = "1";
  const defaultPageSize = "10";
  const pageNumber = (searchParams.get("pageNumber") as string) || defaultPageNumber;
  const pageSize = (searchParams.get("pageSize") as string) || defaultPageSize;
  const search = searchParams.get("search") as string;
  const setPageNumber = (pageNumber: string, pageSize: string) => {
    const filterObject: ILogsFilterObject = {};
    filterObject.pageSize = pageSize || defaultPageSize;
    filterObject.pageNumber = pageNumber || defaultPageNumber;
    filterObject.search = search;
    setSearchParams({ ...filterObject });
  };

  const setPageSize = (pageSize: string) => {
    const filterObject: ILogsFilterObject = {};
    filterObject.pageSize = pageSize || defaultPageSize;
    filterObject.pageNumber = pageNumber || defaultPageNumber;
    setSearchParams({ ...filterObject });
  };

  const setSearch = (search: string) => {
    const filterObject: ILogsFilterObject = {};
    filterObject.pageNumber = defaultPageNumber;
    filterObject.pageSize = defaultPageSize;
    filterObject.search = search;
    setSearchParams({ ...filterObject });
  };
  return {
    pageNumber,
    pageSize,
    setPageNumber,
    setPageSize,
    search,
    setSearch
  };
};

export default useAdminLogsSearchParams;
