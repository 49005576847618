import { Form, Input, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useDebounce } from "use-debounce";

import { useEffect, useState } from "react";
import { useAntToken } from "@hooks/common/useAntToken";
import { useUsersListStyles } from "@Component/Admin/AdminViewUsers/useUsersListStyles";
import useAdminLogsSearchParams from "@hooks/Admin/useAdminLogsSearchParams";

const SearchUserLogs = () => {
  const { setSearch: setAdminLogsSearch } = useAdminLogsSearchParams();
  const [search, setSearch] = useState("");
  const [debouncedSearch] = useDebounce(search, 1000);
  const { token } = useAntToken();
  const { styles } = useUsersListStyles();

  useEffect(() => {
    setAdminLogsSearch(debouncedSearch);
    // eslint-disable-next-line
  }, [debouncedSearch]);

  return (
    <Space.Compact size="large" style={{ width: "100%" }} direction="vertical">
      <Form.Item
        style={{
          background: token.colorFillAlter,
          border: "none",
          height: token.controlHeightLG + 2
        }}>
        <Input
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          className={styles["ant-input-over-ride"]}
          style={{
            border: "none",
            backgroundColor: token.colorFillAlter,
            height: token.controlHeightLG + 2
          }}
          allowClear={true}
          prefix={
            <SearchOutlined
              style={{
                paddingRight: token.paddingXS,
                color: token.colorTextDescription,
                border: 0
              }}
              onClick={() => {
                setSearch(search);
              }}
            />
          }
          onPressEnter={() => {
            setSearch(search);
          }}
          placeholder={"Search logs with user name"}
        />
      </Form.Item>
    </Space.Compact>
  );
};

export default SearchUserLogs;
