import { Modal, Typography } from "antd";
import { useAntToken } from "@hooks/common/useAntToken";
import { FC } from "react";
import EditUserPermissionsForm from "./EditUserPermissionsForm";
import { TAdminUser, TProperties } from "./types";
import { TGlCode } from "@Component/Common/types";

interface IEditUserPermissionsModalProps {
  open: boolean;
  handleModalClose: (value: boolean) => void;
  glCodeGroupFetch: any;
  user: TAdminUser;
  fetchedGlCodes: TGlCode[];
  fetchedCards: string[];
  fetchedProperties: TProperties[];
  glCodesLoading: boolean;
  propertiesLoading: boolean;
}
const EditUserPermissionsModal: FC<IEditUserPermissionsModalProps> = (props) => {
  const {
    open,
    handleModalClose,
    user,
    fetchedGlCodes,
    fetchedCards,
    fetchedProperties,
    glCodesLoading,
    propertiesLoading,
    glCodeGroupFetch
  } = props;
  const { token } = useAntToken();
  return (
    <Modal
      title={
        <Typography.Title
          level={2}
          style={{
            color: token.colorText,
            fontSize: token.fontSizeHeading3,
            fontWeight: token.fontWeightStrong,
            marginTop: -5
          }}>
          Permissions
        </Typography.Title>
      }
      open={open}
      onCancel={() => handleModalClose(false)}
      footer={null}>
      <EditUserPermissionsForm
        loading={false}
        user={user}
        handleModalClose={handleModalClose}
        fetchedCards={fetchedCards}
        fetchedGlCodes={fetchedGlCodes}
        fetchedProperties={fetchedProperties}
        glCodesLoading={glCodesLoading}
        propertiesLoading={propertiesLoading}
        glCodeGroupFetch={glCodeGroupFetch}
      />
    </Modal>
  );
};

export default EditUserPermissionsModal;
