import { useState } from "react";
import { Row, Col, Dropdown, Typography, Table } from "antd";
import { useForm } from "antd/es/form/Form";
import { MoreOutlined } from "@ant-design/icons";
import { useAntToken } from "@hooks/common/useAntToken";
import EditCardModal from "@Component/Admin/AdminSettings/EditCardModal";
import { useCardsFetchAll } from "@hooks/Admin/HomeDepotCards/useCardsFetchAll";

const getColumns = (token: any, handleEditCard: any) => {
  return [
    {
      title: "Card",
      dataIndex: "cardDetails",
      width: "30%",
      key: "cardDetails"
    },
    {
      title: "Nick Name",
      key: "nickName",
      width: "52%",
      dataIndex: "nickName",
      render: (_: any, record: any) => <>{record.nickName || "No Label"}</>
    },
    {
      title: "Action",
      width: "15%",
      key: "action",
      render: (_: any, record: any) => (
        <Col
          style={{
            // marginLeft: 8,
            // marginRight: token.marginLG,
            marginTop: 6,
            cursor: "pointer",
            width: "28px",
            height: "28px",
            border: `1px solid ${token.colorBorder}`,
            boxShadow: token.boxShadow,
            borderRadius: token.borderRadiusSM,
            backgroundColor: "#fff",
            alignSelf: "flex-start",
            justifyContent: "center"
          }}>
          <Row justify="center">
            <Col>
              <Dropdown
                menu={{
                  items: [
                    {
                      label: "Edit Card",
                      key: "0",
                      onClick: () => {
                        handleEditCard(record);
                      }
                    }
                  ]
                }}
                trigger={["click", "contextMenu"]}
                placement="bottomRight">
                <a
                  onClick={(e) => e.preventDefault()}
                  style={{
                    fontSize: token.fontSizeLG,
                    fontWeight: token.fontWeightStrong,
                    color: token.colorText
                  }}>
                  <MoreOutlined />
                </a>
              </Dropdown>
            </Col>
          </Row>
        </Col>
      )
    }
  ];
};

const Cards = () => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedLine, setSelectedLine] = useState<any>(null);
  const { token } = useAntToken();
  const [form] = useForm();
  const { data: homedepotCards, isLoading } = useCardsFetchAll();

  const handleEditCardButton = (item: any) => {
    setSelectedLine(item);
    setOpenEditModal(true);
  };

  return (
    <Col>
      <EditCardModal
        selectedCard={selectedLine}
        form={form}
        visible={openEditModal}
        handleEditCard={() => setOpenEditModal(false)}
        handleCancel={() => setOpenEditModal(false)}
      />
      <Typography.Title
        level={4}
        style={{
          color: token.colorText,
          fontSize: token.fontSizeLG,
          fontWeight: token.fontWeightStrong
        }}>
        Home Depot Cards
      </Typography.Title>
      <Table
        style={{
          maxWidth: 800,
          width: "100%",
          overflow: "scroll",
          background: token.colorBgBase
        }}
        scroll={{ y: 350, x: 500 }}
        columns={getColumns(token, handleEditCardButton)}
        dataSource={homedepotCards}
        pagination={false}
        loading={isLoading}
      />
    </Col>
  );
};

export default Cards;
