import { useQuery } from "@tanstack/react-query";
import { React_Query_Keys } from "@utils/constants";
import { fetchAllInvoiceGlCodes } from "@Services/InvoiceService";

const useFetchAllGlCodes = () => {
  return useQuery({
    queryKey: [React_Query_Keys.ALL_GL_CODES],
    queryFn: () => fetchAllInvoiceGlCodes()
  });
};

export default useFetchAllGlCodes;
