import { Modal, Row, Col, Divider, Form, Input } from "antd";
import { useAntToken } from "@hooks/common/useAntToken";
import { useAdminSettingStyles } from "@Component/Admin/AdminSettings/styles";
import CommonFormLabel from "@Component/Common/CommonFormLabel";
import SelectGLCodes from "@Component/Common/GLCodes/SelectGLCodes";
import { useGlGroupEdit } from "@hooks/Admin/GlGroup";
import ModalFooter from "@Component/Admin/AdminSettings/ModalFooter";
import showToast from "@utils/showToast";

const EditGlGroupModal = (props: any) => {
  const { form, visible, glCodes, handleEditGroup, handleCancel, selectedGroup } = props;
  const { styles } = useAdminSettingStyles();
  const { token } = useAntToken();
  const { mutate: updateGlGroup, isPending } = useGlGroupEdit();

  form.setFieldsValue({
    name: selectedGroup?.name,
    assignedGLCodes: selectedGroup?.glCodes?.map((glCode: any) => {
      return {
        label: `${glCode.reference} ${glCode.name}`,
        value: glCode.id,
        id: glCode.id
      };
    })
  });
  const handleUpdateGroup = () => {
    const edit: any = form.getFieldsValue();
    const selectedGlCodeIds = edit.assignedGLCodes.map((glCode: any) => glCode.id);
    if (selectedGlCodeIds.length < 2) {
      showToast("Gl codes length must be 2", "error");
      return;
    }
    const selectedGlCode = glCodes?.data?.glCodes.filter((glCode: any) =>
      selectedGlCodeIds.includes(glCode.id)
    );
    updateGlGroup({
      id: selectedGroup?.Id,
      glGroup: { Id: selectedGroup?.Id, name: edit.name, glCodes: selectedGlCode }
    });
    form.resetFields();
    handleEditGroup();
  };

  return (
    <Modal
      open={visible}
      title={"Edit GL Code Group"}
      footer={false}
      onCancel={handleCancel}
      className={styles["add-modal"]}>
      <Row justify="center">
        <Col style={{ marginTop: token.marginLG, width: "100%" }}>
          <Form form={form} onFinish={handleUpdateGroup} layout="vertical">
            <Form.Item
              name="name"
              rules={[{ required: true, message: "Please Input group name" }]}
              label={
                <CommonFormLabel
                  style={{
                    color: token.colorText,
                    fontSize: token.fontSize,
                    fontWeight: token.fontWeightStrong
                  }}>
                  GL Group Name
                </CommonFormLabel>
              }>
              <Input
                placeholder="GL Group Name"
                style={{
                  height: token.controlHeightLG,
                  backgroundColor: token.colorBgBase,
                  borderRadius: token.borderRadiusSM,
                  border: `1px solid ${token.colorBorder}`
                }}
              />
            </Form.Item>
            <SelectGLCodes
              fetchedGlCodes={glCodes?.data?.glCodes}
              glCodesLoading={!glCodes}
              glCodes={glCodes?.data?.glCodes}
              form={form}
            />
            <Divider />
            <ModalFooter isPending={isPending} handleCancel={handleCancel} />
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};

export default EditGlGroupModal;
