import { FC } from "react";
import { Form, Select, Typography } from "antd";
import { TFormProperty } from "@Component/Common/types";
import CardTags from "@Component/Common/Card/CardTag";
import { useAntToken } from "@hooks/common/useAntToken";
import SelectInputTag from "@Component/Common/SelectInput/SelectInputTag";

interface ISelectCardProps {
  cards: string[];
}
const SelectCard: FC<ISelectCardProps> = ({ cards }) => {
  const { token } = useAntToken();
  return (
    <>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.assignedProperties != currentValues.assignedProperties
        }>
        {({ getFieldValue }) => {
          const hasHomeDepotProperty = getFieldValue("assignedProperties")?.some(
            (property: TFormProperty) => property.label === "The Home Depot"
          );

          if (hasHomeDepotProperty) {
            return (
              <>
                <Typography.Title
                  level={2}
                  style={{
                    color: token.colorText,
                    fontSize: token.fontSize,
                    fontWeight: token.fontWeightStrong
                  }}>
                  Attach Cards
                </Typography.Title>
                <Form.Item
                  name="cardDetails"
                  style={{ width: "100%", marginBottom: 5 }}
                  rules={[{ required: true, message: "Please Attach minimum 1 card" }]}>
                  <Select
                    tagRender={(props: any) => {
                        console.log(props)
                      const { label, onClose, closable } = props;
                      return <SelectInputTag label={label} onClose={onClose} closable={closable} />;
                    }}
                    mode={"multiple"}
                    placeholder={"Select Cards"}
                    style={{ width: "100%", height: 40 }}
                    options={[
                      { label: "All", value: "All" },
                      ...cards.map((card) => {
                        return {
                          label: card,
                          value: card?.split(" (")[0]
                        };
                      })
                    ]}
                    filterOption={(input, option) => {
                      if (input.replace(/\s/g, "").length > 0) {
                        return String(option?.label)
                          ?.toLowerCase()
                          .includes(input.toLowerCase());
                      }
                      return true;
                    }}
                    maxTagCount={1}
                    allowClear={true}
                    size={"middle"}
                    filterSort={(optionA, optionB) => {
                      if (optionA.label === "All" || optionB.label === "All") return 1;

                      if (optionB.label && optionA.label && optionA.label > optionB.label) {
                        return 1;
                      }
                      return -1;
                    }}
                  />
                </Form.Item>
              </>
            );
          }
          return null;
        }}
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.cardDetails != currentValues.cardDetails ||
          prevValues.assignedProperties != currentValues.assignedProperties
        }>
        {({ getFieldValue, setFieldsValue }) => {
          const cardDetails = getFieldValue("cardDetails");
          const hasHomeDepotProperty = getFieldValue("assignedProperties")?.some(
            (property: TFormProperty) => property.label === "The Home Depot"
          );
          if (cardDetails?.some((card: string) => card === "All") && cardDetails?.length > 1) {
            setFieldsValue({
              cardDetails: ["All"]
            });
          }
          if (
            cardDetails?.some((card: string) => card === "All") &&
            cardDetails[cardDetails?.length - 1] != "All"
          ) {
            setFieldsValue({
              cardDetails: cardDetails.filter((filter: string) => filter != "All")
            });
          }
          return hasHomeDepotProperty ? (
            <CardTags
              cards={cardDetails}
              onDeleteTag={(value) => {
                const cardDetailsFil = cardDetails.filter((card: string) => card !== value);
                setFieldsValue({ cardDetails: cardDetailsFil });
              }}
            />
          ) : null;
        }}
      </Form.Item>
    </>
  );
};

export default SelectCard;
