import { useAntToken } from "@hooks/common/useAntToken";
import { FC } from "react";
interface ICommonFormLabelProps {
  children?: React.ReactNode;
  style?: React.CSSProperties;
}
const CommonFormLabel: FC<ICommonFormLabelProps> = (props) => {
  const { children, style } = props;
  const { token } = useAntToken();
  return (
    <label
      style={{
        color: token.colorText,
        fontSize: token.fontSizeSM,
        fontWeight: token.fontWeightStrong,
        ...style
      }}>
      {children}
    </label>
  );
};

export default CommonFormLabel;
