import { useAntToken } from "@hooks/common/useAntToken";
import formatter from "@utils/formatter";
import { Col, Row, Flex, Typography, Divider } from "antd";
import { FC } from "react";

interface IInvoiceFooterProps {
  totalTaxAmount: number;
  invoiceTotal: number;
}

const InvoiceFooter: FC<IInvoiceFooterProps> = (props: IInvoiceFooterProps) => {
  const { invoiceTotal, totalTaxAmount } = props;
  const { token } = useAntToken();
  const pdfFontFamily = "sans-serif";
  return (
    <Row justify="end" style={{ marginTop: token.marginXXS }}>
      <Flex
        justify="flex-start"
        align="middle"
        style={{
          padding: token.paddingLG,
          width: 300,
          paddingTop: token.paddingXS,
          paddingBottom: token.paddingXS
        }}
        vertical>
        <Flex justify="space-between" align={"bottom"} style={{ height: "30px" }}>
          <Col>
            <Typography.Text
              style={{
                color: token.colorTextBase,
                fontSize: "8px",
                fontFamily: pdfFontFamily
              }}>
              Tax
            </Typography.Text>
          </Col>
          <Col>
            <Typography.Text
              style={{
                color: token.colorTextBase,
                fontSize: "8px",
                fontFamily: pdfFontFamily
              }}>
              {formatter.format(totalTaxAmount)}
            </Typography.Text>
          </Col>
        </Flex>
        <Divider style={{ marginTop: 0, marginBottom: token.marginSM }} />
        <Row justify="space-between">
          <Col>
            <Typography.Text
              style={{
                color: token.colorTextBase,
                fontSize: "10px",
                fontWeight: token.fontWeightStrong,
                wordSpacing: "-1px",
                fontFamily: pdfFontFamily
              }}>
              Total Amount
            </Typography.Text>
          </Col>
          <Col>
            <Typography.Text
              style={{
                color: token.colorTextBase,
                fontSize: "10px",
                fontWeight: token.fontWeightStrong,
                fontFamily: pdfFontFamily
              }}>
              {formatter.format(invoiceTotal as number)}
            </Typography.Text>
          </Col>
        </Row>
      </Flex>
    </Row>
  );
};

export default InvoiceFooter;
