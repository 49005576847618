import { createStyles } from "antd-style";

export const vendorDashboardStyles = createStyles(({ token, css }) => {
  const pdfFontFamily = "sans-serif";

  return {
    "ant-table-overider": {
      ".ant-table": css`
        background: ${token.colorBgContainer} !important;
        font-size: 6px;
        font-family: ${pdfFontFamily} !important;
      `,
      ".ant-table-cell": css`
        background: ${token.colorBgContainer} !important;
        border: 0px !important;
        padding: ${token.paddingXS}px ${token.padding}px !important;
      `,
      ".ant-table-cell::before": css`
        background-color: transparent !important;
      `
    }
  };
});
