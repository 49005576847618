import { FC, ReactNode } from "react";
import { TAdminUser } from "./types";
import { useAntToken } from "@hooks/common/useAntToken";
import InvitationStatusTag from "./InvitationStatusTag";
import ManagerTypeTag from "./ManagerTypeTag";
import PermissionTags from "./PermissionTags";
import { MoreOutlined } from "@ant-design/icons";
import { getMoreOptionsItems } from "./utils";
import { Typography, Dropdown, Row, Col, List } from "antd";
import VendorsActiveTag from "@Component/Admin/AdminViewUsers/VendorsActiveTag";
import { TAdminUserRole } from "@Component/Admin/types";

interface ITabAndSmallerListItemsProps {
  item: TAdminUser;
  itemKey: number;
  userType: string;
  handleShowDeleteModal: (item: TAdminUser) => void;
  handleOpenInviteVendorModal: () => void;
  handleUserChange: (user: TAdminUser) => void;
  headers: string[];
  handleReInviteUser: (user: TAdminUser, role: TAdminUserRole) => void;
}

interface ICommonTabScreenHeaderProps {
  children?: ReactNode;
}
interface ICommonTabScreenContentProps {
  children?: ReactNode;
  style?: React.CSSProperties;
}

const TabAndSmallerListItems: FC<ITabAndSmallerListItemsProps> = (props) => {
  const {
    item,
    userType,
    handleShowDeleteModal,
    handleOpenInviteVendorModal,
    handleUserChange,
    headers,
    handleReInviteUser
  } = props;
  const { token } = useAntToken();

  const CommonTabScreenHeader: FC<ICommonTabScreenHeaderProps> = ({ children }) => (
    <Col span={10}>
      <Typography.Text style={{ fontSize: token.fontSizeSM, fontWeight: token.fontWeightStrong }}>
        {children}&nbsp;
      </Typography.Text>
    </Col>
  );
  const CommonTabScreenContent: FC<ICommonTabScreenContentProps> = ({ children, style = {} }) => (
    <Col span={10} style={style}>
      <Typography.Text style={{ fontSize: token.fontSizeSM }}>{children}</Typography.Text>
    </Col>
  );

  const isVendor = userType === "Vendor";

  return (
    <List.Item
      style={{
        outline: item.isDeleted ? `2px solid ${token.colorErrorBorder}` : "none",
        outlineOffset: item.isDeleted ? -2 : 0
      }}>
      <Row
        style={{
          width: "100%",
          paddingLeft: token.paddingSM,
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "start",
          borderRadius: token.borderRadiusLG
        }}>
        <Row style={{ width: "100%" }} gutter={[8, 8]}>
          <CommonTabScreenHeader>{`${headers[0]}: `}</CommonTabScreenHeader>
          <CommonTabScreenContent>{item.name}</CommonTabScreenContent>
          <Col
            style={{
              marginLeft: "auto",
              width: "28px",
              height: "28px",
              border: `1px solid ${token.colorBorder}`,
              boxShadow: token.boxShadow,
              borderRadius: token.borderRadiusSM,
              backgroundColor: "#fff",
              position: "relative"
            }}>
            <Row align="middle" justify="center">
              <Col>
                <Dropdown
                  menu={{
                    items: getMoreOptionsItems(
                      item,
                      userType,
                      handleShowDeleteModal,
                      handleOpenInviteVendorModal,
                      handleUserChange,
                      handleReInviteUser
                    )
                  }}
                  trigger={["click", "contextMenu"]}
                  placement="bottomRight">
                  <a
                    onClick={(e) => e.preventDefault()}
                    style={{
                      fontSize: token.fontSizeLG,
                      fontWeight: token.fontWeightStrong,
                      color: token.colorText
                    }}>
                    <MoreOutlined />
                  </a>
                </Dropdown>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row style={{ width: "100%" }} gutter={[8, 8]}>
          <CommonTabScreenHeader>{`${headers[1]}: `}</CommonTabScreenHeader>
          <CommonTabScreenContent>{item.email}</CommonTabScreenContent>
          {item.secondaryEmail && (
            <>
              <CommonTabScreenHeader>Secondary Email:</CommonTabScreenHeader>
              <CommonTabScreenContent>{item.secondaryEmail}</CommonTabScreenContent>
            </>
          )}
        </Row>
        <Row style={{ width: "100%" }} gutter={[8, 8]} wrap={false}>
          <CommonTabScreenHeader>
            <Col
              style={{
                marginTop: token.marginXXS,
                fontSize: token.fontSizeSM,
                paddingLeft: 0
              }}>{`${headers[2]}: `}</Col>
          </CommonTabScreenHeader>
          <CommonTabScreenContent>
            <Col
              style={{
                marginTop: token.marginXXS,
                fontSize: token.fontSizeSM,
                justifyContent: "flex-start",
                paddingLeft: 0
              }}>
              <InvitationStatusTag status={item.invitationStatus} />
            </Col>
          </CommonTabScreenContent>
        </Row>

        <Row style={{ width: "100%" }} gutter={[8, 8]} wrap={false}>
          <CommonTabScreenHeader>
            <Col
              style={{
                marginTop: token.marginXXS,
                fontSize: token.fontSizeSM,
                paddingLeft: 0
              }}>{`${headers[3]}: `}</Col>
          </CommonTabScreenHeader>
          <Col
            style={{
              marginTop: token.marginXXS,
              fontSize: token.fontSizeSM,
              paddingLeft: token.paddingXXS,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis"
            }}>
            <PermissionTags tags={item.permissionTags} maxTagCount={3} />
          </Col>
        </Row>
        {isVendor && (
          <Row style={{ width: "100%" }} gutter={[8, 8]} wrap={false}>
            <CommonTabScreenHeader>
              <Col
                style={{
                  marginTop: token.marginXXS,
                  fontSize: token.fontSizeSM,
                  paddingLeft: 0
                }}>
                Active
              </Col>
            </CommonTabScreenHeader>
            <Col
              style={{
                marginTop: token.marginXXS,
                fontSize: token.fontSizeSM,
                paddingLeft: 0
              }}>
              <VendorsActiveTag status={item.isActive ? "active" : "in-active"} />
            </Col>
          </Row>
        )}
        {!isVendor && (
          <Row style={{ width: "100%" }} gutter={[8, 8]} wrap={false}>
            <CommonTabScreenHeader>
              <Col
                style={{
                  marginTop: token.marginXXS,
                  fontSize: token.fontSizeSM,
                  paddingLeft: 0
                }}>{`${headers[4]}: `}</Col>
            </CommonTabScreenHeader>
            <Col
              style={{
                marginTop: token.marginXXS,
                fontSize: token.fontSizeSM,
                // paddingLeft: token.paddingXXS,
                justifyContent: "flex-start"
              }}>
              <ManagerTypeTag isRegional={item.isRegionalManager} />
            </Col>
          </Row>
        )}
      </Row>
    </List.Item>
  );
};

export default TabAndSmallerListItems;
