import { useParams } from "react-router-dom";
import { TAdminUserRole } from "@Component/Admin/types";
import { TReinviteAdminUser } from "@Component/Admin/AdminViewUsers/types";
import useAdminReInviteUser from "@hooks/Admin/useAdminReInviteUser";
import { useFetchAdminReInviteUsersList } from "@hooks/Admin/useFetchAdminReInviteUsersList";
import AdminReinviteUser from "@Component/Admin/AdminReinviteUser/AdminReinviteUser";

const AdminReinviteUsersPage = () => {
  const { role } = useParams();

  const { data, isLoading } = useFetchAdminReInviteUsersList();
  const { mutate: reInviteAdminUsers } = useAdminReInviteUser();
  const users: TReinviteAdminUser[] = data?.users || [];

  const handleSubmit = (data: { usersAuthIds: string[] }) => {
    const selectedUsers =
      (users
        ?.filter((user) => data.usersAuthIds.includes(user.authUserId))
        ?.map((user) => ({
          authUserId: user.authUserId,
          name: user.name,
          email: user.email,
          secondaryEmail: user?.secondaryEmail,
          useSecondaryEmail: user?.useSecondaryEmail,
          role: role
        })) as TReinviteAdminUser[]) || [];
    reInviteAdminUsers({ users: selectedUsers });
  };
  return (
    <AdminReinviteUser
      loading={isLoading}
      users={users}
      handleSubmit={handleSubmit}
      role={role as TAdminUserRole}
    />
  );
};

export default AdminReinviteUsersPage;
