import {
  APP_BASE_URL,
  deleteUserUrl,
  fetchAdminSettingsUrl,
  fetchAdminUsersUrl,
  fetchManagersToBeReInvitedUrl,
  fetchUserLogsUrl,
  fetchUserPropertiesUrl,
  fetchVendorsToBeReInvitedUrl,
  inviteUserUrl,
  reInviteUserUrl,
  regionalManagerInvoicesUrl,
  updateAdminSettingsUrl,
  updateUserPermissionsUrl,
  userInvoicesUrl,
  fetchCardsUrl,
  fetchGlGroup,
  crudGlGroup, crudHomeDepot
} from "@utils/urls";
import http from "@utils/httpService";
import {TAdminUserRole, TUserSearchFilters, TGlGroup, THomeDepotCard} from "@Component/Admin/types";
import { TReinviteAdminUser } from "@Component/Admin/AdminViewUsers/types";

import {
  RequestObject,
  SettingsRequestObject,
  transformAdminUsers,
  transformParamsToQuery,
  transformUserLogs,
  transformUserPermissions
} from "./utilts";
import { ILogsFilterObject } from "@hooks/Admin/useAdminLogsSearchParams";
import { TProperties } from "@Component/Common/types";

export interface IInviteUser {
  user: {
    name: string;
    email: string;
  };
  assignedProperties: TProperties[];
  isRegionalManager: boolean;
  regionalManagerThreshold?: number;
  cardDetails?: string[];
}

export const inviteUser = async (inviteUserParamters: IInviteUser) => {
  const { data } = await http.post(`${APP_BASE_URL}${inviteUserUrl}`, inviteUserParamters);
  return { data };
};

export const fetchAllUserInvoices = async (
  pageNum: number,
  pageSize: number,
  paymentStatus: string,
  approvalStatus: string
) => {
  let url = `${APP_BASE_URL}${userInvoicesUrl}?pageNum=${pageNum}&pageSize=${pageSize}`;
  if (paymentStatus) {
    url = url.concat(`&paymentStatus=${paymentStatus}`);
  }
  if (approvalStatus) {
    url = url.concat(`&approvalStatus=${approvalStatus}`);
  }
  const { data } = await http.get(url);
  return { data };
};
export const fetchRegionalManagerInvoices = async (
  pageNum: number,
  pageSize: number,
  paymentStatus: string,
  approvalStatus: string
) => {
  let url = `${APP_BASE_URL}${regionalManagerInvoicesUrl}?pageNum=${pageNum}&pageSize=${pageSize}`;
  if (paymentStatus) {
    url = url.concat(`&paymentStatus=${paymentStatus}`);
  }
  if (approvalStatus) {
    url = url.concat(`&approvalStatus=UnderReview,${approvalStatus}`);
  }
  const { data } = await http.get(url);
  return { data };
};

export const fetchAdminUsers = async ({
  role,
  invitationStatus,
  search,
  pageNumber: inputPageNumber,
  pageSize: inputPageSize,
  vendorStatus,
  isRegionalManager,
  propertyIds
}: TUserSearchFilters) => {
  const queryParamsString = transformParamsToQuery({
    role: role as string,
    invitationStatus: invitationStatus as string,
    search: search as string,
    pageNumber: inputPageNumber as string,
    pageSize: inputPageSize as string,
    vendorStatus: vendorStatus as string,
    isRegionalManager: isRegionalManager as string,
    propertyIds: propertyIds as string
  });
  const { data: API_DATA } = await http.get(
    `${APP_BASE_URL}${fetchAdminUsersUrl}?${queryParamsString}`
  );
  const { pageNum, pageSize, totalPages } = API_DATA;
  const users = transformAdminUsers({ role }, API_DATA.data);
  return { users, pageNum, pageSize, totalPages };
};

export const reInviteUsers = async ({ users }: { users: TReinviteAdminUser[] }) => {
  const { data } = await http.post(`${APP_BASE_URL}${reInviteUserUrl}`, { users });
  return { data };
};

export const updateUserPermissions = async (requestObject: RequestObject) => {
  const processedRequestObject = transformUserPermissions(requestObject);
  const { data } = await http.post(
    `${APP_BASE_URL}${updateUserPermissionsUrl}`,
    processedRequestObject
  );
  return { data };
};
export const deleteUser = async (authUserId: string) => {
  const uri = deleteUserUrl.replace(":authUserId", authUserId);
  const { data } = await http.delete(`${APP_BASE_URL}${uri}`);
  return { data };
};

export const fetchUserLogs = async ({
  pageNumber: inputPageNumber,
  pageSize: inputPageSize,
  search
}: ILogsFilterObject) => {
  const queryParamsString = transformParamsToQuery({
    pageNumber: inputPageNumber as string,
    pageSize: inputPageSize as string,
    search: search as string
  });
  const { data } = await http.get(`${APP_BASE_URL}${fetchUserLogsUrl}?${queryParamsString}`);
  const logs = transformUserLogs(data.data);
  return { logs, totalPages: data.totalPages };
};

export const updateAdminSettingsService = async ({
  regionalManagerThreshold,
  vendorErrorResponse,
  Id
}: SettingsRequestObject) => {
  const transformedRequestObject: SettingsRequestObject = { Id };
  if (regionalManagerThreshold) {
    transformedRequestObject.regionalManagerThreshold = regionalManagerThreshold;
  }
  if (vendorErrorResponse) {
    transformedRequestObject.vendorErrorResponse = vendorErrorResponse;
  }

  const { data } = await http.put(
    `${APP_BASE_URL}${updateAdminSettingsUrl}`,
    transformedRequestObject
  );
  return { data };
};

export const fetchAdminSettingsService = async () => {
  const { data } = await http.get(`${APP_BASE_URL}${fetchAdminSettingsUrl}`);
  return {
    regionalManagerThreshold: data.regionalManagerThreshold,
    vendorErrorResponse: data.vendorErrorResponse,
    Id: data.Id
  } as {
    regionalManagerThreshold: number;
    vendorErrorResponse: string;
    Id: string;
  };
};

export const fetchAllUsersToBeReInvited = async (role: TAdminUserRole) => {
  const url = role === "vendor" ? fetchVendorsToBeReInvitedUrl : fetchManagersToBeReInvitedUrl;
  const { data } = await http.get(`${APP_BASE_URL}${url}`);
  const users = [...data];
  return { users };
};

export const fetchUserProperties = async () => {
  const { data } = await http.get(`${APP_BASE_URL}${fetchUserPropertiesUrl}`);
  return { data };
};

export const fetchCards = async () => {
  const { data } = await http.get(`${APP_BASE_URL}${fetchCardsUrl}`);
  return data;
};

export const fetchAllGlGroup = async () => {
  const { data } = await http.get(`${APP_BASE_URL}${fetchGlGroup}`);

  return data.sort((a: any, b: any) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
};

export const createGlGroup = async (glGroup: TGlGroup) => {
  const uri = crudGlGroup.replace(":endpoint", "create");
  const { data } = await http.post(`${APP_BASE_URL}${uri}`, glGroup);
  return { data };
};

export const updateGlGroup = async ({ glGroup, id }: { glGroup: TGlGroup; id: string }) => {
  const uri = crudGlGroup.replace(":endpoint", id);
  const { data } = await http.put(`${APP_BASE_URL}${uri}`, glGroup);
  return { data };
};

export const deleteGlGroup = async (id: string) => {
  const uri = crudGlGroup.replace(":endpoint", id);
  const { data } = await http.delete(`${APP_BASE_URL}${uri}`);
  return { data };
};

export const fetchAllCards = async () => {
  const uri = crudHomeDepot.replace(":endpoint", "dropdown");
  const { data } = await http.get(`${APP_BASE_URL}${uri}`);

  return data.cards.sort((a: any, b: any) =>
    a.cardDetails > b.cardDetails ? 1 : b.cardDetails > a.cardDetails ? -1 : 0
  );
};

export const updateCard = async (card: THomeDepotCard) => {
  const uri = crudHomeDepot.replace(":endpoint", card.Id);
  const { data } = await http.put(`${APP_BASE_URL}${uri}`, card);
  return { data };
};
