import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteGlGroup } from "@Services/UserService";
import { React_Query_Keys } from "@utils/constants";
import { isAxiosError } from "axios";
import ShowToast from "@utils/showToast";

const useGlCodeGroupDelete = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteGlGroup,
    mutationKey: [React_Query_Keys.DELETE_GL_GROUP],
    onError: (error: unknown) => {
      if (isAxiosError(error)) {
        ShowToast(error.response?.data.message, "error");
      }
    },
    onSuccess: () => {
      ShowToast("Group Deleted successfully", "success");
      queryClient.invalidateQueries({ queryKey: [React_Query_Keys.FETCH_ALL_GL_GROUP] });
    }
  });
};

export default useGlCodeGroupDelete;
