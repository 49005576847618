import { Col, Typography, Select, Checkbox, Divider, Form, Flex, Input, Radio } from "antd";
import type { SelectProps } from "antd";
import { FC, useState } from "react";
import CommonAppButton from "@Component/Common/CommonAppButton";
import { useForm } from "antd/es/form/Form";
import CommonFormLabel from "@Component/Common/CommonFormLabel";
import { useAntToken } from "@hooks/common/useAntToken";
import VendorTags from "./VendorTags";
import SecondaryEmailField from "./SecondaryEmailField";
import { commonAntStyles } from "@Component/Common/styles";
import { TFormGlCode, TFormProperty, TGlCode, TProperties } from "@Component/Common/types";
import SelectProperties from "@Component/Common/Property/SelectProperties";
import SelectGLCodes from "@Component/Common/GLCodes/SelectGLCodes";
import AdminCancelAndGoBack from "@Component/Common/AdminCancelAndGoBack";
import SelectInputTag from "@Component/Common/SelectInput/SelectInputTag";

type TagRender = SelectProps["tagRender"];

interface Props {
  loading: boolean;
  vendorsOptionList: Array<{ label: string; value: string }>;
  properties: TProperties[];
  glCodes: TGlCode[];
  glCodeGroup: any;
  handleSubmit: (values: {
    showPaymentStatus: boolean;
    showLegalForms: boolean;
    vendors: Array<{ email: string; name: string }>;
    assignedProperties: TFormProperty[];
    assignedGLCodes: TFormGlCode[];
    editGLcodes: boolean;
    threshold: string;
    invitationType: string;
  }) => void;
}

const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const AdminInviteVendor: FC<Props> = (props) => {
  const [form] = useForm();
  const { token } = useAntToken();
  const [disableLoader, setDisableLoader] = useState(false);
  const { loading, vendorsOptionList, properties, glCodes, handleSubmit, glCodeGroup } = props;
  const { styles: commonStyles } = commonAntStyles();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const fontWeightNormal = token.fontWeightNormal;
  const invitationType = Form.useWatch("invitationType", form);

  const vendorSelectProps: SelectProps = {
    mode: "multiple",
    showSearch: true,
    loading: !disableLoader && loading,
    disabled: !disableLoader && loading,
    style: { width: "100%" },
    placeholder: "Select Vendor",
    filterOption: (input, option) => {
      if (input.replace(/\s/g, "").length > 0) {
        return String(option?.label)
          ?.toLowerCase()
          .includes(input.toLowerCase());
      }
      return true;
    },
    options:
      invitationType === "multi"
        ? vendorsOptionList.filter((vendor: { label: string; value: string }) =>
            validateEmail(vendor.value)
          )
        : vendorsOptionList.map((vendor: { label: string; value: string }) => {
            if (!validateEmail(vendor.value)) {
              vendor.value = vendor.label;
            }
            return vendor;
          }),
    allowClear: true,
    maxTagCount: 1,
    size: "middle"
  };

  const selectedVendors = Form.useWatch("vendors", form) || [];
  let filteredSelectedVendors: Array<{ label: string; value: string }> = [];

  if (selectedVendors) {
    filteredSelectedVendors = vendorsOptionList.filter(
      (p) => p.value && selectedVendors.toString().includes(p.value.toString())
    );
  }

  const handleDeleteVendorTag = (value: string) => {
    const formVendors = form.getFieldValue("vendors");
    const vendorsWithLabelAndValues = vendorsOptionList.filter((p) =>
      formVendors.toString().includes(p.value)
    );

    const filteredVendors = vendorsWithLabelAndValues.filter((p) => p.value !== value);
    filteredSelectedVendors = [...filteredVendors];
    form.setFieldsValue({
      vendors: [...filteredSelectedVendors.map((f) => f.value)]
    });
  };

  const commonCheckFormItemStyles = {
    marginBottom: 0
  };

  const commonInputStyles = {
    height: token.controlHeightLG,
    backgroundColor: token.colorBgBase,
    borderRadius: token.borderRadiusSM,
    border: `1px solid ${token.colorBorder}`
  };
  const tagRender: TagRender = (props: any) => {
    const { label, closable, onClose } = props;
    return <SelectInputTag label={label} closable={closable} onClose={onClose} />;
  };
  return (
    <>
      <Flex
        justify="center"
        align="middle"
        style={{ width: "100%", marginTop: token.marginLG, marginBottom: token.marginLG }}>
        <Flex vertical justify="center" align="middle" style={{ maxWidth: "340px", width: "100%" }}>
          <Col style={{ paddingLeft: token.paddingLG, marginBottom: token.marginSM }}>
            <Typography.Title
              level={2}
              style={{
                color: token.colorText,
                fontSize: token.fontSizeHeading2,
                fontWeight: token.fontWeightStrong
              }}>
              Invite Vendor
            </Typography.Title>
          </Col>
          <Col style={{ background: token.colorBgBase, padding: token.paddingLG }}>
            <Form
              onFinish={(values) => {
                setDisableLoader(true);
                handleSubmit(values);
              }}
              form={form}
              initialValues={{
                assignedGLCodes: undefined,
                assignedProperties: undefined,
                editGLcodes: undefined,
                invitationType: "multi",
                showPaymentStatus: undefined,
                threshold: undefined,
                vendors: undefined
              }}
              layout="vertical">
              <Form.Item
                name="threshold"
                className={commonStyles["ant-input-override"]}
                label={
                  <CommonFormLabel
                    style={{
                      color: token.colorText,
                      fontSize: token.fontSize,
                      fontWeight: token.fontWeightStrong
                    }}>
                    Lien Waiver Threshold
                  </CommonFormLabel>
                }>
                <Input
                  placeholder="Lien Waiver Threshold"
                  style={commonInputStyles}
                  disabled={loading}
                />
              </Form.Item>
              <Form.Item name="invitationType">
                <Radio.Group>
                  <Radio value={"single"}>Single-Vendor</Radio>
                  <Radio value={"multi"}>Multiple-Vendors</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => {
                  if (prevValues.invitationType !== currentValues.invitationType) {
                    form.setFieldValue("vendors", []);
                    return true;
                  }
                  return false;
                }}>
                {({ getFieldValue }) => {
                  const invitationType = getFieldValue("invitationType");
                  if (invitationType === "single") {
                    delete vendorSelectProps?.mode;
                  }
                  return (
                    <Form.Item
                      name="vendors"
                      rules={[{ required: true, message: "Please Select Vendors" }]}
                      label={
                        <CommonFormLabel
                          style={{
                            color: token.colorText,
                            fontSize: token.fontSize,
                            fontWeight: token.fontWeightStrong
                          }}>
                          Select Vendor
                        </CommonFormLabel>
                      }>
                      <Select
                        tagRender={tagRender}
                        {...vendorSelectProps}
                        value={selectedVendors}
                        style={{ height: 40 }}
                        onChange={(e) => {
                          form.setFieldValue("vendors", e);
                        }}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
              <VendorTags onDeleteTag={handleDeleteVendorTag} vendors={filteredSelectedVendors} />
              <SecondaryEmailField />
              <Divider style={{ marginBottom: 18 }} />
              <Typography.Title
                level={2}
                style={{
                  color: token.colorText,
                  fontSize: token.fontSizeXL,
                  fontWeight: token.fontWeightStrong,
                  marginTop: 0
                }}>
                Permissions
              </Typography.Title>
              <SelectGLCodes
                fetchedGlCodes={glCodes}
                glCodesLoading={!disableLoader && loading}
                glCodes={glCodes}
                form={form}
                glCodeGroup={glCodeGroup}
              />
              <SelectProperties
                fetchedProperties={properties}
                properties={properties}
                form={form}
                propertiesLoading={!disableLoader && loading}
              />
              <Col span={20}>
                <Form.Item
                  name="showPaymentStatus"
                  valuePropName="checked"
                  style={commonCheckFormItemStyles}>
                  <Checkbox
                    disabled={loading}
                    style={{
                      color: token.colorText,
                      fontSize: 14,
                      fontWeight: fontWeightNormal
                    }}>
                    Show Payment Status
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col span={20}>
                <Form.Item
                  name="editGLcodes"
                  valuePropName="checked"
                  style={{ ...commonCheckFormItemStyles, marginBottom: token.marginSM }}>
                  <Checkbox
                    disabled={loading}
                    style={{
                      color: token.colorText,
                      fontSize: 14,
                      fontWeight: fontWeightNormal
                    }}>
                    Submit Gl Code
                  </Checkbox>
                </Form.Item>
              </Col>
              <CommonAppButton
                type="primary"
                block
                loading={loading}
                htmlType="submit"
                style={{
                  height: token.controlHeightLG,
                  color: token.colorTextLightSolid,
                  fontSize: token.fontSizeLG,
                  boxShadow: token.boxShadow,
                  fontWeight: fontWeightNormal
                }}>
                Send Invite
              </CommonAppButton>
              <AdminCancelAndGoBack marginTop={12} />
            </Form>
          </Col>
        </Flex>
      </Flex>
    </>
  );
};
export default AdminInviteVendor;
