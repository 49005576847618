import { Form, Input, Row, Col, theme, Typography, Flex } from "antd";
import { ILoginValues } from "@redux/actions/Auth/Auth";
import { FC } from "react";
import { LogoIcon } from "@assets/icons/CustomIcons";
import { Link } from "react-router-dom";
import CommonAppButton from "@Component/Common/CommonAppButton";
import { useBreakpoints } from "@hooks/common/useBreakPoints";
import { commonAntStyles } from "@Component/Common/styles";
const { useToken } = theme;

interface Props {
  isLoading: boolean;
  onFinish: (values: ILoginValues) => void;
}

const Auth: FC<Props> = (props) => {
  const { token } = useToken();
  //eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const fontWeightNormal = token.fontWeightNormal;
  const { isLoading, onFinish } = props;
  const { isScreenXS } = useBreakpoints();
  const { styles: commonStyles } = commonAntStyles();
  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
  };

  const { Title } = Typography;
  const commonInputStyles = {
    borderRadius: 0,
    backgroundColor: `${token.colorBgBase} !important`,
    border: `1px solid ${token.colorBorder}`,
    height: token.controlHeightLG,
    paddingBottom: token.paddingXXS
  };
  const CommonLabel = ({ children }: { children: React.ReactNode }) => (
    <label style={{ color: token.colorText, fontWeight: token.fontWeightStrong }}>{children}</label>
  );
  return (
    <Flex justify="center" align="center" vertical>
      <Row
        style={{ marginLeft: "12px", marginRight: "12px", maxWidth: "460px", marginBottom: 100 }}
        justify={"center"}>
        <Col>
          <LogoIcon style={{ fontSize: isScreenXS ? 130 : 230 }} />
        </Col>
        <Col
          span={24}
          style={{
            backgroundColor: token.colorBgBase,
            padding: token.paddingXL,
            paddingTop: token.paddingXS,
            paddingBottom: token.paddingXS,
            borderRadius: token.borderRadiusLG
          }}>
          <Row justify="start">
            <Col>
              <Title level={2}>Sign In</Title>
            </Col>
          </Row>
          <Col span={24}>
            <Form {...layout} onFinish={onFinish} style={{ paddingBottom: 0 }}>
              <Row style={{ marginTop: token.marginLG, paddingBottom: 0 }} justify="center">
                <Col span={24}>
                  <Form.Item
                    className={commonStyles["ant-input-override"]}
                    label={<CommonLabel>Email</CommonLabel>}
                    name="email"
                    rules={[
                      {
                        type: "email",
                        required: true,
                        message: "Please Enter a Valid Email Address, e.g. name@domain.com"
                      }
                    ]}>
                    <Input
                      type="text"
                      placeholder="Email"
                      name="email"
                      style={{
                        ...commonInputStyles,
                        backgroundColor: "transparent"
                      }}
                      autoComplete="off"
                    />
                  </Form.Item>
                  <Form.Item
                    label={<CommonLabel>Password</CommonLabel>}
                    className={commonStyles["ant-input-override"]}
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please Input Your Password"
                      }
                    ]}
                    style={{ background: "transparent" }}>
                    <Input.Password
                      type="password"
                      placeholder="Password"
                      name="password"
                      style={{ ...commonInputStyles, background: "transparent" }}
                    />
                  </Form.Item>

                  <Form.Item>
                    <Link
                      to="/newPassword"
                      style={{
                        float: "right",
                        color: "#0958D9",
                        fontSize: 14,
                        fontWeight: fontWeightNormal,
                        textDecoration: "underline"
                      }}>
                      Forgot Password?
                    </Link>
                  </Form.Item>

                  <Form.Item>
                    <CommonAppButton
                      type="primary"
                      htmlType="submit"
                      block
                      loading={isLoading}
                      style={{
                        backgroundColor: token.colorPrimary,
                        height: isScreenXS ? token.controlHeightSM : token.controlHeightLG,
                        color: token.colorTextLightSolid,
                        fontSize: isScreenXS ? token.fontSizeSM : token.fontSizeLG,
                        boxShadow: token.boxShadow,
                        fontWeight: fontWeightNormal,
                        paddingBottom: isScreenXS ? token.paddingLG : token.paddingXXS
                      }}>
                      Sign In
                    </CommonAppButton>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Col>
      </Row>
    </Flex>
  );
};

export default Auth;
