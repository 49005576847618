import UserLogs from "@Component/Admin/UserLogs";
import { useAdminFetchUserLogs } from "@hooks/Admin/useAdminFetchUserLogs";

const UserLogsPage = () => {
  const { data, isLoading } = useAdminFetchUserLogs();
  const logs = data?.logs || [];
  const totalPages = data?.totalPages || "0";
  return <UserLogs logs={logs} isLoading={isLoading} totalPages={totalPages} />;
};
export default UserLogsPage;
