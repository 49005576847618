import jsPDF from "jspdf";
import { Col, Row, theme, Pagination } from "antd";
import { FC, useCallback, useEffect, useState } from "react";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { IInvoice } from "./utils";
import { vendorDashboardStyles } from "./styles";
import { useBreakpoints } from "@hooks/common/useBreakPoints";
import DashboardHeader from "./DashboardHeader";
import DownloadPdfModal from "@Component/Common/DownloadPdfModal";
import MobileList from "./MobileList";
import DesktopList from "./DesktopList";

const { useToken } = theme;

const blankInvoice: IInvoice = {
  Id: "",
  invoiceNumber: "",
  companyName: "",
  address: "",
  amount: 0,
  paymentStatus: "",
  vendorName: "",
  approvalStatus: "",
  propertyName: "",
  propertyAddress: "",
  propertyType: "UnitProperty",
  issueDate: null,
  lineItems: [],
  totalTaxAmount: 0,
  persistedInvoiceFileId: ""
};

interface IVendorDashboardProps {
  isManagerPortal: boolean;
  navigateToUploadInvoice: () => void;
  pageNum: number;
  pageSize: number;
  totalPages: number;
  invoiceList: IInvoice[];
  onPaginationChange: (page: number, pageSize: number) => void;
  isLoading: boolean;
  navigateToViewInvoice: (invoiceId: string, approvalStatus: string) => void;
  handleCancelInvoice?: (invoiceId: string) => Promise<void>;
  paymentStatus: string[];
  approvalStatus: string[];
  onPaymentStatusChange: (e: CheckboxChangeEvent) => void;
  onApprovalStatusChange: (e: CheckboxChangeEvent) => void;
  clearFilters: () => void;
  showPaymentStatus: boolean;
  title?: string;
  isPropertyManager?: boolean;
  navigateToAdmin?: () => void;
  isActive?: boolean;
  threshold?: string;
}

const VendorDashboard: FC<IVendorDashboardProps> = (props) => {
  const { token } = useToken();
  const { isScreenXS } = useBreakpoints();
  const { styles } = vendorDashboardStyles();

  const {
    isManagerPortal,
    navigateToUploadInvoice,
    invoiceList,
    pageNum,
    totalPages,
    pageSize,
    onPaginationChange,
    isLoading,
    handleCancelInvoice,
    navigateToViewInvoice,
    paymentStatus,
    approvalStatus,
    onPaymentStatusChange,
    onApprovalStatusChange,
    clearFilters,
    showPaymentStatus,
    title,
    isPropertyManager = false,
    navigateToAdmin
  } = props;
  const [invoiceToDownload, setInvoiceToDownload] = useState<IInvoice>(blankInvoice);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [pdfRef, setPdfRef] = useState<any>(null);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const savePdfRef = useCallback((node: any) => {
    if (node !== null) {
      setPdfRef(node);
    }
  }, []);

  const handleDownload = (invoice: IInvoice) => {
    setIsModalOpen(true);
    setInvoiceToDownload(invoice);
  };

  useEffect(() => {
    if (pdfRef && invoiceToDownload != blankInvoice) {
      handleGeneratePdf();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdfRef, invoiceToDownload]);

  const handleGeneratePdf = async () => {
    const doc = new jsPDF({
      format: "a4",
      unit: "px",
      putOnlyUsedFonts: true
    });

    if (pdfRef && invoiceToDownload) {
      doc.html(pdfRef, {
        callback: async (doc) => {
          doc.save("document");
          setIsModalOpen(false);
          setInvoiceToDownload(blankInvoice);
        },
        margin: [10, 0, 10, 0],
        autoPaging: "text"
      });
    }
  };
  return (
    <>
      <Row justify="center" style={{ background: token.colorBgLayout }}>
        <Col xs={24} sm={24} md={22} lg={22} xl={22} xxl={22}>
          <DashboardHeader
            clearFilters={clearFilters}
            navigateToUploadInvoice={navigateToUploadInvoice}
            title={title}
            isPropertyManager={isPropertyManager}
            navigateToAdmin={navigateToAdmin}
          />
          <Row>
            <Col
              span={24}
              style={
                isScreenXS
                  ? { marginTop: token.marginLG }
                  : {
                      paddingTop: token.paddingMD,
                      paddingBottom: token.paddingMD,
                      borderRadius: token.borderRadius
                    }
              }>
              {isScreenXS ? (
                <MobileList
                  isRegionalManager={title?.includes("Regional")}
                  isManagerPortal={isManagerPortal}
                  approvalStatus={approvalStatus}
                  paymentStatus={paymentStatus}
                  handleDownload={handleDownload}
                  invoiceList={invoiceList}
                  navigateToViewInvoice={navigateToViewInvoice}
                  isLoading={isLoading}
                  showPaymentStatus={showPaymentStatus}
                  handleCancelInvoice={handleCancelInvoice}
                  onPaymentStatusChange={onPaymentStatusChange}
                  onApprovalStatusChange={onApprovalStatusChange}
                />
              ) : (
                <DesktopList
                  isRegionalManager={title?.includes("Regional")}
                  isManagerPortal={isManagerPortal}
                  approvalStatus={approvalStatus}
                  paymentStatus={paymentStatus}
                  handleDownload={handleDownload}
                  invoiceList={invoiceList}
                  navigateToViewInvoice={navigateToViewInvoice}
                  handleCancelInvoice={handleCancelInvoice}
                  isLoading={isLoading}
                  showPaymentStatus={showPaymentStatus}
                  onPaymentStatusChange={onPaymentStatusChange}
                  onApprovalStatusChange={onApprovalStatusChange}
                />
              )}
            </Col>
          </Row>
          <Row justify="center" style={{ marginTop: token.marginXL, marginBottom: token.marginXL }}>
            <Col>
              <Pagination
                current={pageNum}
                pageSize={pageSize}
                onChange={onPaginationChange}
                total={totalPages * pageSize}
                className={styles["ant-pagination-over-ride"]}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <DownloadPdfModal
        invoiceToDownload={invoiceToDownload}
        isModalOpen={isModalOpen}
        savePdfRef={savePdfRef}
      />
    </>
  );
};
export default VendorDashboard;
