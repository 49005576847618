import { useQuery } from "@tanstack/react-query";
import { React_Query_Keys } from "@utils/constants";
import { fetchOriginalInvoiceImageUrl } from "@Services/InvoiceService";

const useFetchOriginalInvoiceUrl = (persistedFileId: string) => {
  return useQuery({
    queryKey: [React_Query_Keys.FETCH_ORIGINAL_INVOICE_IMAGE, persistedFileId],
    queryFn: () => fetchOriginalInvoiceImageUrl(persistedFileId),
    enabled: !!persistedFileId
  });
};

export default useFetchOriginalInvoiceUrl;
