import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateUserPermissions } from "@Services/UserService";
import { React_Query_Keys } from "@utils/constants";
import { isAxiosError } from "axios";
import { TUserType } from "@Component/Admin/types";
import { useNavigate } from "react-router-dom";
import ShowToast from "@utils/showToast";
import { ROUTE_PATHS } from "@routes/constants";
import useAdminSearchParams from "@hooks/Admin/useAdminSearchParams";

const useAdminUpdateUserPermissions = () => {
  const queryClient = useQueryClient();
  const { role } = useAdminSearchParams();
  const navigate = useNavigate();
  return useMutation({
    mutationFn: updateUserPermissions,
    mutationKey: [React_Query_Keys.ADMIN_UPDATE_USER_PERMISSIONS],
    onError: (error: unknown) => {
      if (isAxiosError(error)) {
        ShowToast(error.response?.data.message, "error");
      }
    },
    onSuccess: () => {
      ShowToast("Permissions updated successfully", "success");
      queryClient.invalidateQueries({ queryKey: [React_Query_Keys.ADMIN_USERS] });
      const userType: TUserType = role === "vendor" ? "vendors" : "business-users";
      navigate(`${ROUTE_PATHS.ADMIN}/${userType}?role=${role}`);
    }
  });
};

export default useAdminUpdateUserPermissions;
