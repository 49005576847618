import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchAllVendors } from "@Services/VendorService";
import ShowToast from "@utils/showToast";
import AdminInviteBusinessUser from "@Component/Admin/AdminInviteBusinessUser";
import { IInviteUser, inviteUser, fetchAllCards } from "@Services/UserService";
import { isAxiosError } from "axios";
import { TFormProperty, TProperties } from "@Component/Common/types";

const AdminInviteBusinessUserPage: FC = () => {
  const navigate = useNavigate();

  const [assignedProperties, setAssignedProperties] = useState<TProperties[]>([]);
  const [cards, setCardDetails] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const allVendors = async () => {
    try {
      setLoading(true);
      const result = await fetchAllVendors();
      const properties = result?.data?.properties || [];
      setAssignedProperties(properties);
      setLoading(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        ShowToast(error.response?.data.message, "error");
      }
      setLoading(false);
    }
  };

  const cardDetails = async () => {
    const cards = await fetchAllCards();
    setCardDetails(
      cards.map(
        (card: any) => `${card.cardDetails} ${card?.nickName ? "(" + card?.nickName + ")" : ""}`
      )
    );
  };

  const handleSubmit = async (values: {
    name: string;
    email: string;
    assignedProperties: TFormProperty[];
    isRegionalManager: boolean;
    regionalManagerThreshold: string;
    cardDetails?: string[];
  }) => {
    const assignedPropertiesIds = values.assignedProperties.map(({ id }) => id);
    const requestParameters: IInviteUser = {
      user: {
        name: values.name,
        email: values.email
      },
      assignedProperties: assignedProperties.filter(({ id }) => assignedPropertiesIds.includes(id)),
      isRegionalManager: values.isRegionalManager,
      regionalManagerThreshold: Number(values.regionalManagerThreshold),
      cardDetails: values.cardDetails?.map((card: any) => card.split("(")[0])
    };
    if (values?.cardDetails) {
      requestParameters.cardDetails = values.cardDetails?.map((card: any) => card.split(" (")[0]);
    }
    try {
      setLoading(true);
      await inviteUser(requestParameters);
      ShowToast("Manager is invited successfully", "success");
      navigate("/admin/business-users?role=user");
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        ShowToast(error.response?.data.message, "error");
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    allVendors().then(() => {
      cardDetails();
    });
  }, []);

  return (
    <AdminInviteBusinessUser
      loading={loading}
      properties={assignedProperties}
      cards={cards}
      handleSubmit={handleSubmit}
    />
  );
};
export default AdminInviteBusinessUserPage;
