import { FC, useEffect } from "react";
import { useAntToken } from "@hooks/common/useAntToken";
import { Form, Row, Col, Divider, Checkbox, Button, Spin, Input, Typography } from "antd";
import {
  getAssignedGLCodes,
  getAssignedGLCodesWithCategories,
  getAssignedProperties
} from "./utils";
import { TAdminUser, TFormProperty, TProperties } from "./types";
import { useParams } from "react-router-dom";
import useAdminUpdateUserPermissions from "@hooks/Admin/useAdminUpdateUserPermissions";
import { TAdminUserRole } from "@Component/Admin/types";
import { RequestObject } from "@Services/utilts";
import { useForm } from "antd/es/form/Form";
import Loader from "@Component/Common/Loader";
import SelectProperties from "@Component/Common/Property/SelectProperties";
import SelectGLCodes from "@Component/Common/GLCodes/SelectGLCodes";
import { TFormGlCode, TGlCode } from "@Component/Common/types";
import { serialiazeGlCodes } from "@Component/Common/utils";
import { LoadingOutlined } from "@ant-design/icons";
import SelectCard from "@Component/Common/Card/SelectCard";

interface IEditUserPermissionsFormProps {
  loading: boolean;
  user: TAdminUser;
  handleModalClose: (value: boolean) => void;
  fetchedCards: string[];
  fetchedGlCodes: TGlCode[];
  fetchedProperties: TProperties[];
  glCodesLoading: boolean;
  propertiesLoading: boolean;
  glCodeGroupFetch: any;
}

const EditUserPermissionsForm: FC<IEditUserPermissionsFormProps> = (props) => {
  const {
    loading,
    handleModalClose,
    user,
    fetchedGlCodes,
    fetchedCards,
    fetchedProperties,
    glCodesLoading,
    propertiesLoading,
    glCodeGroupFetch
  } = props;
  const { token } = useAntToken();
  const { userType } = useParams();
  const { mutate: updateUserPermissions, isSuccess, isPending } = useAdminUpdateUserPermissions();

  const {
    assignedProperties: properties,
    assignedGLCodes: glCodes,
    glCategories,
    showPaymentStatus,
    editGLCodes: editGLcodes,
    showLegalForms,
    cardDetails
  } = user;
  const isVendor = userType === "vendors";
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const fontWeightNormal = token.fontWeightNormal;

  const handleFormSubmit = (values: {
    assignedProperties: TFormProperty[];
    assignedGLCodes: TFormGlCode[];
    showPaymentStatus: boolean;
    showLegalForms: boolean;
    editGLcodes: boolean;
    cardDetails?: string[];
    threshold: number;
  }) => {
    const assignedPropertiesIds = values?.assignedProperties.map(({ id }) => id);
    const requestBody: RequestObject = {
      assignedProperties:
        fetchedProperties.filter((property) => assignedPropertiesIds.includes(property.id)) || [],
      role: user.role as TAdminUserRole,
      id: user.id as string
    };
    if (user?.role === "vendor") {
      requestBody.glCategoryIds = values.assignedGLCodes
        .filter((glCode) => glCode.id.toString() === "glGroup")
        .map((glCode) => glCode.value);
      requestBody.assignedGLCodes = serialiazeGlCodes(
        values.assignedGLCodes.filter((glCode) => glCode.id.toString() !== "glGroup")
      );
      requestBody.showLegalForms = values?.showLegalForms;
      requestBody.showPaymentStatus = values?.showPaymentStatus;
      requestBody.editGLCodes = values?.editGLcodes;
      if (values?.threshold) {
        requestBody.threshold = Number(values?.threshold);
      }
    }
    if (user?.role !== "vendor" && values?.cardDetails) {
      requestBody.cardDetails = values?.cardDetails.map((card) => card.split(" (")[0]);
    }
    if (user?.role !== "vendor") {
      requestBody.regionalManagerThreshold = Number(values?.threshold);
    }

    updateUserPermissions(requestBody);
  };

  useEffect(() => {
    isSuccess && handleModalClose(true);
  }, [isSuccess, handleModalClose]);

  const assignedProperties = getAssignedProperties(properties);
  const assignedGLCodes = getAssignedGLCodes(glCodes);
  const assignedGLCodesWithCategories = getAssignedGLCodesWithCategories(
    assignedGLCodes,
    glCategories
  );

  const [form] = useForm();
  if (loading) {
    return <Loader isLoading={loading} />;
  }
  return (
    <Form
      onFinish={handleFormSubmit}
      form={form}
      initialValues={{
        showPaymentStatus,
        showLegalForms,
        editGLcodes,
        assignedProperties,
        assignedGLCodes: assignedGLCodesWithCategories,
        cardDetails,
        threshold: isVendor ? user.threshold : user.regionalManagerThreshold
      }}>
      <Row justify="center">
        <Col style={{ width: "100%" }}>
          <Typography.Title
            level={2}
            style={{
              color: token.colorText,
              fontSize: token.fontSize,
              fontWeight: token.fontWeightStrong
            }}>
            {isVendor ? "Lien Waiver Threshold" : "Threshold"}
          </Typography.Title>
          <Form.Item name={"threshold"}>
            <Input
              style={{ width: "100%", height: 40, backgroundColor: token.colorBgBase }}
              placeholder="Threshold"
              disabled={loading}
            />
          </Form.Item>
          {isVendor && (
            <Row>
              <Col xs={24}>
                <SelectGLCodes
                  form={form}
                  fetchedGlCodes={fetchedGlCodes}
                  glCodes={glCodes}
                  glCodesLoading={glCodesLoading}
                  glCodeGroup={glCodeGroupFetch}
                />
              </Col>
            </Row>
          )}
          <Row style={{ marginTop: token.marginSM }}>
            <Col xs={24}>
              <SelectProperties
                fetchedProperties={fetchedProperties}
                properties={properties}
                propertiesLoading={propertiesLoading}
                form={form}
              />
            </Col>
          </Row>
          {!isVendor && (
            <Row style={{ marginTop: token.marginSM }}>
              <Col xs={24}>
                <SelectCard cards={fetchedCards} />
              </Col>
            </Row>
          )}
          {isVendor && (
            <>
              <Row>
                <Col xs={22}>
                  <Row>
                    <Col span={20}>
                      <Form.Item
                        name="showPaymentStatus"
                        valuePropName="checked"
                        style={{ marginBottom: token.marginXXS }}>
                        <Checkbox
                          style={{
                            color: token.colorText,
                            fontSize: 14,
                            fontWeight: fontWeightNormal
                          }}>
                          Show Payment Status
                        </Checkbox>
                      </Form.Item>
                    </Col>
                    <Col span={20}>
                      <Form.Item
                        name="editGLcodes"
                        valuePropName="checked"
                        style={{ marginBottom: token.marginXXS }}>
                        <Checkbox
                          style={{
                            color: token.colorText,
                            fontSize: 14,
                            fontWeight: fontWeightNormal
                          }}>
                          Submit Gl Code
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>
          )}
          <Divider />
          <Row>
            <Col xs={24} sm={24}>
              <Row gutter={[8, 8]}>
                <Col xs={24} sm={12} md={12}>
                  <Button
                    type="primary"
                    block
                    loading={loading}
                    onClick={() => handleModalClose(false)}
                    disabled={isPending}
                    style={{
                      backgroundColor: token.colorBgElevated,
                      borderRadius: token.borderRadius,
                      height: token.controlHeightLG,
                      color: token.colorText,
                      fontSize: token.fontSizeLG,
                      boxShadow: token.boxShadow,
                      borderColor: token.colorBorder,
                      fontWeight: fontWeightNormal
                    }}>
                    Cancel
                  </Button>
                </Col>
                <Col xs={24} sm={12} md={12}>
                  <Button
                    type="primary"
                    block
                    loading={loading}
                    htmlType="submit"
                    disabled={isPending}
                    style={{
                      backgroundColor: token.colorPrimary,
                      borderRadius: token.borderRadius,
                      height: token.controlHeightLG,
                      fontSize: token.fontSizeLG,
                      boxShadow: token.boxShadow,
                      borderColor: token.colorBorder,
                      fontWeight: fontWeightNormal
                    }}>
                    {isPending ? (
                      <Spin
                        indicator={
                          <LoadingOutlined
                            style={{ fontSize: token.fontSizeLG, color: token.colorBgBase }}
                            spin
                          />
                        }
                      />
                    ) : (
                      "Save"
                    )}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default EditUserPermissionsForm;
