import HomedepotReviewInvoice from "@Component/Common/HomedepotReviewInvoice";
import useHomeDepoState from "./useHomeDepoState";

const UserViewHomeDepotInvoicePage = () => {
  const {
    displayIssueDateInput,
    lineItems,
    displayAddItemModal,
    glCodes,
    invoiceTotal,
    totalTaxAmount,
    units,
    loading,
    selectedRowTaxable,
    editGLCodes,
    invoiceInfo,
    originalInvoiceUrl,
    onIssuingCompanyInfoChange,
    onIssueDateChange,
    onSelectedRowTaxableChange,
    handleLineItemDelete,
    setDisplayIssueDateInput,
    setDisplayAddItemModal,
    formRef,
    homeDepoProperties,
    handleSubmit,
    navigateToUploadInvoicePage,
    addItem,
    handleDisplayAddItemModal,
    onTaxAmountChange,
    handleEditLineItem,
    handlePropertyChange,
    readOnly,
    homeDepotPropertyLoading
  } = useHomeDepoState();
  return (
    <HomedepotReviewInvoice
      homeDepotPropertyLoading={homeDepotPropertyLoading}
      invoiceInfo={invoiceInfo}
      onIssuingCompanyInfoChange={onIssuingCompanyInfoChange}
      displayIssueDateInput={displayIssueDateInput}
      setDisplayIssueDateInput={setDisplayIssueDateInput}
      onIssueDateChange={onIssueDateChange}
      lineItems={lineItems}
      handleLineItemDelete={handleLineItemDelete}
      displayAddItemModal={displayAddItemModal}
      setDisplayAddItemModal={setDisplayAddItemModal}
      addItem={addItem}
      glCodes={glCodes}
      invoiceTotal={invoiceTotal}
      loading={loading}
      handleSubmit={handleSubmit}
      navigateToUploadInvoicePage={navigateToUploadInvoicePage}
      formRef={formRef}
      homeDepoProperties={homeDepoProperties}
      handleDisplayAddItemModal={handleDisplayAddItemModal}
      editGLCodes={editGLCodes}
      totalTaxAmount={totalTaxAmount}
      onTaxAmountChange={onTaxAmountChange}
      originalInvoiceUrl={originalInvoiceUrl}
      selectedRowTaxable={selectedRowTaxable}
      onSelectedRowTaxableChange={onSelectedRowTaxableChange}
      handleEditLineItem={handleEditLineItem}
      units={units}
      handlePropertyChange={handlePropertyChange}
      readOnly={readOnly}
    />
  );
};

export default UserViewHomeDepotInvoicePage;
