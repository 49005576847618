import { FC, useEffect, useState } from "react";
import moment from "moment";
import {
  fetchAllInvoiceGlCodes,
  fetchInvoiceById,
  invoiceUpdate
} from "../../../Services/InvoiceService";
import { useNavigate, useParams } from "react-router-dom";
import ShowToast from "../../../utils/showToast";
import {
  INVOICE_PENDING,
  INVOICE_REJECTED,
  INVOICE_APPROVED,
  TInvoiceApprovalStatus
} from "../../../utils/constants";
import { isAxiosError } from "axios";
import useFetchOriginalInvoiceUrl from "@hooks/common/useFetchOriginalInvoiceUrl";
import ReviewInvoice from "@Component/Common/ReviewInvoice";
import { diffCalculator } from "@Component/Common/ReviewInvoice/utils";
import { shortIdGenerator } from "@utils/shortIdGenerator";
import InvoiceRejectionReasonModal from "@Component/Common/ReviewInvoice/InvoiceRejectionReasonModal";
import { IAppState } from "@redux/reducers/rootReducer";
import { useSelector } from "react-redux";
import { IAuthState } from "@redux/reducers/Auth/Auth";
import { IInvoice, ILineItem, InvoiceProps, TGlCode, TUnit } from "@Component/Common/types";

const UserViewInvoicePage: FC = () => {
  const navigate = useNavigate();
  const { invoiceId } = useParams();
  const [invoiceInfo, setInvoiceInfo] = useState<InvoiceProps>({
    invoiceId: "",
    companyName: "",
    propertyName: "",
    propertyAddress: "",
    approvalStatus: "",
    vendorName: "",
    address: "",
    invoiceNumber: "",
    issueDate: ""
  });
  const [lineItems, setLineItems] = useState<Array<ILineItem>>([]);
  const [vendorLineItems, setVendorLineItems] = useState<Array<ILineItem>>([]);
  const [preLineItems, setPreLineItems] = useState<Array<any>>([]);
  const [invoiceTotal, setInvoiceTotal] = useState<number>(0);
  const [invoiceApprovalStatus, setInvoiceApprovalStatus] =
    useState<TInvoiceApprovalStatus>(INVOICE_PENDING);
  const [fetchedinvoiceApprovalStatus, setFetchedInvoiceApprovalStatus] =
    useState<TInvoiceApprovalStatus>();
  const [loading, setLoading] = useState<boolean>(false);
  const [isInvoiceApprovalAllowed, setIsInvoiceApprovalAllowed] = useState<boolean>(false);
  const [invoiceRejectionModalOpen, setInvoiceRejectionModalOpen] = useState<boolean>(false);
  const [glCodes, setGlCodes] = useState<TGlCode[]>([]);
  const [invoiceRejectionReason, setInvoiceRejectionReason] = useState<string>("");
  const [units, setUnits] = useState<TUnit[]>([]);
  const [invoice, setInvoice] = useState<IInvoice>();
  const loginState: IAuthState = useSelector((state: IAppState) => state.auth);
  const {
    loginInfo: { isRegionalManager, role }
  } = loginState;

  const fetchInvoiceData = async () => {
    setLoading(true);

    const [invoiceResponse, glCodesResponse] = await Promise.all([
      fetchInvoiceById(invoiceId),
      fetchAllInvoiceGlCodes()
    ]);
    setGlCodes(glCodesResponse.data.glCodes);

    setLoading(false);
    const info: IInvoice = invoiceResponse?.data;
    if (info?.diff) {
      const diffLineItems = info?.diff.filter((diff: any) => diff.property == "lineItems")[0]
        .previousValue;

      const changedLineItems = info.lineItems
        .map((l: any) => {
          const preVal = diffLineItems.filter((line: any) => line.lineId == l.lineId)[0];
          if (!preVal) return false;
          return diffCalculator(l, preVal);
        })
        .filter((val) => val);
      setPreLineItems(changedLineItems);
    }
    setInvoice(info);
    if (info?.property?.unit) {
      const unitsArray = info.property.unit.filter((unit) => unit.id != 0);
      info.property.unit = [{ id: 0, name: "None" }, ...unitsArray];
      setUnits(info.property.unit);
    }

    setInvoiceInfo({
      invoiceId: info?.Id,
      companyName: info?.payee.name,
      propertyName: info?.property.name,
      approvalStatus: info?.approvalStatus,
      propertyAddress: info?.propertyAddresses[0]?.Address || "",
      vendorName: info?.vendor?.name,
      address: info?.payee.address,
      invoiceNumber: info.invoiceId,
      issueDate: info.issueDate ? moment(info.issueDate).format("YYYY-MM-DD") : null
    });
    let lineItems: ILineItem[] = info.lineItems;
    setVendorLineItems(transformLineItem(lineItems, glCodesResponse));
    if (role === "user") {
      lineItems = info.internalLineItems;
    }
    lineItems = transformLineItem(lineItems, glCodesResponse);
    reviseInvoiceApprovalStatus(lineItems);
    setInvoiceTotal(info.totalAmount);
    setLineItems(transformLineItem(lineItems, glCodesResponse));
    if (info?.approvalStatus) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      setInvoiceApprovalStatus(info?.approvalStatus);
      setFetchedInvoiceApprovalStatus(info?.approvalStatus as TInvoiceApprovalStatus);
    }
    if (info?.rejectionReason) {
      setInvoiceRejectionReason(info?.rejectionReason);
    }
  };

  useEffect(() => {
    fetchInvoiceData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data } = useFetchOriginalInvoiceUrl(invoice?.persistedInvoiceFileId as string);
  const originalInvoiceUrl = data?.data?.downloadUrl;
  let legalFormUrl = "";

  const { data: fetchedLink } = useFetchOriginalInvoiceUrl(invoice?.legalFormId as string);
  legalFormUrl = fetchedLink?.data?.downloadUrl;

  const transformLineItem = (lineItems: any, glCodesResponse: any) => {
    return lineItems.map((l: any, index: number) => {
      return {
        ...l,
        key: index,
        taxable: l.isTaxable,
        description: l.description,
        pricePerUnit: l.preTaxAmount as number,
        preTaxAmount: l.preTaxAmount as number,
        postTaxAmount: l.amount as number,
        glCode: l.glCode,
        gl: l.glCode as TGlCode,
        total: l.amount,
        alternativeGlCodes: glCodesResponse.data.glCodes.map((g: TGlCode) => ({
          glCode: g,
          colorCode: "grey",
          confidenceScore: 0
        })),
        approvalStatus: l.approvalStatus,
        rejectionReason: l.rejectionReason,
        lineItemShortId: shortIdGenerator()
      };
    });
  };
  const handleInvoiceApprovalStatusChange = (status: TInvoiceApprovalStatus) => {
    const isAnyLineItemRejected = lineItems.some((l) => l.approvalStatus === INVOICE_REJECTED);
    if (isAnyLineItemRejected) {
      ShowToast("Can't Approve invoice with rejected line items", "error");
      return;
    }
    update(status, "");
  };

  const handleSubmit = async () => {
    update(invoiceApprovalStatus, invoiceRejectionReason);
  };

  const update = async (
    invoiceStatus: string,
    invoiceRejectionReason: string,
    isLienWaiverRequired = false
  ) => {
    try {
      let invoiceApprovalStatusToUpdate = invoiceStatus;
      let serializeLineItems: ILineItem[] = lineItems.map((l: ILineItem) => {
        return {
          lineId: l.lineId,
          description: l.description,
          amount: l.amount,
          approvalStatus: l.approvalStatus,
          rejectionReason: l.rejectionReason ? l.rejectionReason : "",
          glCode: l.gl as TGlCode,
          gl: l.gl as TGlCode,
          taxable: l.taxable,
          isTaxable: l.taxable,
          unit: l.unit,
          key: l.key,
          pricePerUnit: l.pricePerUnit,
          preTaxAmount: l.preTaxAmount,
          postTaxAmount: l.postTaxAmount,
          lineItemShortId: l.lineItemShortId
        };
      });
      const isAnyLineItemRejected = serializeLineItems.some(
        (l) => l.approvalStatus === INVOICE_REJECTED
      );
      if (isAnyLineItemRejected) {
        invoiceApprovalStatusToUpdate = INVOICE_REJECTED;
      }
      if (!isAnyLineItemRejected && invoiceStatus !== INVOICE_REJECTED) {
        const isLineItemApproved = serializeLineItems.some(
          (l) => l.approvalStatus === INVOICE_APPROVED
        );
        if (isLineItemApproved) {
          invoiceApprovalStatusToUpdate = INVOICE_APPROVED;
        }
      }
      if (invoiceApprovalStatusToUpdate === INVOICE_APPROVED) {
        serializeLineItems = lineItems.map((l: ILineItem) => {
          return {
            lineId: l.lineId,
            description: l.description,
            amount: l.amount,
            approvalStatus: INVOICE_APPROVED,
            rejectionReason: "",
            glCode: l.gl as TGlCode,
            gl: l.gl as TGlCode,
            taxable: l.taxable,
            isTaxable: l.taxable,
            unit: l.unit,
            key: l.key,
            pricePerUnit: l.pricePerUnit,
            preTaxAmount: l.preTaxAmount,
            postTaxAmount: l.postTaxAmount,
            lineItemShortId: l.lineItemShortId
          };
        });
      }
      if (invoice) {
        if (role === "user") {
          invoice.internalLineItems = serializeLineItems;
        }

        invoice.lineItems = serializeLineItems.map((lineItem: ILineItem) => {
          const vendorLineItem = vendorLineItems.filter(
            (line: ILineItem) => line.lineId == lineItem.lineId
          )[0];
          if (!vendorLineItem) {
            return lineItem;
          }
          return {
            ...lineItem,
            description: vendorLineItem.description
          };
        });
        invoice.approvalStatus = invoiceApprovalStatusToUpdate;
        invoice.rejectionReason = invoiceRejectionReason;
        invoice.isLienWaiverRequired = isLienWaiverRequired;
        setLoading(true);
        await invoiceUpdate(invoice);
        navigate("/");
        ShowToast(
          invoiceStatus === INVOICE_REJECTED
            ? "Invoice Rejected"
            : invoiceStatus === INVOICE_APPROVED
            ? "Invoice Approved"
            : "Invoice Submit successfully",
          invoiceStatus === INVOICE_REJECTED ? "warning" : "success"
        );
      }
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        ShowToast(error.response?.data.message, "error");
      }
      setLoading(false);
    }
  };

  const reviseInvoiceApprovalStatus = (lineItems: ILineItem[]) => {
    const isAnyLineItemRejected = lineItems.some((l) => l.approvalStatus === INVOICE_REJECTED);

    if (isAnyLineItemRejected) {
      setIsInvoiceApprovalAllowed(false);
    } else {
      setIsInvoiceApprovalAllowed(true);
    }
  };
  const handleEditLineItem = (
    lineItemId: string,
    lineItemChangeKey: string,
    lineItemValue: unknown
  ) => {
    if (lineItemChangeKey === "unit") {
      const unit = units.filter((u) => u.id === lineItemValue);
      lineItemValue = unit[0];
    }
    const copiedLineItems: Array<ILineItem> = [...lineItems];
    const objIndex = copiedLineItems.findIndex((obj) => obj.lineItemShortId === lineItemId);
    copiedLineItems[objIndex] = {
      ...copiedLineItems[objIndex],
      [lineItemChangeKey]: lineItemValue
    };

    if (lineItemChangeKey === "rejectionReason") {
      copiedLineItems[objIndex].approvalStatus = INVOICE_REJECTED;
    }
    reviseInvoiceApprovalStatus(copiedLineItems);
    setLineItems(copiedLineItems);
  };

  const handleInvoiceRejection = (rejectionReason?: string, requestLienWavierForm = false) => {
    if (!requestLienWavierForm) {
      setInvoiceRejectionModalOpen(!invoiceRejectionModalOpen);
    }

    if (rejectionReason) {
      update(INVOICE_REJECTED, rejectionReason, requestLienWavierForm);
    }
  };

  const isInvoiceEditableForPM =
    role === "user" && (fetchedinvoiceApprovalStatus === INVOICE_PENDING || isRegionalManager);

  return (
    <>
      <ReviewInvoice
        preLineItems={preLineItems}
        isManagerPortal={role === "user"}
        invoiceId={invoiceId}
        invoiceInfo={invoiceInfo}
        lineItems={lineItems}
        glCodes={glCodes}
        invoiceTotal={invoiceTotal}
        loading={loading}
        totalTaxAmount={invoice?.totalTaxAmount || 0}
        originalInvoiceUrl={originalInvoiceUrl}
        readonlyView={!isInvoiceEditableForPM}
        editGLCodes={true}
        propertyManagerView={isInvoiceEditableForPM}
        handleEditLineItem={handleEditLineItem}
        handleSubmit={handleSubmit}
        handleInvoiceApprovalStatusChange={handleInvoiceApprovalStatusChange}
        isInvoiceApprovalAllowed={isInvoiceApprovalAllowed}
        handleInvoiceRejection={handleInvoiceRejection}
        invoiceApprovalStatus={invoiceApprovalStatus}
        invoiceRejectionReason={invoiceRejectionReason}
        units={units}
        propertyType={invoice?.propertyType ? invoice?.propertyType : "UnitProperty"}
        isRegionalManager={isRegionalManager}
        requestLienWavier={!invoice?.isLienWaiverRequired && !invoice?.legalFormId}
        legalFormUrl={legalFormUrl}
        userLogsView={false}
      />
      <InvoiceRejectionReasonModal
        handleRejectionModalHide={handleInvoiceRejection}
        isRejectionModalOpen={invoiceRejectionModalOpen}
        setInvoiceRejectionModalOpen={setInvoiceRejectionModalOpen}
        isRequestLienWavierAvailable={!invoice?.isLienWaiverRequired && !invoice?.legalFormId}
      />
    </>
  );
};
export default UserViewInvoicePage;
