import { AnyAction } from "redux";
import { SCANNED_INVOICE } from "../../types/Invoice/Invoice";

export interface IInvoiceState {
  // eslint-disable-next-line
  scannedInvoice: any;
}

const initialState: IInvoiceState = {
  scannedInvoice: {}
};

const invoice = (state = initialState, action: AnyAction) => {
  if (typeof state === "undefined") {
    return initialState;
  }

  switch (action.type) {
    case SCANNED_INVOICE:
      return {
        scannedInvoice: action.payload
      };
    default:
      return state;
  }
};

export default invoice;
