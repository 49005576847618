import { FC } from "react";
import { Col, Row, Typography, Flex, Select } from "antd";
import { useBreakpoints } from "@hooks/common/useBreakPoints";
import { useAntToken } from "@hooks/common/useAntToken";
import { invoiceStyles } from "./styles";
import ReviewApprovalTag from "@Component/Common/ReviewInvoice/ApprovalTag";
interface IInvoiceContentHeaderProps {
  homeDepotPropertyLoading: boolean;
  invoiceInfo: {
    companyName: string;
    propertyName: string;
    address: string;
    propertyAddress?: string;
    rmPropertyAddress?: string;
    approvalStatus?: string;
    vendorName?: string;
    invoiceNumber: string;
    issueDate: string | null;
  };
  originalInvoiceUrl: string;
  loading: boolean;
  navigateToUploadInvoicePage?: () => void;
  onIssuingCompanyInfoChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  homeDepoProperties: Array<{
    id: number;
    name: string;
    unit: Array<{ id: number; name: string }>;
    addresses: Array<{
      City: string;
      PostalCode: string;
      State: string;
      Street: string;
      id: number;
    }>;
  }>;
  handlePropertyChange: (value: string) => void;
  readOnly: boolean;
}

const InvoiceContentHeader: FC<IInvoiceContentHeaderProps> = (props) => {
  const { styles } = invoiceStyles();
  const {
    invoiceInfo,
    loading,
    homeDepoProperties,
    handlePropertyChange,
    readOnly,
    homeDepotPropertyLoading
  } = props;
  const clonedProperties = homeDepoProperties ? structuredClone(homeDepoProperties) : [];
  const { isScreenXS } = useBreakpoints();
  const { token } = useAntToken();

  if (loading) {
    return null;
  }
  return (
    <Flex
      vertical
      style={{
        paddingLeft: isScreenXS ? token.paddingXS : token.paddingXL,
        paddingRight: isScreenXS ? token.paddingXS : token.paddingXL
      }}>
      <Row justify={"space-between"}>
        <Col>
          <Typography.Title
            level={4}
            style={{
              fontSize: isScreenXS ? token.fontSize : token.fontSizeXL,
              fontWeight: token.fontWeightStrong,
              color: token.colorText
            }}>
            Invoice No: &nbsp;{invoiceInfo.invoiceNumber}
          </Typography.Title>
        </Col>
        <Col>
          <Typography.Title
            level={4}
            style={{
              fontSize: isScreenXS ? token.fontSize : token.fontSizeXL,
              fontWeight: token.fontWeightStrong,
              color: token.colorText
            }}>
            Status: &nbsp;
            <ReviewApprovalTag approvalStatus={invoiceInfo?.approvalStatus} />
          </Typography.Title>
        </Col>
      </Row>
      <Flex
        justify={"space-between"}
        vertical={isScreenXS}
        style={{
          background: token.colorPrimary,
          padding: token.paddingLG
        }}
        align={"middle"}>
        <Col>
          <Row>
            <Col>
              <Typography.Text
                style={{
                  fontSize: token.fontSizeSM,
                  color: token.colorTextLightSolid
                }}>
                Property
              </Typography.Text>
              <Typography.Title
                level={4}
                style={{
                  fontSize: token.fontSizeLG,
                  fontWeight: token.fontWeightStrong,
                  color: token.colorTextLightSolid,
                  marginTop: 0
                }}>
                {invoiceInfo.propertyName}
              </Typography.Title>
            </Col>
          </Row>
          {invoiceInfo.approvalStatus === "Approved" && (
            <Row>
              <Col>
                <Typography.Text
                  style={{
                    fontSize: token.fontSizeSM,
                    color: token.colorTextLightSolid
                  }}>
                  Property Address
                </Typography.Text>
                <Typography.Title
                  level={4}
                  style={{
                    fontSize: token.fontSizeLG,
                    fontWeight: token.fontWeightStrong,
                    color: token.colorTextLightSolid,
                    marginTop: 0
                  }}>
                  {invoiceInfo.rmPropertyAddress || invoiceInfo.propertyAddress}
                </Typography.Title>
              </Col>
            </Row>
          )}
          {!readOnly && invoiceInfo.approvalStatus != "Approved" && (
            <Row>
              <Col>
                <Select
                  showSearch={true}
                  bordered={false}
                  placeholder="Select Property"
                  size="large"
                  loading={homeDepotPropertyLoading}
                  style={{
                    width: "100%",
                    fontSize: token.fontSizeSM,
                    border: `1px solid ${token.colorBorder}`,
                    borderRadius: token.borderRadiusSM
                  }}
                  className={styles["ant-select-header-override"]}
                  onChange={handlePropertyChange}
                  options={clonedProperties.map((p: { id: number; name: string }) => {
                    return { value: `${p.id}`, label: p.name };
                  })}
                  filterOption={(input, option) => {
                    if (input.replace(/\s/g, "").length > 0) {
                      return String(option?.label)
                        ?.toLowerCase()
                        .includes(input.toLowerCase());
                    }
                    return true;
                  }}
                />
              </Col>
            </Row>
          )}
        </Col>
        <Col style={{ marginTop: isScreenXS ? 12 : 0 }}>
          <Flex vertical align={isScreenXS ? "start" : "end"}>
            <Typography.Text
              style={{
                fontSize: token.fontSizeSM,
                color: token.colorTextLightSolid
              }}>
              Vendor Name
            </Typography.Text>
            <Col>
              <Row>
                <Col>
                  <Typography.Title
                    level={4}
                    style={{
                      fontSize: token.fontSizeLG,
                      fontWeight: token.fontWeightStrong,
                      color: token.colorTextLightSolid,
                      marginTop: 0
                    }}>
                    {invoiceInfo?.vendorName}
                  </Typography.Title>
                </Col>
              </Row>
            </Col>
            <Typography.Text
              style={{
                fontSize: token.fontSizeSM,
                color: token.colorTextLightSolid
              }}>
              Address
            </Typography.Text>
            <Col>
              <Row>
                <Col style={{ direction: isScreenXS ? "ltr" : "rtl" }}>
                  <Typography.Title
                    level={4}
                    style={{
                      fontSize: token.fontSizeLG,
                      fontWeight: token.fontWeightStrong,
                      color: token.colorTextLightSolid,
                      marginTop: 0
                    }}>
                    {invoiceInfo.address}
                  </Typography.Title>
                </Col>
              </Row>
            </Col>
          </Flex>
        </Col>
      </Flex>
    </Flex>
  );
};

export default InvoiceContentHeader;
