import { useAntToken } from "@hooks/common/useAntToken";
import { Modal, Typography } from "antd";
import { FC } from "react";
import LienWaiverForm from "./LienWaiverForm";
import { useBreakpoints } from "@hooks/common/useBreakPoints";
import { TDefaultSignDocumentValuesObject } from "./utils";

interface ISignDocumentModalProps {
  openSignDocumentModal: boolean;
  handleSignDocumentModalClose: () => void;
  defaultValues?: TDefaultSignDocumentValuesObject;
  handleFormSignSuccess: (props: { submission_id: string }) => void;
}
const SignDocumentModal: FC<ISignDocumentModalProps> = (props) => {
  const {
    handleSignDocumentModalClose,
    openSignDocumentModal,
    defaultValues,
    handleFormSignSuccess
  } = props;
  const { token } = useAntToken();
  const { isScreenXS } = useBreakpoints();
  return (
    <Modal
      width={isScreenXS ? "100%" : "90%"}
      style={{ top: 20, bottom: 0 }}
      title={
        <Typography.Title
          level={2}
          style={{
            color: token.colorText,
            fontSize: token.fontSizeHeading3,
            fontWeight: token.fontWeightStrong,
            marginTop: -5
          }}>
          Lien Waiver Form
        </Typography.Title>
      }
      open={openSignDocumentModal}
      onCancel={() => handleSignDocumentModalClose()}
      footer={null}>
      <LienWaiverForm defaultValues={defaultValues} handleFormSignSuccess={handleFormSignSuccess} />
    </Modal>
  );
};

export default SignDocumentModal;
