import { Row, Col, Button, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useAntToken } from "@hooks/common/useAntToken";

const ModalFooter = (props: any) => {
  const { handleCancel, isPending } = props;
  const { token } = useAntToken();

  return (
    <Row>
      <Col xs={24} sm={24}>
        <Row gutter={[8, 8]}>
          <Col xs={24} sm={12} md={12}>
            <Button
              type="primary"
              block
              loading={isPending}
              onClick={() => handleCancel()}
              disabled={isPending}
              style={{
                backgroundColor: token.colorBgElevated,
                borderRadius: token.borderRadius,
                height: token.controlHeightLG,
                color: token.colorText,
                fontSize: token.fontSizeLG,
                boxShadow: token.boxShadow,
                borderColor: token.colorBorder
              }}>
              Cancel
            </Button>
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Button
              type="primary"
              block
              loading={isPending}
              htmlType="submit"
              disabled={isPending}
              style={{
                backgroundColor: token.colorPrimary,
                borderRadius: token.borderRadius,
                height: token.controlHeightLG,
                fontSize: token.fontSizeLG,
                boxShadow: token.boxShadow,
                borderColor: token.colorBorder
              }}>
              {isPending ? (
                <Spin
                  indicator={
                    <LoadingOutlined
                      style={{ fontSize: token.fontSizeLG, color: token.colorBgBase }}
                      spin
                    />
                  }
                />
              ) : (
                "Save"
              )}
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ModalFooter;
