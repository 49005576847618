import { FC } from "react";
import {
  CheckCircleOutlinedIcon,
  CloseCircleOutlinedIcon,
  WarningOutlinedIcon
} from "../../assets/icons/CustomIcons";

interface IInvoice {
  Id: string;
  invoiceNumber: string;
  amount: number;
  paymentStatus: string;
  approvalStatus: string;
  propertyName: string;
}

interface Props {
  invoice: IInvoice;
}

const PaymentTag: FC<Props> = (props) => {
  const { invoice } = props;
  return (
    <>
      {invoice.paymentStatus === "Paid" && <CheckCircleOutlinedIcon />}
      {invoice.paymentStatus === "Unpaid" && <CloseCircleOutlinedIcon />}
      {invoice.paymentStatus === "Pending" && <WarningOutlinedIcon />}
    </>
  );
};
export default PaymentTag;
