import { useParams, useSearchParams } from "react-router-dom";
import UsersList from "@Component/Admin/AdminViewUsers/UsersList";
import EditUserPermissionsModal from "@Component/Admin/AdminViewUsers/EditUserPermissionsModal";
import { useState } from "react";
import { TAdminUser, TProperties } from "@Component/Admin/AdminViewUsers/types";
import useFetchAllGlCodes from "@hooks/Admin/useFetchAllGlCodes";
import useFetchAllProperties from "@hooks/Admin/useFetchAllProperties";
import { useFetchAdminUsers } from "@hooks/Admin/useFetchAdminUsers";
import useAdminDeleteUser from "@hooks/Admin/useAdminDeleteUser";
import { TGlCode } from "@Component/Common/types";
import useFetchAllPropertiesByRole from "@hooks/Admin/useFetchAllPropertiesByRole";
import { useGlGroupFetchAll } from "@hooks/Admin/GlGroup";
import { useCardsFetchAll } from "@hooks/Admin/HomeDepotCards/useCardsFetchAll";

const AdminViewUsersPage = () => {
  const { userType } = useParams();
  const [searchParams] = useSearchParams();

  const { data, isLoading: adminUsersLoading } = useFetchAdminUsers();
  const { data: fetchedGlCodesData, isLoading: glCodesLoading } = useFetchAllGlCodes();
  const { data: glCodeGroupFetch } = useGlGroupFetchAll();
  const { data: fetchedCardsData } = useCardsFetchAll();
  const { data: fetchedPropertiesData, isLoading: propertiesLoading } = useFetchAllProperties();
  const {
    data: fetchedRolePropertiesData,
    isLoading: rolePropertiesDataLoading,
    refetch
  } = useFetchAllPropertiesByRole(searchParams.get("role") as string);
  const [selectedUser, setSelectedUser] = useState<TAdminUser>();
  const fetchedGlCodes = (fetchedGlCodesData?.data?.glCodes as TGlCode[]) || [];
  const totalPages = data?.totalPages as number;
  const fetchedProperties: TProperties[] = fetchedPropertiesData?.data || [];
  const fetchedPropertiesByRole: TProperties[] = fetchedRolePropertiesData?.data || [];
  const [open, setOpen] = useState(false);
  const headers = ["Vendor Name", "Email", "Invitation Status", "Permissions"];
  const isLoading = adminUsersLoading;

  const handleOpenInviteVendorModal = () => {
    setOpen(true);
  };

  const handleUserChange = (vendor: TAdminUser) => {
    setSelectedUser(vendor);
  };

  const handleClose = (value: boolean) => {
    setSelectedUser(undefined);
    refetch();
    setOpen(value);
  };

  if (userType === "business-users") {
    headers[0] = "Manager Name";
    headers.push("Manager Type");
  }

  const { mutate: deleteUser } = useAdminDeleteUser();
  const handleDeleteUser = (authUserId: string) => {
    deleteUser(authUserId);
  };

  return (
    <>
      <UsersList
        headers={headers}
        users={(data?.users as TAdminUser[]) || []}
        userType={userType === "vendors" ? "Vendor" : "User"}
        handleOpenInviteVendorModal={handleOpenInviteVendorModal}
        handleUserChange={handleUserChange}
        isLoading={isLoading}
        totalPages={totalPages}
        handleDeleteUser={handleDeleteUser}
        properties={fetchedPropertiesByRole}
        rolePropertiesDataLoading={rolePropertiesDataLoading}
      />
      {selectedUser && (
        <EditUserPermissionsModal
          open={open}
          handleModalClose={handleClose}
          user={selectedUser}
          fetchedCards={
            fetchedCardsData?.map(
              (card: any) => `${card.cardDetails} ${card.nickName ? "(" + card.nickName + ")" : ""}`
            ) || []
          }
          fetchedGlCodes={fetchedGlCodes}
          glCodeGroupFetch={glCodeGroupFetch}
          fetchedProperties={fetchedProperties}
          glCodesLoading={glCodesLoading}
          propertiesLoading={propertiesLoading}
        />
      )}
    </>
  );
};

export default AdminViewUsersPage;
