import { FC } from "react";
import { Tag } from "antd";
import { useBreakpoints } from "@hooks/common/useBreakPoints";

interface ITagRender {
  label: string;
  closable: boolean;
  onClose: () => void;
}

const SelectInputTag: FC<ITagRender> = (props) => {
  const { label, closable, onClose } = props;

  const { isScreenXS } = useBreakpoints();

  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const truncatedLabel = () => {
    if (isScreenXS && String(label).length > 10) {
      return `${String(label).substring(0, 13)}...`;
    }
    if (!isScreenXS && String(label).length > 23) {
      return `${String(label).substring(0, 20)}...`;
    }
    return label;
  };

  return (
    <Tag
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{
        maxWidth: 180
      }}>
      {truncatedLabel()}
    </Tag>
  );
};

export default SelectInputTag;
