import { useAntToken } from "@hooks/common/useAntToken";
import { SelectProps, Form, FormInstance, Select, Typography, Col } from "antd";
import { FC, useCallback } from "react";
import { TProperties } from "../types";
import usePropertiesState from "./usePropertiesState";
import PropertyTags from "./PropertyTags";
import { getPropertiesSelectProps } from "../utils";
import SelectInputTag from "@Component/Common/SelectInput/SelectInputTag";

interface ISelectPropertiesProps {
  properties: TProperties[];
  fetchedProperties: TProperties[];
  propertiesLoading: boolean;
  form: FormInstance;
}

type TagRender = SelectProps["tagRender"];

const SelectProperties: FC<ISelectPropertiesProps> = (props) => {
  const { properties, fetchedProperties, propertiesLoading, form } = props;
  const getPropertiesValueFromEvent = useCallback(
    (value: Array<string>, selections: Array<any>) => {
      if (!selections?.length) return selections;
      if (!selections?.some((s) => s.value === "All")) {
        return selections;
      }
      if (value.toString().includes("All")) {
        if (value.length === fetchedProperties.length + 1) {
          return [];
        }
        return [...fetchedProperties].map((p) => ({ label: p.name, value: p.name, id: p.id }));
      }
      const labelInValue = typeof value[0] === "string";
      return labelInValue ? properties : properties.map((o: any) => o.label);
    },
    [properties]
  );
  const { token } = useAntToken();

  const {
    filteredSelectedProperties,
    handleChangeProperty,
    handleDeselectProperty,
    handleDeletePropertyTag,
    selectedProperties
  } = usePropertiesState({
    fetchedProperties,
    form
  });

  const tagRender: TagRender = (props: any) => {
    const { label, closable, onClose } = props;
    return <SelectInputTag label={label} closable={closable} onClose={onClose} />;
  };

  return (
    <Col style={{ marginBottom: 10 }}>
      <Typography.Title
        level={2}
        style={{
          color: token.colorText,
          fontSize: token.fontSize,
          fontWeight: token.fontWeightStrong
        }}>
        Property
      </Typography.Title>
      <Form.Item
        getValueFromEvent={getPropertiesValueFromEvent}
        name="assignedProperties"
        rules={[{ required: true, message: "Please Enter Assigned Properties" }]}
        style={{ width: "100%", marginBottom: 5 }}>
        <Select
          value={selectedProperties}
          tagRender={tagRender}
          onDeselect={(e) => {
            handleDeselectProperty(e);
          }}
          {...getPropertiesSelectProps(properties, fetchedProperties, propertiesLoading)}
          onChange={(e) => {
            handleChangeProperty(e);
          }}
          filterSort={(optionA, optionB) => {
            if (optionA.label === "All" || optionB.label === "All") return 1;

            if (optionB.label && optionA.label && optionA.label > optionB.label) {
              return 1;
            }
            return -1;
          }}
        />
      </Form.Item>
      <PropertyTags
        properties={filteredSelectedProperties}
        onDeleteTag={handleDeletePropertyTag}
        loading={propertiesLoading}
      />
    </Col>
  );
};

export default SelectProperties;
