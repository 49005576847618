import {
  INVOICE_APPROVED,
  INVOICE_PENDING,
  INVOICE_REJECTED,
  INVOICE_UNDER_REVIEW,
  INVOICE_CANCELLED
} from "@utils/constants";
import { Flex, Tag, Tooltip } from "antd";
import { useAntToken } from "@hooks/common/useAntToken";
import { FC } from "react";
interface IInvoice {
  Id: string;
  invoiceNumber: string;
  amount: number;
  paymentStatus: string;
  approvalStatus: string;
  propertyName: string;
}

interface Props {
  invoice: IInvoice;
}
const ApprovalTag: FC<Props> = (props) => {
  const {
    invoice: { approvalStatus: status }
  } = props;
  const { token } = useAntToken();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const fontWeightMedium = token.fontWeightMedium;
  const commonStyles = {
    boxShadow: token.boxShadow,
    fontWeight: fontWeightMedium,
    fontSize: token.fontSize
  };

  const commonFlexStyles = {
    height: "24px",
    width: "104px"
  };

  return (
    <>
      {status === INVOICE_APPROVED && (
        <Flex justify="center" align="middle" style={commonFlexStyles}>
          <Tag
            bordered={false}
            color={token.colorSuccess}
            style={{
              ...commonStyles,
              background: token.colorSuccessBgHover,
              color: token.colorText
            }}>
            Approved
          </Tag>
        </Flex>
      )}
      {status === INVOICE_REJECTED && (
        <Flex justify="center" align="middle" style={commonFlexStyles}>
          <Tag
            bordered={false}
            color="error"
            style={{
              ...commonStyles,
              background: token.colorErrorBgHover,
              color: token.colorText
            }}>
            Rejected
          </Tag>
        </Flex>
      )}
      {status === INVOICE_PENDING && (
        <Flex justify="center" align="middle" style={commonFlexStyles}>
          <Tag
            bordered={false}
            color="warning"
            style={{
              ...commonStyles,
              background: token.colorWarningBgHover,
              color: token.colorText
            }}>
            Pending
          </Tag>
        </Flex>
      )}
      {status === INVOICE_UNDER_REVIEW && (
        <Flex justify="center" align="middle" style={commonFlexStyles}>
          <Tooltip title={"Regional Manager Review"}>
            <Tag
              bordered={false}
              color="warning"
              style={{
                ...commonStyles,
                background: token.colorWarning,
                color: token.colorText
              }}>
              RM Review
            </Tag>
          </Tooltip>
        </Flex>
      )}
      {status === INVOICE_CANCELLED && (
        <Flex justify="center" align="middle" style={commonFlexStyles}>
          <Tag
            bordered={false}
            color="error"
            style={{
              ...commonStyles,
              background: token.colorWarning,
              color: token.colorText
            }}>
            Cancelled
          </Tag>
        </Flex>
      )}
    </>
  );
};

export default ApprovalTag;
