import { Col, Row, Typography } from "antd";
import { FC, ReactNode, useEffect, useState } from "react";
import SearchUsers from "../SearchUsers";

import { UserTabsHeadersView, UsersTabsContentView } from "../UsersTabsView";
import { TUserType } from "../types";
import { useBreakpoints } from "@hooks/common/useBreakPoints";
import { useParams } from "react-router-dom";
import { ROUTE_PATHS } from "@routes/constants";
import { PlusCircleOutlined } from "@ant-design/icons";
import { GrayFilterIcon } from "@assets/icons/CustomIcons";
import useAdminSearchParams from "@hooks/Admin/useAdminSearchParams";
import { useAntToken } from "@hooks/common/useAntToken";
import CommonAppButton from "@Component/Common/CommonAppButton";
import { IAuthState } from "@redux/reducers/Auth/Auth.ts";
import { useSelector } from "react-redux";
import { IAppState } from "@redux/reducers/rootReducer.ts";

interface Props {
  navigateToInviteVendor: () => void;
  navigateToInviteBusinessUser: () => void;
  navigateToViewUsers: (userType: TUserType) => void;
  navigateToViewVendorInvoices: () => void;
}

interface ICommonRowProps {
  children?: ReactNode;
}
const CommonRow: FC<ICommonRowProps> = ({ children }) => {
  const { token } = useAntToken();
  return (
    <Row gutter={[8, 8]} style={{ marginTop: token.marginLG }} justify={"space-between"}>
      {children}
    </Row>
  );
};
const AdminDashboard: FC<Props> = (props) => {
  const { token } = useAntToken();
  const loginState: IAuthState = useSelector((state: IAppState) => state.auth);
  const { role } = loginState;
  const { clearFilters } = useAdminSearchParams();
  const [activeTab, setActiveTab] = useState<TUserType>("vendors");
  const { isScreenXS: isMobileScreen } = useBreakpoints();
  const { userType } = useParams();
  const handleChangeTab = (tab: TUserType) => {
    setActiveTab(tab);
    navigateToViewUsers(tab);
  };
  const { navigateToInviteVendor, navigateToInviteBusinessUser, navigateToViewUsers } = props;
  const isVendor = userType === "vendors";

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const fontWeightNormal = token.fontWeightNormal;

  const commonButtonStyles = {
    height: isMobileScreen ? token.controlHeightSM : token.controlHeightLG,
    color: token.colorTextLightSolid,
    fontSize: isMobileScreen ? token.fontSizeSM : token.fontSizeLG,
    paddingBottom: isMobileScreen ? token.paddingLG : token.paddingXXS,
    boxShadow: token.boxShadow,
    fontWeight: fontWeightNormal
  };

  useEffect(() => {
    if (!userType) {
      navigateToViewUsers(ROUTE_PATHS.ADMIN_VIEW_VENDOR_RELATIVE_PATH as TUserType);
      setActiveTab(ROUTE_PATHS.ADMIN_VIEW_VENDOR_RELATIVE_PATH as TUserType);
    } else {
      setActiveTab(userType as TUserType);
    }
  }, [userType, navigateToViewUsers]);

  return (
    <>
      <Row justify="center">
        <Col span={22}>
          <Row justify={"space-between"} align={"middle"}>
            <Col>
              <Typography.Title
                level={2}
                style={{
                  color: token.colorText,
                  fontSize: isMobileScreen ? token.fontSizeHeading3 : token.fontSizeHeading1,
                  paddingTop: isMobileScreen ? token.paddingSM : token.padding,
                  fontWeight: token.fontWeightStrong
                }}>
                Admin Portal
              </Typography.Title>
            </Col>
          </Row>
          <Row justify="space-between" gutter={[2, 2]}>
            <CommonRow>
              <Col>
                <UserTabsHeadersView
                  role={role as "user" | "admin"}
                  activeTab={activeTab}
                  handleChangeTab={handleChangeTab}
                />
              </Col>
            </CommonRow>
            <CommonRow>
              <Col>
                <SearchUsers />
              </Col>
              <Col>
                <Row gutter={[8, 8]}>
                  <Col>
                    <CommonAppButton
                      type="primary"
                      block
                      icon={<PlusCircleOutlined />}
                      onClick={isVendor ? navigateToInviteVendor : navigateToInviteBusinessUser}
                      style={commonButtonStyles}>
                      {isVendor ? "Invite Vendor" : "Invite Property Manager"}
                    </CommonAppButton>
                  </Col>
                  <Col>
                    <CommonAppButton
                      type="primary"
                      block
                      icon={<GrayFilterIcon />}
                      onClick={() => clearFilters()}
                      style={{
                        ...commonButtonStyles,
                        backgroundColor: token.colorBgContainer,
                        color: token.colorPrimary
                      }}
                      text="Clear Filters"
                    />
                  </Col>
                </Row>
              </Col>
            </CommonRow>
          </Row>
          <UsersTabsContentView />
        </Col>
      </Row>
    </>
  );
};
export default AdminDashboard;
