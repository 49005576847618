import { FC } from "react";
import { Col, Row, Tabs, theme } from "antd";
import AdminViewUsersPage from "../../../Pages/Admin/AdminViewUsersPage";
import { useParams } from "react-router-dom";
import { TTabItem, TUserType } from "../types";
import TabButton from "./TabButton";

const { TabPane } = Tabs;
const { useToken } = theme;

interface ICustomTabProps {
  elements: TTabItem[];
}
const CustomTab: FC<ICustomTabProps> = ({ elements }) => {
  const { userType } = useParams();
  return (
    <>
      <Tabs
        activeKey={userType}
        tabBarGutter={70}
        tabBarStyle={{ display: "none" }}
        size="large"
        className="Tabs">
        {elements.map((element: TTabItem) => (
          <TabPane tab={element.tabTitle} key={element.path}>
            {element.tabBody}
          </TabPane>
        ))}
      </Tabs>
    </>
  );
};

interface IUserTabsHeadersViewProps {
  role: "user" | "admin";
  activeTab: TUserType;
  handleChangeTab: (tab: TUserType) => void;
}
export const UserTabsHeadersView: FC<IUserTabsHeadersViewProps> = (props) => {
  const { role, activeTab, handleChangeTab } = props;
  const { token } = useToken();
  return (
    <Row
      style={{
        backgroundColor: "rgba(118, 118, 128, 0.12)",
        padding: "3px",
        borderRadius: token.borderRadius
      }}>
      <Col>
        <TabButton
          title="Vendors"
          onTabClick={handleChangeTab}
          activeTab={activeTab}
          userType="vendors"
        />
      </Col>
      {role === "admin" && (
        <Col>
          <TabButton
            title="Managers"
            onTabClick={handleChangeTab}
            activeTab={activeTab}
            userType="business-users"
          />
        </Col>
      )}
    </Row>
  );
};

export const UsersTabsContentView = () => {
  return (
    <CustomTab
      elements={[
        {
          tabTitle: "Vendors",
          tabBody: <AdminViewUsersPage />,
          path: "vendors"
        },
        {
          tabTitle: "Business Users",
          tabBody: <AdminViewUsersPage />,
          path: "business-users"
        }
      ]}
    />
  );
};
