import {
  INVOICE_APPROVED,
  INVOICE_PENDING,
  INVOICE_REJECTED,
  INVOICE_UNDER_REVIEW
} from "@utils/constants";
import { Tag } from "antd";
import { useAntToken } from "@hooks/common/useAntToken";
import { FC } from "react";
import { useBreakpoints } from "@hooks/common/useBreakPoints";

interface Props {
  approvalStatus?: string;
}
const ReviewApprovalTag: FC<Props> = (props) => {
  const { approvalStatus: status } = props;
  const { isScreenXS } = useBreakpoints();
  const { token } = useAntToken();

  const commonStyles = {
    boxShadow: token.boxShadow,
    fontSize: isScreenXS ? token.fontSize : token.fontSizeXL,
    fontWeight: token.fontWeightStrong,
    color: token.colorText
  };

  let color;
  let backGroundColor;

  switch (status) {
    case INVOICE_APPROVED:
      color = token.colorSuccess;
      backGroundColor = token.colorSuccessBgHover;
      break;
    case INVOICE_REJECTED:
      color = "error";
      backGroundColor = token.colorErrorBgHover;
      break;
    case INVOICE_PENDING:
      color = "warning";
      backGroundColor = token.colorWarningBgHover;
      break;
    case INVOICE_UNDER_REVIEW:
      color = "warning";
      backGroundColor = token.colorWarning;
      break;
    default:
      color = "error";
      backGroundColor = token.colorErrorBgHover;
      break;
  }
  return (
    <Tag
      bordered={false}
      color={color}
      style={{
        ...commonStyles,
        background: backGroundColor,
        color: token.colorText
      }}>
      {status === "UnderReview" ? "RM-Review" : status}
    </Tag>
  );
};

export default ReviewApprovalTag;
