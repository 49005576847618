export const ROUTE_PATHS = {
  HOME: "/",
  AUTH_LOGIN: "/auth/login",
  UNAUTHORIZED: "/unauthorized",
  NEW_PASSWORD: "/newPassword",
  UPDATE_PASSWORD: "/updatePassword/:token",
  USER_VIEW_INVOICE: "/userViewInvoice/:invoiceId",
  USER_VIEW_HOME_DEPOT_INVOICE: "/userViewInvoice/homedepot/:invoiceId",
  ADMIN_VIEW_INVOICE: "/admin/invoice/:invoiceId",
  VENDOR: "/vendor",
  VENDOR_UPLOAD_INVOICE: "/vendorUploadInvoice",
  VENDOR_REVIEW_INVOICE: "/vendorReviewInvoice",
  VENDOR_VIEW_INVOICE_PAGE: "/vendorViewInvoice/:invoiceId",
  VENDOR_VIEW_REJECTED_INVOICE: "/vendorViewRejectedInvoice/:invoiceId",
  ADMIN: "/admin",
  ADMIN_INVITE_VENDOR: "/adminInviteVendor",
  ADMIN_INVITE_BUSINESS_USER: "/adminInviteBusinessUser",
  ADMIN_RE_INVITE: "/adminReInvite",
  ADMIN_RE_INVITE_WITH_ROLE_PARAM: "/adminReInvite/:role",
  ADMIN_VIEW_USERS: "/admin/:userType",
  ADMIN_VIEW_VENDORS: "/admin/vendors",
  ADMIN_VIEW_USER_LOGS: "/admin/userLogs",
  ADMIN_SETTINGS: "/admin/settings",
  ADMIN_VIEW_BUSINESS_USERS: "/admin/business-users",
  ADMIN_VIEW_VENDOR_RELATIVE_PATH: "vendors",
  ADMIN_VIEW_BUSINESS_USER_RELATIVE_PATH: "business-users"
};
