import { FC } from "react";
import { Col, Divider, Flex, Form, Input, Row, Typography } from "antd";
import { useAntToken } from "@hooks/common/useAntToken";
import formatter from "@utils/formatter";
import { useBreakpoints } from "@hooks/common/useBreakPoints";
import { commonAntStyles } from "@Component/Common/styles";
interface IInvoiceContentFooterProps {
  totalTaxAmount?: number;
  invoiceTotal?: number;
  onTaxAmountChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  readOnly: boolean;
}

const InvoiceContentFooter: FC<IInvoiceContentFooterProps> = (props) => {
  const { token } = useAntToken();
  const { isScreenXS } = useBreakpoints();
  const { styles: commonStyles } = commonAntStyles();
  const { totalTaxAmount, onTaxAmountChange, invoiceTotal, readOnly } = props;
  const commonInputStyles = {
    border: 0,
    borderRadius: 0,
    backgroundColor: token.colorBgBase
  };
  const commonFormItemStyes = {
    border: `1px solid ${token.colorBorder}`
  };
  return (
    <Row justify="end" style={{ marginTop: token.marginXL }}>
      <Flex
        justify="flex-start"
        align="middle"
        style={{
          padding: token.paddingLG,
          width: 300,
          paddingTop: isScreenXS ? token.paddingLG : 0,
          paddingBottom: isScreenXS ? token.paddingLG : token.paddingSM
        }}
        vertical>
        <Flex
          justify="space-between"
          align={"bottom"}
          style={{ height: "40px" }}
          className={commonStyles["ant-input-override"]}>
          <Col style={{ marginTop: token.marginXXS }}>
            <Typography.Text style={{ color: token.colorTextBase, fontSize: token.fontSizeSM }}>
              Tax
            </Typography.Text>
          </Col>
          <Col>
            <Form.Item
              style={{
                ...commonFormItemStyes,
                height: "28px",
                textAlign: "right"
              }}>
              <Input
                disabled={readOnly}
                style={{
                  ...commonInputStyles,
                  fontSize: token.fontSizeSM,
                  width: "100px",
                  marginBottom: token.marginXS,
                  height: "26px",
                  textAlign: "right"
                }}
                placeholder="Total Tax"
                defaultValue={totalTaxAmount}
                value={totalTaxAmount}
                name="totalTaxAmount"
                onChange={onTaxAmountChange}
              />
            </Form.Item>
          </Col>
        </Flex>
        <Divider style={{ marginTop: 0, marginBottom: token.marginSM }} />
        <Row justify="space-between">
          <Col>
            <Typography.Text
              style={{
                color: token.colorTextBase,
                fontSize: token.fontSizeLG,
                fontWeight: token.fontWeightStrong
              }}>
              Total Amount
            </Typography.Text>
          </Col>
          <Col>
            <Typography.Text
              style={{
                color: token.colorTextBase,
                fontSize: token.fontSizeLG,
                fontWeight: token.fontWeightStrong
              }}>
              {formatter.format(invoiceTotal as number)}
            </Typography.Text>
          </Col>
        </Row>
      </Flex>
    </Row>
  );
};

export default InvoiceContentFooter;
