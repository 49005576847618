import { rest } from "msw";
import { vendorsList } from "./data/vendorsList";
import { inviteVendorResponse } from "./data/inviteVendorResponse";
import { APP_BASE_URL, fetchAllVendorsUrl, inviteVendorsUrl, signInUrl } from "../utils/urls";
import { adminLoginResponse } from "./data/adminLoginResponse";

const handlers = [
  rest.get(`${APP_BASE_URL}${fetchAllVendorsUrl}`, (_req, res, ctx) => {
    return res(ctx.delay(1000), ctx.status(200, "Mocked status"), ctx.json(vendorsList));
  }),
  rest.post(`${APP_BASE_URL}${inviteVendorsUrl}`, (_req, res, ctx) => {
    return res(ctx.delay(1000), ctx.status(200, "Mocked status"), ctx.json(inviteVendorResponse));
  }),
  rest.post(`${APP_BASE_URL}${signInUrl}`, (_req, res, ctx) => {
    return res(ctx.delay(1000), ctx.status(200, "Mocked status"), ctx.json(adminLoginResponse));
  })
];

export default handlers;
