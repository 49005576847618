import { Form, Input, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import useAdminSearchParams from "@hooks/Admin/useAdminSearchParams";
import { useDebounce } from "use-debounce";

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAntToken } from "@hooks/common/useAntToken";
import { useUsersListStyles } from "@Component/Admin/AdminViewUsers/useUsersListStyles";
import { commonAntStyles } from "@Component/Common/styles";
import {useBreakpoints} from "@hooks/common/useBreakPoints";

const SearchUsers = () => {
  const { userType } = useParams();
  const { setSearch: setAdminSearch } = useAdminSearchParams();
  const { isScreenXS: isMobileScreen } = useBreakpoints();
  const { styles: commonStyles } = commonAntStyles();
  const [search, setSearch] = useState("");
  const [debouncedSearch] = useDebounce(search, 1000);
  const { token } = useAntToken();
  const { styles } = useUsersListStyles();

  useEffect(() => {
    setAdminSearch(debouncedSearch);
    // eslint-disable-next-line
  }, [debouncedSearch]);

  useEffect(() => {
    setSearch("");
  }, [userType]);
  return (
    <Space.Compact size="large" style={{ width: "100%" }} direction="vertical">
      <Form.Item
        className={commonStyles["ant-input-override"]}
        style={{
          background: token.colorFillAlter,
          border: "none",
          height: isMobileScreen ? token.controlHeight + 4 : token.controlHeightLG + 4
        }}>
        <Input
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          className={styles["ant-input-over-ride"]}
          style={{
            border: "none",
            backgroundColor: token.colorFillAlter,
            height: isMobileScreen ? token.controlHeight + 4 : token.controlHeightLG + 4,
            fontSize: isMobileScreen ? token.fontSizeSM : token.fontSizeLG
          }}
          allowClear={true}
          prefix={
            <SearchOutlined
              style={{
                paddingRight: token.paddingXS,
                color: token.colorTextDescription,
                border: 0
              }}
              onClick={() => {
                setSearch(search);
              }}
            />
          }
          onPressEnter={() => {
            setSearch(search);
          }}
          placeholder={userType === "vendors" ? "Search Vendors" : "Search Property Managers"}
        />
      </Form.Item>
    </Space.Compact>
  );
};

export default SearchUsers;
