import { theme } from "antd";
import { TUserType } from "../types";
import { FC } from "react";
import { useBreakpoints } from "@hooks/common/useBreakPoints";
import CommonAppButton from "@Component/Common/CommonAppButton";
const { useToken } = theme;

interface ITabButtonProps {
  onTabClick: (tab: TUserType) => void;
  title: string;
  userType: TUserType;
  activeTab: TUserType;
}
const TabButton: FC<ITabButtonProps> = (props) => {
  const { onTabClick, title, userType, activeTab } = props;
  const { token } = useToken();
  const { isScreenXS: isMobileScreen } = useBreakpoints();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const fontWeightNormal = token.fontWeightNormal;
  const isCurrentTabActive = activeTab === userType;
  return (
    <CommonAppButton
      type="primary"
      block
      onClick={() => {
        onTabClick(userType);
      }}
      style={{
        backgroundColor: isCurrentTabActive ? token.colorBgContainer : "transparent",
        zIndex: isCurrentTabActive ? 1 : 0,
        position: isCurrentTabActive ? "unset" : "relative",
        left: isCurrentTabActive ? 0 : -3,
        color: isCurrentTabActive ? token.colorPrimary : token.colorText,
        fontWeight: isCurrentTabActive ? token.fontWeightStrong : fontWeightNormal.fontWeightNormal,
        fontSize: isMobileScreen ? token.fontSizeSM : token.fontSize,
        paddingBottom: isMobileScreen ? token.paddingLG : token.paddingXXS,
        boxShadow: isCurrentTabActive ? token.boxShadow : "none",
        height: isMobileScreen ? token.controlHeightSM : token.controlHeightLG,
        borderRadius: isCurrentTabActive ? token.borderRadius : 0
      }}>
      {title}
    </CommonAppButton>
  );
};

export default TabButton;
