import { Col, Row, Typography, Modal, Form, Input } from "antd";
import { ExclamationCircleFilledIcon } from "@assets/icons/CustomIcons";
import { useAntToken } from "@hooks/common/useAntToken";
import { FC, useState } from "react";
import CommonAppButton from "@Component/Common/CommonAppButton";

const { TextArea } = Input;

interface IRejectLineItemModalProps {
  handleEditLineItem?: (
    lineItemId: string,
    lineItemChangeKey: string,
    lineItemValue: unknown
  ) => void;
  lineItemShortId: string;
  handleRejectionModalHide: () => void;
  isRejectionModalOpen: boolean;
}
const RejectLineItemModal: FC<IRejectLineItemModalProps> = (props) => {
  const { handleEditLineItem, handleRejectionModalHide, isRejectionModalOpen, lineItemShortId } =
    props;
  const [rejectionReason, setRejectionReason] = useState("");
  const { token } = useAntToken();
  return (
    <Modal
      destroyOnClose
      afterClose={() => setRejectionReason("")}
      title={
        <Row align="middle" gutter={[8, 0]}>
          <Col>
            <ExclamationCircleFilledIcon style={{ fontSize: token.fontSizeHeading1 }} />
          </Col>
          <Col>
            <Typography.Title
              level={4}
              style={{
                fontSize: token.fontSizeLG,
                fontWeight: token.fontWeightStrong,
                color: token.colorText,
                margin: 0
              }}>
              Reason for Rejection
            </Typography.Title>
          </Col>
        </Row>
      }
      open={isRejectionModalOpen}
      onCancel={handleRejectionModalHide}
      footer={null}>
      <Form
        name="rejectionForm"
        onFinish={() => {
          handleEditLineItem?.(lineItemShortId, "rejectionReason", rejectionReason);
          handleRejectionModalHide();
        }}>
        <Row>
          <Col span={24}>
            <Form.Item
              name="rejectionReason"
              rules={[{ required: true, message: "Please input your reason!" }]}>
              <TextArea
                rows={4}
                style={{ width: "100%" }}
                value={rejectionReason}
                onChange={(e) => setRejectionReason(e.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end" gutter={[8, 0]}>
          <CommonAppButton
            type="text"
            onClick={handleRejectionModalHide}
            style={{
              color: token.colorPrimary,
              border: "1px solid rgba(0, 0, 0, 0.15)",
              marginRight: 4,
              boxShadow:
                "rgba(0, 0, 0, 0.03) 0px 1px 2px 0px, rgba(0, 0, 0, 0.02) 0px 1px 6px -1px, rgba(0, 0, 0, 0.02) 0px 2px 4px 0px"
            }}>
            Dismiss
          </CommonAppButton>
          <CommonAppButton
            type="text"
            htmlType="submit"
            style={{ background: token.colorPrimary, color: token.colorTextLightSolid }}>
            Submit
          </CommonAppButton>
        </Row>
      </Form>
    </Modal>
  );
};

export default RejectLineItemModal;
