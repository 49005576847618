import { FC, Ref } from "react";
import { Col, Input, Row, Select, Modal, Form, FormInstance, Typography } from "antd";
import { useAntToken } from "@hooks/common/useAntToken";
import { allowOnlyNumbers } from "@utils/validators";
import CommonAppButton from "@Component/Common/CommonAppButton";
import CommonFormLabel from "@Component/Common/CommonFormLabel";
import { ILineItem, TGlCode, TUnit } from "@Component/Common/types";
import { generateGlCodeLabel } from "@Component/Common/utils";
interface IAddInvoiceItemModalProps {
  displayAddItemModal?: boolean;
  setDisplayAddItemModal?: (value: boolean) => void;
  addItem?: (values: ILineItem) => void;
  formRef?: Ref<FormInstance<ILineItem>>;
  glCodes?: TGlCode[];
  units: TUnit[];
  propertyType: string;
}

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 }
};
const AddInvoiceItemModal: FC<IAddInvoiceItemModalProps> = (props) => {
  const {
    addItem,
    displayAddItemModal,
    setDisplayAddItemModal,
    formRef,
    glCodes,
    units,
    propertyType
  } = props;
  const { token } = useAntToken();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const fontWeightNormal = token.fontWeightNormal;
  const commonInputStyles = {
    height: token.controlHeightLG,
    backgroundColor: token.colorBgBase,
    borderRadius: 0,
    border: `1px solid ${token.colorBorder}`
  };
  return (
    <Modal
      title={
        <Typography.Text
          style={{
            fontSize: token.fontSizeHeading3,
            paddingLeft: token.marginLG,
            marginLeft: token.marginSM
          }}>
          Add Item
        </Typography.Text>
      }
      open={displayAddItemModal}
      onOk={() => setDisplayAddItemModal?.(false)}
      onCancel={() => setDisplayAddItemModal?.(false)}
      footer={false}>
      <Row justify="center" align="middle">
        <Col
          span={24}
          style={{ backgroundColor: token.colorBgContainer, borderRadius: token.borderRadiusLG }}>
          <Row justify="center">
            <Col span={24}>
              <Form {...layout} onFinish={addItem} initialValues={{unitId: "None", gl: "None"}} ref={formRef} layout="vertical">
                <Row style={{ marginTop: token.marginLG }} justify="center">
                  <Col span={20}>
                    <Form.Item
                      label={<CommonFormLabel>Description</CommonFormLabel>}
                      name="description"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Description"
                        }
                      ]}>
                      <Input
                        placeholder="Description"
                        name="description"
                        style={commonInputStyles}
                      />
                    </Form.Item>

                    <Form.Item
                      label={<CommonFormLabel>Price Per Unit</CommonFormLabel>}
                      name="pricePerUnit"
                      rules={[
                        {
                          required: true,
                          message: "Please Price Per Unit"
                        },
                        {
                          validator: allowOnlyNumbers
                        }
                      ]}>
                      <Input
                        placeholder="Price Per Unit"
                        name="pricePerUnit"
                        style={commonInputStyles}
                      />
                    </Form.Item>

                    <Form.Item
                      label={<CommonFormLabel>GL Code</CommonFormLabel>}
                      name="gl"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter GL Code"
                        }
                      ]}>
                      <Select
                        showSearch
                        optionFilterProp="label"
                        placeholder={"GL Code"}
                        style={{ ...commonInputStyles, border: "none" }}
                        options={glCodes
                          ?.map((g: TGlCode) => {
                            return {
                              value: g.id,
                              label: generateGlCodeLabel(g.name, g.reference)
                            };
                          })
                          .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0))}
                      />
                    </Form.Item>
                    {propertyType === "UnitProperty" && (
                      <Form.Item label={<CommonFormLabel>Unit</CommonFormLabel>} name="unitId">
                        <Select
                          showSearch
                          placeholder={"Property Unit"}
                          optionFilterProp="label"
                          style={{ ...commonInputStyles, border: "none" }}
                          options={units?.map(
                            (u: { id: number; name: string }) => {
                              return {
                                value: u.id,
                                label: u.name
                              };
                            }
                          )}
                        />
                      </Form.Item>
                    )}

                    <Form.Item>
                      <CommonAppButton
                        type="primary"
                        htmlType="submit"
                        block
                        style={{
                          height: token.controlHeightLG,
                          color: token.colorTextLightSolid,
                          fontSize: token.fontSizeLG,
                          fontWeight: fontWeightNormal
                        }}>
                        Add Item
                      </CommonAppButton>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};

export default AddInvoiceItemModal;
