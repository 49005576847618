import { FC, useState } from "react";
import dayjs from "dayjs";
import { Col, Input, Row, Typography, DatePicker, Form, Drawer } from "antd";
import CommonAppButton from "@Component/Common/CommonAppButton";
import CommonFormLabel from "@Component/Common/CommonFormLabel";
import RedirectToOrignalInvoiceButton from "@Component/Common/RedirectToOrignalInvoiceButton";
import { EyeOutlined } from "@ant-design/icons";
import { useAntToken } from "@hooks/common/useAntToken";
import { useBreakpoints } from "@hooks/common/useBreakPoints";
import { commonAntStyles } from "@Component/Common/styles";
import AdminCancelAndGoBack from "@Component/Common/AdminCancelAndGoBack";

interface IInvoiceSideContentProps {
  invoiceInfo: {
    companyName: string;
    propertyName: string;
    address: string;
    invoiceNumber: string;
    issueDate: string | null;
    cards?: [];
    isSubmittable?: boolean;
  };
  onIssuingCompanyInfoChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onIssueDateChange?: (date: any, dateString: any) => void;
  handleSubmit?: () => void;
  loading: boolean;
  originalInvoiceUrl: string;
  readOnly: boolean;
  invoiceId?: string;
}

const InvoiceSideContent: FC<IInvoiceSideContentProps> = (props) => {
  const {
    invoiceId,
    invoiceInfo,
    onIssuingCompanyInfoChange,
    onIssueDateChange,
    handleSubmit,
    loading,
    originalInvoiceUrl,
    readOnly
  } = props;
  const [open, setOpen] = useState(false);

  const { isScreenXS } = useBreakpoints();
  const { token } = useAntToken();
  const { styles: commonStyles } = commonAntStyles();

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Col
      style={{
        padding: !isScreenXS ? token.paddingXL : token.paddingXS,
        paddingTop: !isScreenXS ? token.paddingMD : token.paddingXS,
        margin: "auto",
        marginTop: token.marginMD
      }}>
      <Col>
        <Typography.Title
          style={{
            fontWeight: token.fontWeightStrong,
            fontSize: token.fontSizeHeading4
          }}>
          Basic Info
        </Typography.Title>
      </Col>
      <Col>
        <Form layout="vertical">
          {invoiceId && (
            <Form.Item
              label={<CommonFormLabel>Invoice Id</CommonFormLabel>}
              className={commonStyles["ant-input-override"]}>
              <Input
                disabled={true}
                style={{
                  borderRadius: token.borderRadiusXS,
                  backgroundColor: "transparent",
                  borderColor: token.colorBorderSecondary
                }}
                value={invoiceId}
                name="invoiceId"
              />
            </Form.Item>
          )}
          <Form.Item
            label={<CommonFormLabel>Invoice Number</CommonFormLabel>}
            className={commonStyles["ant-input-override"]}>
            <Input
              disabled={readOnly}
              style={{
                borderRadius: token.borderRadiusXS,
                backgroundColor: "transparent",
                borderColor: token.colorBorderSecondary
              }}
              placeholder="Invoice Number"
              defaultValue={invoiceInfo.invoiceNumber}
              value={invoiceInfo.invoiceNumber}
              name="invoiceNumber"
              onChange={onIssuingCompanyInfoChange}
            />
          </Form.Item>
          <Form.Item
            label={<CommonFormLabel>Invoice Date</CommonFormLabel>}
            className={commonStyles["ant-input-override"]}>
            <DatePicker
              disabled={readOnly}
              className={commonStyles["ant-input-override"]}
              style={{
                padding: "0 10px",
                borderRadius: token.borderRadiusXS,
                backgroundColor: "transparent",
                width: "100%",
                height: token.controlHeight,
                borderColor: token.colorBorderSecondary
              }}
              value={invoiceInfo.issueDate ? dayjs(invoiceInfo.issueDate as string) : null}
              onChange={onIssueDateChange}
            />
          </Form.Item>
        </Form>
      </Col>
      <Row>
        <Row
          justify="center"
          style={{
            marginTop: token.marginLG,
            marginBottom: token.marginLG,
            width: "100%"
          }}>
          <Col span={24} style={{ textAlign: "center" }}>
            {!readOnly && (
              <>
                <CommonAppButton
                  type="primary"
                  block
                  style={{
                    fontSize: token.fontSizeLG,
                    height: token.controlHeightLG
                  }}
                  onClick={handleSubmit}
                  loading={loading}>
                  Send Invoice
                </CommonAppButton>
                {invoiceInfo.propertyName.includes("Home Depot") && !invoiceInfo.isSubmittable && (
                  <Typography.Text style={{ fontSize: token.fontSizeSM, color: token.colorError }}>
                    {invoiceInfo.cards && invoiceInfo.cards.length > 0
                      ? "Multiple Cards attached"
                      : "Invoice is Non submittable on rent manager"}
                    . Contact admin to upload invoice
                  </Typography.Text>
                )}
              </>
            )}

            <CommonAppButton
              type="primary"
              block
              style={{
                marginTop: token.marginLG,
                fontSize: token.fontSizeLG,
                height: token.controlHeightLG,
                background: "transparent",
                boxShadow: token.boxShadow,
                color: token.colorTextBase,
                borderColor: token.colorBorder
              }}
              icon={<EyeOutlined />}
              onClick={showDrawer}
              loading={loading}>
              View Original Invoice
            </CommonAppButton>

            <RedirectToOrignalInvoiceButton
              linkText="View Original Invoice in New Tab"
              redirectionUrl={originalInvoiceUrl}
              style={{
                color: token.colorTextBase,
                borderColor: token.colorBorder,
                height: token.controlHeightLG
              }}
              icon={<EyeOutlined />}
            />
            <AdminCancelAndGoBack
              marginTop={12}
              title={"Go Back"}
              buttonStyle={{ marginTop: token.marginSM }}
            />
          </Col>
        </Row>
      </Row>
      <Drawer
        title="Original Invoice"
        onClose={onClose}
        open={open}
        width={isScreenXS ? "100%" : "50%"}>
        <Row style={{ height: "100%", width: "100%" }}>
          <iframe src={originalInvoiceUrl} width={"100%"} height={"100%"} />
        </Row>
      </Drawer>
    </Col>
  );
};

export default InvoiceSideContent;
