import { CheckCircleOutlined, HistoryOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { useAntToken } from "@hooks/common/useAntToken";
import { INVOICE_APPROVED, INVOICE_PENDING, INVOICE_REJECTED } from "@utils/constants";
import { Form, Tooltip } from "antd";
import { FC } from "react";

interface IInvoiceLineItemStatusIconProps {
  approvalStatus?: string;
  tooltipTitle?: string;
  propertyManagerView?: boolean;
}
const InvoiceLineItemStatusIcon: FC<IInvoiceLineItemStatusIconProps> = (props) => {
  const { approvalStatus, tooltipTitle = "" } = props;
  const { token } = useAntToken();
  const commonFormItemStyes = {
    border: `1px solid ${token.colorBorder}`,
    height: token.controlHeightLG,
    paddingTop: token.paddingXXS
  };

  if (approvalStatus === INVOICE_REJECTED) {
    return (
      <Form.Item
        style={{
          ...commonFormItemStyes,
          color: token.colorError,
          border: 0,
          cursor: "pointer"
        }}>
        <Tooltip color={token.colorError} title={tooltipTitle}>
          <CloseCircleOutlined style={{ fontSize: token.fontSizeXL }} />
        </Tooltip>
      </Form.Item>
    );
  }
  if (approvalStatus === INVOICE_APPROVED) {
    return (
      <Form.Item
        style={{
          ...commonFormItemStyes,
          color: token.colorSuccess,
          border: 0,
          cursor: "pointer"
        }}>
        <Tooltip color={token.colorSuccess} title={"Item Approved"}>
          <CheckCircleOutlined style={{ fontSize: token.fontSizeXL }} />
        </Tooltip>
      </Form.Item>
    );
  }

  if (approvalStatus === INVOICE_PENDING) {
    return (
      <Form.Item
        style={{
          ...commonFormItemStyes,
          color: token.colorWarning,
          border: 0,
          cursor: "pointer"
        }}>
        <Tooltip color={token.colorWarning} title={"Item Pending"}>
          <HistoryOutlined style={{ fontSize: token.fontSizeXL }} />
        </Tooltip>
      </Form.Item>
    );
  }
  return null;
};

export default InvoiceLineItemStatusIcon;
