import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { fetchInvoiceById } from "@Services/InvoiceService";
import { useParams, useSearchParams } from "react-router-dom";
import useFetchOriginalInvoiceUrl from "@hooks/common/useFetchOriginalInvoiceUrl";
import ReviewInvoice from "@Component/Common/ReviewInvoice";
import { diffCalculator } from "@Component/Common/ReviewInvoice/utils";
import { TInvoiceApprovalStatus } from "@utils/constants";
import { ILineItem, InvoiceProps, TUnit } from "@Component/Common/types";
import { IAuthState } from "@redux/reducers/Auth/Auth";
import { IAppState } from "@redux/reducers/rootReducer";

const VendorViewInvoicePage: FC = () => {
  const loginState: IAuthState = useSelector((state: IAppState) => state.auth);
  const { invoiceId } = useParams();
  const [searchParams] = useSearchParams();
  const [invoiceInfo, setInvoiceInfo] = useState<InvoiceProps>({
    invoiceId: "",
    propertyAddress: "",
    approvalStatus: "",
    companyName: "",
    propertyName: "",
    propertyType: "",
    address: "",
    invoiceNumber: "",
    issueDate: "",
    totalTaxAmount: 0,
    persistedInvoiceFileId: "",
    legalFormId: ""
  });
  const [lineItems, setLineItems] = useState<Array<ILineItem>>([]);
  const [preLineItems, setPreLineItems] = useState<Array<any>>([]);
  const [invoiceTotal, setInvoiceTotal] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [units, setUnits] = useState<Array<{ id: number; name: string }>>([]);
  const { loginInfo } = loginState;
  const vendorName = loginInfo?.name;

  const fetchInvoice = async () => {
    setLoading(true);
    const invoiceIdType = (searchParams.get("invoiceIdType") as string) || "";
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const response: any = await fetchInvoiceById(invoiceId, invoiceIdType as string);
    setLoading(false);
    // eslint-disable-next-line
    const info: any = response.data;

    if (info?.diff) {
      const diffLineItems = info?.diff.filter((diff: any) => diff.property == "lineItems")[0]
        .previousValue;

      const changedLineItems = info.lineItems
        .map((l: any) => {
          const preVal = diffLineItems.filter((line: any) => line.lineId == l.lineId)[0];
          if (!preVal) return false;
          return diffCalculator(l, preVal);
        })
        .filter((val: any) => val);
      setPreLineItems(changedLineItems);
    }

    if (info?.property?.unit) {
      const unitsArray = info.property.unit.filter((unit: TUnit) => unit.id != 0);
      info.property.unit = [{ id: 0, name: "None" }, ...unitsArray];
      setUnits(info.property.unit);
    }
    setInvoiceInfo({
      invoiceId: info?.Id,
      companyName: info?.payee.name,
      propertyName: info?.property.name,
      propertyAddress: info?.isHomeDepot
        ? info?.rmProperty?.name
        : info?.propertyAddresses[0]?.Address,
      propertyType: info?.propertyType,
      vendorName: info?.vendor?.name || vendorName,
      address: info?.isHomeDepot ? info?.vendorAddresses[0].Address : info?.payee.address,
      invoiceNumber: info.invoiceId,
      issueDate: info.issueDate ? moment(info.issueDate).format("YYYY-MM-DD") : null,
      totalTaxAmount: info?.totalTaxAmount,
      persistedInvoiceFileId: info?.persistedInvoiceFileId,
      approvalStatus: info?.approvalStatus,
      rejectionReason: info?.rejectionReason,
      legalFormId: info?.legalFormId
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const lineItems: Array<ILineItem> = info.lineItems.map((l: any) => ({
      ...l,
      pricePerUnit: l.preTaxAmount,
      postTaxAmount: l.amount,
      amount: l.amount,
      taxable: l.isTaxable,
      preTaxAmount: l.preTaxAmount,
      unit: l.unit || "",
      gl: l.glCode
    }));
    const total = info.totalAmount;
    setInvoiceTotal(total);
    setLineItems(lineItems);
  };

  useEffect(() => {
    fetchInvoice();
    // eslint-disable-next-line
  }, []);

  const { data } = useFetchOriginalInvoiceUrl(invoiceInfo.persistedInvoiceFileId as string);
  const originalInvoiceUrl = data?.data?.downloadUrl;
  const { data: legalFormUrl } = useFetchOriginalInvoiceUrl(invoiceInfo.legalFormId as string);

  return (
    <ReviewInvoice
      preLineItems={preLineItems}
      invoiceId={invoiceId}
      invoiceInfo={invoiceInfo}
      lineItems={lineItems}
      glCodes={[...lineItems.map((l: ILineItem) => l.gl)]}
      invoiceTotal={invoiceTotal}
      loading={loading}
      totalTaxAmount={invoiceInfo?.totalTaxAmount}
      originalInvoiceUrl={originalInvoiceUrl}
      readonlyView={true}
      invoiceApprovalStatus={invoiceInfo.approvalStatus as TInvoiceApprovalStatus}
      invoiceRejectionReason={invoiceInfo.rejectionReason}
      units={units}
      propertyType={invoiceInfo?.propertyType as string}
      legalFormUrl={legalFormUrl?.data?.downloadUrl}
      displayCancelButton={true}
      userLogsView={loginInfo?.role == "admin"}
    />
  );
};
export default VendorViewInvoicePage;
