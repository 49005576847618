import { FC } from "react";
import { Col, Row } from "antd";
import { useBreakpoints } from "@hooks/common/useBreakPoints";
import { useAntToken } from "@hooks/common/useAntToken";
import LineItemsTable from "./LineItemsTable";
import LineItemsList from "./LineItemsList";
import { ILineItem, TGlCode, TUnit } from "@Component/Common/types";
interface IInvoiceLineItemsProps {
  preLineItems?: any[];
  lineItems: ILineItem[];
  glCodes?: TGlCode[];
  handleLineItemDelete?: (value: number) => void;
  editGLCodes?: boolean;
  handleEditLineItem?: (
    lineItemId: string,
    lineItemChangeKey: string,
    lineItemValue: unknown
  ) => void;
  readonlyView?: boolean;
  rejectedInvoiceView?: boolean;
  propertyManagerView?: boolean;
  loading?: boolean;
  units: TUnit[];
  propertyType: string;
  invoiceApprovalStatus: string;
  isRegionalManager?: boolean;
}
const InvoiceLineItems: FC<IInvoiceLineItemsProps> = (props) => {
  const {
    preLineItems,
    lineItems,
    glCodes,
    handleLineItemDelete,
    editGLCodes,
    handleEditLineItem,
    readonlyView,
    rejectedInvoiceView,
    propertyManagerView,
    units,
    propertyType,
    invoiceApprovalStatus,
    isRegionalManager
  } = props;
  const { isScreenXL } = useBreakpoints();
  const { token } = useAntToken();

  return (
    <Row
      style={{
        marginTop: token.marginXXS
      }}
      justify="center">
      <Col span={23}>
        <Row justify="center">
          <Col style={{ width: "100%" }}>
            {!isScreenXL ? (
              <LineItemsList
                preLineItems={preLineItems}
                editGLCodes={editGLCodes}
                glCodes={glCodes}
                handleLineItemDelete={handleLineItemDelete}
                lineItems={lineItems}
                handleEditLineItem={handleEditLineItem}
                readonlyView={readonlyView}
                rejectedInvoiceView={rejectedInvoiceView}
                propertyManagerView={propertyManagerView}
                units={units}
                propertyType={propertyType}
                invoiceApprovalStatus={invoiceApprovalStatus}
                isRegionalManager={isRegionalManager}
              />
            ) : (
              <LineItemsTable
                preLineItems={preLineItems}
                editGLCodes={editGLCodes}
                glCodes={glCodes}
                handleLineItemDelete={handleLineItemDelete}
                lineItems={lineItems}
                handleEditLineItem={handleEditLineItem}
                readonlyView={readonlyView}
                rejectedInvoiceView={rejectedInvoiceView}
                propertyManagerView={propertyManagerView}
                units={units}
                propertyType={propertyType}
                invoiceApprovalStatus={invoiceApprovalStatus}
                isRegionalManager={isRegionalManager}
              />
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default InvoiceLineItems;
