import { useDispatch, useSelector } from "react-redux";
import SignIn from "../../Component/SignIn";
import { logIn, ILoginValues } from "../../redux/actions/Auth/Auth";
import { IAppState, AppDispatch } from "../../redux/reducers/rootReducer";
import { IAuthState } from "../../redux/reducers/Auth/Auth";

const AuthPage: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const loginState: IAuthState = useSelector((state: IAppState) => state.auth);
  const isLoading: boolean = loginState.isLoading;

  const onFinish = (values: ILoginValues) => {
    dispatch(logIn(values));
  };

  return <SignIn onFinish={onFinish} isLoading={isLoading} />;
};
export default AuthPage;
