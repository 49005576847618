import { useAntToken } from "@hooks/common/useAntToken";
import { Form, FormInstance, Select, Typography, Col } from "antd";
import { FC, useCallback } from "react";
import useGlCodesState from "./useGlCodesState";
import { getGlCodesSelectProps } from "../utils";
import GLCodesTags from "./GLCodesTags";
import { TGlCode } from "../types";
import SelectInputTag from "@Component/Common/SelectInput/SelectInputTag";

interface ISelectGLCodesProps {
  fetchedGlCodes: TGlCode[];
  glCodesLoading: boolean;
  glCodes: TGlCode[];
  form: FormInstance;
  glCodeGroup?: any;
}

const SelectGLCodes: FC<ISelectGLCodesProps> = (props) => {
  const { fetchedGlCodes, glCodesLoading, glCodes, glCodeGroup, form } = props;
  const { token } = useAntToken();

  const getglCodeValueFromEvent = useCallback(
    (value: Array<string>, selections: Array<any>) => {
      if (!selections?.length) return selections;
      if (!selections?.some((s) => s.value === "All")) {
        return selections;
      }
      const labelInValue = typeof value[0] === "string";
      return labelInValue ? glCodes : glCodes.map((o: any) => o.label);
    },
    [glCodes]
  );

  const {
    filteredSelectedGLCodes,
    handleDeleteGlCodeTag,
    handleGLCodesChange,
    selectedGLCodes,
    handleDeselectGlCode
  } = useGlCodesState({
    fetchedGlCodes,
    fetchedGlGroup: glCodeGroup,
    form
  });

  return (
    <Col style={{ marginBottom: 15 }}>
      <Typography.Title
        level={2}
        style={{
          color: token.colorText,
          fontSize: token.fontSize,
          fontWeight: token.fontWeightStrong
        }}>
        GL Code
      </Typography.Title>
      <Form.Item
        getValueFromEvent={getglCodeValueFromEvent}
        name="assignedGLCodes"
        rules={[{ required: true, message: "Please Enter Assigned GL Codes" }]}
        style={{
          width: "100%",
          marginBottom: 5
        }}>
        <Select
          {...getGlCodesSelectProps(selectedGLCodes, fetchedGlCodes, glCodesLoading, glCodeGroup)}
          tagRender={(props: any) => {
            const { label, onClose, closable } = props;
            return <SelectInputTag label={label} onClose={onClose} closable={closable} />;
          }}
          value={selectedGLCodes}
          onDeselect={(e) => {
            handleDeselectGlCode(e);
          }}
          onChange={(e) => handleGLCodesChange(e)}
        />
      </Form.Item>
      <GLCodesTags glCodes={filteredSelectedGLCodes} onDeleteTag={handleDeleteGlCodeTag} />
    </Col>
  );
};

export default SelectGLCodes;
