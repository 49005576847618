import { fetchUserProperties } from "@Services/UserService";
import { useQuery } from "@tanstack/react-query";
import { React_Query_Keys } from "@utils/constants";

export const useFetchUserProperties = () => {
  return useQuery({
    queryKey: [React_Query_Keys.FETCH_USER_PROPERTIES],
    queryFn: fetchUserProperties
  });
};
