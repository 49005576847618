import { FC } from "react";
import { Col, Button } from "antd";
import { useNavigate } from "react-router-dom";
import { useAntToken } from "@hooks/common/useAntToken";

interface IAdminCancelAndGoBack {
  buttonStyle?: object;
  marginTop?: number;
  title?: string;
}

const AdminCancelAndGoBack: FC<IAdminCancelAndGoBack> = ({ marginTop, buttonStyle, title }) => {
  const { token } = useAntToken();
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <Col style={{ marginTop: marginTop }}>
      <Button
        block
        onClick={goBack}
        style={{
          height: token.controlHeightLG,
          color: token.colorTextBase,
          fontSize: token.fontSizeLG,
          fontWeight: 500,
          borderRadius: 0,
          ...buttonStyle
        }}>
        {title ? title : "Cancel"}
      </Button>
    </Col>
  );
};

export default AdminCancelAndGoBack;
