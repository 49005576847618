import { Form, FormInstance } from "antd";
import { TFormProperty, TProperties } from "../types";
import { combineFetchedAndAssignedProperties } from "../utils";

interface IPropertiesStateProps {
  fetchedProperties: TProperties[];
  form: FormInstance;
}

const usePropertiesState = (props: IPropertiesStateProps) => {
  const { fetchedProperties, form } = props;
  const selectedProperties: TFormProperty[] = Form.useWatch("assignedProperties", form) || [];
  const allProperties = combineFetchedAndAssignedProperties(fetchedProperties, selectedProperties);
  let filteredSelectedProperties: TFormProperty[] = [];
  if (selectedProperties) {
    filteredSelectedProperties = allProperties.filter((p) =>
      selectedProperties.map((s) => s.value).includes(p.value.toString())
    );
  }

  const handleDeletePropertyTag = (tag: TFormProperty) => {
    const formProperties: TFormProperty[] = form.getFieldValue("assignedProperties");
    const filteredProperties = formProperties.filter((p) => p.id !== tag.id);
    filteredSelectedProperties = [...filteredProperties];
    form.setFieldsValue({
      assignedProperties: [...filteredProperties]
    });
  };
  const handleChangeProperty = (value: string[]) => {
    if (value.length === 1 && value[0] === "All") {
      const filteredProperties = allProperties.filter((p) => p.value !== "All");
      form.setFieldValue("assignedProperties", filteredProperties);
    } else if (value.some((p: string) => p === "All")) {
      form.setFieldValue("assignedProperties", []);
    } else {
      const propertyWithLabelAndValue = value.reduce((acc: TFormProperty[], current: string) => {
        const property = allProperties.find((p) => p.value === current);
        if (property) {
          acc.push(property);
        }
        return acc;
      }, []);
      form.setFieldValue("assignedProperties", propertyWithLabelAndValue);
    }
  };

  const handleDeselectProperty = (e: string) => {
    if (e === "All") {
      form.setFieldValue("assignedProperties", []);
    }
  };

  return {
    selectedProperties,
    handleChangeProperty,
    handleDeletePropertyTag,
    handleDeselectProperty,
    filteredSelectedProperties
  };
};

export default usePropertiesState;
