import { Avatar, Col, List, Pagination, Row, Typography, theme } from "antd";
import { FC } from "react";
import { adminUserLogsStyles } from "./styles";
import useAdminLogsSearchParams from "@hooks/Admin/useAdminLogsSearchParams";
import { UserOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { ROUTE_PATHS } from "@routes/constants";
import { useBreakpoints } from "@hooks/common/useBreakPoints";
import SearchUserLogs from "./SearchUserLogs";

const { useToken } = theme;

interface IUserLogsProps {
  logs: { message: string; timestamp: string; invoiceId: string }[];
  isLoading?: boolean;
  totalPages: string;
}
const UserLogs: FC<IUserLogsProps> = (props) => {
  const { token } = useToken();
  const { styles } = adminUserLogsStyles();
  const { pageNumber, pageSize, setPageNumber } = useAdminLogsSearchParams();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const fontWeightNormal = token.fontWeightNormal;

  const { logs, isLoading, totalPages } = props;
  const { isScreenXS: isMobileScreen } = useBreakpoints();

  return (
    <>
      <Row
        justify="space-between"
        style={{ paddingLeft: isMobileScreen ? token.paddingXL : token.paddingXL + 20 }}>
        <Col>
          <Typography.Title
            level={2}
            style={{
              color: token.colorText,
              fontSize: isMobileScreen ? token.fontSizeHeading3 : token.fontSizeHeading1,
              paddingTop: isMobileScreen ? token.paddingSM : token.padding,
              fontWeight: token.fontWeightStrong
            }}>
            User Logs
          </Typography.Title>
        </Col>
        <Col>
          <Typography.Title
            level={2}
            style={{
              paddingTop: isMobileScreen ? token.paddingXXS : token.paddingXL,
              paddingRight: token.paddingXL
            }}>
            <SearchUserLogs />
          </Typography.Title>
        </Col>
      </Row>
      <Row>
        <Col
          span={24}
          style={{
            paddingLeft: isMobileScreen ? token.paddingXL : token.paddingXL + 20,
            paddingRight: token.paddingXL
          }}>
          <List
            itemLayout="horizontal"
            dataSource={logs}
            loading={isLoading}
            renderItem={(item) => {
              const redirectionLink = ROUTE_PATHS.ADMIN_VIEW_INVOICE.replace(
                ":invoiceId",
                `${item.invoiceId}?invoiceIdType=short`
              );
              const log = item.message.split(item.invoiceId);
              return (
                <List.Item>
                  <List.Item.Meta
                    avatar={<Avatar icon={<UserOutlined />} />}
                    style={{ alignItems: "center" }}
                    description={
                      <Row align={"middle"} justify="space-between">
                        <Col style={{ width: 590, maxWidth: 700 }}>
                          <Typography.Title
                            level={4}
                            style={{
                              color: token.colorText,
                              fontSize: token.fontSize,
                              fontWeight: fontWeightNormal,
                              margin: 0
                            }}>
                            {log[0]} <Link to={redirectionLink}>{item.invoiceId}</Link>{" "}
                            {log[1].replace("UnderReview", "RM-Review")}
                          </Typography.Title>
                        </Col>
                        <Col>
                          <Typography.Title
                            level={4}
                            style={{
                              color: token.colorText,
                              fontSize: token.fontSize,
                              fontWeight: fontWeightNormal,
                              paddingTop: 0,
                              marginTop: 0
                            }}>
                            {item.timestamp}
                          </Typography.Title>
                        </Col>
                      </Row>
                    }
                  />
                </List.Item>
              );
            }}
          />
        </Col>
      </Row>
      <Row justify="center" style={{ marginTop: token.marginXL, marginBottom: token.marginXL }}>
        <Col>
          <Pagination
            size={isMobileScreen ? "small" : "default"}
            current={Number(pageNumber)}
            pageSize={Number(pageSize)}
            onChange={(page: number, pageSize: number) => {
              setPageNumber(String(page), String(pageSize));
            }}
            total={Number(totalPages) * Number(pageSize)}
            className={styles["ant-pagination-over-ride"]}
          />
        </Col>
      </Row>
    </>
  );
};

export default UserLogs;
