import { useQuery } from "@tanstack/react-query";
import { React_Query_Keys } from "@utils/constants";
import { fetchAdminSettingsService } from "@Services/UserService";

const useFetchAdminSettings = () => {
  return useQuery({
    queryKey: [React_Query_Keys.FETCH_SETTINGS],
    queryFn: fetchAdminSettingsService
  });
};

export default useFetchAdminSettings;
