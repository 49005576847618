import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchAllVendors, inviteVendors } from "@Services/VendorService";
import ShowToast from "@utils/showToast";
import AdminInviteVendor from "@Component/Admin/AdminInviteVendor";
import { isAxiosError } from "axios";
import { TFormGlCode, TFormProperty, TGlCode, TProperties } from "@Component/Common/types";
import { serialiazeGlCodes } from "@Component/Common/utils";
import { useGlGroupFetchAll } from "@hooks/Admin/GlGroup";

const AdminInviteVendorPage: FC = () => {
  const navigate = useNavigate();
  const [vendorsList, setVendorsList] = useState<
    Array<{ label: string; value: string; id: string }>
  >([]);
  const [assignedProperties, setaAsignedProperties] = useState<TProperties[]>([]);
  const [glCodes, setGlCodes] = useState<TGlCode[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { data: glCodeGroupFetch } = useGlGroupFetchAll();

  const allVendors = async () => {
    try {
      setLoading(true);
      const result = await fetchAllVendors();
      const vendorList = result.data.vendorData.map(
        (v: { name: string; email: string; id: string }) => {
          return { label: v.name, value: v.email, id: v.id };
        }
      );
      setVendorsList(vendorList);
      const properties = result?.data?.properties;
      setaAsignedProperties(properties);
      const glCodes = result.data.glCodes;
      setGlCodes(glCodes);
      setLoading(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        ShowToast(error.response?.data.message, "error");
      }
      setLoading(false);
    }
  };

  const handleSubmit = async (values: {
    showPaymentStatus: boolean;
    vendors: Array<{ email: string; name: string }>;
    assignedProperties: TFormProperty[];
    assignedGLCodes: TFormGlCode[];
    editGLcodes: boolean;
    threshold: string;
    invitationType: string;
    useSecondaryEmail?: boolean;
    secondaryEmail?: string;
  }) => {
    const requestParameters = JSON.parse(JSON.stringify(values));
    try {
      setLoading(true);
      const vendors: Array<{
        email: string;
        name: string;
        id: string;
        secondaryEmail?: string;
        useSecondaryEmail?: boolean;
      }> = [];
      if (values.invitationType === "single") {
        const vendor: Array<{ value: string; label: string; id: string }> = vendorsList.filter(
          (ve: { label: string; value: string }) => ve.value === requestParameters.vendors
        );
        if (vendor.length === 0) {
          ShowToast("Please select vendor again", "error");
          return;
        }

        vendors.push({
          email: vendor[0].value,
          name: vendor[0].label,
          id: vendor[0].id,
          secondaryEmail: requestParameters.secondaryEmail
            ? requestParameters.secondaryEmail
            : null,
          useSecondaryEmail: requestParameters.useSecondaryEmail
            ? requestParameters.useSecondaryEmail
            : false
        });
      } else {
        requestParameters.vendors.forEach((v: string) => {
          const vendor: Array<{ value: string; label: string; id: string }> = vendorsList.filter(
            (ve: { label: string; value: string }) => ve.value === v
          );
          vendors.push({ email: vendor[0].value, name: vendor[0].label, id: vendor[0].id });
        });
      }

      requestParameters.showPaymentStatus = requestParameters.showPaymentStatus
        ? requestParameters.showPaymentStatus
        : false;
      requestParameters.editGLcodes = requestParameters.editGLcodes
        ? requestParameters.editGLcodes
        : false;
      requestParameters.vendors = vendors;
      requestParameters.showLegalForms = false;
      const assignedPropertiesIds = values.assignedProperties.map(({ id }) => id);
      requestParameters.assignedProperties = assignedProperties.filter(({ id }) =>
        assignedPropertiesIds.includes(id)
      );

      requestParameters.threshold = Number(values.threshold);
      requestParameters.glCategoryIds = values.assignedGLCodes
        .filter((glCode) => glCode.id.toString() === "glGroup")
        .map((glCode) => glCode.value);
      requestParameters.assignedGLCodes = serialiazeGlCodes(
        values.assignedGLCodes.filter((glCode) => glCode.id.toString() !== "glGroup")
      );

      await inviteVendors(requestParameters);
      setLoading(false);
      ShowToast("Vendors Invited successfully", "success");
      navigate(`/admin/vendors?role=vendor`);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        ShowToast(error.response?.data.message, "error");
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    allVendors();
  }, []);

  return (
    <AdminInviteVendor
      loading={loading}
      vendorsOptionList={vendorsList}
      properties={assignedProperties}
      glCodes={glCodes}
      handleSubmit={handleSubmit}
      glCodeGroup={glCodeGroupFetch}
    />
  );
};
export default AdminInviteVendorPage;
