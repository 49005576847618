import { FC } from "react";
import { Col, Input, Row, Table, Select, Switch, Form, Flex, Dropdown, InputNumber } from "antd";
import { useAntToken } from "@hooks/common/useAntToken";
import { invoiceStyles } from "./styles";
import { getInvoiceLineItemColumns, getMoreOptions } from "./utils";
import { MoreOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import { commonAntStyles } from "@Component/Common/styles";
import { ILineItem, TAlternativeGlCode, TGlCode, TUnit } from "@Component/Common/types";
import { generateGlCodeLabel } from "@Component/Common/utils";

interface ILineItemsTableProps {
  lineItems: ILineItem[];
  glCodes?: TGlCode[];
  handleLineItemDelete?: (value: number) => void;
  editGLCodes?: boolean;
  selectedRowTaxable?: boolean;
  onSelectedRowTaxableChange?: (checked: boolean) => void;
  handleEditLineItem?: (
    lineItemId: string,
    lineItemChangeKey: string,
    lineItemValue: unknown
  ) => void;
  readonlyView?: boolean;
  rejectedInvoiceView?: boolean;
  loading?: boolean;
  propertyManagerView?: boolean;
  units: Array<{ id: number; name: string }>;
  propertyType: string;
  readOnly: boolean;
}

const LineItemsTable: FC<ILineItemsTableProps> = (props) => {
  const { styles } = invoiceStyles();
  const { styles: commonStyles } = commonAntStyles();
  const { token } = useAntToken();
  const {
    lineItems,
    handleLineItemDelete,
    handleEditLineItem,
    loading,
    units,
    propertyType,
    glCodes,
    readOnly
  } = props;

  const commonFormItemStyes = {
    border: `1px solid ${token.colorBorder}`,
    height: token.controlHeightLG,
    paddingTop: token.paddingXXS
  };
  const commontInputStyles = {
    border: 0,
    borderRadius: 0,
    backgroundColor: "transparent",
    height: "inherit"
  };

  const GlCodeSelectDropdown = (props: { d: ILineItem; isEditingGlCodesDisabled?: boolean }) => {
    const { d, isEditingGlCodesDisabled } = props;
    return (
      <>
        {d.alternativeGlCodes ? (
          <Select
            style={{ width: "80%" }}
            showSearch
            optionFilterProp="label"
            value={generateGlCodeLabel(d.gl.name, d.gl.reference)}
            className={styles["ant-select-override"]}
            disabled={isEditingGlCodesDisabled || readOnly}
            onChange={(value) => {
              const filteredGlCodes =
                d.alternativeGlCodes?.filter(
                  (g: TAlternativeGlCode) => g.glCode.id === Number(value)
                ) || [];
              handleEditLineItem?.(d.lineItemShortId, "gl", filteredGlCodes[0]?.glCode);
            }}>
            {d.alternativeGlCodes
              ?.sort((a, b) =>
                a.glCode.reference > b.glCode.reference
                  ? 1
                  : b.glCode.reference > a.glCode.reference
                  ? -1
                  : 0
              )
              .map((g: TAlternativeGlCode) => {
                const color =
                  g.colorCode === "green"
                    ? "green"
                    : g.colorCode === "yellow"
                    ? "orange"
                    : token.colorTextSecondary;
                return (
                  <Select.Option
                    key={g.glCode.id}
                    value={g.glCode.id}
                    label={generateGlCodeLabel(g.glCode.name, g.glCode.reference)}>
                    <div style={{ color: color }}>
                      {generateGlCodeLabel(g.glCode.name, g.glCode.reference)}
                    </div>
                  </Select.Option>
                );
              })}
          </Select>
        ) : (
          <Select
            style={{ width: "80%" }}
            showSearch
            optionFilterProp="label"
            value={generateGlCodeLabel(d.gl.name, d.gl.reference)}
            className={styles["ant-select-override"]}
            disabled={isEditingGlCodesDisabled || readOnly}
            onChange={(value) => {
              const filteredGlCodes = glCodes?.filter((g) => g.id === Number(value)) || [];
              handleEditLineItem?.(d.lineItemShortId, "gl", filteredGlCodes[0]);
            }}>
            {glCodes
              ?.sort((a, b) => (a.reference > b.reference ? 1 : b.reference > a.reference ? -1 : 0))
              .map((g) => {
                const color = token.colorTextSecondary;
                return (
                  <Select.Option
                    key={g.id}
                    value={g.id}
                    label={generateGlCodeLabel(g.name, g.reference)}>
                    <div style={{ color: color }}>{generateGlCodeLabel(g.name, g.reference)}</div>
                  </Select.Option>
                );
              })}
          </Select>
        )}
      </>
    );
  };

  const clonedLineItems = structuredClone(lineItems);

  return (
    <>
      <Table
        scroll={{ y: 450 }}
        virtual={true}
        loading={loading}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        dataSource={clonedLineItems.map((d: any) => {
          d.glCode = (
            <Form.Item style={{ ...commonFormItemStyes, height: 50, paddingTop: token.paddingXS }}>
              <Flex align="center" justify="center">
                <GlCodeSelectDropdown d={d} isEditingGlCodesDisabled={false} />
              </Flex>
            </Form.Item>
          );
          d.lineItemTaxable = (
            <Form.Item style={commonFormItemStyes}>
              <Flex align="center" justify="center">
                <Switch
                  disabled={readOnly}
                  defaultValue={d.taxable}
                  value={d.taxable}
                  onChange={(checked) =>
                    handleEditLineItem?.(d.lineItemShortId, "taxable", checked)
                  }
                />
              </Flex>
            </Form.Item>
          );
          d.lineItemDescription = (
            <Form.Item className={commonStyles["ant-input-override"]}>
              <TextArea
                disabled={readOnly}
                placeholder={"Description"}
                defaultValue={d.description}
                style={{
                  borderRadius: 0,
                  backgroundColor: "transparent",
                  borderColor: token.colorBorderSecondary,
                  height: 70,
                  resize: "none",
                  paddingLeft: token.paddingMD
                }}
                value={d.description}
                name="description"
                onChange={(e) =>
                  handleEditLineItem?.(d.lineItemShortId, "description", e.target.value)
                }
              />
            </Form.Item>
          );
          d.lineItemPostTaxAmount = (
            <Form.Item style={{ ...commonFormItemStyes, textAlign: "right" }}>
              <Input
                disabled={true}
                defaultValue={d.postTaxAmount}
                style={{ ...commontInputStyles, textAlign: "right" }}
                value={Number(d.postTaxAmount).toFixed(2)}
                name="postTaxAmount"
                onChange={(e) =>
                  handleEditLineItem?.(d.lineItemShortId, "postTaxAmount", e.target.value)
                }
              />
            </Form.Item>
          );
          d.lineItemPreTaxAmount = (
            <Form.Item
              style={{ ...commonFormItemStyes, textAlign: "right" }}
              className={commonStyles["ant-input-override"]}>
              <InputNumber
                type={"number"}
                controls={false}
                disabled={readOnly}
                precision={2}
                style={{ ...commontInputStyles, textAlign: "right" }}
                value={d.pricePerUnit || 0}
                name="pricePerUnit"
                onChange={(value) => {
                  if (isNaN(Number(value?.toString()?.replace(".", "")))) {
                    return;
                  }
                  handleEditLineItem?.(d.lineItemShortId, "pricePerUnit", value.toString());
                }}
              />
            </Form.Item>
          );
          d.lineItemUnit = (
            <Form.Item style={{ ...commonFormItemStyes, height: 50, paddingTop: token.paddingXS }}>
              <Flex align="center" justify="center">
                <Select
                  disabled={readOnly}
                  style={{ width: "80%" }}
                  showSearch
                  optionFilterProp="label"
                  defaultValue={"None"}
                  value={d?.unit?.id || "None"}
                  className={styles["ant-select-override"]}
                  onChange={(value) => handleEditLineItem?.(d.lineItemShortId, "unit", value)}
                  options={units?.map((u: TUnit) => {
                    return {
                      value: u.id,
                      label: u.name
                    };
                  })}
                />
              </Flex>
            </Form.Item>
          );
          d.actions = (
            <>
              {!readOnly && (
                <Flex align="center" justify="center" style={{ height: "45%" }}>
                  <Col
                    style={{
                      marginLeft: "auto",
                      marginRight: token.marginLG,
                      cursor: "pointer",
                      width: "28px",
                      border: `1px solid ${token.colorBorder}`,
                      boxShadow: token.boxShadow,
                      borderRadius: token.borderRadiusSM,
                      position: "absolute",
                      right: 0
                    }}>
                    <Row align="middle" justify="center">
                      <Col>
                        <Dropdown
                          menu={{
                            items: getMoreOptions({
                              handleEditLineItem,
                              lineItemShortId: d.lineItemShortId,
                              handleLineItemDelete,
                              key: d.key,
                              isDeletable: true
                            })
                          }}
                          trigger={["click", "contextMenu"]}
                          placement="bottomRight">
                          <a
                            onClick={(e) => e.preventDefault()}
                            style={{
                              fontSize: token.fontSizeLG,
                              fontWeight: token.fontWeightStrong,
                              color: token.colorText
                            }}>
                            <MoreOutlined />
                          </a>
                        </Dropdown>
                      </Col>
                    </Row>
                  </Col>
                </Flex>
              )}
            </>
          );
          return d;
        })}
        columns={getInvoiceLineItemColumns(propertyType as string)}
        pagination={false}
        className={styles["ant-table-overider"]}
      />
    </>
  );
};

export default LineItemsTable;
