export const ADMIN = "admin";
export const VENDOR = "vendor";
export const USER = "user";
export const PENDING = "pending";
export const REJECTED = "rejected";
export const APPROVED = "accepted";
export const INVOICE_PENDING = "Pending";
export const INVOICE_REJECTED = "Rejected";
export const INVOICE_APPROVED = "Approved";
export const INVOICE_CANCELLED = "Cancelled";
export const INVOICE_UNDER_REVIEW = "UnderReview";
export const GENERAL_PROPERTY = { label: "General Property", value: "GeneralProperty" };
export const UNIT_PROPERTY = { label: "Unit Property", value: "UnitProperty" };
export const ALL = "All";
export const CLEAR = "clear";
export const REGIONAL = "regional";
export const PROPERTY = "property";

export type TInvitationStatus = "" | "pending" | "rejected" | "accepted" | "All" | "clear";
export type TVendorActiveStatus = "" | "active" | "in-active" | "clear" | "All";
export type TManagerType = "" | "regional" | "property" | "clear" | "All";
export const VENDOR_ACTIVE_STATUS_ACTIVE = "active";
export const VENDOR_ACTIVE_STATUS_IN_ACTIVE = "in-active";
export type TInvoiceApprovalStatus = "Pending" | "Rejected" | "Approved";
export const React_Query_Keys = {
  ALL_CARDS: "allCards",
  ALL_GL_CODES: "allGlCodes",
  ALL_PROPERTIES: "allProperties",
  ALL_PROPERTIES_BY_ROLE: "allPropertiesByRole",
  ADMIN_USERS: "admin-users",
  RE_INVITE_USER_MUTATE: "reInviteUser",
  FETCH_RE_INVITE_USERS_LIST: "fetchReInviteUsersList",
  ADMIN_UPDATE_USER_PERMISSIONS: "adminUpdateUserPermissions",
  DELETE_USER_MUTATE: "deleteUser",
  FETCH_USER_LOGS: "fetchUserLogs",
  FETCH_ORIGINAL_INVOICE_IMAGE: "fetchOriginalInvoiceImage",
  UPDATE_SETTINGS: "updateSettings",
  FETCH_SETTINGS: "fetchSettings",
  SIGN_DOCUMENT: "signDocument",
  FETCH_DOCUMENT_SIGN_TOKEN: "fetchDocumentSignToken",
  FETCH_INVOICE_BY_ID: "fetchInvoiceById",
  FETCH_GL_CODES: "fetchGlCodes",
  FETCH_USER_PROPERTIES: "fetchUserProperties",
  CREATE_GL_GROUP: "createGlGroup",
  FETCH_ALL_GL_GROUP: "fetchAllGlGroup",
  UPDATE_GL_GROUP: "updateGlGroup",
  DELETE_GL_GROUP: "deleteGlGroup",
  FETCH_ALL_HOME_DEPOT_CARDS: "fetchAllHomeDepotCards",
  UPDATE_HOME_DEPOT_CARD: "fetchAllHomeDepotCards"
};
