import {
  APP_BASE_URL,
  fetchAllVendorsUrl,
  inviteVendorsUrl,
  fetchAllVendorPropertiesUrl,
  vendorInvoicesUrl,
  fetchAdminUserPropertiesUrl,
  fetchSignDocumentUrl,
  fetchAdminUserTypePropertiesUrl
} from "../utils/urls";
import http from "../utils/httpService";
import axios from "axios";

export const fetchAllVendors = async () => {
  const { data } = await http.get(`${APP_BASE_URL}${fetchAllVendorsUrl}`);
  return { data };
};

// eslint-disable-next-line
export const inviteVendors = async (inviteVendorParamters: any) => {
  const { data } = await http.post(`${APP_BASE_URL}${inviteVendorsUrl}`, inviteVendorParamters);
  return { data };
};

export const fetchAllVendorProperties = async () => {
  const { data } = await http.get(`${APP_BASE_URL}${fetchAllVendorPropertiesUrl}`);
  return { data };
};
export const fetchAllAdminProperties = async () => {
  const { data } = await http.get(`${APP_BASE_URL}${fetchAdminUserPropertiesUrl}`);
  return { data };
};

export const fetchAllPropertiesByUserType = async (userType: string) => {
  const { data } = await http.get(
    `${APP_BASE_URL}${fetchAdminUserTypePropertiesUrl}?role=${userType}`
  );
  return { data };
};

export const fetchAllVendorInvoices = async (
  pageNum: number,
  pageSize: number,
  paymentStatus: string,
  approvalStatus: string
) => {
  let url = `${APP_BASE_URL}${vendorInvoicesUrl}?pageNum=${pageNum}&pageSize=${pageSize}`;
  if (paymentStatus) {
    url = url.concat(`&paymentStatus=${paymentStatus}`);
  }
  if (approvalStatus) {
    url = url.concat(`&approvalStatus=${approvalStatus}`);
  }
  const { data } = await http.get(url);
  return { data };
};

export const signDocumentService = async () => {
  const options = {
    method: "GET",
    url: "https://api.docuseal.co/submissions"
  };
  const { data } = await axios.request(options);
  return { data };
};

export const fetchSignDocumentTokenService = async (
  data: any
): Promise<{
  email: string;
  slug: string;
}> => {
  const {
    data: { email, slug }
  } = await http.post(`${APP_BASE_URL}${fetchSignDocumentUrl}`, data);
  return { email, slug };
};
