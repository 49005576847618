import { Modal, Row, Col, Divider, Typography } from "antd";
import { DeleteIcon } from "@assets/icons/CustomIcons";
import { useAntToken } from "@hooks/common/useAntToken";
import { useAdminSettingStyles } from "@Component/Admin/AdminSettings/styles";
import CommonAppButton from "@Component/Common/CommonAppButton";

const ConfirmationModal = ({ visible, handleDelete }: any) => {
  const { styles } = useAdminSettingStyles();
  const { token } = useAntToken();

  const DeleteModalFooter = () => {
    return (
      <Row gutter={[8, 8]} style={{ paddingLeft: token.padding, paddingRight: token.padding }}>
        <Col span={12}>
          <CommonAppButton
            type="default"
            style={{
              height: 40,
              border: `1px solid ${token.colorTextPlaceholder}`,
              background: token.colorBgContainer
            }}
            onClick={() => handleDelete("cancel")}
            block>
            No
          </CommonAppButton>
        </Col>
        <Col span={12}>
          <CommonAppButton
            type="primary"
            style={{ height: 40, background: token.colorPrimary }}
            onClick={() => handleDelete("delete")}
            block>
            Yes
          </CommonAppButton>
        </Col>
      </Row>
    );
  };

  return (
    <Modal
      open={visible}
      onCancel={() => handleDelete("cancel")}
      onOk={() => handleDelete("delete")}
      footer={<DeleteModalFooter />}
      title={null}
      className={styles["delete-modal"]}
      width={330}>
      <Row justify="center">
        <Col style={{ marginTop: token.marginLG }}>
          <DeleteIcon style={{ fontSize: 105 }} />
        </Col>
      </Row>
      <Row justify="center">
        <Col
          style={{ marginTop: token.marginLG, textAlign: "center", padding: token.padding }}
          span={24}>
          <Typography.Text
            style={{ fontSize: token.fontSizeXL, fontWeight: token.fontWeightStrong }}>
            Are you sure you want to Delete this Group?
          </Typography.Text>
        </Col>
      </Row>
      <Divider />
    </Modal>
  );
};

export default ConfirmationModal;
