import { Layout } from "antd";
import { FC, ReactNode } from "react";
interface Props {
  children?: ReactNode;
  style?: React.CSSProperties;
}
const AppLayout: FC<Props> = ({ children, style, ...props }) => {
  return (
    <Layout style={{ ...style, maxWidth: "1920px", margin: "auto" }} {...props}>
      {children}
    </Layout>
  );
};

export default AppLayout;
