import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import UploadInvoice from "@Component/Vendor/UploadInvoice";
import { uploadInvoice } from "@Services/InvoiceUploadService";
import ShowToast from "@utils/showToast";
import { AppDispatch, IAppState } from "@redux/reducers/rootReducer";
import { fetchAllVendorProperties } from "@Services/VendorService";
import { scannedInvoice } from "@redux/actions/Invoice/Invoice";
import { isAxiosError } from "axios";
import { IAuthState } from "@redux/reducers/Auth/Auth";
import { UNIT_PROPERTY } from "@utils/constants";

const UploadInvoicePage: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [properties, setProperties] = useState<
    Array<{ id: number; name: string; unit: Array<{ id: number; name: string }> }>
  >([]);
  const [selectedProperty, setSelectedProperty] = useState<{
    id: number;
    name: string;
    unit: Array<{ id: number; name: string }>;
  } | null>(null);
  const [file, setFile] = useState(null);
  const loginState: IAuthState = useSelector((state: IAppState) => state.auth);
  const { loginInfo } = loginState;
  const vendorNotActiveErrorMessage =
    loginInfo?.vendorErrorResponse ||
    "You are Not Allowed to Upload Invoice. Kindly contact Navarino support for further assistance";
  const isActive = loginInfo?.isActive;

  useEffect(() => {
    allVendorProperties();
  }, []);

  const allVendorProperties = async () => {
    try {
      setLoading(true);
      const result = await fetchAllVendorProperties();
      setProperties(result.data);
      setLoading(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        ShowToast(error.response?.data.message, "error");
      }
      setLoading(false);
    }
  };

  const handleChange = (value: string) => {
    const filteredProperty = properties.filter((p) => p.id.toString() === value);
    setSelectedProperty(filteredProperty[0]);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fileChange = async (info: any) => {
    if (info.fileList.length > 0) {
      setFile(info.file);
    } else {
      setFile(null);
    }
  };

  const uploadFile = async () => {
    try {
      if (selectedProperty) {
        if (file) {
          setLoading(true);
          const response = await uploadInvoice({
            file: file,
            propertyName: selectedProperty.name,
            propertyId: selectedProperty.id,
            propertyType: UNIT_PROPERTY.value,
            propertyUnit: selectedProperty.unit
          });
          const invoice = response.data;
          invoice["propertyType"] = UNIT_PROPERTY.value;
          dispatch(scannedInvoice(invoice));
          ShowToast("Invoice uploaded successfully.", "success");
          navigate("/vendorReviewInvoice");
        } else {
          ShowToast("Please upload file", "error");
        }
      } else {
        ShowToast("Please select property", "error");
      }
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        ShowToast(error.response?.data.message, "error");
      }
      setLoading(false);
    }
  };

  return (
    <UploadInvoice
      handleChange={handleChange}
      fileChange={fileChange}
      uploadFile={uploadFile}
      loading={loading}
      properties={properties}
      vendorNotActiveErrorMessage={vendorNotActiveErrorMessage}
      isActive={isActive}
    />
  );
};
export default UploadInvoicePage;
