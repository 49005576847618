import { formatToPrecession } from "@utils/formatter";
import {
  IInvoice,
  IInvoiceSerialized,
  ILineItem,
  InvoiceProps,
  TGlCode,
  TProperties,
  TUnit
} from "@Component/Common/types";
import { INVOICE_PENDING } from "@utils/constants";

export const getUpdatedLineItemsOnEditing = (
  lineItemId: string,
  lineItemChangeKey: string,
  lineItemValue: unknown,
  lineItems: Array<ILineItem>,
  totalTaxAmount: number,
  units: TUnit[],
  originalLineItems?: any
) => {
  let copiedLineItems = [...lineItems];
  const objIndex = copiedLineItems.findIndex((obj) => obj.lineItemShortId === lineItemId);

  if (lineItemChangeKey === "taxable") {
    copiedLineItems[objIndex] = {
      ...copiedLineItems[objIndex],
      taxable: lineItemValue as boolean
    };
    const taxableLineItemsCount = copiedLineItems.filter((l) => l.taxable).length;
    if (taxableLineItemsCount > 0) {
      copiedLineItems = copiedLineItems.map((l) => {
        const pricePerUnit = Number(l.pricePerUnit);
        return {
          ...l,
          postTaxAmount: l.taxable
            ? pricePerUnit + formatToPrecession(totalTaxAmount / taxableLineItemsCount, 4)
            : pricePerUnit
        };
      });
    } else {
      copiedLineItems[objIndex] = {
        ...copiedLineItems[objIndex],
        taxable: lineItemValue as boolean,
        postTaxAmount: Number(copiedLineItems[objIndex].pricePerUnit)
      };
    }
  } else if (lineItemChangeKey === "pricePerUnit") {
    copiedLineItems[objIndex] = {
      ...copiedLineItems[objIndex],
      pricePerUnit: Number(lineItemValue),
      preTaxAmount: Number(lineItemValue)
    };
    const taxableLineItemsCount = copiedLineItems.filter((l) => l.taxable).length;
    if (taxableLineItemsCount > 0) {
      copiedLineItems = copiedLineItems.map((l) => {
        const pricePerUnit = Number(l.pricePerUnit);
        return {
          ...l,
          postTaxAmount: l.taxable
            ? pricePerUnit + formatToPrecession(totalTaxAmount / taxableLineItemsCount, 4)
            : pricePerUnit
        };
      });
    } else {
      copiedLineItems[objIndex] = {
        ...copiedLineItems[objIndex],
        postTaxAmount: Number(lineItemValue)
      };
    }
  } else if (lineItemChangeKey === "unit") {
    const filteredUnit: any = units.filter((u: any) => u.id === lineItemValue)[0];
    copiedLineItems[objIndex] = {
      ...copiedLineItems[objIndex],
      unit: filteredUnit?.id != "None" ? filteredUnit : null
    };
  } else {
    copiedLineItems[objIndex] = {
      ...copiedLineItems[objIndex],
      [lineItemChangeKey]: lineItemValue
    };
  }

  if (originalLineItems[objIndex]) {
    const keys = Object.keys(originalLineItems[objIndex]);
    const changes = keys
      .filter((key) => key != "glCode" && key != "unit")
      .map((key: string) => {
        const returnObj: any = {};
        const obj: any = copiedLineItems[objIndex];
        const objKey: string =
          key === "isTaxable" ? "taxable" : key === "preTaxAmount" ? "pricePerUnit" : key;
        if (originalLineItems[objIndex][key] !== obj[objKey]) {
          return { ...returnObj, [objKey]: obj[objKey], key: objIndex + 1 };
        }

        return originalLineItems[objIndex][key] !== obj[objKey];
      })
      .filter((field) => field);

    copiedLineItems[objIndex] = {
      ...copiedLineItems[objIndex],
      esignRequired: changes.reduce((result, current) => {
        return Object.assign(result, current);
      }, {})
    };
  }

  return copiedLineItems;
};

export const transformInvoiceForSubmission = (
  invoice: IInvoice,
  invoiceInfo: InvoiceProps,
  invoiceTotal: unknown,
  totalTaxAmount: unknown,
  lineItems: ILineItem[],
  property?: TProperties
) => {
  const copiedInvoice: IInvoiceSerialized = JSON.parse(JSON.stringify(invoice));
  copiedInvoice.invoiceId = invoiceInfo.invoiceNumber;
  copiedInvoice.issueDate = new Date(invoiceInfo.issueDate as string);
  copiedInvoice.totalAmount = Number(invoiceTotal);
  copiedInvoice.totalTaxAmount = Number(totalTaxAmount);
  copiedInvoice.persistedInvoiceFileId = invoiceInfo?.persistedInvoiceFileId as string;
  copiedInvoice.payee = {
    name: invoiceInfo.companyName,
    address: invoiceInfo.address
  };
  if (property) {
    copiedInvoice.property = property;
  }

  copiedInvoice.lineItems = lineItems.map((l) => {
    return {
      lineId: l.lineId,
      amount: l.postTaxAmount ? Number(l.postTaxAmount) : 0,
      approvalStatus: INVOICE_PENDING,
      description: l.description,
      glCode: l.gl as TGlCode,
      isTaxable: l.taxable || false,
      preTaxAmount: l.pricePerUnit,
      rejectionReason: "",
      unit: l.unit
    };
  });
  copiedInvoice.internalLineItems = copiedInvoice.lineItems;
  copiedInvoice.approvalStatus = INVOICE_PENDING;
  return copiedInvoice;
};
