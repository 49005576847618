import { FC, useState } from "react";
import { Col, Row, Typography } from "antd";
import { useAntToken } from "@hooks/common/useAntToken";
import AddInvoiceItemModal from "./AddInvoiceItemModal";
import InvoiceContentHeader from "./InvoiceContentHeader";
import InvoiceLineItems from "./InvoiceLineItems";
import InvoiceContentFooter from "./InvoiceContentFooter";
import { IReviewInvoiceProps } from "./utils";
import InvoiceSideContent from "./InvoiceSideContent";
import { useBreakpoints } from "@hooks/common/useBreakPoints";
import CommonAppButton from "@Component/Common/CommonAppButton";
import { PlusCircleOutlined } from "@ant-design/icons";
import Loader from "@Component/Common/Loader";
import { INVOICE_PENDING } from "@utils/constants";
import { reviewInvoiceStyles } from "@Component/Common/ReviewInvoice/styles";
import GlobalInvoiceUnitSelect from "@Component/Common/Unit/GlobalInvoiceUnitSelect";

const ReviewInvoice: FC<IReviewInvoiceProps> = (props) => {
  const { token } = useAntToken();
  const {
    preLineItems,
    isManagerPortal,
    invoiceId,
    invoiceInfo,
    onIssuingCompanyInfoChange,
    onIssueDateChange,
    lineItems,
    handleLineItemDelete,
    displayAddItemModal,
    setDisplayAddItemModal,
    addItem,
    glCodes,
    invoiceTotal,
    handleSubmit,
    loading,
    formRef,
    handleDisplayAddItemModal,
    editGLCodes,
    totalTaxAmount,
    onTaxAmountChange,
    originalInvoiceUrl,
    handleEditLineItem,
    readonlyView,
    rejectedInvoiceView,
    propertyManagerView,
    handleInvoiceApprovalStatusChange,
    isInvoiceApprovalAllowed,
    handleInvoiceRejection,
    invoiceApprovalStatus,
    invoiceRejectionReason,
    units,
    propertyType,
    isRegionalManager,
    requestLienWavier,
    legalFormUrl,
    displayCancelButton,
    userLogsView
  } = props;
  const { isScreenXS } = useBreakpoints();
  const { styles } = reviewInvoiceStyles();
  const [globalUnit, setGlobalUnit] = useState("None");
  if (loading) {
    return <Loader isLoading={loading} />;
  }
  return (
    <>
      <Row
        justify={"center"}
        gutter={[16, 16]}
        style={{
          paddingTop: isScreenXS ? 0 : token.paddingXL,
          paddingBottom: isScreenXS ? 0 : token.paddingXL,
          width: "100%",
          margin: "auto"
        }}>
        <Col sm={24} xl={16}>
          <Row justify="center" style={{ width: "100%" }}>
            <Col
              span={24}
              style={{
                background: token.colorBgBase,
                minHeight: "85vh",
                marginBottom: token.paddingXL
              }}>
              <InvoiceContentHeader
                invoiceInfo={invoiceInfo}
                loading={loading}
                propertyManagerView={isManagerPortal || false}
              />
              <Col
                span={24}
                style={{
                  paddingLeft: isScreenXS ? token.paddingXS : token.paddingXL,
                  paddingRight: token.paddingXL
                }}>
                <Typography.Title
                  level={3}
                  style={{
                    fontWeight: token.fontWeightStrong,
                    paddingTop: token.paddingMD,
                    paddingBottom: isScreenXS ? 0 : token.fontSizeXL,
                    margin: 0,
                    fontSize: isScreenXS ? token.fontSizeLG : token.fontSizeXL
                  }}>
                  Item Details
                </Typography.Title>
              </Col>
              {!readonlyView && !propertyManagerView && (
                <GlobalInvoiceUnitSelect
                  units={units}
                  handleEditLineItem={handleEditLineItem}
                  globalUnit={globalUnit}
                  setGlobalUnit={(value: string) => setGlobalUnit(value)}
                />
              )}
              <InvoiceLineItems
                preLineItems={userLogsView ? [] : preLineItems}
                editGLCodes={editGLCodes}
                lineItems={lineItems}
                glCodes={glCodes}
                handleLineItemDelete={handleLineItemDelete}
                handleEditLineItem={handleEditLineItem}
                readonlyView={readonlyView}
                rejectedInvoiceView={rejectedInvoiceView}
                loading={loading}
                propertyManagerView={propertyManagerView}
                units={units}
                propertyType={propertyType}
                invoiceApprovalStatus={
                  invoiceApprovalStatus ? invoiceApprovalStatus : INVOICE_PENDING
                }
                isRegionalManager={isRegionalManager}
              />
              {!readonlyView && !propertyManagerView && (
                <Row
                  justify={isScreenXS ? "end" : "start"}
                  style={{
                    marginTop: token.marginSM,
                    marginRight: isScreenXS ? token.marginLG : 0
                  }}>
                  <Col md={1} lg={1}></Col>
                  <Col
                    xs={24}
                    md={23}
                    lg={22}
                    style={{ paddingLeft: isScreenXS ? token.marginMD : token.marginXXS }}>
                    <Row justify="start">
                      <Col span={24}>
                        <CommonAppButton
                          type="primary"
                          block={isScreenXS}
                          icon={<PlusCircleOutlined />}
                          style={{
                            border: 0,
                            fontSize: token.fontSizeLG,
                            fontWeight: token.fontWeightStrong,
                            height: token.controlHeightLG
                          }}
                          onClick={handleDisplayAddItemModal}>
                          Add Item
                        </CommonAppButton>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
              <InvoiceContentFooter
                invoiceTotal={invoiceTotal}
                onTaxAmountChange={onTaxAmountChange}
                totalTaxAmount={totalTaxAmount}
                readonlyView={readonlyView}
                propertyManagerView={propertyManagerView}
              />
            </Col>
          </Row>
        </Col>
        <Col sm={24} xl={6} style={{ width: "100%" }}>
          <Row
            style={{
              background: token.colorBgBase,
              minHeight: isScreenXS ? "10vh" : "85vh"
            }}>
            <InvoiceSideContent
              invoiceId={invoiceId as string}
              invoiceInfo={invoiceInfo}
              onIssuingCompanyInfoChange={onIssuingCompanyInfoChange}
              onIssueDateChange={onIssueDateChange}
              handleSubmit={handleSubmit}
              loading={loading}
              originalInvoiceUrl={originalInvoiceUrl}
              readonlyView={readonlyView}
              propertyManagerView={propertyManagerView}
              handleInvoiceApprovalStatusChange={handleInvoiceApprovalStatusChange}
              isInvoiceApprovalAllowed={isInvoiceApprovalAllowed}
              handleInvoiceRejection={handleInvoiceRejection}
              invoiceApprovalStatus={invoiceApprovalStatus}
              invoiceRejectionReason={invoiceRejectionReason}
              isRegionalManager={isRegionalManager}
              requestLienWavier={requestLienWavier}
              legalFormUrl={legalFormUrl}
              displayCancelButton={!userLogsView && displayCancelButton}
            />
          </Row>
        </Col>
      </Row>

      {!readonlyView && (
        <AddInvoiceItemModal
          addItem={addItem}
          displayAddItemModal={displayAddItemModal}
          setDisplayAddItemModal={setDisplayAddItemModal}
          formRef={formRef}
          glCodes={glCodes}
          units={units}
          propertyType={propertyType}
          classStyles={styles}
        />
      )}
    </>
  );
};
export default ReviewInvoice;
