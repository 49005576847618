import { useAntToken } from "@hooks/common/useAntToken";
import { Col, Row, Tag } from "antd";
import { FC } from "react";
import { useBreakpoints } from "@hooks/common/useBreakPoints";

interface IVendorTagsProps {
  vendors: Array<{ label: string; value: string }>;
  onDeleteTag: (value: string) => void;
}

const VendorTags: FC<IVendorTagsProps> = (props) => {
  const { vendors, onDeleteTag } = props;
  const { token } = useAntToken();
  const { isScreenXS } = useBreakpoints();

  if (!vendors?.length) return null;
  return (
    <Row
      justify="start"
      align="top"
      style={{
        flexWrap: "wrap",
        marginTop: token.marginSM,
        maxHeight: "100px",
        overflow: "auto",
        alignContent: "flex-start"
      }}>
      {vendors.map((p: { label: string; value: string }, index) => {
        return (
          <Col key={p.value + index}>
            <Tag
              bordered
              onClose={() => {
                onDeleteTag(p.value);
              }}
              closable
              color={token.colorPrimary}
              key={p.value}
              style={{ border: 0, borderRadius: token.borderRadiusSM, marginTop: token.marginXXS }}>
              {isScreenXS && String(p.label).length > 20
                ? `${String(p.label).substring(0, 23)}...`
                : p.label}
            </Tag>
          </Col>
        );
      })}
    </Row>
  );
};

export default VendorTags;
