import { IInvoice, ILineItem, InvoiceProps, TGlCode, TUnit } from "@Component/Common/types";
import { useFetchGlCodes } from "@hooks/Invoice/useFetchGlCodes";
import { useFetchInvoiceById } from "@hooks/Invoice/useFetchInvoice";
import { useFetchUserProperties } from "@hooks/Invoice/useFetchUserProperties";
import useFetchOriginalInvoiceUrl from "@hooks/common/useFetchOriginalInvoiceUrl";
import { shortIdGenerator } from "@utils/shortIdGenerator";
import { DatePickerProps } from "antd";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getInvoiceTotal, getUpdatedLineItemsOnEditing } from "./utils";
import { invoiceUpdate } from "@Services/InvoiceService";
import ShowToast from "@utils/showToast";
import { isAxiosError } from "axios";
import { INVOICE_APPROVED, TInvoiceApprovalStatus } from "@utils/constants";

const useHomeDepoState = () => {
  const { invoiceId } = useParams();
  const { data: homeDepotPropertiesData, isLoading: homeDepotPropertyLoading } =
    useFetchUserProperties();
  const navigate = useNavigate();
  const homeDepoProperties = homeDepotPropertiesData?.data;
  const { data: glCodesData } = useFetchGlCodes();
  const glCodes = (glCodesData?.data?.glCodes || []) as TGlCode[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRef = useRef<any>();
  const [invoiceInfo, setInvoiceInfo] = useState<InvoiceProps>({
    invoiceId: "",
    companyName: "",
    propertyName: "",
    propertyAddress: "",
    rmPropertyAddress: "",
    approvalStatus: "",
    vendorName: "",
    address: "",
    invoiceNumber: "",
    issueDate: "",
    totalAmount: 0,
    totalTaxAmount: 0,
    isSubmittable: true,
    cards: []
  });
  const [displayIssueDateInput, setDisplayIssueDateInput] = useState<boolean>(false);
  const [lineItems, setLineItems] = useState<ILineItem[]>([]);
  const [lastIndex, setLastIndex] = useState<number>(0);
  const [displayAddItemModal, setDisplayAddItemModal] = useState<boolean>(false);
  const [invoiceTotal, setInvoiceTotal] = useState<number>(0);
  const [totalTaxAmount, setTotalTaxAmount] = useState<number>(0);
  const [units, setUnits] = useState<TUnit[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedRowTaxable, setSelectedRowTaxable] = useState<boolean>(false);
  const [propertyType] = useState<string>("UnitProperty");
  const [selectedProperty, setSelectedProperty] = useState<{
    id: number;
    name: string;
    unit: Array<{ id: number; name: string }>;
    addresses: Array<{
      Address: string;
      City: string;
      PostalCode: string;
      State: string;
      Street: string;
      id: number;
    }>;
  }>();
  const [fetchedinvoiceApprovalStatus, setFetchedInvoiceApprovalStatus] =
    useState<TInvoiceApprovalStatus>();
  const { data: invoiceData, isSuccess } = useFetchInvoiceById(invoiceId as string);
  const invoice = invoiceData?.data as IInvoice;
  useEffect(() => {
    if (isSuccess) {
      if (invoice.property.unit) {
        setUnits(invoice.property.unit);
      }
      let lastIndex = 0;

      setInvoiceInfo({
        invoiceId: invoice?.Id,
        companyName: invoice?.payee.name,
        propertyAddress: invoice?.propertyAddresses[0]?.Address,
        rmPropertyAddress: invoice?.rmPropertyAddresses[0]?.Address,
        approvalStatus: invoice?.approvalStatus,
        propertyName: invoice?.property.name,
        address: invoice?.vendorAddresses ? invoice?.vendorAddresses[0]?.Address : "",
        vendorName: invoice?.vendor?.name,
        invoiceNumber: invoice.invoiceId,
        totalAmount: invoice.totalAmount,
        totalTaxAmount: invoice.totalTaxAmount,
        issueDate: invoice.issueDate ? moment(invoice.issueDate).format("YYYY-MM-DD") : null,
        isSubmittable: invoice.isSubmittable,
        cards: invoice.cardDetails
      });
      setTotalTaxAmount(invoice.totalTaxAmount);
      const lineItems = invoice.lineItems.map((l: any, index: number) => {
        lastIndex = index;
        return {
          lineId: l.lineId,
          key: index,
          description: l.description,
          pricePerUnit: Number(l.preTaxAmount),
          preTaxAmount: Number(l.preTaxAmount),
          postTaxAmount: Number(l.amount),
          gl: l.glCode as TGlCode,
          total: Number(l.amount),
          taxable: l.isTaxable || false,
          lineItemShortId: shortIdGenerator(),
          unit: l.unit,
          alternativeGlCodes: l.alternativeGlCodes,
          isTaxable: l.isTaxable
        };
      });
      setInvoiceTotal(invoice.totalAmount);
      setTotalTaxAmount(invoice.totalTaxAmount);
      setLastIndex(lastIndex);
      setLineItems(lineItems);
      setFetchedInvoiceApprovalStatus(invoice?.approvalStatus as TInvoiceApprovalStatus);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  const persistedInvoiceFileId = invoice?.persistedInvoiceFileId || "";
  const { data: originalInvoiceData } = useFetchOriginalInvoiceUrl(persistedInvoiceFileId);
  const originalInvoiceUrl = originalInvoiceData?.data?.downloadUrl;

  const onIssuingCompanyInfoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const invoiceInfoCopy: InvoiceProps = { ...invoiceInfo };
    invoiceInfoCopy[e.target.name as keyof InvoiceProps] = e.target.value;
    setInvoiceInfo(invoiceInfoCopy);
  };

  const onIssueDateChange: DatePickerProps["onChange"] = (_date, dateString) => {
    const invoiceInfoCopy: InvoiceProps = { ...invoiceInfo };
    invoiceInfoCopy["issueDate"] = dateString;
    setInvoiceInfo(invoiceInfoCopy);
  };
  const onSelectedRowTaxableChange = (checked: boolean) => {
    setSelectedRowTaxable(checked);
  };

  const handleLineItemDelete = (key: number) => {
    const filteredLineItems = lineItems.filter((l) => l.key !== key);
    setInvoiceTotal(getInvoiceTotal(filteredLineItems));
    setLineItems(filteredLineItems);
  };

  const revisePostTaxAmount = (lineItems: Array<ILineItem>) => {
    lineItems.map((l) => {
      handleEditLineItem(l.lineItemShortId, "taxable", l.taxable);
    });
  };
  const handleEditLineItem = (
    lineItemId: string,
    lineItemChangeKey: string,
    lineItemValue: unknown,
    unitAllToAll = false
  ) => {
    if (lineItemChangeKey === "pricePerUnit" || lineItemChangeKey === "postTaxAmount") {
      if (isNaN(Number(lineItemValue))) {
        return;
      }
    }
    let copiedLineItems;
    if (unitAllToAll) {
      copiedLineItems = lineItems.map((lineItem) => {
        return getUpdatedLineItemsOnEditing(
          lineItem.lineItemShortId,
          lineItemChangeKey,
          lineItemValue,
          lineItems,
          totalTaxAmount,
          units
        ).filter((lineItemFil) => lineItemFil.lineItemShortId === lineItem.lineItemShortId)[0];
      });
    } else {
      copiedLineItems = getUpdatedLineItemsOnEditing(
        lineItemId,
        lineItemChangeKey,
        lineItemValue,
        lineItems,
        totalTaxAmount,
        units
      );
    }
    setInvoiceTotal(getInvoiceTotal(copiedLineItems));
    setLineItems(copiedLineItems);
  };

  const handleSubmit = async () => {
    try {
      if (!selectedProperty) {
        ShowToast("Please select Property", "error");
        return;
      }
      for (const lineItem of lineItems) {
        if (!lineItem.description) {
          ShowToast("Line Item description can't be empty", "error");
          return;
        }
        if (!lineItem.preTaxAmount) {
          ShowToast("Line Item pre tax amount value can't be zero", "error");
          return;
        }
      }
      const taxableLineItemsCount = lineItems.filter((l) => l.taxable).length;
      if (totalTaxAmount > 0 && taxableLineItemsCount === 0) {
        ShowToast(
          "Either total tax amount should be 0 or atleast one line item should be taxable",
          "error"
        );
        return;
      }
      const clonedLineItems = structuredClone(lineItems);
      const serializeLineItems: ILineItem[] = clonedLineItems.map((l: ILineItem) => {
        return {
          lineId: l.lineId,
          amount: l.postTaxAmount,
          description: l.description,
          approvalStatus: "Approved",
          rejectionReason: l.rejectionReason ? l.rejectionReason : "",
          glCode: l.gl as TGlCode,
          gl: l.gl as TGlCode,
          taxable: l.taxable,
          isTaxable: l.taxable,
          unit: l.unit,
          key: l.key,
          pricePerUnit: l.pricePerUnit,
          preTaxAmount: l.preTaxAmount,
          postTaxAmount: l.postTaxAmount,
          lineItemShortId: l.lineItemShortId
        };
      });
      if (invoice) {
        invoice.lineItems = serializeLineItems;
        invoice.approvalStatus = INVOICE_APPROVED;
        invoice.rmProperty = selectedProperty;
        invoice.rmPropertyAddresses = selectedProperty.addresses;
        invoice.totalTaxAmount = Number(totalTaxAmount);
        invoice.totalAmount = invoiceTotal;
        invoice.propertyType = propertyType;
        setLoading(true);
        await invoiceUpdate(invoice);
        navigate("/");
        ShowToast("Invoice Approved", "success");
      }
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        ShowToast(error.response?.data.message, "error");
      }
      setLoading(false);
    }
  };

  const addItem = (newItem: ILineItem) => {
    const index = lastIndex + 1;
    newItem.key = index;
    newItem.lineItemShortId = shortIdGenerator();
    newItem.pricePerUnit = Number(newItem.pricePerUnit);
    newItem.preTaxAmount = Number(newItem.pricePerUnit);
    newItem.postTaxAmount = Number(newItem.pricePerUnit);
    newItem.gl = glCodes.filter((glCode: TGlCode) => glCode.id === Number(newItem.gl))[0];
    newItem.taxable = false;
    if (propertyType === "UnitProperty") {
      const filteredUnits = units.filter((u: TUnit) => u.id === newItem.unitId);
      newItem.unit = filteredUnits[0];
    }
    const copiedLineItems: Array<ILineItem> = structuredClone(lineItems);
    copiedLineItems.push(newItem as ILineItem);
    setLastIndex(index);
    revisePostTaxAmount(lineItems);
    setInvoiceTotal(getInvoiceTotal(copiedLineItems));
    setLineItems(copiedLineItems);
    setDisplayAddItemModal(false);
  };

  const handleDisplayAddItemModal = () => {
    formRef.current?.setFieldsValue({
      description: null,
      pricePerUnit: null,
      gl: null,
      unitId: null
    });
    setDisplayAddItemModal(true);
  };

  const navigateToUploadInvoicePage = () => {
    navigate("/");
  };

  const onTaxAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const decimalRegex = /^-?\d*\.?\d*$/;
    const numValidator = new RegExp(decimalRegex);
    const isValidNumber = numValidator.test(e.target.value);
    if (isValidNumber) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setTotalTaxAmount(e.target.value as any);
    }
  };

  useEffect(() => {
    const taxableLineItemsCount = lineItems.filter((l) => l.taxable).length;
    if (taxableLineItemsCount > 0) {
      revisePostTaxAmount(lineItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalTaxAmount]);

  const handlePropertyChange = (value: string) => {
    const filteredProperties = homeDepoProperties.filter(
      (p: { id: number; name: string }) => p.id === Number(value)
    );
    setUnits(filteredProperties[0].unit);
    setSelectedProperty(filteredProperties[0]);
    if (propertyType === "UnitProperty") {
      const clonedLineItems = structuredClone(lineItems);
      const mappedlineItems = clonedLineItems.map((l) => {
        l.unit = undefined;
        return l;
      });
      setLineItems(mappedlineItems);
    }
  };

  const readOnly = fetchedinvoiceApprovalStatus === INVOICE_APPROVED;

  return {
    displayIssueDateInput,
    lineItems,
    displayAddItemModal,
    glCodes,
    invoiceTotal,
    totalTaxAmount,
    units,
    loading,
    selectedRowTaxable,
    originalInvoiceUrl,
    editGLCodes: true,
    invoiceInfo,
    onIssuingCompanyInfoChange,
    onIssueDateChange,
    onSelectedRowTaxableChange,
    handleLineItemDelete,
    setDisplayIssueDateInput,
    setDisplayAddItemModal,
    formRef,
    homeDepoProperties,
    handleSubmit,
    navigateToUploadInvoicePage,
    addItem,
    handleDisplayAddItemModal,
    onTaxAmountChange,
    handleEditLineItem,
    handlePropertyChange,
    readOnly,
    homeDepotPropertyLoading
  };
};

export default useHomeDepoState;
