import { APP_BASE_URL, resetPasswordUrl, setPasswordUrl } from "../utils/urls";
import http from "../utils/httpService";

// eslint-disable-next-line
export const resetPassword = async (resetPasswordParamters: any) => {
  const { data } = await http.post(`${APP_BASE_URL}${resetPasswordUrl}`, resetPasswordParamters);
  return { data };
};
// eslint-disable-next-line
export const setPassword = async (setPasswordParamters: any) => {
  const { data } = await http.post(`${APP_BASE_URL}${setPasswordUrl}`, setPasswordParamters);
  return { data };
};
