import { FC, ReactNode } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { IAppState } from "../redux/reducers/rootReducer";
import { IAuthState } from "../redux/reducers/Auth/Auth";
import { Layout, theme } from "antd";
import AppHeader from "../Component/Common/AppHeader";
import { USER } from "../utils/constants";
const { Content } = Layout;

const { useToken } = theme;

interface Props {
  children?: ReactNode;
}

export const UserRoute: FC<Props> = ({ children }) => {
  const { token } = useToken();
  const loginState: IAuthState = useSelector((state: IAppState) => state.auth);

  const { role } = loginState;

  if (!loginState.token) {
    return <Navigate to="/auth/login" />;
  }
  if (role !== USER) {
    return <Navigate to="/unauthorized" />;
  }
  return (
    <Layout style={{ minHeight: "100vh", background: token.colorFillSecondary }}>
      <AppHeader />
      <Content>{children}</Content>
    </Layout>
  );
};
