import { Modal, Row, Col, Divider, Form, Input } from "antd";
import { useAntToken } from "@hooks/common/useAntToken";
import { useAdminSettingStyles } from "@Component/Admin/AdminSettings/styles";
import CommonFormLabel from "@Component/Common/CommonFormLabel";
import useCardEdit from "@hooks/Admin/HomeDepotCards/useCardEdit";
import ModalFooter from "@Component/Admin/AdminSettings/ModalFooter";

const EditGlGroupModal = (props: any) => {
  const { form, visible, handleEditCard, handleCancel, selectedCard } = props;
  const { styles } = useAdminSettingStyles();
  const { token } = useAntToken();
  const { mutate: updateCard, isPending } = useCardEdit();

  form.setFieldsValue({
    cardDetails: selectedCard?.cardDetails,
    nickName: selectedCard?.nickName
  });
  const handleUpdateCard = () => {
    updateCard({ ...selectedCard, nickName: form.getFieldValue("nickName") || "" });
    form.resetFields();
    handleEditCard();
  };

  return (
    <Modal
      open={visible}
      title={"Edit Card Detail"}
      footer={false}
      onCancel={handleCancel}
      className={styles["add-modal"]}>
      <Row justify="center">
        <Col style={{ marginTop: token.marginLG, width: "100%" }}>
          <Form form={form} onFinish={handleUpdateCard} layout="vertical">
            <Form.Item
              name="cardDetails"
              rules={[{ required: true, message: "Please Input Card Detail" }]}
              label={
                <CommonFormLabel
                  style={{
                    color: token.colorText,
                    fontSize: token.fontSize,
                    fontWeight: token.fontWeightStrong
                  }}>
                  Card Detail
                </CommonFormLabel>
              }>
              <Input
                disabled={true}
                placeholder="Card Detail"
                style={{
                  height: token.controlHeightLG,
                  backgroundColor: token.colorBgBase,
                  borderRadius: token.borderRadiusSM,
                  border: `1px solid ${token.colorBorder}`
                }}
              />
            </Form.Item>
            <Form.Item
              name="nickName"
              label={
                <CommonFormLabel
                  style={{
                    color: token.colorText,
                    fontSize: token.fontSize,
                    fontWeight: token.fontWeightStrong
                  }}>
                  Name
                </CommonFormLabel>
              }>
              <Input
                placeholder="Card Name"
                style={{
                  height: token.controlHeightLG,
                  backgroundColor: token.colorBgBase,
                  borderRadius: token.borderRadiusSM,
                  border: `1px solid ${token.colorBorder}`
                }}
              />
            </Form.Item>
            <Divider />
            <ModalFooter isPending={isPending} handleCancel={handleCancel} />
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};

export default EditGlGroupModal;
