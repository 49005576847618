import { useAntToken } from "@hooks/common/useAntToken";
import { useBreakpoints } from "@hooks/common/useBreakPoints";
import { ExportOutlined } from "@ant-design/icons";
import { FC } from "react";
import { Link } from "react-router-dom";
import CommonAppButton from "./CommonAppButton";

interface IRedirectToOrignalInvoiceButtonProps {
  redirectionUrl: string;
  linkText: string;
  backgroundColor?: string;
  color?: string;
  icon?: React.ReactNode;
  style?: React.CSSProperties;
}
const RedirectToOrignalInvoiceButton: FC<IRedirectToOrignalInvoiceButtonProps> = (props) => {
  const { token } = useAntToken();
  const { isScreenXS: isMobileScreen, isScreenXL } = useBreakpoints();
  const {
    redirectionUrl,
    linkText,
    backgroundColor = "transparent",
    color = token.colorTextLightSolid,
    icon = <ExportOutlined />,
    style
  } = props;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const fontWeightNormal = token.fontWeightStrong;
  return (
    <Link to={redirectionUrl} target="_blank">
      <CommonAppButton
        type="primary"
        block
        icon={icon}
        style={{
          marginTop: isMobileScreen ? token.marginMD : token.marginLG,
          background: backgroundColor,
          height: isMobileScreen ? token.controlHeightSM : token.controlHeightLG,
          color: color,
          fontSize: isMobileScreen ? token.fontSizeSM : isScreenXL ? ".85vw" : "2vw",
          boxShadow: token.boxShadow,
          fontWeight: 500,
          paddingLeft: 10,
          paddingRight: 10,
          ...style
        }}>
        {linkText}
      </CommonAppButton>
    </Link>
  );
};

export default RedirectToOrignalInvoiceButton;
