import { notification } from "antd";

// eslint-disable-next-line
const parseMessage = (message: any) => {
  const messageArray = message.split(".");
  return (
    messageArray
      // eslint-disable-next-line
      .map((m: any) => {
        let finalMessage;
        if (m[0] !== " ") {
          finalMessage = m.charAt(0).toUpperCase() + m.slice(1).toLowerCase();
        } else {
          finalMessage = m.charAt(0) + m.charAt(1).toUpperCase() + m.slice(2).toLowerCase();
        }
        return finalMessage;
      })
      .join(".")
  );
};

const ShowToast = (message: string, type = "info", duration = 3.5, description = "") => {
  //eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  notification[type]({
    message: parseMessage(message),
    description: description,
    placement: "topLeft",
    duration: duration,
    style: { borderRadius: "30px" }
  });
};

export default ShowToast;
