import { useAntToken } from "@hooks/common/useAntToken";
import { Col, Row, Tag } from "antd";
import { FC } from "react";
import { TFormGlCode } from "../types";

interface IGLCodesTagsProps {
  glCodes: TFormGlCode[];
  onDeleteTag: (tag: TFormGlCode) => void;
}

const GLCodesTags: FC<IGLCodesTagsProps> = (props) => {
  const { glCodes, onDeleteTag } = props;
  const { token } = useAntToken();

  if (!glCodes?.length) return null;
  const filteredGlCodes = glCodes.filter((p: TFormGlCode) => p.label !== "All");
  return (
    <Row
      justify="start"
      align="top"
      style={{
        maxHeight: "150px",
        overflow: "auto"
      }}>
      {filteredGlCodes.map((p: TFormGlCode) => {
        return (
          <Col key={p.label}>
            <Tag
              bordered
              onClose={() => {
                onDeleteTag(p);
              }}
              closable
              color={token.colorPrimary}
              key={p.value}
              style={{
                border: 0,
                borderRadius: token.borderRadiusSM,
                marginTop: token.marginXXS
              }}>
              {p.label}
            </Tag>
          </Col>
        );
      })}
    </Row>
  );
};

export default GLCodesTags;
