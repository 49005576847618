import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IInvoiceListState } from "@redux/reducers/Invoice/InvoiceList";
import { AppDispatch, IAppState } from "@redux/reducers/rootReducer";
import {
  loadAllUserInvoices,
  invoiceListWithInitialFilters,
  setApprovalStatus,
  setCurrentPage,
  setPaymentStatus,
  loadAllRegionalManagerInvoices
} from "@redux/actions/Invoice/InvoiceList";
import { PaginationProps } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import moment from "moment";
import VendorDashboard from "@Component/Vendor/VendorDashboard";
import { IAuthState } from "@redux/reducers/Auth/Auth";

const UserDashboardPage: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const loginState: IAuthState = useSelector((state: IAppState) => state.auth);
  const {
    loginInfo: { name, isRegionalManager, role }
  } = loginState;

  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [invoiceList, setInvoiceList] = useState<any>([]);
  const invoiceListState: IInvoiceListState = useSelector((state: IAppState) => state.invoiceList);
  const { pageNum, pageSize, invoices, totalPages, isLoading, paymentStatus, approvalStatus } =
    invoiceListState;

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const list = [...invoices].map((i: any) => {
      const sum = i.totalAmount;

      return {
        Id: i.Id,
        PO: i?.PO,
        invoiceNumber: i.invoiceId,
        companyName: i.payee.name,
        address: i?.vendorAddresses[0]?.Address || i.payee.address,
        approvalStatus: i.approvalStatus,
        paymentStatus: i.paymentStatus,
        amount: sum,
        propertyName: i.property.name,
        propertyAddress: i?.propertyAddresses[0]?.Address,
        rmPropertyAddress: i?.rmPropertyAddresses[0]?.Address,
        rmAddress: i?.rmProperty?.name,
        propertyType: i.propertyType,
        issueDate: i.issueDate ? moment(i.issueDate).format("MM-DD-YYYY") : null,
        lineItems: i.lineItems,
        totalTaxAmount: i.totalTaxAmount,
        isHomeDepot: i.isHomeDepot,
        persistedInvoiceFileId: i.persistedInvoiceFileId,
        submitted: moment(i.createdAt).format("MM-DD-YYYY"),
        vendorName: i.vendor.name
      };
    });
    setInvoiceList(list);
  }, [invoices]);

  const navigateToUploadInvoice = () => {
    navigate("/vendorUploadInvoice");
  };

  const allUserInvoices = () => {
    dispatch(
      loadAllUserInvoices(pageNum, pageSize, paymentStatus.toString(), approvalStatus.toString())
    );
  };

  const regionalManagerInvoices = () => {
    dispatch(
      loadAllRegionalManagerInvoices(
        pageNum,
        pageSize,
        paymentStatus.toString(),
        approvalStatus.toString()
      )
    );
  };

  useEffect(() => {
    if (isRegionalManager) {
      regionalManagerInvoices();
    } else {
      allUserInvoices();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNum, pageSize, paymentStatus, approvalStatus, isRegionalManager]);

  const onPaginationChange: PaginationProps["onChange"] = (page, size) => {
    dispatch(setCurrentPage(page, size));
  };

  const navigateToViewInvoice = (invoiceId: string) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const invoice = invoiceList.find((i: any) => i.Id === invoiceId);
    if (invoice?.isHomeDepot) {
      navigate(`/userViewInvoice/homedepot/${invoiceId}`);
    } else {
      navigate(`/userViewInvoice/${invoiceId}`);
    }
  };

  const onPaymentStatusChange = (e: CheckboxChangeEvent) => {
    dispatch(setPaymentStatus(getFilterValue(e, paymentStatus)));
  };

  const onApprovalStatusChange = (e: CheckboxChangeEvent) => {
    dispatch(setApprovalStatus(getFilterValue(e, approvalStatus)));
  };

  const getFilterValue = (e: CheckboxChangeEvent, filterArray: any) => {
    if (e.target.checked) {
      return [...filterArray, e.target.name];
    }
    return filterArray.filter((filter: any) => filter != e.target.name);
  };

  const clearFilters = () => {
    dispatch(invoiceListWithInitialFilters());
  };

  const navigateToAdmin = () => {
    navigate("/admin");
  };

  return (
    <VendorDashboard
      isManagerPortal={role === "user"}
      navigateToUploadInvoice={navigateToUploadInvoice}
      pageNum={pageNum}
      pageSize={pageSize}
      totalPages={totalPages}
      invoiceList={invoiceList}
      onPaginationChange={onPaginationChange}
      isLoading={isLoading}
      navigateToViewInvoice={navigateToViewInvoice}
      paymentStatus={paymentStatus}
      approvalStatus={approvalStatus}
      onPaymentStatusChange={onPaymentStatusChange}
      onApprovalStatusChange={onApprovalStatusChange}
      clearFilters={clearFilters}
      showPaymentStatus={true}
      title={`${isRegionalManager ? "Regional" : "Property"} Manager Portal (${name})`}
      isPropertyManager={true}
      navigateToAdmin={navigateToAdmin}
    />
  );
};
export default UserDashboardPage;
