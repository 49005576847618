import { FC } from "react";
import { Col, Row, Modal, Flex, Typography } from "antd";
import { useAntToken } from "@hooks/common/useAntToken";
import { IInvoice } from "./utils";
import InvoiceInfoHeader from "./InvoiceInfoHeader";
import InvoiceLineItemsTable from "./InvoiceLineItemsTable";
import InvoiceFooter from "./InvoiceFooter";

interface IDownloadPdfModalProps {
  isModalOpen: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  savePdfRef: any;
  invoiceToDownload: IInvoice;
}
const DownloadPdfModal: FC<IDownloadPdfModalProps> = (props) => {
  const { isModalOpen, savePdfRef, invoiceToDownload } = props;

  const { token } = useAntToken();
  const invoiceInfo: {
    address: string;
    propertyAddress: string | undefined;
    propertyName: string;
    companyName: string;
    propertyType: string;
    invoiceNumber: string;
    invoiceId: string;
    issueDate: string;
    vendorName: string;
  } = {
    invoiceId: invoiceToDownload.Id,
    address: invoiceToDownload.address,
    propertyAddress: invoiceToDownload?.isHomeDepot
      ? invoiceToDownload?.rmPropertyAddress
      : invoiceToDownload.propertyAddress,
    companyName: invoiceToDownload.companyName,
    issueDate: invoiceToDownload.issueDate as string,
    vendorName: invoiceToDownload.vendorName,
    invoiceNumber: invoiceToDownload.invoiceNumber,
    propertyName: invoiceToDownload.propertyName,
    propertyType: invoiceToDownload.propertyType
  };

  const invoiceTotal = invoiceToDownload.amount;
  const totalTaxAmount = invoiceToDownload.totalTaxAmount;
  const pdfFontFamily = "sans-serif";
  return (
    <Modal title="Downloading pdf" open={isModalOpen} footer={false}>
      <Row justify="center" style={{ width: "100%" }} ref={savePdfRef}>
        <Col
          span={23}
          style={{
            background: token.colorBgBase,
            marginBottom: token.paddingXL,
            fontFamily: pdfFontFamily
          }}>
          <Row>
            <Col span={23}>
              <Flex
                vertical
                style={{
                  paddingLeft: token.paddingXS,
                  paddingRight: token.paddingXS
                }}>
                <Typography.Title
                  level={4}
                  style={{
                    fontWeight: token.fontWeightStrong,
                    marginBottom: 0,
                    fontSize: "14px",
                    color: token.colorText,
                    fontFamily: pdfFontFamily
                  }}>
                  Invoice ID: <span style={{ visibility: "hidden" }}>a</span>
                  {invoiceInfo.invoiceId}
                </Typography.Title>
                <Typography.Title
                  level={4}
                  style={{
                    fontWeight: token.fontWeightStrong,
                    marginTop: 0,
                    fontSize: "14px",
                    color: token.colorText,
                    fontFamily: pdfFontFamily
                  }}>
                  Invoice No: <span style={{ visibility: "hidden" }}>a</span>
                  {invoiceInfo.invoiceNumber}
                </Typography.Title>
                <InvoiceInfoHeader
                  propertyAddress={invoiceInfo.propertyAddress as string}
                  address={invoiceInfo.address}
                  issueDate={invoiceInfo.issueDate}
                  propertyName={invoiceInfo.propertyName}
                  vendorName={invoiceInfo.vendorName}
                />
              </Flex>
            </Col>
            <Col span={23}>
              <InvoiceLineItemsTable
                lineItems={invoiceToDownload.lineItems}
                propertyType={invoiceInfo.propertyType}
              />
            </Col>
            <Col span={12} style={{ marginLeft: "auto", marginRight: token.marginSM }}>
              <InvoiceFooter invoiceTotal={invoiceTotal} totalTaxAmount={totalTaxAmount} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};

export default DownloadPdfModal;
