import { Form, Input, Checkbox } from "antd";
import { useAntToken } from "@hooks/common/useAntToken";
import CommonFormLabel from "@Component/Common/CommonFormLabel";
import { commonAntStyles } from "@Component/Common/styles";

const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const SecondaryEmailField = () => {
  const { token } = useAntToken();
  const { styles } = commonAntStyles();

  return (
    <Form.Item
      noStyle
      shouldUpdate={(prevValues, currentValues) =>
        prevValues.invitationType !== currentValues.invitationType
      }>
      {({ getFieldValue }) => {
        const invitationType = getFieldValue("invitationType");
        const vendor = getFieldValue("vendors");
        if (invitationType === "single") {
          return (
            <>
              <Form.Item
                style={{ marginTop: token.marginMD }}
                name="secondaryEmail"
                className={styles["ant-input-override"]}
                rules={[
                  {
                    required: !validateEmail(vendor),
                    type: "email",
                    message: "Please enter valid email"
                  }
                ]}
                label={
                  <CommonFormLabel
                    style={{
                      color: token.colorText,
                      fontSize: token.fontSize,
                      fontWeight: token.fontWeightStrong
                    }}>
                    Secondary Email
                  </CommonFormLabel>
                }>
                <Input
                  placeholder="Secondary Email"
                  style={{
                    height: token.controlHeightLG,
                    backgroundColor: token.colorBgBase,
                    borderRadius: token.borderRadiusSM,
                    border: `1px solid ${token.colorBorder}`
                  }}
                />
              </Form.Item>
              <Form.Item
                name="useSecondaryEmail"
                valuePropName="checked"
                className={styles["ant-input-override"]}>
                <Checkbox>Use secondary email for Invitation.</Checkbox>
              </Form.Item>
            </>
          );
        }
        return null;
      }}
    </Form.Item>
  );
};

export default SecondaryEmailField;
