import { useQuery } from "@tanstack/react-query";
import { fetchAllCards } from "@Services/UserService";
import { React_Query_Keys } from "@utils/constants";

export const useCardsFetchAll = () => {
  return useQuery({
    queryKey: [React_Query_Keys.FETCH_ALL_HOME_DEPOT_CARDS],
    queryFn: () => fetchAllCards()
  });
};
