import { createStyles } from "antd-style";

export const vendorDashboardStyles = createStyles(({ token, css }) => {
  const pdfFontFamily = "sans-serif";

  return {
    "ant-list-over-ride": {
      ".ant-list-items": css`
        max-height: 61vh !important;
        overflow-y: scroll !important;
      `,
      ".list-item": css`
        background: ${token.colorBgContainer} !important;
      `,
      ".even-list-item": css`
        background: ${token.colorBgContainer} !important;
      `,
      ".odd-list-item": css`
        background: transparent !important;
      `,
      ".ant-list-item": css`
        border-block-end: 0px !important;
      `,
      ".ant-list-header": css`
        border-block-end: 0px !important;
        background: ${token.colorBgBase};
      `
    },
    "ant-pagination-over-ride": {
      ".ant-pagination-item-active": css`
        background-color: transparent !important;
      `
    },
    "ant-table-overider": {
      ".ant-table": css`
        background: ${token.colorPrimaryBg} !important;
        border-radius: ${token.borderRadius}px;
        font-size: 6px;
        font-family: ${pdfFontFamily} !important;
      `,
      ".ant-table-cell": css`
        background: ${token.colorPrimaryBg} !important;
        border: 0px !important;
        padding: ${token.paddingXS}px ${token.padding}px !important;
      `,
      "table>tbody>tr:last-child >*:last-child": css`
        border-bottom-right-radius: ${token.borderRadius}px !important;
      `,
      "table>tbody>tr:last-child >*:first-child": css`
        border-bottom-left-radius: ${token.borderRadius}px !important;
      `,
      ".ant-table-cell::before": css`
        background-color: transparent !important;
      `
    }
  };
});
