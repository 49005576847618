import { GrayFilterIcon, SelectedFilterIcon } from "@assets/icons/CustomIcons";
import { useAntToken } from "@hooks/common/useAntToken";
import { useBreakpoints } from "@hooks/common/useBreakPoints";
import { Col, Dropdown, MenuProps, Row, Typography } from "antd";
import { FC } from "react";

interface IMobileListHeaderProps {
  showPaymentStatus: boolean;
  paymentStatusItems: MenuProps["items"];
  approvalStatusItems: MenuProps["items"];
  approvalStatus: any;
  paymentStatus: any;
}
const MobileListHeader: FC<IMobileListHeaderProps> = (props) => {
  const {
    showPaymentStatus,
    paymentStatusItems,
    approvalStatusItems,
    approvalStatus,
    paymentStatus
  } = props;
  const { token } = useAntToken();
  const { isScreenXL } = useBreakpoints();

  return (
    <Row align="middle" style={{ width: "100%", padding: 10 }}>
      <Col span={5}>
        <Typography.Text style={{ fontSize: token.fontSizeSM, fontWeight: token.fontWeightStrong }}>
          Invoice
        </Typography.Text>
      </Col>
      <Col span={5}>
        <Typography.Text style={{ fontSize: token.fontSizeSM, fontWeight: token.fontWeightStrong }}>
          Total
        </Typography.Text>
      </Col>
      {showPaymentStatus && (
        <Col span={5}>
          <Dropdown menu={{ items: paymentStatusItems }} trigger={["click", "contextMenu"]}>
            <a onClick={(e) => e.preventDefault()}>
              {paymentStatus == "" ? <GrayFilterIcon /> : <SelectedFilterIcon />}{" "}
            </a>
          </Dropdown>
          <Typography.Text
            style={{
              fontSize: !isScreenXL ? token.fontSizeSM : token.fontSizeLG,
              fontWeight: token.fontWeightStrong,
              color: token.colorText
            }}>
            Status
          </Typography.Text>
        </Col>
      )}
      <Col span={6}>
        <Dropdown menu={{ items: approvalStatusItems }} trigger={["click", "contextMenu"]}>
          <a onClick={(e) => e.preventDefault()}>
            {approvalStatus == "" ? <GrayFilterIcon /> : <SelectedFilterIcon />}{" "}
          </a>
        </Dropdown>
        <Typography.Text
          style={{
            fontSize: !isScreenXL ? token.fontSizeSM : token.fontSizeLG,
            fontWeight: token.fontWeightStrong,
            color: token.colorText
          }}>
          Approval
        </Typography.Text>
      </Col>
      <Col span={1}></Col>
    </Row>
  );
};

export default MobileListHeader;
