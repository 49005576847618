import { FC } from "react";
import {
  IInvoice,
  getApprovalStatusItems,
  getMoreOptionsItems,
  getPaymentStatusItems
} from "./utils";
import { Col, Row, Typography, List, MenuProps, Dropdown } from "antd";
import { vendorDashboardStyles } from "./styles";
import { useAntToken } from "@hooks/common/useAntToken";
import formatter from "@utils/formatter";
import PaymentTag from "@Component/Common/PaymentTag";
import ApprovalTag from "@Component/Common/ApprovalTag";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import DesktopListHeader from "./DesktopListHeader";
import CommonListDivider from "@Component/Common/CommonListDivider";
import { MoreOutlined } from "@ant-design/icons";
import { fetchOriginalInvoiceImageUrl } from "@Services/InvoiceService";
import { INVOICE_CANCELLED, INVOICE_PENDING } from "@utils/constants";
interface IDesktopListProps {
  isRegionalManager?: boolean;
  isManagerPortal: boolean;
  isLoading: boolean;
  invoiceList: IInvoice[];
  showPaymentStatus: boolean;
  navigateToViewInvoice: (invoiceId: string, approvalStatus: string) => void;
  handleCancelInvoice?: (invoiceId: string) => Promise<void>;
  handleDownload: (invoice: IInvoice) => void;
  paymentStatus: string[];
  approvalStatus: string[];
  onPaymentStatusChange: (e: CheckboxChangeEvent) => void;
  onApprovalStatusChange: (e: CheckboxChangeEvent) => void;
}
const DesktopList: FC<IDesktopListProps> = (props) => {
  const {
    isRegionalManager,
    isManagerPortal,
    isLoading,
    invoiceList,
    showPaymentStatus,
    navigateToViewInvoice,
    handleCancelInvoice,
    handleDownload,
    approvalStatus,
    paymentStatus,
    onPaymentStatusChange,
    onApprovalStatusChange
  } = props;

  const { styles } = vendorDashboardStyles();
  const { token } = useAntToken();
  const paymentStatusItems: MenuProps["items"] = getPaymentStatusItems(
    paymentStatus,
    onPaymentStatusChange
  );

  const approvalStatusItems: MenuProps["items"] = getApprovalStatusItems(
    approvalStatus,
    onApprovalStatusChange,
    !isManagerPortal,
    isRegionalManager
  );

  const commonPadding = 12;
  return (
    <List
      loading={isLoading}
      className={styles["ant-list-over-ride"]}
      header={
        <DesktopListHeader
          approvalStatusItems={approvalStatusItems}
          paymentStatusItems={paymentStatusItems}
          showPaymentStatus={showPaymentStatus}
          paymentStatus={paymentStatus}
          approvalStatus={approvalStatus}
        />
      }
      footer={false}
      dataSource={invoiceList}
      renderItem={(item) => {
        const handleViewInvoice = (item: IInvoice) => {
          navigateToViewInvoice(item.Id, item.approvalStatus);
        };
        const handleViewOriginalInvoice = async (persistedInvoiceFileId: string) => {
          const { data } = await fetchOriginalInvoiceImageUrl(persistedInvoiceFileId);
          const url = data?.downloadUrl;
          window.open(url, "_blank");
        };
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        const fontWeightMedium = token.fontWeightMedium;

        return (
          <>
            <List.Item
              style={{
                background: token.colorBgContainer,
                outline:
                  item.approvalStatus === INVOICE_CANCELLED
                    ? `2px solid ${token.colorErrorBorder}`
                    : "none",
                outlineOffset: item.approvalStatus === INVOICE_CANCELLED ? -2 : 0,
                fontWeight: fontWeightMedium
              }}>
              <Row align="middle" style={{ width: "100%" }}>
                <Col span={2} style={{ paddingLeft: commonPadding }}>
                  <Typography.Text>{item.Id}</Typography.Text>
                </Col>
                <Col span={3} style={{ paddingLeft: commonPadding }}>
                  <Typography.Text>{item.invoiceNumber}</Typography.Text>
                </Col>
                <Col span={3} style={{ paddingLeft: commonPadding }}>
                  <Typography.Text>{formatter.format(item.amount)}</Typography.Text>
                </Col>
                <Col span={6} style={{ paddingLeft: commonPadding }}>
                  <Row>
                    <Typography.Text>
                      {item.propertyName.includes("Depot")
                        ? item.address.includes("NOT FOUND")
                          ? item.propertyName
                          : item.rmAddress
                        : item.propertyName}
                    </Typography.Text>
                  </Row>
                  <Row align="middle" justify={"start"}>
                    <Typography.Text strong>
                      {item.propertyName.includes("Depot") ? "PO" : "Address"}:&nbsp;
                    </Typography.Text>
                    <Typography.Text>
                      {item.propertyName.includes("Depot")
                        ? item?.PO || "Not Found"
                        : item.propertyAddress}
                    </Typography.Text>
                  </Row>
                </Col>
                <Col span={2} style={{ paddingLeft: commonPadding }}>
                  <Typography.Text>{item.submitted}</Typography.Text>
                </Col>
                {showPaymentStatus && (
                  <Col span={3}>
                    <PaymentTag invoice={item} />
                  </Col>
                )}
                <Col span={3} style={{ paddingLeft: 12 }}>
                  <ApprovalTag invoice={item} />
                </Col>
                <Col span={2} style={{ cursor: "pointer" }}>
                  <Col
                    style={{
                      margin: "auto",
                      marginLeft: token.marginMD,
                      cursor: "pointer",
                      width: "28px",
                      border: `1px solid ${token.colorBorder}`,
                      boxShadow: token.boxShadow,
                      borderRadius: token.borderRadiusSM
                    }}>
                    <Row align="middle" justify="center">
                      <Col>
                        <Dropdown
                          menu={{
                            items: getMoreOptionsItems(
                              item,
                              handleViewInvoice,
                              handleDownload,
                              handleViewOriginalInvoice,
                              handleCancelInvoice,
                              !isManagerPortal && item.approvalStatus === INVOICE_PENDING
                            )
                          }}
                          trigger={["click", "contextMenu"]}
                          placement="bottomRight">
                          <a
                            onClick={(e) => e.preventDefault()}
                            style={{
                              fontSize: token.fontSizeLG,
                              fontWeight: token.fontWeightStrong,
                              color: token.colorText
                            }}>
                            <MoreOutlined />
                          </a>
                        </Dropdown>
                      </Col>
                    </Row>
                  </Col>
                </Col>
              </Row>
            </List.Item>
            <CommonListDivider />
          </>
        );
      }}
    />
  );
};

export default DesktopList;
