import { FC } from "react";
import { Tooltip } from "antd";
import {
  LikeOutlinedIcon,
  DislikeOutlineIcon,
  WarningOutlinedIcon
} from "../../assets/icons/CustomIcons";
import {
  INVOICE_APPROVED,
  INVOICE_CANCELLED,
  INVOICE_PENDING,
  INVOICE_REJECTED,
  INVOICE_UNDER_REVIEW
} from "../../utils/constants";

interface IInvoice {
  Id: string;
  invoiceNumber: string;
  amount: number;
  paymentStatus: string;
  approvalStatus: string;
  propertyName: string;
}

interface Props {
  invoice: IInvoice;
}

const ApprovalTag: FC<Props> = (props) => {
  const { invoice } = props;
  return (
    <>
      {invoice.approvalStatus === INVOICE_APPROVED && <LikeOutlinedIcon />}
      {invoice.approvalStatus === INVOICE_REJECTED && <DislikeOutlineIcon />}
      {invoice.approvalStatus === INVOICE_PENDING && <WarningOutlinedIcon />}
      {invoice.approvalStatus === INVOICE_CANCELLED && <DislikeOutlineIcon />}
      {invoice.approvalStatus === INVOICE_UNDER_REVIEW && (
        <Tooltip title={"Regional Manager Review"}>
          <WarningOutlinedIcon />
        </Tooltip>
      )}
    </>
  );
};
export default ApprovalTag;
