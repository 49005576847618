import { useNavigate } from "react-router-dom";
import { isAxiosError } from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateGlGroup } from "@Services/UserService";
import { React_Query_Keys } from "@utils/constants";
import ShowToast from "@utils/showToast";
import { ROUTE_PATHS } from "@routes/constants";

const useGlCodeGroupEdit = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: updateGlGroup,
    mutationKey: [React_Query_Keys.UPDATE_GL_GROUP],
    onError: (error: unknown) => {
      if (isAxiosError(error)) {
        ShowToast(error.response?.data.message, "error");
      }
    },
    onSuccess: () => {
      ShowToast("Group Updated successfully", "success");
      queryClient.invalidateQueries({ queryKey: [React_Query_Keys.FETCH_ALL_GL_GROUP] });
      navigate(`${ROUTE_PATHS.ADMIN}/settings`);
    }
  });
};

export default useGlCodeGroupEdit;
