import { FC } from "react";
import { Typography, Modal } from "antd";
import { useAntToken } from "@hooks/common/useAntToken";

interface ILineItemChangeModalModalProps {
  isModalOpen: boolean;
  setChangeModalOpen: (val: boolean) => void;
  setCheckboxSigned: (val: boolean) => void;
}
const LineItemChangeModal: FC<ILineItemChangeModalModalProps> = (props) => {
  const { isModalOpen, setChangeModalOpen, setCheckboxSigned } =
    props;
  const { token } = useAntToken();

  return (
    <Modal
      destroyOnClose
      okText={"Confirm & Resend"}
      cancelText={"Dismiss"}
      onOk={() => {
        setCheckboxSigned(true);
        setChangeModalOpen(false);
      }}
      title={
        <Typography.Title
          level={4}
          style={{
            fontSize: token.fontSizeLG,
            fontWeight: token.fontWeightStrong,
            color: token.colorText,
            margin: 0
          }}>
          Confirm your changes
        </Typography.Title>
      }
      open={isModalOpen}
      onCancel={() => {
        setCheckboxSigned(false);
        setChangeModalOpen(false);
      }}>
      <Typography.Text
        style={{
          fontSize: token.fontSizeLG,
          color: token.colorText,
          margin: 0
        }}>
        You have made changes to the original invoice.
        <br /> Click "Dismiss" to go back or Click "Confirm & Resend" to ensure your changes and
        send the Invoice.
      </Typography.Text>
    </Modal>
  );
};

export default LineItemChangeModal;
