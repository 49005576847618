import { Checkbox, MenuProps } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import {
  APPROVED,
  PENDING,
  PROPERTY,
  REGIONAL,
  VENDOR_ACTIVE_STATUS_ACTIVE,
  VENDOR_ACTIVE_STATUS_IN_ACTIVE
} from "@utils/constants";
import { TAdminUser, TProperties } from "@Component/Admin/AdminViewUsers/types";
import { TFormGlCode, TGlCode } from "@Component/Common/types";
import { generateGlCodeLabel } from "@Component/Common/utils";
import { TAdminUserRole } from "@Component/Admin/types";

export const getApprovalStatusItems = (
  approvalStatus: any[],
  onApprovalStatusChange: (e: CheckboxChangeEvent) => void
) => {
  return [
    {
      label: (
        <Checkbox
          checked={approvalStatus.includes(PENDING)}
          name={PENDING}
          onChange={onApprovalStatusChange}>
          Pending
        </Checkbox>
      ),
      key: "0"
    },
    {
      label: (
        <Checkbox
          checked={approvalStatus.includes(APPROVED)}
          name={APPROVED}
          onChange={onApprovalStatusChange}>
          Approved
        </Checkbox>
      ),
      key: "1"
    }
  ];
};
export const getManagerTypeItems = (
  managerType: string,
  onManagerTypeChange: (e: CheckboxChangeEvent) => void
) => {
  return [
    {
      label: (
        <Checkbox
          checked={managerType.includes(REGIONAL)}
          name={REGIONAL}
          onChange={onManagerTypeChange}>
          Regional
        </Checkbox>
      ),
      key: "0"
    },
    {
      label: (
        <Checkbox
          checked={managerType.includes(PROPERTY)}
          name={PROPERTY}
          onChange={onManagerTypeChange}>
          Property
        </Checkbox>
      ),
      key: "1"
    }
  ];
};
export const getVendorStatusItems = (
  vendorActiveStatus: string,
  onVendorStatusChange: (e: CheckboxChangeEvent) => void
) => {
  return [
    {
      label: (
        <Checkbox
          checked={vendorActiveStatus === VENDOR_ACTIVE_STATUS_ACTIVE}
          name={VENDOR_ACTIVE_STATUS_ACTIVE}
          onChange={onVendorStatusChange}>
          Active
        </Checkbox>
      ),
      key: "1"
    },
    {
      label: (
        <Checkbox
          checked={vendorActiveStatus === VENDOR_ACTIVE_STATUS_IN_ACTIVE}
          name={VENDOR_ACTIVE_STATUS_IN_ACTIVE}
          onChange={onVendorStatusChange}>
          In Active
        </Checkbox>
      ),
      key: "2"
    }
  ];
};

export const getMoreOptionsItems = (
  item: TAdminUser,
  userType: string,
  handleShowDeleteModal: (item: TAdminUser) => void,
  handleOpenInviteVendorModal: () => void,
  handleUserChange: (user: TAdminUser) => void,
  handleReInviteUser: (user: TAdminUser, role: TAdminUserRole) => void
) => {
  const options: MenuProps["items"] = [];

  if (!item.isDeleted) {
    options.push({
      label: "Edit Permissions",
      key: "0",
      onClick: () => {
        handleOpenInviteVendorModal();
        handleUserChange(item);
      }
    });
  }

  if (item.invitationStatus === "pending" && !item.isDeleted) {
    options.push({
      label: `Re-Invite ${userType}`,
      key: "1",
      onClick: () => {
        handleReInviteUser(item, userType.toLowerCase() as TAdminUserRole);
      }
    });
  }

  options.push({
    label: `${item.isDeleted ? "Enable" : "Disable"} ${userType}`,
    key: "2",
    onClick: () => {
      if (item.isDeleted) {
        handleReInviteUser(item, userType.toLowerCase() as TAdminUserRole);
      } else {
        handleShowDeleteModal(item);
      }
    }
  });
  return options;
};

export const getAssignedProperties = (properties: TProperties[]) => {
  return (
    properties?.map((p: TProperties) => {
      return { label: p.name, value: p.name, id: p.id };
    }) || []
  );
};

export const getAssignedGLCodes = (glCodes: TGlCode[]): TFormGlCode[] => {
  return (
    glCodes?.map((g) => {
      return { label: generateGlCodeLabel(g.name, g.reference), value: g.id, id: g.id };
    }) || []
  );
};

export const getAssignedGLCodesWithCategories = (
  glCodes: any,
  glCategories: any
): TFormGlCode[] => {
  return [
    ...(glCategories?.map((g: any) => {
      return { label: g.name, value: g.Id, id: "glGroup" };
    }) || []),
    ...glCodes
  ];
};
