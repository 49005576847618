import { FC, useState } from "react";
import {
  Col,
  Input,
  InputNumber,
  Row,
  Table,
  Select,
  Switch,
  Form,
  Flex,
  Dropdown,
  Tooltip
} from "antd";
import { useAntToken } from "@hooks/common/useAntToken";
import { reviewInvoiceStyles } from "./styles";
import { getInvoiceLineItemColumns, getMoreOptions, previousValueTooltip } from "./utils";
import { MoreOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import { INVOICE_APPROVED, INVOICE_PENDING, INVOICE_REJECTED } from "@utils/constants";
import InvoiceLineItemStatusIcon from "./InvoiceLineItemStatusIcon";
import RejectLineItemModal from "./RejectLineItemModal";
import { commonAntStyles } from "@Component/Common/styles";
import { ILineItem, TAlternativeGlCode, TGlCode, TUnit } from "@Component/Common/types";
import { generateGlCodeLabel } from "@Component/Common/utils";

interface ILineItemsTableProps {
  preLineItems?: Array<any>;
  lineItems: ILineItem[];
  glCodes?: TGlCode[];
  handleLineItemDelete?: (value: number) => void;
  editGLCodes?: boolean;
  handleEditLineItem?: (
    lineItemId: string,
    lineItemChangeKey: string,
    lineItemValue: unknown
  ) => void;
  readonlyView?: boolean;
  rejectedInvoiceView?: boolean;
  loading?: boolean;
  propertyManagerView?: boolean;
  units: Array<{ id: number; name: string }>;
  propertyType: string;
  invoiceApprovalStatus: string;
  isRegionalManager?: boolean;
}

const LineItemsTable: FC<ILineItemsTableProps> = (props) => {
  const { styles } = reviewInvoiceStyles();
  const { styles: commonStyles } = commonAntStyles();
  const { token } = useAntToken();
  const {
    preLineItems,
    lineItems,
    handleLineItemDelete,
    handleEditLineItem,
    editGLCodes,
    readonlyView,
    rejectedInvoiceView,
    propertyManagerView,
    loading,
    units,
    propertyType,
    invoiceApprovalStatus,
    glCodes,
    isRegionalManager
  } = props;

  const [isRejectionModalOpen, setIsRejectionModalOpen] = useState<boolean>(false);
  const [selectedLineItemForRejectionId, setSelectedLineItemForRejectionId] = useState<string>("");
  const keyArr = ["description", "isTaxable", "amount"];

  const handleRejectionModalHide = () => {
    setSelectedLineItemForRejectionId("");
    setIsRejectionModalOpen(false);
  };

  const handleRejectionModalOpen = (lineItemShortId: string) => {
    setSelectedLineItemForRejectionId(lineItemShortId);
    setIsRejectionModalOpen(true);
  };

  const commonFormItemStyes = {
    border: `1px solid ${token.colorBorder}`,
    height: token.controlHeightLG,
    paddingTop: token.paddingXXS
  };

  const commonInputStyles = {
    border: 0,
    borderRadius: 0,
    backgroundColor: "transparent",
    height: "inherit"
  };

  const getGlCodeSelectOptions = (glCodes: TAlternativeGlCode[], color: string) => {
    return glCodes
      ?.sort((a, b) =>
        a.glCode.reference > b.glCode.reference
          ? 1
          : b.glCode.reference > a.glCode.reference
          ? -1
          : 0
      )
      ?.filter((g: TAlternativeGlCode) => g.colorCode === color)
      ?.map((g: TAlternativeGlCode, index) => {
        const color =
          g.colorCode === "green"
            ? "green"
            : g.colorCode === "yellow"
            ? "orange"
            : token.colorTextSecondary;
        return (
          <Select.Option
            key={`${g.glCode.id}${index}`}
            value={g.glCode.id}
            label={generateGlCodeLabel(g.glCode.name, g.glCode.reference)}>
            <div style={{ color }}>{generateGlCodeLabel(g.glCode.name, g.glCode.reference)}</div>
          </Select.Option>
        );
      });
  };

  const GlCodeSelectDropdown = (props: { d: ILineItem; isEditingGlCodesDisabled?: boolean }) => {
    const { d, isEditingGlCodesDisabled } = props;
    return (
      <>
        {d.alternativeGlCodes ? (
          <Select
            style={{ width: "80%" }}
            showSearch
            value={generateGlCodeLabel(d.gl.name, d.gl.reference)}
            className={styles["ant-select-override"]}
            disabled={isEditingGlCodesDisabled}
            filterOption={(input, option) => {
              if (input.replace(/\s/g, "").length > 0) {
                return String(option?.label)
                  ?.toLowerCase()
                  .includes(input.toLowerCase());
              }
              return true;
            }}
            onChange={(value) => {
              const filteredGlCodes =
                d.alternativeGlCodes?.filter(
                  (g: TAlternativeGlCode) => g.glCode.id === Number(value)
                ) || [];
              handleEditLineItem?.(d.lineItemShortId, "gl", filteredGlCodes[0]?.glCode);
            }}>
            {[
              ...getGlCodeSelectOptions(d.alternativeGlCodes, "green"),
              ...getGlCodeSelectOptions(d.alternativeGlCodes, "yellow"),
              ...getGlCodeSelectOptions(d.alternativeGlCodes, "grey")
            ]}
          </Select>
        ) : (
          <Select
            style={{ width: "80%" }}
            showSearch
            value={generateGlCodeLabel(d.gl.name, d.gl.reference)}
            className={styles["ant-select-override"]}
            disabled={isEditingGlCodesDisabled}
            filterOption={(input, option) => {
              if (input.replace(/\s/g, "").length > 0) {
                return String(option?.label)
                  ?.toLowerCase()
                  .includes(input.toLowerCase());
              }
              return true;
            }}
            onChange={(value) => {
              const filteredGlCodes = glCodes?.filter((g) => g.id === Number(value)) || [];
              handleEditLineItem?.(d.lineItemShortId, "gl", filteredGlCodes[0]);
            }}>
            {glCodes
              ?.sort((a, b) => (a.reference > b.reference ? 1 : b.reference > a.reference ? -1 : 0))
              .map((g, index) => {
                const color = token.colorTextSecondary;
                return (
                  <Select.Option
                    key={`${g.id}${index}`}
                    value={g.id}
                    label={generateGlCodeLabel(g.name, g.reference)}>
                    <div style={{ color: color }}>{generateGlCodeLabel(g.name, g.reference)}</div>
                  </Select.Option>
                );
              })}
          </Select>
        )}
      </>
    );
  };

  return (
    <>
      <Table
        scroll={{ x: "hidden" }}
        virtual={!readonlyView}
        loading={loading}
        rowClassName={(record: any): any => {
          const style = [];
          // style.push(styles["default-line-item"]);
          const previous = preLineItems?.filter((line: any) => line?.lineId === record.lineId)[0];
          if (previous && Object.keys(previous).some((key) => keyArr.includes(key))) {
            style.push(styles["changed-line-item"]);
          }
          if (preLineItems && preLineItems.length > 0 && !previous) {
            style.push(styles["changed-line-item"]);
          }
          if (record.approvalStatus === INVOICE_REJECTED) {
            style.push(styles["table-row-rejected"]);
          }
          if (record.approvalStatus === INVOICE_APPROVED) {
            style.push(styles["table-row-approved"]);
          }
          return style;
        }}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any

        dataSource={lineItems.map((d: any) => {
          let isChange = false;
          const previous = preLineItems?.filter((line: any) => line?.lineId === d.lineId)[0];

          if (previous && Object.keys(previous).some((key) => keyArr.includes(key))) {
            isChange = true;
          }
          if (preLineItems && preLineItems.length > 0 && !previous) {
            isChange = true;
          }
          const isEditingGlCodesDisabled = propertyManagerView
            ? invoiceApprovalStatus !== INVOICE_PENDING
            : !editGLCodes || readonlyView;
          d.glCode = (
            <Form.Item
              style={{
                ...commonFormItemStyes,
                height: 50,
                paddingTop: token.paddingXS,
                marginTop: isChange ? 22 : 0
              }}>
              <Flex align="center" justify="center">
                <GlCodeSelectDropdown d={d} isEditingGlCodesDisabled={isEditingGlCodesDisabled} />
              </Flex>
            </Form.Item>
          );
          d.lineItemTaxable = (
            <Form.Item style={{ ...commonFormItemStyes, marginTop: isChange ? 22 : 0 }}>
              <Flex align="center" justify="center">
                <Switch
                  defaultValue={d.taxable}
                  disabled={readonlyView || propertyManagerView}
                  value={d.taxable}
                  onChange={(checked) =>
                    handleEditLineItem?.(d.lineItemShortId, "taxable", checked)
                  }
                />
              </Flex>
            </Form.Item>
          );
          d.lineItemDescription = (
            <>
              {isChange && (
                <Tooltip
                  overlayClassName={commonStyles["tooltip-inner"]}
                  title={`Previous Values:\n ${previousValueTooltip(previous)}`}>
                  <ExclamationCircleOutlined />
                </Tooltip>
              )}

              <Form.Item className={commonStyles["ant-input-override"]}>
                <TextArea
                  disabled={isEditingGlCodesDisabled}
                  placeholder={"Description"}
                  defaultValue={d.description}
                  style={{
                    borderRadius: 0,
                    backgroundColor: "transparent",
                    borderColor: token.colorBorderSecondary,
                    height: 70,
                    resize: "none",
                    paddingLeft: token.paddingMD
                  }}
                  value={d.description}
                  name="description"
                  onChange={(e) =>
                    handleEditLineItem?.(d.lineItemShortId, "description", e.target.value)
                  }
                />
              </Form.Item>
            </>
          );
          d.lineItemPostTaxAmount = (
            <Form.Item style={{ ...commonFormItemStyes, marginTop: isChange ? 22 : 0 }}>
              <Input
                disabled={true}
                defaultValue={d.postTaxAmount}
                style={{ ...commonInputStyles, textAlign: "right" }}
                value={Number(d.postTaxAmount)
                  ?.toFixed(2)
                  ?.toString()
                  ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                name="postTaxAmount"
                onChange={(e) => {
                  if (isNaN(Number(e.target.value))) {
                    return;
                  }
                  handleEditLineItem?.(d.lineItemShortId, "postTaxAmount", e.target.value);
                }}
              />
            </Form.Item>
          );
          d.lineItemPreTaxAmount = (
            <Form.Item
              style={{ ...commonFormItemStyes, marginTop: isChange ? 22 : 0, textAlign: "right" }}
              className={commonStyles["ant-input-override"]}>
              <InputNumber
                controls={false}
                disabled={readonlyView || propertyManagerView}
                precision={2}
                style={commonInputStyles}
                value={
                  Number(d.pricePerUnit)
                    ?.toFixed(2)
                    ?.toString()
                    ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0
                }
                name="pricePerUnit"
                onChange={(value) => {
                  if (isNaN(Number(value?.toString()?.replace(".", "")))) {
                    return;
                  }
                  handleEditLineItem?.(d.lineItemShortId, "pricePerUnit", value?.toString());
                }}
              />
            </Form.Item>
          );
          d.lineItemUnit = (
            <Form.Item
              style={{
                ...commonFormItemStyes,
                height: 50,
                paddingTop: token.paddingXS,
                marginTop: isChange ? 22 : 0
              }}>
              <Flex align="center" justify="center">
                <Select
                  style={{ width: "80%" }}
                  showSearch
                  optionFilterProp="label"
                  defaultValue={"None"}
                  value={d?.unit?.id}
                  className={styles["ant-select-override"]}
                  disabled={isEditingGlCodesDisabled}
                  onChange={(value) => handleEditLineItem?.(d.lineItemShortId, "unit", value)}
                  options={units?.map((u: TUnit) => {
                    return {
                      value: u.id,
                      label: u.name
                    };
                  })}
                />
              </Flex>
            </Form.Item>
          );
          d.lineItemStatus = (readonlyView || propertyManagerView) && (
            <Flex align="center" justify="center" style={{ marginTop: isChange ? 22 : 0 }}>
              <InvoiceLineItemStatusIcon
                approvalStatus={d.approvalStatus}
                tooltipTitle={d.rejectionReason}
                propertyManagerView={propertyManagerView}
              />
            </Flex>
          );
          d.lineItemRejectionReason = (
            <Flex align="center" justify="center" style={{ marginTop: isChange ? 22 : 0 }}>
              <InvoiceLineItemStatusIcon
                approvalStatus={d.approvalStatus}
                tooltipTitle={d.rejectionReason}
                propertyManagerView={propertyManagerView}
              />
            </Flex>
          );
          d.actions =
            propertyManagerView && !isRegionalManager ? (
              <Flex
                align="center"
                justify="center"
                style={{ height: "45%", marginTop: isChange ? 22 : 0 }}>
                <Col
                  style={{
                    marginLeft: "auto",
                    marginRight: token.marginLG,
                    cursor: "pointer",
                    width: "28px",
                    border: `1px solid ${token.colorBorder}`,
                    boxShadow: token.boxShadow,
                    borderRadius: token.borderRadiusSM,
                    position: "absolute",
                    right: 0
                  }}>
                  <Row align="middle" justify="center">
                    <Col>
                      <Dropdown
                        menu={{
                          items: getMoreOptions({
                            handleEditLineItem,
                            lineItemShortId: d.lineItemShortId,
                            handleRejectionModalOpen,
                            handleLineItemDelete,
                            key: d.key,
                            isDeletable: false
                          })
                        }}
                        trigger={["click", "contextMenu"]}
                        placement="bottomRight">
                        <a
                          onClick={(e) => e.preventDefault()}
                          style={{
                            fontSize: token.fontSizeLG,
                            fontWeight: token.fontWeightStrong,
                            color: token.colorText
                          }}>
                          <MoreOutlined />
                        </a>
                      </Dropdown>
                    </Col>
                  </Row>
                </Col>
              </Flex>
            ) : rejectedInvoiceView && !isRegionalManager ? (
              <Flex
                align="center"
                justify="center"
                style={{ height: "45%", marginTop: isChange ? 22 : 0 }}>
                <Col
                  style={{
                    marginLeft: "auto",
                    marginRight: token.marginLG,
                    cursor: "pointer",
                    width: "28px",
                    border: `1px solid ${token.colorBorder}`,
                    boxShadow: token.boxShadow,
                    borderRadius: token.borderRadiusSM,
                    position: "absolute",
                    right: 0
                  }}>
                  <Row align="middle" justify="center">
                    <Col>
                      <Dropdown
                        menu={{
                          items: getMoreOptions({
                            handleEditLineItem,
                            lineItemShortId: d.lineItemShortId,
                            handleRejectionModalOpen,
                            handleLineItemDelete,
                            key: d.key,
                            isDeletable: true
                          })
                        }}
                        trigger={["click", "contextMenu"]}
                        placement="bottomRight">
                        <a
                          onClick={(e) => e.preventDefault()}
                          style={{
                            fontSize: token.fontSizeLG,
                            fontWeight: token.fontWeightStrong,
                            color: token.colorText
                          }}>
                          <MoreOutlined />
                        </a>
                      </Dropdown>
                    </Col>
                  </Row>
                </Col>
              </Flex>
            ) : !readonlyView && !isRegionalManager ? (
              <Flex
                align="center"
                justify="center"
                style={{ height: "45%", marginTop: isChange ? 22 : 0, marginLeft: 20 }}>
                <Col
                  style={{
                    marginLeft: "auto",
                    marginRight: token.marginLG,
                    cursor: "pointer",
                    width: "28px",
                    border: `1px solid ${token.colorBorder}`,
                    boxShadow: token.boxShadow,
                    borderRadius: token.borderRadiusSM,
                    position: "absolute",
                    right: 0
                  }}>
                  <Row align="middle" justify="center">
                    <Col>
                      <Dropdown
                        menu={{
                          items: getMoreOptions({
                            handleEditLineItem,
                            lineItemShortId: d.lineItemShortId,
                            handleRejectionModalOpen,
                            handleLineItemDelete,
                            key: d.key,
                            isDeletable: true
                          })
                        }}
                        trigger={["click", "contextMenu"]}
                        placement="bottomRight">
                        <a
                          onClick={(e) => e.preventDefault()}
                          style={{
                            fontSize: token.fontSizeLG,
                            fontWeight: token.fontWeightStrong,
                            color: token.colorText
                          }}>
                          <MoreOutlined />
                        </a>
                      </Dropdown>
                    </Col>
                  </Row>
                </Col>
              </Flex>
            ) : null;
          return d;
        })}
        columns={getInvoiceLineItemColumns(
          readonlyView as boolean,
          rejectedInvoiceView as boolean,
          propertyManagerView as boolean,
          propertyType as string
        )}
        pagination={false}
        className={styles["ant-table-overider"]}></Table>
      <RejectLineItemModal
        handleRejectionModalHide={handleRejectionModalHide}
        isRejectionModalOpen={isRejectionModalOpen}
        lineItemShortId={selectedLineItemForRejectionId}
        handleEditLineItem={handleEditLineItem}
      />
    </>
  );
};

export default LineItemsTable;
