import { useQuery } from "@tanstack/react-query";
import { fetchAllUsersToBeReInvited } from "@Services/UserService";
import { React_Query_Keys } from "@utils/constants";
import { TAdminUserRole } from "@Component/Admin/types";
import { useParams } from "react-router-dom";

export const useFetchAdminReInviteUsersList = () => {
  const { role } = useParams();
  return useQuery({
    queryKey: [React_Query_Keys.FETCH_RE_INVITE_USERS_LIST],
    queryFn: () => fetchAllUsersToBeReInvited(role as TAdminUserRole)
  });
};
