import { useDispatch } from "react-redux";
import { FC } from "react";
import { Layout, Col, Flex } from "antd";
import { useNavigate } from "react-router-dom";
import { BackIcon, LogoIcon, MobileLogoIcon, PowerOffIcon } from "@assets/icons/CustomIcons";
import { Logout } from "@redux/actions/Auth/Auth";
import { AppDispatch } from "@redux/reducers/rootReducer";
import { useBreakpoints } from "@hooks/common/useBreakPoints";
import { useAntToken } from "@hooks/common/useAntToken";
import CommonAppButton from "./CommonAppButton";
import { LogoutOutlined } from "@ant-design/icons";

const { Header } = Layout;
interface IAppHeaderProps {
  isLoggedIn?: boolean;
}
const AppHeader: FC<IAppHeaderProps> = (props) => {
  const { isLoggedIn = true } = props;
  const { token } = useAntToken();
  const navigate = useNavigate();
  //eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const fontWeightNormal = token.fontWeightNormal;
  const dispatch = useDispatch<AppDispatch>();
  const { isScreenXS } = useBreakpoints();

  const logOut = () => {
    dispatch(Logout());
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <Header
      style={{
        backgroundColor: token.colorBgContainer,
        height: 85,
        borderBottom: `1px solid ${token.colorBorder}`,
        padding: 0,
        justifyContent: "center",
        display: "flex"
      }}>
      <Col span={22}>
        <Flex
          justify="space-between"
          align="middle"
          style={{
            height: "100%"
            // paddingRight: isScreenXS ? 0 : 15,
            // paddingLeft: isScreenXS ? 0 : 15
          }}>
          <Col style={{ marginTop: token.marginXS }}>
            {isLoggedIn && (
              <span style={{ cursor: "pointer" }} onClick={goBack}>
                <BackIcon />
              </span>
            )}
          </Col>
          <Col>
            {isScreenXS ? (
              <MobileLogoIcon style={{ fontSize: 50, height: "100%" }} />
            ) : (
              <LogoIcon style={{ fontSize: 200, height: "100%" }} />
            )}
          </Col>
          <Col onClick={logOut} style={{ marginTop: token.marginXS }}>
            {isScreenXS ? (
              isLoggedIn && (
                <CommonAppButton
                  icon={<PowerOffIcon />}
                  style={{
                    height: token.controlHeight,
                    background: "none",
                    border: `1px solid ${token.colorBorder}`,
                    color: token.volcano5,
                    fontSize: token.fontSize,
                    fontWeight: fontWeightNormal,
                    boxShadow: token.boxShadow
                  }}></CommonAppButton>
              )
            ) : isLoggedIn ? (
              <CommonAppButton
                icon={<LogoutOutlined />}
                style={{
                  height: token.controlHeightLG,
                  border: `1px solid ${token.colorBorder}`,
                  backgroundColor: token.colorBgContainer,
                  color: token.colorText,
                  fontSize: token.fontSize,
                  fontWeight: fontWeightNormal,
                  boxShadow: token.boxShadow
                }}>
                Logout
              </CommonAppButton>
            ) : null}
          </Col>
        </Flex>
      </Col>
    </Header>
  );
};

export default AppHeader;
