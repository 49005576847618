import { useAntToken } from "@hooks/common/useAntToken";
import { Col, Row, Tag } from "antd";
import { FC } from "react";
import { TFormProperty } from "../types";

interface IPropertyTagsProps {
  properties: TFormProperty[];
  onDeleteTag: (tag: TFormProperty) => void;
  loading: boolean;
}

const PropertyTags: FC<IPropertyTagsProps> = (props) => {
  const { properties, onDeleteTag, loading } = props;
  const { token } = useAntToken();

  if (!properties?.length || loading) return null;
  return (
    <Row
      justify="start"
      align="top"
      style={{
        maxHeight: "150px",
        overflow: "auto"
      }}>
      {properties
        .filter((p: TFormProperty) => p.value !== "All")
        .map((p: TFormProperty, index) => {
          return (
            <Col key={p.value + index}>
              <Tag
                bordered
                onClose={() => {
                  onDeleteTag(p);
                }}
                closable
                color={token.colorPrimary}
                key={p.value}
                style={{
                  border: 0,
                  borderRadius: token.borderRadiusSM,
                  marginTop: token.marginXXS
                }}>
                {String(p.label).length > 28 ? `${String(p.label).substring(0, 28)}...` : p.label}
              </Tag>
            </Col>
          );
        })}
    </Row>
  );
};

export default PropertyTags;
