import { useMutation } from "@tanstack/react-query";
import { reInviteUsers } from "@Services/UserService";
import { React_Query_Keys } from "@utils/constants";
import { isAxiosError } from "axios";
import { QueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import ShowToast from "@utils/showToast";

const queryClient = new QueryClient();

const useAdminReInviteUser = () => {
  const navigate = useNavigate();
  return useMutation({
    mutationFn: reInviteUsers,
    mutationKey: [React_Query_Keys.RE_INVITE_USER_MUTATE],
    onError: (error: unknown) => {
      if (isAxiosError(error)) {
        ShowToast(error.response?.data.message, "error");
      }
    },
    onSuccess: () => {
      ShowToast("User re-invited", "success");
      queryClient.invalidateQueries({ queryKey: [React_Query_Keys.ADMIN_USERS] });
      navigate(0);
    }
  });
};

export default useAdminReInviteUser;
