import { Form, FormInstance } from "antd";
import { TFormGlCode, TGlCode } from "../types";
import { combineFetchedAndAssignedGlCodes } from "../utils";

interface IGlCodesStateProps {
  fetchedGlCodes: TGlCode[];
  fetchedGlGroup?: any;
  form: FormInstance;
}

const useGlCodesState = (props: IGlCodesStateProps) => {
  const { fetchedGlCodes, fetchedGlGroup, form } = props;
  const selectedGLCodes: TFormGlCode[] = Form.useWatch("assignedGLCodes", form) || [];
  const allGlCodes = combineFetchedAndAssignedGlCodes(fetchedGlCodes, selectedGLCodes);
  let allGlGroup: any = [];
  if (fetchedGlGroup?.length > 0) {
    allGlGroup = fetchedGlGroup.map((group: any) => {
      return { label: group.name, value: group.Id, id: "glGroup" };
    });
  }

  let filteredSelectedGLCodes: TFormGlCode[] = [];
  if (selectedGLCodes) {
    const selectedGLCodesIds = selectedGLCodes.map((glCode) => glCode.id);
    filteredSelectedGLCodes = allGlCodes.filter((p) => selectedGLCodesIds.includes(p.id));
  }

  const handleDeleteGlCodeTag = (tag: TFormGlCode) => {
    const formGLCodes: TFormGlCode[] = form.getFieldValue("assignedGLCodes");
    const filteredGLCodes = formGLCodes.filter((p) => p.value !== tag.value);
    filteredSelectedGLCodes = [...filteredGLCodes];
    form.setFieldsValue({
      assignedGLCodes: [...filteredGLCodes]
    });
  };

  const handleGLCodesChange = (value: readonly (number | string)[]) => {
    if (value.length === allGlCodes.length + 1) {
      form.setFieldValue("assignedGLCodes", []);
    } else if (value[selectedGLCodes.length] === -1 || value[0] === "All" || value[0] === -1) {
      form.setFieldValue("assignedGLCodes", allGlCodes);
    } else if (value.some((p: number | string) => p === -1 || p === "All")) {
      const filteredInputValue = value.filter((p: unknown) => {
        if (typeof p === "number") {
          return p !== -1;
        }
        return p !== "All";
      });
      const filteredValues = allGlCodes.filter((p) => filteredInputValue.includes(p.value));
      form.setFieldValue("assignedGLCodes", filteredValues);
    } else {
      const glCodeWithLabelAndValue = value.reduce(
        (acc: TFormGlCode[], current: number | string) => {
          const glCode = [...allGlCodes, ...allGlGroup].find(
            (p) => p.id === current || p.value === current
          );
          if (glCode) {
            acc.push(glCode);
          }
          return acc;
        },
        []
      );
      form.setFieldValue("assignedGLCodes", glCodeWithLabelAndValue);
    }
  };

  const handleDeselectGlCode = (e: string) => {
    if (e === "All") {
      form.setFieldValue("assignedGLCodes", []);
    }
  };

  return {
    selectedGLCodes,
    handleDeleteGlCodeTag,
    handleGLCodesChange,
    filteredSelectedGLCodes,
    handleDeselectGlCode
  };
};

export default useGlCodesState;
