import { Button } from "antd";
import { FC } from "react";

interface ICommonAppButtonProps {
  style?: React.CSSProperties;
  text?: string;
  onClick?: () => void;
  disabled?: boolean;
  loading?: boolean;
  type?: "primary" | "default" | "dashed" | "link" | "text";
  size?: "small" | "middle" | "large";
  icon?: React.ReactNode;
  block?: boolean;
  children?: React.ReactNode;
  htmlType?: "submit" | "button" | "reset";
}
const CommonAppButton: FC<ICommonAppButtonProps> = (props) => {
  const {
    style,
    text = "",
    disabled = false,
    loading = false,
    type = "primary",
    size = "middle",
    icon = null,
    block = false,
    children = "",
    onClick,
    htmlType = "button"
  } = props;
  const title = text || children;
  return (
    <Button
      type={type}
      htmlType={htmlType}
      block={block}
      icon={icon}
      disabled={disabled}
      loading={loading}
      size={size}
      onClick={onClick}
      style={{ borderRadius: 0, ...style }}>
      {title}
    </Button>
  );
};

export default CommonAppButton;
