import { createStyles } from "antd-style";

export const adminDashboardStyles = createStyles(({ css }) => {
  return {
    "ant-select-over-ride": {
      ".ant-select-selector": css`
        max-height: 120px !important;
        overflow: scroll !important;
      `
    }
  };
});
