import { useQuery } from "@tanstack/react-query";
import { React_Query_Keys } from "@utils/constants";
import { fetchAllAdminProperties } from "@Services/VendorService";

const useFetchAllProperties = () => {
  // CHANGE THIS TO FETCH ALL PROPERTIES BY USING DIFFERENT ENDPOINT FOR ADMIN
  return useQuery({
    queryKey: [React_Query_Keys.ALL_PROPERTIES],
    queryFn: () => fetchAllAdminProperties()
  });
};

export default useFetchAllProperties;
