import { Form, Input, Row, Col, theme, Typography, Flex } from "antd";
import { FC } from "react";
import { Link } from "react-router-dom";
import CommonAppButton from "@Component/Common/CommonAppButton";
import CommonFormLabel from "@Component/Common/CommonFormLabel";
import { LeftOutlined } from "@ant-design/icons";
import { commonAntStyles } from "@Component/Common/styles";
import { LogoIcon } from "@assets/icons/CustomIcons";
import { useBreakpoints } from "@hooks/common/useBreakPoints";
const { useToken } = theme;
interface INewPassword {
  email: string;
  loading: boolean;
}

interface Props {
  onFinish: (values: INewPassword) => void;
  loading: boolean;
}

const NewPassword: FC<Props> = (props) => {
  const { token } = useToken();
  //eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const fontWeightNormal = token.fontWeightNormal;
  const { loading, onFinish } = props;
  const { isScreenXS } = useBreakpoints();
  const { styles: commonStyles } = commonAntStyles();

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
  };

  const { Title } = Typography;
  const commonInputStyles = {
    height: token.controlHeightLG,
    backgroundColor: token.colorBgBase,
    borderRadius: 0,
    border: `1px solid ${token.colorBorder}`
  };
  return (
    <Flex justify="center" align="center" vertical>
      <Row
        style={{ marginLeft: "12px", marginRight: "12px", maxWidth: "460px", marginBottom: 100 }}
        justify={"center"}>
        <Col>
          <LogoIcon style={{ fontSize: isScreenXS ? 130 : 230 }} />
        </Col>
        <Col
          span={24}
          style={{
            backgroundColor: token.colorBgBase,
            padding: token.paddingXL,
            paddingTop: token.paddingXS,
            paddingBottom: token.paddingXS,
            borderRadius: token.borderRadiusLG
          }}>
          <Row justify="start">
            <Col>
              <Title
                level={2}
                style={{
                  fontSize: 32,
                  fontWeight: token.fontWeightStrong,
                  color: token.colorText,
                  marginBottom: 0
                }}>
                Forgot Password?
              </Title>
            </Col>
            <Col>
              <Title
                level={4}
                style={{
                  fontSize: token.fontSizeHeading5,
                  fontWeight: fontWeightNormal,
                  color: token.colorTextLabel,
                  marginTop: 0,
                  marginBottom: token.marginLG
                }}>
                Enter your Email to change password
              </Title>
            </Col>
          </Row>
          <Col span={24}>
            <Form {...layout} onFinish={onFinish} style={{ paddingBottom: 0 }}>
              <Row style={{ marginTop: token.marginLG, paddingBottom: 0 }} justify="center">
                <Col span={24}>
                  <Form.Item
                    className={commonStyles["ant-input-override"]}
                    label={<CommonFormLabel>Email</CommonFormLabel>}
                    name="email"
                    rules={[
                      {
                        type: "email",
                        required: true,
                        message: "Please Enter a Valid Email Address, e.g. name@domain.com"
                      }
                    ]}>
                    <Input
                      placeholder="Email ID"
                      name="email"
                      style={{
                        ...commonInputStyles,
                        height: token.controlHeightLG,
                        border: `1px solid ${token.colorBorder}`
                      }}
                    />
                  </Form.Item>
                  <Form.Item style={{ marginTop: token.marginXL }}>
                    <CommonAppButton
                      type="primary"
                      htmlType="submit"
                      block
                      loading={loading}
                      style={{
                        backgroundColor: token.colorPrimary,
                        height: isScreenXS ? token.controlHeightSM : token.controlHeightLG,
                        color: token.colorTextLightSolid,
                        fontSize: isScreenXS ? token.fontSizeSM : token.fontSizeLG,
                        boxShadow: token.boxShadow,
                        fontWeight: fontWeightNormal,
                        paddingBottom: isScreenXS ? token.paddingLG : token.paddingXXS
                      }}>
                      Submit
                    </CommonAppButton>
                  </Form.Item>
                </Col>
                <Col
                  span={24}
                  style={{
                    textAlign: "center",
                    color: token.colorPrimary,
                    height: 40
                  }}>
                  <Link
                    to="/auth/login"
                    style={{
                      fontSize: token.fontSize,
                      paddingBottom: token.paddingXS,
                      fontWeight: 600,
                      color: token.colorPrimary
                    }}>
                    <LeftOutlined /> &nbsp;&nbsp;&nbsp;Back to Sign In
                  </Link>
                </Col>
              </Row>
            </Form>
          </Col>
        </Col>
      </Row>
    </Flex>
  );
};

export default NewPassword;
