import { fetchAllVendorInvoices } from "../../../Services/VendorService";
import { fetchAllUserInvoices, fetchRegionalManagerInvoices } from "../../../Services/UserService";
import { AppDispatch } from "../../reducers/rootReducer";
import {
  REQUEST_TO_GET_ALL_INVOICES,
  RECEIVE_ALL_INVOICES,
  GETTING_ERROR_IN_GET_ALL_INVOICES,
  SET_CURRENT_PAGE,
  RESET_INVOICE_LIST,
  SET_PAYMENT_STATUS,
  SET_APPROVAL_STATUS,
  INVOICE_LIST_WITH_INITIAL_FILTERS
} from "../../types/Invoice/InvoiceList";
import { INVOICE_PENDING, INVOICE_UNDER_REVIEW } from "../../../utils/constants";

export const requestToGetAllInvoices = () => {
  return {
    type: REQUEST_TO_GET_ALL_INVOICES
  };
};

export const receiveAllInvoices = (invoices: unknown) => {
  return {
    type: RECEIVE_ALL_INVOICES,
    payload: invoices
  };
};

export const failedToGetAllInvoices = (errorMessage: unknown) => {
  return {
    type: GETTING_ERROR_IN_GET_ALL_INVOICES,
    payload: errorMessage
  };
};

export const setPaymentStatus = (paymentStatus: string[]) => {
  return {
    type: SET_PAYMENT_STATUS,
    payload: paymentStatus
  };
};

export const setApprovalStatus = (approvalStatus: string[]) => {
  return {
    type: SET_APPROVAL_STATUS,
    payload: approvalStatus
  };
};

export const setCurrentPage = (pageNum: number, pageSize: number) => {
  return {
    type: SET_CURRENT_PAGE,
    payload: { pageNum, pageSize }
  };
};

export const invoiceListWithInitialFilters = () => {
  return {
    type: INVOICE_LIST_WITH_INITIAL_FILTERS
  };
};

export const resetInvoiceList = () => {
  return {
    type: RESET_INVOICE_LIST
  };
};

export const loadAllInvoices = (
  pageNumber = 1,
  perPage = 10,
  paymentStatus = "",
  approvalStatus = INVOICE_PENDING
) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(requestToGetAllInvoices());
      const response = await fetchAllVendorInvoices(
        pageNumber,
        perPage,
        paymentStatus,
        approvalStatus
      );
      const { pageNum, pageSize, totalPages, data } = response.data;
      dispatch(receiveAllInvoices({ pageNum, pageSize, totalPages, invoices: data }));
    } catch (err) {
      dispatch(failedToGetAllInvoices(err));
    }
  };
};

export const loadAllUserInvoices = (
  pageNumber = 1,
  perPage = 10,
  paymentStatus = "",
  approvalStatus = INVOICE_PENDING
) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(requestToGetAllInvoices());
      const response = await fetchAllUserInvoices(
        pageNumber,
        perPage,
        paymentStatus,
        approvalStatus
      );
      const { pageNum, pageSize, totalPages, data } = response.data;
      dispatch(receiveAllInvoices({ pageNum, pageSize, totalPages, invoices: data }));
    } catch (err) {
      dispatch(failedToGetAllInvoices(err));
    }
  };
};
export const loadAllRegionalManagerInvoices = (
  pageNumber = 1,
  perPage = 10,
  paymentStatus = "",
  approvalStatus = INVOICE_UNDER_REVIEW
) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(requestToGetAllInvoices());
      const response = await fetchRegionalManagerInvoices(
        pageNumber,
        perPage,
        paymentStatus,
        approvalStatus
      );
      const { pageNum, pageSize, totalPages, data } = response.data;
      dispatch(receiveAllInvoices({ pageNum, pageSize, totalPages, invoices: data }));
    } catch (err) {
      dispatch(failedToGetAllInvoices(err));
    }
  };
};
