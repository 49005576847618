export const columns = [
  {
    title: <p style={{ fontSize: 9, fontWeight: 600 }}>Description</p>,
    dataIndex: "lineItemDescription",
    key: "lineItemDescription",
    width: "30%"
  },
  {
    title: <p style={{ fontSize: 9, fontWeight: 600 }}>Taxable</p>,
    dataIndex: "lineItemTaxable",
    key: "lineItemTaxable",
    width: "10%"
  },
  {
    title: <p style={{ fontSize: 9, fontWeight: 600 }}>Pre Tax Amount</p>,
    dataIndex: "lineItemPreTaxAmount",
    key: "lineItemPreTaxAmount",
    width: "20%"
  },
  {
    title: <p style={{ fontSize: 9, fontWeight: 600 }}>Post Tax Amount</p>,
    dataIndex: "lineItemPostTaxAmount",
    key: "lineItemPostTaxAmount",
    width: "20%"
  },
  {
    title: <p style={{ fontSize: 9, fontWeight: 600 }}>GL codes</p>,
    dataIndex: "glCode",
    key: "glCode",
    width: "20%"
  }
];

export interface IInvoice {
  Id: string;
  invoiceNumber: string;
  companyName: string;
  address: string;
  amount: number;
  paymentStatus: string;
  approvalStatus: string;
  propertyName: string;
  propertyType: string;
  issueDate: string | null;
  isHomeDepot?: boolean;
  lineItems: Array<{
    description: string;
    pricePerUnit: number;
    gl: string;
    unit?: { id: number; name: string };
  }>;
  totalTaxAmount: number;
  persistedInvoiceFileId: string;
  propertyAddress: string;
  rmPropertyAddress?: string;
  vendorName: string;
}

export type TInvoiceInfo = {
  invoiceId: string;
  companyName: string;
  propertyName: string;
  propertyAddress: string;
  vendorName: string;
  propertyType: string;
  address: string;
  invoiceNumber: string;
  issueDate: string;
};
