import { FormInstance, MenuProps, Modal } from "antd";
import { Ref } from "react";
import type { ColumnsType } from "antd/es/table";
import { CloseCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { TInvoiceApprovalStatus } from "@utils/constants";
import { ILineItem, TGlCode, TUnit } from "@Component/Common/types";
import { IInvoiceProps } from "@hooks/Invoice/types";
const { confirm } = Modal;

export const getInvoiceLineItemColumns = (propertyType?: string): ColumnsType<ILineItem> => {
  const columns: ColumnsType<ILineItem> = [
    {
      title: "Description",
      dataIndex: "lineItemDescription",
      key: "lineItemDescription",
      width: "25%"
    },
    {
      title: "Taxable",
      dataIndex: "lineItemTaxable",
      key: "lineItemTaxable",
      width: "11%"
    },
    {
      title: "Pre Tax Amount",
      dataIndex: "lineItemPreTaxAmount",
      key: "lineItemPreTaxAmount",
      width: "12%"
    },
    {
      title: "Post Tax Amount",
      dataIndex: "lineItemPostTaxAmount",
      key: "lineItemPostTaxAmount",
      width: "12%"
    },
    {
      title: "GL Code",
      dataIndex: "glCode",
      key: "glCode",
      width: "25%"
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      width: "6%"
    }
  ];

  if (propertyType === "UnitProperty") {
    columns.splice(columns.length - 1, 0, {
      title: "Unit",
      dataIndex: "lineItemUnit",
      key: "lineItemUnit",
      width: "15%"
    });
  }

  return columns;
};

export const getMoreOptions = ({
  handleLineItemDelete,
  key
}: {
  handleEditLineItem?: (
    lineItemId: string,
    lineItemChangeKey: string,
    lineItemValue: unknown
  ) => void;
  lineItemShortId: string;
  handleLineItemDelete?: (value: number) => void;
  key: number;
  isDeletable?: boolean;
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const showConfirm = (onOk: any) => {
    confirm({
      title: `Are You Sure?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      cancelText: "No",
      onOk: onOk
    });
  };
  const moreOptions: MenuProps["items"] = [
    {
      label: "Delete",
      key: "0",
      icon: <CloseCircleOutlined />,
      onClick: () => {
        showConfirm(() => {
          if (handleLineItemDelete) {
            handleLineItemDelete(key);
          }
        });
      }
    }
  ];
  return moreOptions;
};

export interface IReviewInvoiceProps {
  homeDepotPropertyLoading: boolean;
  invoiceInfo: IInvoiceProps;
  displayIssuingCompanyForm?: boolean;
  setDisplayIssuingCompanyForm?: (value: boolean) => void;
  onIssuingCompanyInfoChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  displayIssueDateInput?: boolean;
  setDisplayIssueDateInput?: (value: boolean) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onIssueDateChange?: (date: any, dateString: any) => void;
  lineItems: ILineItem[];
  handleLineItemDelete?: (value: number) => void;
  displayAddItemModal?: boolean;
  setDisplayAddItemModal?: (value: boolean) => void;
  addItem?: (values: ILineItem) => void;
  glCodes?: TGlCode[];
  invoiceTotal?: number;
  loading: boolean;
  handleSubmit?: () => void;
  navigateToUploadInvoicePage?: () => void;
  formRef?: Ref<FormInstance<ILineItem>>;
  homeDepoProperties: Array<{
    id: number;
    name: string;
    unit: Array<{ id: number; name: string }>;
    addresses: Array<{
      City: string;
      PostalCode: string;
      State: string;
      Street: string;
      id: number;
    }>;
  }>;
  handleDisplayAddItemModal?: () => void;
  editGLCodes?: boolean;
  totalTaxAmount?: number;
  onTaxAmountChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  originalInvoiceUrl: string;
  selectedRowTaxable?: boolean;
  onSelectedRowTaxableChange?: (checked: boolean) => void;
  handleEditLineItem?: (
    lineItemId: string,
    lineItemChangeKey: string,
    lineItemValue: unknown
  ) => void;
  readonlyView?: boolean;
  rejectedInvoiceView?: boolean;
  propertyManagerView?: boolean;
  handleInvoiceApprovalStatusChange?: (status: TInvoiceApprovalStatus) => void;
  isInvoiceApprovalAllowed?: boolean;
  handleInvoiceRejection?: (rejectionReason?: string) => void;
  invoiceApprovalStatus?: TInvoiceApprovalStatus;
  invoiceRejectionReason?: string;
  units: TUnit[];
  handlePropertyChange: (value: string) => void;
  readOnly: boolean;
}

export type TDefaultSignDocumentValuesObject = {
  vendorName?: string;
  vendorAddress?: string;
  ownerName?: string;
  propertyLocation?: string;
  contractorName?: string;
  contractorAddress?: string;
  invoiceAmount?: string;
  invoiceUploadDate?: string;
  signatureName?: string;
  today?: number;
  currentMonth?: string;
  currentYear?: number;
  its?: string;
  vendorEmail?: string;
};
