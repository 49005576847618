import { useAntToken } from "@hooks/common/useAntToken";
import { Col, Row, Tag } from "antd";
import { FC } from "react";

interface IUserTagsProps {
  users: Array<{ label: string; value: string }>;
  onDeleteTag: (value: string) => void;
}

const UserTags: FC<IUserTagsProps> = (props) => {
  const { users, onDeleteTag } = props;
  const { token } = useAntToken();

  if (!users?.length) return null;
  return (
    <Row
      justify="start"
      align="top"
      style={{
        flexWrap: "wrap",
        marginTop: token.marginSM,
        maxHeight: "300px",
        overflow: "auto",
        alignContent: "flex-start"
      }}>
      {users.map((p: { label: string; value: string }, index) => {
        return (
          <Col key={p.value + index}>
            <Tag
              bordered
              onClose={() => {
                onDeleteTag(p.value);
              }}
              closable
              color={token.colorPrimary}
              key={p.value}
              style={{ border: 0, borderRadius: token.borderRadiusSM, marginTop: token.marginXXS }}>
              {p.label}
            </Tag>
          </Col>
        );
      })}
    </Row>
  );
};

export default UserTags;
