import { Spin } from "antd";
import { FC } from "react";
interface ILoaderProps {
  isLoading: boolean;
}
const Loader: FC<ILoaderProps> = ({ isLoading }) => {
  if (!isLoading) return null;
  return <Spin size="large" fullscreen />;
};

export default Loader;
