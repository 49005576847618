import { FC } from "react";
import { Col, Row, Typography, Flex } from "antd";
import { useBreakpoints } from "@hooks/common/useBreakPoints";
import { useAntToken } from "@hooks/common/useAntToken";
import ReviewApprovalTag from "@Component/Common/ReviewInvoice/ApprovalTag";

interface IInvoiceContentHeaderProps {
  invoiceInfo: {
    companyName: string;
    propertyName: string;
    propertyAddress?: string;
    approvalStatus?: string;
    address: string;
    vendorName?: string;
    invoiceNumber: string;
    issueDate: string | null;
  };
  loading: boolean;
  propertyManagerView: boolean;
}

const InvoiceContentHeader: FC<IInvoiceContentHeaderProps> = (props) => {
  const { invoiceInfo, loading } = props;
  const { isScreenXS } = useBreakpoints();
  const { token } = useAntToken();

  if (loading) {
    return null;
  }
  return (
    <Flex
      vertical
      style={{
        paddingLeft: isScreenXS ? token.paddingXS : token.paddingXL,
        paddingRight: isScreenXS ? token.paddingXS : token.paddingXL
      }}>
      <Row justify={"space-between"}>
        {invoiceInfo.invoiceNumber && (
          <Col>
            <Typography.Title
              level={4}
              style={{
                fontSize: isScreenXS ? token.fontSize : token.fontSizeXL,
                fontWeight: token.fontWeightStrong,
                color: token.colorText
              }}>
              Invoice No: &nbsp;{invoiceInfo.invoiceNumber}
            </Typography.Title>
          </Col>
        )}
        {invoiceInfo.approvalStatus && (
          <Col>
            <Typography.Title
              level={4}
              style={{
                fontSize: isScreenXS ? token.fontSize : token.fontSizeXL,
                fontWeight: token.fontWeightStrong,
                color: token.colorText
              }}>
              Status: &nbsp;
              <ReviewApprovalTag approvalStatus={invoiceInfo.approvalStatus} />
            </Typography.Title>
          </Col>
        )}
      </Row>
      <Flex
        justify={"space-between"}
        vertical={isScreenXS}
        style={{
          background: token.colorPrimary,
          padding: token.paddingLG
        }}
        align={"middle"}>
        <Col>
          <Row>
            <Col>
              <Typography.Text
                style={{
                  fontSize: token.fontSizeSM,
                  color: token.colorTextLightSolid
                }}>
                Property
              </Typography.Text>
              <Typography.Title
                level={4}
                style={{
                  fontSize: token.fontSizeLG,
                  fontWeight: token.fontWeightStrong,
                  color: token.colorTextLightSolid,
                  marginTop: 0,
                  paddingTop: isScreenXS ? 5 : 0
                }}>
                {invoiceInfo.propertyName}
              </Typography.Title>
            </Col>
          </Row>
          <Row>
            <Col>
              <Typography.Text
                style={{
                  fontSize: token.fontSizeSM,
                  color: token.colorTextLightSolid
                }}>
                Property Address
              </Typography.Text>
              <Typography.Title
                level={4}
                style={{
                  fontSize: token.fontSizeLG,
                  fontWeight: token.fontWeightStrong,
                  color: token.colorTextLightSolid,
                  marginTop: 0,
                  paddingTop: isScreenXS ? 5 : 0
                }}>
                {invoiceInfo?.propertyAddress || ""}
              </Typography.Title>
            </Col>
          </Row>
        </Col>
        <Col style={{ marginTop: isScreenXS ? 12 : 0 }}>
          <Flex
            vertical
            align={isScreenXS ? "start" : "end"}
            style={{ marginTop: isScreenXS ? 15 : 0 }}>
            <Typography.Text
              style={{
                fontSize: token.fontSizeSM,
                color: token.colorTextLightSolid
              }}>
              Vendor Name
            </Typography.Text>
            <Col>
              <Row>
                <Col>
                  <Typography.Title
                    level={4}
                    style={{
                      fontSize: token.fontSizeLG,
                      fontWeight: token.fontWeightStrong,
                      color: token.colorTextLightSolid,
                      marginTop: 0,
                      paddingTop: isScreenXS ? 5 : 0
                    }}>
                    {invoiceInfo?.vendorName}
                  </Typography.Title>
                </Col>
              </Row>
            </Col>
            <Typography.Text
              style={{
                fontSize: token.fontSizeSM,
                color: token.colorTextLightSolid
              }}>
              Address
            </Typography.Text>
            <Col>
              <Row>
                <Col style={{ direction: isScreenXS ? "ltr" : "rtl" }}>
                  <Typography.Title
                    level={4}
                    style={{
                      fontSize: token.fontSizeLG,
                      fontWeight: token.fontWeightStrong,
                      color: token.colorTextLightSolid,
                      marginTop: 0,
                      paddingTop: isScreenXS ? 5 : 0
                    }}>
                    {invoiceInfo.address}
                  </Typography.Title>
                </Col>
              </Row>
            </Col>
          </Flex>
        </Col>
      </Flex>
    </Flex>
  );
};

export default InvoiceContentHeader;
