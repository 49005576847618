import { useMutation } from "@tanstack/react-query";
import { deleteUser } from "@Services/UserService";
import { React_Query_Keys } from "@utils/constants";
import { isAxiosError } from "axios";
import { QueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import ShowToast from "@utils/showToast";

const queryClient = new QueryClient();
const useAdminDeleteUser = () => {
  const navigate = useNavigate();
  return useMutation({
    mutationFn: deleteUser,
    mutationKey: [React_Query_Keys.DELETE_USER_MUTATE],
    onError: (error: unknown) => {
      if (isAxiosError(error)) {
        ShowToast(error.response?.data.message, "error");
      }
    },
    onSuccess: () => {
      ShowToast("User disabled", "warning");
      queryClient.invalidateQueries({ queryKey: [React_Query_Keys.ADMIN_USERS] });
      navigate(0);
    }
  });
};

export default useAdminDeleteUser;
