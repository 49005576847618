import {
  INVOICE_APPROVED,
  INVOICE_CANCELLED,
  INVOICE_PENDING,
  INVOICE_REJECTED,
  INVOICE_UNDER_REVIEW
} from "@utils/constants";
import { MenuProps } from "antd";
import Checkbox, { CheckboxChangeEvent } from "antd/es/checkbox";

export const getPaymentStatusItems = (
  paymentStatus: string[],
  onPaymentStatusChange: (e: CheckboxChangeEvent) => void
) => {
  const paymentStatusItems: MenuProps["items"] = [
    {
      label: (
        <Checkbox
          checked={paymentStatus.includes("Unpaid")}
          name="Unpaid"
          onChange={onPaymentStatusChange}>
          Unpaid
        </Checkbox>
      ),
      key: "0"
    },
    {
      label: (
        <Checkbox
          checked={paymentStatus.includes("InProgress")}
          name="InProgress"
          onChange={onPaymentStatusChange}>
          In Progress
        </Checkbox>
      ),
      key: "1"
    },
    {
      label: (
        <Checkbox
          checked={paymentStatus.includes("Paid")}
          name="Paid"
          onChange={onPaymentStatusChange}>
          Paid
        </Checkbox>
      ),
      key: "2"
    }
  ];
  return paymentStatusItems;
};

export const getApprovalStatusItems = (
  approvalStatus: string[],
  onApprovalStatusChange: (e: CheckboxChangeEvent) => void,
  requiredCancelled = false,
  isRegionalManager = false
) => {
  const approvalStatusItems: MenuProps["items"] = [
    {
      label: (
        <Checkbox
          checked={approvalStatus.includes(INVOICE_PENDING)}
          name={INVOICE_PENDING}
          onChange={onApprovalStatusChange}>
          Pending
        </Checkbox>
      ),
      key: "0"
    },
    {
      label: (
        <Checkbox
          checked={approvalStatus.includes(INVOICE_APPROVED)}
          name={INVOICE_APPROVED}
          onChange={onApprovalStatusChange}>
          Approved
        </Checkbox>
      ),
      key: "1"
    },
    {
      label: (
        <Checkbox
          checked={approvalStatus.includes(INVOICE_REJECTED)}
          name={INVOICE_REJECTED}
          onChange={onApprovalStatusChange}>
          Rejected
        </Checkbox>
      ),
      key: "2"
    },
    {
      label: (
        <Checkbox
          disabled={isRegionalManager}
          checked={isRegionalManager || approvalStatus.includes(INVOICE_UNDER_REVIEW)}
          name={INVOICE_UNDER_REVIEW}
          onChange={onApprovalStatusChange}>
          {"RM-Review"}
        </Checkbox>
      ),
      key: "3"
    }
  ];
  if (requiredCancelled) {
    approvalStatusItems.push({
      label: (
        <Checkbox
          checked={approvalStatus.includes(INVOICE_CANCELLED)}
          name={INVOICE_CANCELLED}
          onChange={onApprovalStatusChange}>
          {"Cancelled"}
        </Checkbox>
      ),
      key: "4"
    });
  }
  return approvalStatusItems;
};

export const columns = [
  {
    title: "Description",
    dataIndex: "lineItemDescription",
    key: "lineItemDescription"
  },
  {
    title: "Price Per Unit",
    dataIndex: "lineItemPricePerUnit",
    key: "lineItemPricePerUnit"
  },
  {
    title: "GL codes",
    dataIndex: "glCode",
    key: "glCode"
  }
];

export const getMoreOptionsItems = (
  item: IInvoice,
  handleViewInvoice: (item: IInvoice) => void,
  handleDownloadInvoice: (item: IInvoice) => void,
  handleViewOriginalInvoice: (invoiceUrl: string) => void,
  handleCancelInvoice?: (invoiceId: string) => Promise<void>,
  isCancelAvailable = false
) => {
  const paymentStatusItems: MenuProps["items"] = [
    {
      label: "View Invoice",
      key: "0",
      onClick: () => {
        handleViewInvoice(item);
      }
    },
    {
      label: "Download Invoice",
      key: "1",
      onClick: () => {
        handleDownloadInvoice(item);
      }
    },
    {
      label: "View Original Invoice",
      key: "2",
      onClick: () => {
        handleViewOriginalInvoice(item.persistedInvoiceFileId);
      }
    }
  ];

  if (isCancelAvailable) {
    paymentStatusItems.push({
      label: "Cancel Invoice",
      key: "3",
      onClick: () => {
        if (handleCancelInvoice) {
          handleCancelInvoice(item.Id);
        }
      }
    });
  }

  return paymentStatusItems;
};

export interface IInvoice {
  Id: string;
  invoiceNumber: string;
  companyName: string;
  address: string;
  amount: number;
  vendorName: string;
  paymentStatus: string;
  approvalStatus: string;
  propertyName: string;
  propertyAddress: string;
  rmPropertyAddress?: string;
  propertyType: string;
  issueDate: string | null;
  lineItems: Array<{
    description: string;
    pricePerUnit: number;
    gl: string;
    unit?: { id: number; name: string };
  }>;
  totalTaxAmount: number;
  persistedInvoiceFileId: string;
  submitted?: string;
  rmAddress?: string;
  PO?: string;
}
