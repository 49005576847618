import { SelectProps } from "antd";
import { TFormGlCode, TFormProperty, TGlCode, TProperties } from "./types";

export const combineFetchedAndAssignedProperties = (
  fetchedProperties: TProperties[],
  assignedProperties: TFormProperty[]
): TFormProperty[] => {
  const filteredFromAssignedProperties = fetchedProperties
    .filter((fetchedProperty) => !assignedProperties.map((p) => p.id).includes(fetchedProperty.id))
    .map((p) => ({ label: p.name, value: p.name, id: p.id }));
  return [
    { label: "All", value: "All", id: "All" },
    ...assignedProperties,
    ...filteredFromAssignedProperties
  ];
};

export const getUniqueProperties = (
  properties: TProperties[],
  fetchedProperties: TProperties[]
) => {
  const propertiesNamesString = properties.map((p) => p.name).toString();
  return fetchedProperties.filter((property) => !propertiesNamesString.includes(property.name));
};
export const getPropertiesSelectProps = (
  properties: TProperties[],
  fetchedProperties: TProperties[],
  propertiesLoading: boolean
) => {
  const filteredFromAssignedProperties = getUniqueProperties(properties, fetchedProperties);

  const allProperties = [
    { id: "All", name: "All", value: "All" },
    ...properties,
    ...filteredFromAssignedProperties
  ].map((p: TProperties) => {
    return { label: p.name, value: p.name, id: p.id };
  });

  const propertiesSelectProps: SelectProps = {
    mode: "multiple",
    placeholder: "Select Properties",
    style: { width: "100%", height: 40 },
    loading: propertiesLoading,
    disabled: propertiesLoading,
    options: allProperties,
    filterOption: (input, option) => {
      if (input.replace(/\s/g, "").length > 0) {
        return String(option?.label)
          ?.toLowerCase()
          .includes(input.toLowerCase());
      }
      return true;
    },
    maxTagCount: 1,
    allowClear: true,
    size: "middle"
  };
  return propertiesSelectProps;
};

export const generateGlCodeLabel = (name: string, reference: string) =>
  name === "All" ? name : String(reference).trim() + " " + String(name).trim();

export const getUniqueGlCodes = (assignedGLCodes: TFormGlCode[], fetchedGlCodes: TGlCode[]) => {
  const glCodesIds = assignedGLCodes.map((p) => p.id);
  return fetchedGlCodes.filter((glCode) => !glCodesIds.includes(glCode.id));
};
export const combineFetchedAndAssignedGlCodes = (
  fetchedGlCodes: TGlCode[],
  assignedGLCodes: TFormGlCode[]
): TFormGlCode[] => {
  const filteredFromAssignedGlCodes = getUniqueGlCodes(assignedGLCodes, fetchedGlCodes);
  return [
    ...assignedGLCodes,
    ...filteredFromAssignedGlCodes.map((g: TGlCode) => ({
      label: generateGlCodeLabel(g.name, g.reference),
      value: g.id,
      id: g.id
    }))
  ] as TFormGlCode[];
};

export const getGlCodesSelectProps = (
  glCodes: TFormGlCode[],
  fetchedGlCodes: TGlCode[],
  glCodesLoading: boolean,
  glCodeGroups: any = []
) => {
  const filteredFromAssignedGlCodes = getUniqueGlCodes(glCodes, fetchedGlCodes);
  const allGlCodes = [
    ...glCodes,
    ...(filteredFromAssignedGlCodes.map(
      (g: TGlCode): TFormGlCode => ({
        label: generateGlCodeLabel(g.name, g.reference),
        value: g.id,
        id: g.id
      })
    ) as TFormGlCode[])
  ].sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));
  if (!glCodes && !glCodeGroups) return;
  let options: any =
    allGlCodes.length === 1 ? [] : [{ label: "All", id: -1, value: -1 }, ...allGlCodes];
  if (glCodeGroups?.length > 0) {
    options = [
      {
        label: <span>Gl Group</span>,
        name: "glGroup",
        options: glCodeGroups.map((group: any) => {
          return {
            label: group.name,
            value: group.Id
          };
        })
      },
      {
        label: <span>Gl Code</span>,
        name: "glCode",
        options:
          allGlCodes.length === 1
            ? []
            : [
                { label: "All", id: -1, value: -1 },
                ...allGlCodes.filter((glCode: any) => glCode.id != "glGroup")
              ]
      }
    ];
  }

  const glCodesSelectProps: SelectProps = {
    mode: "multiple",
    style: { width: "100%", height: 40 },
    placeholder: "Select Gl Codes",
    loading: glCodesLoading,
    maxTagCount: 1,
    options,
    filterOption: (input, option) => {
      if (input.replace(/\s/g, "").length > 0) {
        return String(option?.label)
          ?.toLowerCase()
          .includes(input.toLowerCase());
      }
      return true;
    },
    allowClear: true,
    size: "middle"
  };
  return glCodesSelectProps;
};

export const serialiazeGlCodes = (glCodes: TFormGlCode[]) =>
  glCodes
    .filter((glCode) => glCode.label !== "All")
    .map((glCode: TFormGlCode) => {
      const { label, value } = glCode;
      const reference = label.split(" ")[0];
      const name = label.split(" ").slice(1).join(" ");
      return { reference, name, id: value };
    });
