import { useQuery } from "@tanstack/react-query";
import { React_Query_Keys } from "@utils/constants";
import { fetchAllPropertiesByUserType } from "@Services/VendorService";

const useFetchAllPropertiesByRole = (userType: string) => {
  return useQuery({
    queryKey: [React_Query_Keys.ALL_PROPERTIES_BY_ROLE, userType],
    queryFn: () => fetchAllPropertiesByUserType(userType)
  });
};

export default useFetchAllPropertiesByRole;
