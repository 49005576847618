import { FC, useEffect, useState } from "react";
import { DocusealForm } from "@docuseal/react";
import { Flex } from "antd";
import { TDefaultSignDocumentValuesObject } from "./utils";
import { fetchSignDocumentTokenService } from "@Services/VendorService";

interface ILienWaiverFormProps {
  defaultValues?: TDefaultSignDocumentValuesObject;
  handleFormSignSuccess: (props: { submission_id: string }) => void;
}
const LienWaiverForm: FC<ILienWaiverFormProps> = (props) => {
  const { defaultValues: defaultValuesProps, handleFormSignSuccess } = props;
  const [slug, setSlug] = useState("");

  useEffect(() => {
    if (Object.keys(defaultValuesProps as object)?.length > 9) {
      const defaultValues = {
        vendorName: defaultValuesProps?.vendorName,
        vendorAddress: defaultValuesProps?.vendorAddress,
        ownerName: defaultValuesProps?.ownerName,
        propertyLocation: defaultValuesProps?.propertyLocation,
        contractorName: defaultValuesProps?.contractorName,
        contractorAddress: defaultValuesProps?.contractorAddress,
        invoiceAmount: defaultValuesProps?.invoiceAmount,
        invoiceUploadDate: defaultValuesProps?.invoiceUploadDate,
        today: defaultValuesProps?.today,
        currentMonth: defaultValuesProps?.currentMonth?.toString(),
        currentYear: defaultValuesProps?.currentYear?.toString(),
        signatureName: defaultValuesProps?.signatureName || "",
        its: defaultValuesProps?.its || ""
      };
      fetchSignDocumentTokenService({
        email: defaultValuesProps?.vendorEmail,
        values: defaultValues
      }).then((data) => {
        setSlug(data.slug);
      });
    }
  }, [defaultValuesProps]);

  return slug ? (
    <Flex justify="center" align="middle">
      <DocusealForm
        expand={true}
        src={`https://docuseal.co/s/${slug}` as string}
        onComplete={(props) => {
          handleFormSignSuccess(props);
        }}
      />
    </Flex>
  ) : null;
};

export default LienWaiverForm;
