import { FC } from "react";
import { Col, Row, Typography, Select, Divider, Form } from "antd";
import { useAntToken } from "@hooks/common/useAntToken";
import { TReinviteAdminUser } from "@Component/Admin/AdminViewUsers/types";
import { TAdminUserRole } from "@Component/Admin/types";
import { getUserSelectProps } from "./utils";
import CommonAppButton from "@Component/Common/CommonAppButton";
import Loader from "@Component/Common/Loader";
import { useForm } from "antd/es/form/Form";
import UserTags from "./UserTags";
import AdminCancelAndGoBack from "@Component/Common/AdminCancelAndGoBack";

interface IAdminReinviteUserProps {
  loading: boolean;
  handleSubmit: (values: { usersAuthIds: string[] }) => void;
  users: TReinviteAdminUser[];
  role: TAdminUserRole;
}

const AdminReinviteUser: FC<IAdminReinviteUserProps> = (props) => {
  const { token } = useAntToken();
  const { loading, handleSubmit, users, role } = props;
  const title = role === "vendor" ? "Re-invite Vendor" : "Re-invite Manager";
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const fontWeightNormal = token.fontWeightNormal;
  const usersOptionList: Array<{ label: string; value: string }> = [
    { label: "All", value: "all" },
    ...(users?.map((user) => {
      return { label: user.name, value: user.authUserId };
    }) || [])
  ];

  const userSelectProps = getUserSelectProps(loading, usersOptionList, role);
  const [form] = useForm();
  const selectedUsers = Form.useWatch("usersAuthIds", form) || [];
  let filteredSelectedUsers: Array<{ label: string; value: string }> = [];

  if (selectedUsers) {
    filteredSelectedUsers = usersOptionList.filter((p) =>
      selectedUsers.toString().includes(p.value.toString())
    );
  }
  const handleDeleteTag = (value: string) => {
    const formUsers = form.getFieldValue("usersAuthIds");
    const usersWithLabelAndValues = usersOptionList.filter((u) =>
      formUsers.toString().includes(u.value)
    );
    const filteredUsers = usersWithLabelAndValues.filter((p) => p.value !== value);
    filteredSelectedUsers = [...filteredUsers];
    form.setFieldsValue({
      usersAuthIds: [...filteredUsers.map((f) => f.value)]
    });
  };

  if (loading) {
    return <Loader isLoading={loading} />;
  }

  return (
    <Form onFinish={handleSubmit} form={form}>
      <Row justify="center">
        <Col
          xs={22}
          sm={20}
          md={16}
          xl={10}
          xxl={8}
          style={{
            background: token.colorBgBase,
            marginTop: token.marginXXL,
            marginBottom: token.marginXXL,
            borderRadius: token.borderRadius
          }}>
          <Row>
            <Col style={{ paddingLeft: token.paddingMD }}>
              <Typography.Title
                level={2}
                style={{
                  color: token.colorText,
                  fontSize: token.fontSizeHeading2,
                  fontWeight: token.fontWeightStrong
                }}>
                {title}
              </Typography.Title>
            </Col>
          </Row>
          <Row>
            <Col xs={24} style={{ paddingLeft: token.paddingMD, paddingRight: token.paddingMD }}>
              <Form.Item
                name="usersAuthIds"
                rules={[
                  {
                    required: true,
                    message: `Please select a ${role === "vendor" ? "Vendor" : "Manager"}`
                  }
                ]}
                style={{
                  width: "100%"
                }}>
                <Select
                  {...userSelectProps}
                  value={selectedUsers}
                  onDeselect={(e) => {
                    if (e === "all") {
                      form.setFieldValue("usersAuthIds", []);
                    } else {
                      form.setFieldValue(
                        "usersAuthIds",
                        selectedUsers.filter((p: any) => p !== e)
                      );
                    }
                  }}
                  onChange={(e) => {
                    if (e.length === 1 && e[0] === "all") {
                      form.setFieldValue(
                        "usersAuthIds",
                        usersOptionList.filter((p) => p.value !== "all").map((p) => p.value)
                      );
                    } else if (e.some((p: string) => p === "all")) {
                      if (e.length === selectedUsers.length + 1) {
                        form.setFieldValue("usersAuthIds", []);
                      } else {
                        form.setFieldValue(
                          "usersAuthIds",
                          usersOptionList.filter((p) => p.value !== "all").map((p) => p.value)
                        );
                      }
                    } else {
                      form.setFieldValue("usersAuthIds", e);
                    }
                  }}
                />
              </Form.Item>
              <UserTags users={filteredSelectedUsers} onDeleteTag={handleDeleteTag} />
            </Col>
          </Row>
          <Divider style={{ marginBottom: 12 }} />
          <Row>
            <Col
              xs={24}
              sm={24}
              md={24}
              style={{
                paddingLeft: token.paddingMD,
                paddingBottom: token.paddingMD,
                paddingRight: token.paddingMD
              }}>
              <Row justify={"space-between"}>
                <Col xs={24} sm={12} md={10} style={{ marginTop: 12 }}>
                  <CommonAppButton
                    type="primary"
                    block
                    loading={loading}
                    htmlType="submit"
                    style={{
                      height: token.controlHeightLG,
                      color: token.colorTextLightSolid,
                      fontSize: token.fontSizeLG,
                      boxShadow: token.boxShadow,
                      fontWeight: fontWeightNormal
                    }}>
                    Send Invite
                  </CommonAppButton>
                </Col>
                <Col xs={24} sm={12} md={10} style={{ marginTop: 12 }}>
                  <AdminCancelAndGoBack />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};
export default AdminReinviteUser;
