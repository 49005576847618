import { APP_BASE_URL, invoiceUploadUrl } from "../utils/urls";
import http from "../utils/httpService";
// eslint-disable-next-line
export const uploadInvoice = async (fileData: any) => {
  const formData = new FormData();
  for (const value in fileData) {
    formData.append(
      value,
      value !== "file" && typeof fileData[value] === "object"
        ? JSON.stringify(fileData[value])
        : fileData[value]
    );
  }
  const { data } = await http.post(`${APP_BASE_URL}${invoiceUploadUrl}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
  return { data };
};
