import { fetchInvoiceById } from "@Services/InvoiceService";
import { useQuery } from "@tanstack/react-query";
import { React_Query_Keys } from "@utils/constants";

export const useFetchInvoiceById = (invoiceId: string) => {
  return useQuery({
    queryKey: [React_Query_Keys.FETCH_INVOICE_BY_ID, invoiceId],
    queryFn: () => fetchInvoiceById(invoiceId),
    enabled: !!invoiceId
  });
};
