import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";

export const useBreakpoints = () => {
  const { lg, xs, sm, md, xl, xxl } = useBreakpoint();
  return {
    isScreenXS: xs,
    isScreenSM: sm,
    isScreenMD: md,
    isScreenLG: lg,
    isScreenXL: xl,
    isScreenXXL: xxl
  };
};
