import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import UpdatePassword from "../../Component/UpdatePassword";
import { setPassword } from "../../Services/PasswordService";
import ShowToast from "../../utils/showToast";
import { isAxiosError } from "axios";

interface IUpdatePassword {
  password: string;
  confirmPassword: string;
  token?: string | null;
}

const UpdatePasswordPage: React.FC = () => {
  const navigate = useNavigate();
  const { token } = useParams();

  const [loading, setLoading] = useState(false);

  const onFinish = async (values: IUpdatePassword) => {
    try {
      if (values.password === values.confirmPassword) {
        setLoading(true);
        values.token = token;
        await setPassword(values);
        setLoading(false);
        ShowToast("Password updated successfully", "success");
        navigate("/auth/login");
      } else {
        ShowToast("Password should match confirm password", "error");
      }
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        ShowToast(error.response?.data.message, "error");
      }
      setLoading(false);
    }
  };

  return <UpdatePassword loading={loading} onFinish={onFinish} />;
};
export default UpdatePasswordPage;
