import { Col, Row, Tag, Tooltip } from "antd";
import { FC } from "react";
import { useAntToken } from "@hooks/common/useAntToken";
import { TVendorPermissionsTags } from "./types";
import { useBreakpoints } from "@hooks/common/useBreakPoints";

interface IPermissionTagsProps {
  tags: TVendorPermissionsTags[];
  maxTagCount?: number;
}

const PermissionTags: FC<IPermissionTagsProps> = ({ tags, maxTagCount = 1 }) => {
  const { token } = useAntToken();
  const { isScreenXS } = useBreakpoints();
  if (!tags?.length) {
    return null;
  }
  const tagsToShow = tags.slice(0, maxTagCount);
  const remainingTags = tags.slice(maxTagCount);
  const remiainingTagsCount = tags.length - maxTagCount;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const fontWeightMedium = token.fontWeightMedium;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const fontSizeXS = token.fontSizeXS;
  const commontTagStyles = {
    backgroundColor: "#ede7fb",
    fontWeight: fontWeightMedium,
    padding: token.paddingXXS,
    paddingLeft: token.paddingSM,
    paddingRight: token.paddingSM,
    borderRadius: token.borderRadiusSM,
    fontSize: isScreenXS ? fontSizeXS : token.fontSizeSM
  };

  const getTagColor = (tagId: string) => {
    switch (tagId) {
      case "editGLCodes":
        return "#b4feb1";
      case "showPaymentStatus":
        return "#f9f9cd";
      case "showLegalForms":
        return "#f9c7c7";
      default:
        return "#ede7fb";
    }
  };

  return (
    <Row gutter={[5, 5]}>
      {tagsToShow.map((tag) => (
        <Tag
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            ...commontTagStyles,
            backgroundColor: getTagColor(tag.id)
          }}
          bordered={false}
          key={tag.id}>
          {tag.name}
        </Tag>
      ))}
      {remiainingTagsCount >= 1 && (
        <Tag key={remiainingTagsCount} style={commontTagStyles} bordered={false}>
          <Tooltip
            color={token.colorBgElevated}
            autoAdjustOverflow={true}
            placement="bottom"
            overlayInnerStyle={{
              height: remiainingTagsCount >= 10 ? "300px" : "auto",
              overflow: "auto",
              width: "fit-content"
            }}
            title={
              <Row gutter={[5, 5]}>
                {remainingTags.map((tag) => (
                  <Col key={tag.id}>
                    <Tag
                      style={{ ...commontTagStyles, backgroundColor: getTagColor(tag.id) }}
                      bordered={false}>
                      {tag.name}
                    </Tag>
                  </Col>
                ))}
              </Row>
            }>
            +{remiainingTagsCount}
          </Tooltip>
        </Tag>
      )}
    </Row>
  );
};

export default PermissionTags;
