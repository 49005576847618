import { FC, useState } from "react";
import { Row, Col, Dropdown, Typography, Table } from "antd";
import { useForm } from "antd/es/form/Form";
import { MoreOutlined } from "@ant-design/icons";
import CommonAppButton from "@Component/Common/CommonAppButton";
import ConfirmationModal from "./ConfirmationModal";
import AddGlGroupModal from "./AddGlGroupModal";
import { useAntToken } from "@hooks/common/useAntToken";
import { useBreakpoints } from "@hooks/common/useBreakPoints";
import { useGlGroupFetchAll, useGlGroupDelete } from "@hooks/Admin/GlGroup";
import useFetchAllGlCodes from "@hooks/Admin/useFetchAllGlCodes";
import EditGlGroupModal from "@Component/Admin/AdminSettings/EditGlGroupModal";
import PermissionTags from "@Component/Admin/AdminViewUsers/PermissionTags";

interface IGlGroupProps {
  loading: boolean;
}

const getColumns = (token: any, handleEditGroup: any, handleDeleteGroup: any) => {
  return [
    {
      title: "Name",
      dataIndex: "name",
      width: "14%",
      key: "name"
    },
    {
      title: "Gl Codes",
      key: "glCodes",
      width: "60%",
      dataIndex: "glCodes",
      render: (_: any, { glCodes }: any) => (
        <PermissionTags
          tags={glCodes.map((glCode: any) => {
            return {
              ...glCode,
              name: `${glCode.reference} ${glCode.name}`
            };
          })}
          maxTagCount={2}
        />
      )
    },
    {
      title: "Action",
      key: "action",
      width: "16%",
      render: (_: any, record: any) => (
        <Col
          style={{
            // marginLeft: 8,
            // marginRight: token.marginLG,
            marginTop: 6,
            cursor: "pointer",
            width: "28px",
            height: "28px",
            border: `1px solid ${token.colorBorder}`,
            boxShadow: token.boxShadow,
            borderRadius: token.borderRadiusSM,
            backgroundColor: "#fff",
            alignSelf: "flex-start",
            justifyContent: "center"
          }}>
          <Row justify="center">
            <Col>
              <Dropdown
                menu={{
                  items: [
                    {
                      label: "Edit Group",
                      key: "0",
                      onClick: () => {
                        handleEditGroup(record);
                      }
                    },
                    {
                      label: "Delete Group",
                      key: "1",
                      onClick: () => {
                        handleDeleteGroup(record);
                      }
                    }
                  ]
                }}
                trigger={["click", "contextMenu"]}
                placement="bottomRight">
                <a
                  onClick={(e) => e.preventDefault()}
                  style={{
                    fontSize: token.fontSizeLG,
                    fontWeight: token.fontWeightStrong,
                    color: token.colorText
                  }}>
                  <MoreOutlined />
                </a>
              </Dropdown>
            </Col>
          </Row>
        </Col>
      )
    }
  ];
};

const GlGroup: FC<IGlGroupProps> = (props) => {
  const { loading } = props;
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedLine, setSelectedLine] = useState<any>(null);
  const { isScreenXS } = useBreakpoints();
  const { token } = useAntToken();
  const [form] = useForm();
  const { data: fetchedGlCodesData } = useFetchAllGlCodes();
  const { data: glCodeGroupFetch, refetch, isLoading: glCodeGroupLoading } = useGlGroupFetchAll();
  const { mutate: deleteGlGroup, isPending: deleteIsPending } = useGlGroupDelete();

  const handleEditGroupButton = (item: any) => {
    setSelectedLine(item);
    setOpenEditModal(true);
  };
  const handleDeleteGroupButton = (item: any) => {
    setSelectedLine(item);
    setOpenDeleteModal(true);
  };

  const handleDeleteGroup = (key: string) => {
    if (key === "delete") {
      deleteGlGroup(selectedLine.Id);
      refetch().then(() => {
        setOpenDeleteModal(false);
        setSelectedLine(null);
      });
    } else {
      setOpenDeleteModal(false);
    }
  };

  const handleAddGroupButton = () => {
    setOpenAddModal(true);
  };

  return (
    <Col>
      <EditGlGroupModal
        selectedGroup={selectedLine}
        form={form}
        visible={openEditModal}
        glCodes={fetchedGlCodesData}
        handleEditGroup={() => setOpenEditModal(false)}
        handleCancel={() => setOpenEditModal(false)}
      />
      <AddGlGroupModal
        form={form}
        visible={openAddModal}
        glCodes={fetchedGlCodesData}
        handleAddGroup={() => setOpenAddModal(false)}
        handleCancel={() => setOpenAddModal(false)}
      />
      <ConfirmationModal visible={openDeleteModal} handleDelete={handleDeleteGroup} />
      <Typography.Title
        level={4}
        style={{
          color: token.colorText,
          fontSize: token.fontSizeLG,
          fontWeight: token.fontWeightStrong
        }}>
        GL Groups
      </Typography.Title>
      <Col style={{ width: isScreenXS ? "100%" : "25%", marginBottom: token.marginLG }}>
        <CommonAppButton
          type="primary"
          block
          style={{ height: 40 }}
          onClick={handleAddGroupButton}
          disabled={loading}>
          Add GL Group
        </CommonAppButton>
      </Col>
      <Table
        style={{
          maxWidth: 800,
          width: "100%",
          overflow: "scroll",
          background: token.colorBgBase
        }}
        scroll={{ y: 350, x: 500 }}
        columns={getColumns(token, handleEditGroupButton, handleDeleteGroupButton)}
        dataSource={glCodeGroupFetch}
        pagination={false}
        loading={deleteIsPending || glCodeGroupLoading}
      />
    </Col>
  );
};

export default GlGroup;
