import { useAntToken } from "@hooks/common/useAntToken";
import { FC } from "react";
import { Col, Row, Typography, Flex } from "antd";

interface IInvoiceInfoHeaderProps {
  propertyName: string;
  address: string;
  issueDate: string;
  propertyAddress: string;
  vendorName: string;
}

const InvoiceInfoHeader: FC<IInvoiceInfoHeaderProps> = (props: IInvoiceInfoHeaderProps) => {
  const { address, issueDate, propertyName, propertyAddress, vendorName } = props;
  const { token } = useAntToken();
  const pdfFontFamily = "sans-serif";
  return (
    <Flex
      justify={"space-between"}
      style={{
        background: token.colorPrimary,
        padding: token.paddingMD,
        paddingTop: token.paddingSM,
        paddingBottom: token.paddingSM,
        height: "auto"
      }}
      align={"middle"}>
      <Col>
        <Row>
          <Col>
            <Typography.Text
              style={{
                fontSize: "8px",
                color: token.colorTextLightSolid,
                fontFamily: pdfFontFamily
              }}>
              Property
            </Typography.Text>
            <Typography.Title
              level={4}
              style={{
                fontSize: "10px",
                fontWeight: token.fontWeightStrong,
                color: token.colorTextLightSolid,
                marginTop: 0,
                wordSpacing: "-2px",
                fontFamily: pdfFontFamily
              }}>
              {propertyName}
            </Typography.Title>
          </Col>
        </Row>
        <Row>
          <Col>
            <Typography.Text
              style={{
                fontSize: "8px",
                color: token.colorTextLightSolid,
                fontFamily: pdfFontFamily
              }}>
              Property Address
            </Typography.Text>
            <Typography.Title
              level={4}
              style={{
                fontSize: "10px",
                fontWeight: token.fontWeightStrong,
                color: token.colorTextLightSolid,
                marginTop: 0,
                wordSpacing: "-1px",
                fontFamily: pdfFontFamily
              }}>
              {propertyAddress}
            </Typography.Title>
          </Col>
        </Row>
        <Flex vertical>
          <Col>
            <Typography.Text
              style={{
                fontSize: "8px",
                color: token.colorTextLightSolid,
                wordSpacing: "-1px",
                fontFamily: pdfFontFamily
              }}>
              Issue Date
            </Typography.Text>
          </Col>
          <Col>
            <Typography.Title
              level={4}
              style={{
                fontSize: "10px",
                fontWeight: token.fontWeightStrong,
                color: token.colorTextLightSolid,
                marginTop: 0,
                wordSpacing: "-1px",
                fontFamily: pdfFontFamily
              }}>
              {issueDate}
            </Typography.Title>
          </Col>
        </Flex>
      </Col>
      <Col>
        <Flex vertical align={"end"}>
          <Col>
            <Typography.Text
              style={{
                fontSize: "8px",
                color: token.colorTextLightSolid,
                fontFamily: pdfFontFamily
              }}>
              Vendor Name
            </Typography.Text>
          </Col>
          <Col>
            <Typography.Title
              level={4}
              style={{
                fontSize: "10px",
                fontWeight: token.fontWeightStrong,
                color: token.colorTextLightSolid,
                marginTop: 0,
                wordSpacing: "-1px",
                fontFamily: pdfFontFamily
              }}>
              {vendorName}
            </Typography.Title>
          </Col>
        </Flex>
        <Flex vertical align={"end"}>
          <Col>
            <Typography.Text
              style={{
                fontSize: "8px",
                color: token.colorTextLightSolid,
                fontFamily: pdfFontFamily
              }}>
              Address
            </Typography.Text>
          </Col>
          <Col style={{ direction: "rtl" }}>
            <Typography.Title
              level={4}
              style={{
                fontSize: "10px",
                fontWeight: token.fontWeightStrong,
                color: token.colorTextLightSolid,
                marginTop: 0,
                wordSpacing: "-1px",
                fontFamily: pdfFontFamily
              }}>
              {address}
            </Typography.Title>
          </Col>
        </Flex>
      </Col>
    </Flex>
  );
};

export default InvoiceInfoHeader;
