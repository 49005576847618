import { useQuery } from "@tanstack/react-query";
import { fetchUserLogs } from "@Services/UserService";
import { React_Query_Keys } from "@utils/constants";
import useAdminLogsSearchParams, { ILogsFilterObject } from "./useAdminLogsSearchParams";

export const useAdminFetchUserLogs = () => {
  const { pageNumber, pageSize, search } = useAdminLogsSearchParams();
  const filters: ILogsFilterObject = {
    pageNumber,
    pageSize,
    search
  };
  return useQuery({
    queryKey: [React_Query_Keys.FETCH_USER_LOGS, filters],
    queryFn: () => fetchUserLogs(filters),
    refetchInterval: 10000
  });
};
