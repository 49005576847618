import { List, Row, Col, Typography, Dropdown } from "antd";
import { FC } from "react";
import { useAntToken } from "@hooks/common/useAntToken";
import InvitationStatusTag from "./InvitationStatusTag";
import ManagerTypeTag from "./ManagerTypeTag";
import PermissionTags from "./PermissionTags";
import { MoreOutlined } from "@ant-design/icons";
import { TAdminUser } from "./types";
import { getMoreOptionsItems } from "./utils";
import CommonListDivider from "@Component/Common/CommonListDivider";
import VendorsActiveTag from "./VendorsActiveTag";
import { TAdminUserRole } from "@Component/Admin/types";
interface IDesktopListItemsProps {
  item: TAdminUser;
  itemKey: number;
  userType: string;
  handleShowDeleteModal: (item: TAdminUser) => void;
  handleOpenInviteVendorModal: () => void;
  handleUserChange: (user: TAdminUser) => void;
  handleReInviteUser: (user: TAdminUser, role: TAdminUserRole) => void;
}
const DesktopListItems: FC<IDesktopListItemsProps> = (props) => {
  const {
    item,
    userType,
    handleShowDeleteModal,
    handleOpenInviteVendorModal,
    handleUserChange,
    handleReInviteUser
  } = props;

  const { token } = useAntToken();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const fontWeightMedium = token.fontWeightMedium;

  const commonColumnStyles = {
    padding: token.paddingXS,
    paddingLeft: token.paddingXS
  };

  const commonTypographyStyles = {
    fontWeight: fontWeightMedium
  };

  const isVendor = userType === "Vendor";
  return (
    <>
      <List.Item
        style={{
          padding: token.padding,
          outline: item.isDeleted ? `2px solid ${token.colorErrorBorder}` : "none",
          outlineOffset: item.isDeleted ? -2 : 0
        }}>
        <Col span={22}>
          <Row justify={"start"}>
            <Col
              sm={4}
              xs={8}
              md={4}
              style={{ ...commonColumnStyles, paddingLeft: 0, marginLeft: 0 }}>
              <Typography.Text style={commonTypographyStyles}>{item.name}</Typography.Text>
            </Col>
            <Col sm={6} xs={6} md={6} style={{ ...commonColumnStyles }}>
              <Typography.Text style={commonTypographyStyles}>{item.email}</Typography.Text>
              {item.secondaryEmail && (
                <>
                  <br />
                  <Typography.Text
                    style={{ ...commonTypographyStyles, fontWeight: token.fontWeightStrong }}>
                    Secondary Email:
                  </Typography.Text>
                  <br />
                  <Typography.Text style={commonTypographyStyles}>
                    {item.secondaryEmail}
                  </Typography.Text>
                </>
              )}
            </Col>
            <Col
              sm={4}
              xs={4}
              md={4}
              style={{ ...commonColumnStyles, paddingLeft: token.paddingLG }}>
              <InvitationStatusTag status={item.invitationStatus} />
            </Col>

            <Col
              sm={6}
              xs={6}
              md={6}
              style={{ ...commonColumnStyles, paddingLeft: token.paddingLG }}>
              <PermissionTags tags={item.permissionTags} maxTagCount={2} />
            </Col>
            {isVendor && (
              <Col
                sm={4}
                xs={4}
                md={3}
                style={{ ...commonColumnStyles, paddingLeft: token.paddingLG }}>
                <VendorsActiveTag status={item.isActive ? "active" : "in-active"} />
              </Col>
            )}
            {!isVendor && (
              <Col
                sm={4}
                xs={4}
                md={3}
                style={{ ...commonColumnStyles, paddingLeft: token.paddingLG }}>
                <ManagerTypeTag isRegional={item.isRegionalManager} />
              </Col>
            )}
          </Row>
        </Col>
        <Col
          style={{
            // marginLeft: 8,
            // marginRight: token.marginLG,
            marginTop: 6,
            cursor: "pointer",
            width: "28px",
            height: "28px",
            border: `1px solid ${token.colorBorder}`,
            boxShadow: token.boxShadow,
            borderRadius: token.borderRadiusSM,
            backgroundColor: "#fff",
            alignSelf: "flex-start",
            justifyContent: "center"
          }}>
          <Row justify="center">
            <Col>
              <Dropdown
                menu={{
                  items: getMoreOptionsItems(
                    item,
                    userType,
                    handleShowDeleteModal,
                    handleOpenInviteVendorModal,
                    handleUserChange,
                    handleReInviteUser
                  )
                }}
                trigger={["click", "contextMenu"]}
                placement="bottomRight">
                <a
                  onClick={(e) => e.preventDefault()}
                  style={{
                    fontSize: token.fontSizeLG,
                    fontWeight: token.fontWeightStrong,
                    color: token.colorText
                  }}>
                  <MoreOutlined />
                </a>
              </Dropdown>
            </Col>
          </Row>
        </Col>
      </List.Item>
      <CommonListDivider />
    </>
  );
};

export default DesktopListItems;
