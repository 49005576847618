import { createStyles } from "antd-style";

const pdfFontFamily = "sans-serif";
export const useAdminSettingStyles = createStyles(({ token, css }) => ({
  "ant-pagination-over-ride": {
    ".ant-pagination-item-active": css`
      background-color: transparent !important;
    `
  },
  "delete-modal": {
    ".ant-modal-content": css`
      padding: 20px 0px !important;
    `
  },
  "add-modal": {
    ".ant-modal-content": css`
      padding: 20px 20px !important;
    `
  },
  "ant-input-over-ride": {
    ".ant-input": css`
      background-color: transparent !important;
      font-size: ${token.fontSizeSM};
    `,
    ".ant-input::focus": css`
      outline-style: none;
      box-shadow: none;
      border-color: transparent;
    `
  },
  "ant-table-overider": {
    ".ant-table": css`
      background: ${token.colorPrimaryBg} !important;
      border-radius: ${token.borderRadius}px;
      font-size: 6px;
      font-family: ${pdfFontFamily} !important;
    `,
    ".ant-table-cell": css`
      background: ${token.colorPrimaryBg} !important;
      border: 0px !important;
      padding: ${token.paddingXS}px ${token.padding}px !important;
    `,
    "table>tbody>tr:last-child >*:last-child": css`
      border-bottom-right-radius: ${token.borderRadius}px !important;
    `,
    "table>tbody>tr:last-child >*:first-child": css`
      border-bottom-left-radius: ${token.borderRadius}px !important;
    `,
    ".ant-table-cell::before": css`
      background-color: transparent !important;
    `
  }
}));
