import { Col, Row, Typography, theme, Select, Upload, Form } from "antd";
import { ExclamationCircleOutlined, InboxOutlined } from "@ant-design/icons";
import { FC, useState } from "react";
import CommonAppButton from "@Component/Common/CommonAppButton";
import { uploadInvoiceStyles } from "./styles";
const { Dragger } = Upload;

const { useToken } = theme;

interface Props {
  handleChange: (value: string) => void;
  fileChange: (info: any) => void;
  uploadFile: () => void;
  loading: boolean;
  properties: Array<{ id: number; name: string }>;
  vendorNotActiveErrorMessage?: string;
  isActive?: boolean;
}

const UploadInvoice: FC<Props> = (props) => {
  const { token } = useToken();
  const [propertyLoading, setPropertyLoading] = useState(false);
  const {
    handleChange,
    fileChange,
    uploadFile,
    properties,
    loading,
    vendorNotActiveErrorMessage,
    isActive
  } = props;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const fontWeightNormal = token.fontWeightNormal;
  const { styles } = uploadInvoiceStyles();

  if (!isActive) {
    return (
      <Row
        style={{
          backgroundColor: token.colorBgBase,
          maxWidth: "452px",
          margin: "auto",
          marginTop: token.marginXXL,
          padding: token.paddingXL,
          paddingTop: token.paddingSM,
          border: `2px solid ${token.colorError}`
        }}>
        <Col span={24}>
          <Typography.Paragraph
            style={{
              fontWeight: fontWeightNormal,
              fontSize: token.fontSizeHeading1,
              color: token.colorError,
              marginBottom: 0,
              textAlign: "center"
            }}>
            <ExclamationCircleOutlined />
          </Typography.Paragraph>
          <Typography.Paragraph
            style={{
              fontWeight: fontWeightNormal,
              fontSize: token.fontSizeXL,
              color: token.colorError,
              padding: token.padding,
              textAlign: "center"
            }}>
            {vendorNotActiveErrorMessage}
          </Typography.Paragraph>
        </Col>
      </Row>
    );
  }
  return (
    <Row
      style={{
        backgroundColor: token.colorBgBase,
        maxWidth: "452px",
        margin: "auto",
        marginTop: token.marginXXL,
        marginBottom: token.marginXXL,
        padding: token.paddingXL,
        paddingTop: token.paddingSM
      }}>
      <Col span={24}>
        <Row justify="start">
          <Col>
            <Typography.Title
              level={2}
              style={{
                color: token.colorText,
                fontSize: token.fontSizeHeading3,
                fontWeight: token.fontWeightStrong
              }}>
              Add Invoice
            </Typography.Title>
          </Col>
        </Row>
        <Row justify="start">
          <Col span={24}>
            <Form layout="vertical">
              <Form.Item
                label={
                  <label
                    style={{ color: token.colorText, fontSize: token.fontSize, fontWeight: 600 }}>
                    Property
                  </label>
                }>
                <Select
                  bordered={false}
                  placeholder="Select Property"
                  showSearch={true}
                  size="large"
                  loading={!propertyLoading && loading}
                  style={{
                    width: "100%",
                    fontSize: token.fontSize,
                    border: `1px solid ${token.colorBorder}`,
                    borderRadius: token.borderRadiusSM
                  }}
                  className={styles["ant-select-override"]}
                  onChange={handleChange}
                  filterOption={(input, option) => {
                    if (input.replace(/\s/g, "").length > 0) {
                      return String(option?.label)
                        ?.toLowerCase()
                        .includes(input.toLowerCase());
                    }
                    return true;
                  }}
                  options={[...properties].map((p: { id: number; name: string }) => {
                    return { value: `${p.id}`, label: p.name };
                  })}
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
        <Row justify="center" style={{ width: "100%" }}>
          <Col span={24}>
            <Row justify="center">
              <Col span={24} className={styles["ant-upload-override"]}>
                <Dragger
                  disabled={loading}
                  maxCount={1}
                  style={{
                    backgroundColor: token.colorBgBase,
                    borderColor: token.colorBorder,
                    borderRadius: 0
                  }}
                  onChange={fileChange}
                  accept=".pdf"
                  multiple={false}
                  beforeUpload={() => false}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <Typography.Paragraph
                    style={{
                      fontSize: token.fontSizeLG,
                      marginBottom: token.marginXXS,
                      fontWeight: token.fontWeightStrong
                    }}>
                    Drag or drop your files here
                  </Typography.Paragraph>
                  <Typography.Paragraph
                    style={{
                      color: token.colorText,
                      fontSize: token.fontSizeSM,
                      fontWeight: fontWeightNormal,
                      marginBottom: token.marginXXS
                    }}>
                    (PDF Max 10MB each)
                  </Typography.Paragraph>
                  <Typography.Paragraph
                    style={{
                      color: token.colorText,
                      fontSize: token.fontSizeLG,
                      fontWeight: fontWeightNormal,
                      marginBottom: token.marginXXS
                    }}>
                    Or
                  </Typography.Paragraph>
                  <CommonAppButton
                    type="primary"
                    disabled={loading}
                    style={{
                      backgroundColor: token.colorBgElevated,
                      borderRadius: 0,
                      color: token.colorText,
                      fontSize: token.fontSizeSM,
                      boxShadow: token.boxShadow,
                      borderColor: token.colorBorder,
                      fontWeight: fontWeightNormal
                    }}
                    text="Browse Files"
                  />
                </Dragger>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify="center" style={{ marginTop: token.margin, width: "100%" }}>
          <Col span={24}>
            <Row justify="center">
              <Col
                onClick={() => {
                  setPropertyLoading(true);
                  uploadFile();
                }}
                span={24}>
                <CommonAppButton
                  type="primary"
                  loading={loading}
                  block={true}
                  style={{
                    backgroundColor: token.colorPrimary,
                    borderRadius: 0,
                    color: token.colorTextLightSolid,
                    fontSize: token.fontSizeLG,
                    boxShadow: token.boxShadow,
                    borderColor: token.colorBorder,
                    fontWeight: fontWeightNormal,
                    height: token.controlHeightLG
                  }}
                  text="Add Invoice"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default UploadInvoice;
