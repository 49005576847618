import { useState } from "react";
import NewPassword from "../../Component/NewPassword";
import { resetPassword } from "../../Services/PasswordService";
import ShowToast from "../../utils/showToast";
import { isAxiosError } from "axios";

interface INewPassword {
  email: string;
}

const NewPasswordPage: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const onFinish = async (values: INewPassword) => {
    try {
      setLoading(true);
      await resetPassword(values);
      setLoading(false);
      ShowToast("Please check your email", "success");
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        ShowToast(error.response?.data.message, "error");
      }
      setLoading(false);
    }
  };
  return <NewPassword loading={loading} onFinish={onFinish} />;
};
export default NewPasswordPage;
