import { AnyAction } from "redux";
import {
  REQUEST_USER_LOGIN,
  USER_LOGGED_IN,
  LOGOUT_USER,
  SET_USER_ROLE
} from "../../types/Auth/Auth";

export interface IAuthState {
  loginInfo: {
    token: string | null;
    authId: string | null;
    role: string | null;
    showPaymentStatus?: boolean;
    showLegalForms?: boolean;
    editGLCodes?: boolean;
    name: string;
    threshold?: string;
    isActive?: boolean;
    errorMessage?: string;
    isRegionalManager?: boolean;
    email?: string;
    vendorErrorResponse?: string;
  };
  token: object | null;
  isLoading: boolean;
  hasError: boolean;
  errorMessage: object | null;
  role: string;
}

const initialState: IAuthState = {
  loginInfo: {
    token: null,
    authId: null,
    role: null,
    name: "",
    vendorErrorResponse: ""
  },
  token: null,
  isLoading: false,
  hasError: false,
  errorMessage: null,

  role: ""
};

const auth = (state = initialState, action: AnyAction) => {
  if (typeof state === "undefined") {
    return initialState;
  }

  switch (action.type) {
    case REQUEST_USER_LOGIN:
      return {
        ...state,
        isLoading: true
      };
    case USER_LOGGED_IN:
      const { data } = action.payload; // eslint-disable-line

      return {
        ...state,
        loginInfo: data,
        token: data.token,
        isLoading: false,
        hasError: false,
        errorMessage: null
      };
    case SET_USER_ROLE:
      return {
        ...state,
        role: action.payload
      };
    case LOGOUT_USER:
      return initialState;
    default:
      return state;
  }
};

export default auth;
