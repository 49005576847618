import { Modal, Row, Col, Divider, Form, Input } from "antd";
import { useForm } from "antd/es/form/Form";
import { useAntToken } from "@hooks/common/useAntToken";
import { useAdminSettingStyles } from "@Component/Admin/AdminSettings/styles";
import CommonFormLabel from "@Component/Common/CommonFormLabel";
import SelectGLCodes from "@Component/Common/GLCodes/SelectGLCodes";
import { useGlCodeGroupCreate } from "@hooks/Admin/GlGroup";
import showToast from "@utils/showToast";
import ModalFooter from "@Component/Admin/AdminSettings/ModalFooter";

const AddGlGroupModal = (props: any) => {
  const [form] = useForm();
  const { visible, glCodes, handleAddGroup, handleCancel } = props;
  const { mutate: createGlGroup, isPending } = useGlCodeGroupCreate();
  const { styles } = useAdminSettingStyles();
  const { token } = useAntToken();

  const handleCreateGroup = () => {
    const create: any = form.getFieldsValue();
    const selectedGlCodeIds = create.assignedGLCodes.map((glCode: any) => glCode.id);
    if (selectedGlCodeIds.length < 2) {
      showToast("Gl codes length must be 2", "error");
      return;
    }
    const selectedGlCode = glCodes?.data?.glCodes.filter((glCode: any) =>
      selectedGlCodeIds.includes(glCode.id)
    );
    createGlGroup({ name: create.name, glCodes: selectedGlCode });
    form.resetFields();
    handleAddGroup();
  };

  return (
    <Modal
      open={visible}
      title={"Create GL Code Group"}
      footer={false}
      onCancel={handleCancel}
      className={styles["add-modal"]}>
      <Row justify="center">
        <Col style={{ marginTop: token.marginLG, width: "100%" }}>
          <Form form={form} onFinish={handleCreateGroup} layout="vertical">
            <Form.Item
              name="name"
              rules={[{ required: true, message: "Please Input group name" }]}
              label={
                <CommonFormLabel
                  style={{
                    color: token.colorText,
                    fontSize: token.fontSize,
                    fontWeight: token.fontWeightStrong
                  }}>
                  GL Group Name
                </CommonFormLabel>
              }>
              <Input
                placeholder="GL Group Name"
                style={{
                  height: token.controlHeightLG,
                  backgroundColor: token.colorBgBase,
                  borderRadius: token.borderRadiusSM,
                  border: `1px solid ${token.colorBorder}`
                }}
              />
            </Form.Item>
            <SelectGLCodes
              fetchedGlCodes={glCodes?.data?.glCodes}
              glCodesLoading={!glCodes}
              glCodes={glCodes?.data?.glCodes}
              form={form}
            />
            <Divider />
            <ModalFooter isPending={isPending} handleCancel={handleCancel} />
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};

export default AddGlGroupModal;
