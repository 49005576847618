import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ConfigProvider } from "antd";
import { setupWorker } from "msw";
import lightTheme from "./ant-tokens/light.json";
import { VendorRoute } from "./routes/VendorRoute";
import { GuestRoute } from "./routes/GuestRoute";
import VendorDashboardPage from "./Pages/Vendor/VendorDashboardPage";
import SignInPage from "./Pages/SignInPage";
import { AdminRoute } from "./routes/AdminRoute";
import AdminDashboardPage from "./Pages/Admin/AdminDashboardPage";
import NewPasswordPage from "./Pages/NewPasswordPage";
import UpdatePasswordPage from "./Pages/UpdatePasswordPage";
import handlers from "../src/mock-server/handlers";
import AdminInviteVendorPage from "./Pages/Admin/AdminInviteVendorPage";
import UnauthorizedPage from "./Pages/UnauthorizedPage";
import VendorReviewInvoicePage from "./Pages/Vendor/VendorReviewInvoicePage";
import UploadInvoicePage from "./Pages/Vendor/UploadInvoicePage";
import VendorViewInvoicePage from "./Pages/Vendor/VendorViewInvoicePage";
import AdminInviteBusinessUserPage from "./Pages/Admin/AdminInviteBusinessUserPage";
import UserDashboardPage from "./Pages/User/UserDashboardPage";
import { UserRoute } from "./routes/UserRoute";
import UserViewInvoicePage from "./Pages/User/UserViewInvoicePage";
import { ROUTE_PATHS } from "./routes/constants";
import AdminViewUsersPage from "./Pages/Admin/AdminViewUsersPage";
import UserLogsPage from "./Pages/Admin/UserLogsPage";
import VendorViewRejectedInvoicePage from "./Pages/Vendor/VendorViewRejectedInvoicePage";
import AdminReinviteUsersPage from "./Pages/Admin/AdminReinviteUsersPage";
import AdminSettingsPage from "@Pages/Admin/AdminSettingsPage";
import UserViewHomeDepotInvoicePage from "@Pages/User/UserViewHomeDepotInvoicePage";

const App = () => {
  if (import.meta.env.VITE_MOCK_API === "true") {
    const worker = setupWorker(...handlers);
    worker.start({ onUnhandledRequest: "bypass" });
  }

  return (
    <ConfigProvider
      theme={{
        token: lightTheme,
        components: {
          Checkbox: {
            colorPrimary: lightTheme.colorPrimary
          },
          Input: {
            colorBgContainer: lightTheme.colorBgLayout
          }
        }
      }}>
      <BrowserRouter>
        <Routes>
          <Route
            path={ROUTE_PATHS.AUTH_LOGIN}
            element={
              <GuestRoute>
                <SignInPage />
              </GuestRoute>
            }
          />
          <Route
            path={ROUTE_PATHS.UNAUTHORIZED}
            element={
              <GuestRoute>
                <UnauthorizedPage />
              </GuestRoute>
            }
          />
          <Route
            path={ROUTE_PATHS.NEW_PASSWORD}
            element={
              <GuestRoute>
                <NewPasswordPage />
              </GuestRoute>
            }
          />
          <Route
            path={ROUTE_PATHS.UPDATE_PASSWORD}
            element={
              <GuestRoute>
                <UpdatePasswordPage />
              </GuestRoute>
            }
          />
          <Route
            path={ROUTE_PATHS.HOME}
            element={
              <UserRoute>
                <UserDashboardPage />
              </UserRoute>
            }
          />
          <Route
            path={ROUTE_PATHS.USER_VIEW_INVOICE}
            element={
              <UserRoute>
                <UserViewInvoicePage />
              </UserRoute>
            }
          />
          <Route
            path={ROUTE_PATHS.USER_VIEW_HOME_DEPOT_INVOICE}
            element={
              <UserRoute>
                <UserViewHomeDepotInvoicePage />
              </UserRoute>
            }
          />
          <Route
            path={ROUTE_PATHS.VENDOR}
            element={
              <VendorRoute>
                <VendorDashboardPage />
              </VendorRoute>
            }
          />
          <Route
            path={ROUTE_PATHS.VENDOR_UPLOAD_INVOICE}
            element={
              <VendorRoute>
                <UploadInvoicePage />
              </VendorRoute>
            }
          />
          <Route
            path={ROUTE_PATHS.VENDOR_REVIEW_INVOICE}
            element={
              <VendorRoute>
                <VendorReviewInvoicePage />
              </VendorRoute>
            }
          />
          <Route
            path={ROUTE_PATHS.VENDOR_VIEW_INVOICE_PAGE}
            element={
              <VendorRoute>
                <VendorViewInvoicePage />
              </VendorRoute>
            }
          />
          <Route
            path={ROUTE_PATHS.VENDOR_VIEW_REJECTED_INVOICE}
            element={
              <VendorRoute>
                <VendorViewRejectedInvoicePage />
              </VendorRoute>
            }
          />
          <Route
            path={ROUTE_PATHS.ADMIN_VIEW_INVOICE}
            element={
              <AdminRoute>
                <VendorViewInvoicePage />
              </AdminRoute>
            }
          />
          <Route
            path={ROUTE_PATHS.ADMIN}
            element={
              <AdminRoute>
                <AdminDashboardPage />
              </AdminRoute>
            }>
            <Route path={ROUTE_PATHS.ADMIN_VIEW_USERS} element={<AdminViewUsersPage />} />
          </Route>

          <Route
            path={ROUTE_PATHS.ADMIN_INVITE_VENDOR}
            element={
              <AdminRoute>
                <AdminInviteVendorPage />
              </AdminRoute>
            }
          />
          <Route
            path={ROUTE_PATHS.ADMIN_INVITE_BUSINESS_USER}
            element={
              <AdminRoute>
                <AdminInviteBusinessUserPage />
              </AdminRoute>
            }
          />
          <Route
            path={ROUTE_PATHS.ADMIN_RE_INVITE_WITH_ROLE_PARAM}
            element={
              <AdminRoute>
                <AdminReinviteUsersPage />
              </AdminRoute>
            }
          />

          <Route
            path={ROUTE_PATHS.ADMIN_VIEW_USER_LOGS}
            element={
              <AdminRoute>
                <UserLogsPage />
              </AdminRoute>
            }
          />
          <Route
            path={ROUTE_PATHS.ADMIN_SETTINGS}
            element={
              <AdminRoute>
                <AdminSettingsPage />
              </AdminRoute>
            }
          />
        </Routes>
      </BrowserRouter>
    </ConfigProvider>
  );
};

export default App;
