import { fetchAllInvoiceGlCodes } from "@Services/InvoiceService";
import { useQuery } from "@tanstack/react-query";
import { React_Query_Keys } from "@utils/constants";

export const useFetchGlCodes = () => {
  return useQuery({
    queryKey: [React_Query_Keys.FETCH_GL_CODES],
    queryFn: fetchAllInvoiceGlCodes
  });
};
